<template>
    <v-slide-x-reverse-transition group hide-on-leave>
        <v-list-item
            v-if="!isEditing && !isNew"
            :dense="dense"
            key="1"
            :class="fieldClass">
            <v-list-item-content>
                <v-list-item-subtitle>{{ label }}</v-list-item-subtitle>
                <v-list-item-title>{{ displayOptions[toggle].value }}</v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-else
            :dense="dense"
            key="2"
            :class="fieldClass">
            <v-list-item-content>
                <v-list-item-subtitle>{{ label }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                <v-btn-toggle
                    v-model="toggle"
                    :dense="dense"
                    color="primary lighten-4"
                    mandatory
                    rounded
                    @change="update">
                    <v-btn
                        v-for="(opt, index) in displayOptions"
                        :key="index"
                        :icon="useIcons"
                        :title="opt.text"
                        small
                        :disabled="disabled">
                        <v-icon v-if="useIcons">{{ opt.icon }}</v-icon>
                        <span v-else>{{ opt.text }}</span>
                    </v-btn>
                </v-btn-toggle>
                <slot name="action" />
                <v-btn
                    v-if="fieldAction != null"
                    :disabled="disabled"
                    icon
                    small
                    :title="actionTitle"
                    @click="fieldAction">
                    <v-icon>{{ actionIcon }}</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-slide-x-reverse-transition>
</template>

<script>
export default {
    name: 'BT-Field-Toggle-Trigger',
    data: function() {
        return {
            toggle: 0
        }
    },
    props: {
        actionIcon: {
            type: String,
            default: null
        },
        actionTitle: {
            type: String,
            default: null
        },
        value: {
            Type: String,
            default: null
        },
        label: {
            Type: String,
            default: null
        },
        toggleOptions: {
            Type: Array,
            default: () => { return [{ text: 'Manual', value: 'Manual', icon: 'mdi-power-plug-off' }, { text: 'Auto', value: 'Auto', icon: 'mdi-power-plug' }] }
        },
        useIcons: {
            type: Boolean,
            default: false
        },
        backgroundColor: {
            type: String,
            default: 'primary darken-2'
        },
        rightAlign: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        isScheduled: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: true
        },
        fieldAction: {
            type: Function,
            default: null
        },
        fieldClass: {
            type: String,
            default: null
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        showAction: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        value: function(val) {
            this.str = val;
        }
    },
    computed: {
        displayOptions() {
            if (!this.isScheduled) {
                return this.toggleOptions
            }

            return [{ text: 'Manual', value: 'Manual', icon: 'mdi-power-plug-off' }, { text: 'Auto', value: 'Auto', icon: 'mdi-power-plug' }, { text: 'Scheduled', value: 'Scheduled', icon: 'mdi-power-plug-off' }]
        }
    },
    created() {
        if (this.value) {           
            var ind = this.displayOptions.findIndex(x => x.value === this.value);            
            if (ind >= 0) {
                this.toggle = ind;
            }
        }
    },
    methods: {
        update() {                    
            this.$emit('input', this.displayOptions[this.toggle].value);
            this.$emit('change', this.displayOptions[this.toggle].value);
        }
    }
}
</script>