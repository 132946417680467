<template>
    <v-slide-x-reverse-transition group hide-on-leave>
        <v-list-item 
            v-if="!isEditing && !isNew" 
            :dense="dense" 
            key="1"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>
                <v-list-item-title>
                    <v-chip-group small>
                        <v-chip
                            v-for="(item, index) in selection"
                            pill
                            small
                            :key="index">
                            {{ item }}
                        </v-chip>
                    </v-chip-group>
                </v-list-item-title>
            </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-else
            :dense="dense"
            key="2"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-combobox
                    v-model="selection"
                    :label="label"
                    :items="csvValues"
                    multiple
                    :disabled="disabled"
                    small-chips
                    hide-details
                    @change="update"
                    outlined/>
            </v-list-item-content>
        </v-list-item>
    </v-slide-x-reverse-transition>
</template>

<script>
export default {
    name: 'BT-Field-Tags',
    data: function() {
        return {
            csvValues: [],
            selection: [],
        }
    },
    props: {
        value: null,
        dense: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fieldClass: {
            type: String,
            default: null
        },
        icon: null,
        isEditing: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        label: null,
        options: {
            type: Array,
            default: null
        }
    },
    mounted() {
        var r = [];
        if (this.value) {
            r = this.value.split(',');
            this.selection = r;
            this.csvValues = r;
        }
        // if (this.options) {
        // }
    },
    methods: {
        update() {
            this.$emit('input', this.selection.toString());
        }
    }
}
</script>