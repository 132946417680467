<template>
    <v-list-item dense>
        <v-list-item-avatar :rounded="rounded" :size="size">
            <v-img :src="companyLogoURL(id)" class="mx-auto">
                <template v-slot:placeholder>
                    <v-icon class="primary--text">{{ icon }}</v-icon>
                </template>
            </v-img>
        </v-list-item-avatar>
        <v-list-item-content>
            <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>
            <v-list-item-title>
                <span v-if="title != null">{{ title }}</span>
                <BT-Entity
                    v-else-if="cID != null"
                    :itemValue="cID"
                    navigation="public-companies"
                    single
                    useLocalCache>
                    <template v-slot="{ item }">
                        {{ item.companyName }}
                    </template>
                </BT-Entity>
                <BT-Btn
                    v-if="toBladeName != null"
                    :bladeName="toBladeName"
                    buttonClass="primary--text"
                    icon="mdi-open-in-new"
                    :id="cID"
                    inline
                    small
                    title="Open in new tab." />
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="showAction">
            <slot name="action" />
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'BT-Field-Company',
    props: {
        icon: {
            type: String,
            default: 'mdi-account'
        },
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        rounded: {
            type: Boolean,
            default: false
        },
        showAction: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: '40'
        },
        title: {
            type: String,
            default: null
        },
        toBladeName: {
            type: String,
            default: null
        }
    },
    computed: {
        cID() {
            return this.id;
        }
    }
}
</script>