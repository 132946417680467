<template>
  <v-dialog 
    v-model="shown"
    @click:outside="cancel"
    :max-width="325"
    :persistent="required">
    <v-card>
      <v-responsive>
        <v-card-title v-if="label != null">{{ label }}</v-card-title>
        <v-card-text>
            <v-date-picker
                v-model="mDate.d"
                @change="accept"
                :allowed-dates="dateRules"
                locale="en-AU"
                :min="startDate"
                :type="type" />
        </v-card-text>
      </v-responsive>
      <v-overlay :value="loadingMsg != null" absolute class="text-center">
        <v-progress-circular indeterminate />
        <p>{{ loadingMsg }}</p>
    </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name : "SelectDateDialog",
        props : {
            message : Object,
            resolve : Function
        },
        data() {
            return {
                dateFrom: null,
                dateRules: null, //Function
                fromNow: true, //Boolean
                label: null,
                loadingMsg: null,
                mDate: { d: null },
                required: false,
                shown: false,
                startDate: null,
                textFilter: null,
                type: null,
                value: null
            }
        },
        methods : {
            accept(d) {
                if (d == false) {
                    d = this.mDate.d;
                }

                var res = this.$BlitzIt.auth.formTZtoUTC(d);
                
                if (this.requireTime && this.time == null) {
                    return;
                }
                else if (this.time != null && d != null) {
                    res = this.$BlitzIt.auth.formTZtoUTCAndTime(d, this.time);
                    console.log('resing');
                    console.log(res.toString());
                }

                if (!this.required || res != null) {
                    this.shown = false;
                    this.resolve(res);
                }
            },
            cancel() {
                this.shown = false;
                this.resolve(false);
            },
        },
        beforeMount() {
            this.dateFrom = this.message.dateFrom || null;
            this.dateRules = this.message.dateRules || null;
            this.fromNow = this.message.fromNow || null;
            this.label = this.message.label || 'Select';
            this.required = this.message.required;
            this.textFilter = this.message.textFilter || 'yyyy-LL-dd';
            this.value = this.message.originalValue || null;
            this.theme = this.message.theme || {};
            this.type = this.message.type || 'date';
            this.breakpoint = {};
        },
        mounted() {
            if (this.value != null) {
                this.mDate.d = this.$BlitzIt.auth.formTZ(this.value, 'yyyy-LL-dd'); //this.textFilter);
            }
            else {
                this.mDate.d = this.$BlitzIt.auth.createTZ('yyyy-LL-dd');
            }

            if (this.dateFrom == null && this.fromNow) {
                this.startDate = this.$BlitzIt.auth.createTZ('yyyy-LL-dd'); //.createUTC();
            }
            else {
                this.startDate = this.dateFrom != null ? this.$BlitzIt.auth.formTZ(this.dateFrom, 'yyyy-LL-dd') : null;
            }

            this.shown = true;
        }
    }
</script>

<style scoped>
  .vdp-message {
    white-space: pre-wrap;
  }
</style>