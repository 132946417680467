<template>
    <v-slide-x-reverse-transition group hide-on-leave>
        <v-list-item 
            v-if="!isEditing && !isNew && navigation != null"
            :dense="dense" 
            key="1"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>
                <v-list-item-title>
                    <BT-Entity
                        :navigation="navigation"
                        :ignorePermissions="ignorePermissions"
                        :itemValue="str"
                        :itemText="itemText"
                        :textFilter="textFilter"
                        :label="label"
                        :params="params"
                        :proxyID="proxyID"
                        :single="single"
                        :alternateText="alternateText" />
                </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="canSelect && str != null">
                <v-icon class="primary--text" @click.stop="select">{{ selectIcon }}</v-icon>
            </v-list-item-action>
        </v-list-item>
        <v-list-item
            v-else
            :dense="dense"
            key="2"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <BT-Select
                    :navigation="navigation"
                    v-model="str"
                    :hideDetails="hideDetails"
                    :disabled="!isEditing || disabled"
                    :itemText="itemText"
                    :textFilter="textFilter"
                    :items="items"
                    :itemValue="navigation != null ? 'id' : itemValue"
                    :label="label"
                    @input="update"
                    :onFilter="onFilter"
                    :params="params"
                    :proxyID="proxyID"
                    :returnIndex="returnIndex"
                    :suffix="suffix">
                    <template v-slot:item="{ item }">
                        <slot name="item" v-bind:item="item">{{ nestVal(item, itemText, textFilter) }}</slot>
                    </template>
                    <template v-slot:selection="{ item }">
                        <slot name="selection" v-bind:item="item">
                            <v-chip v-if="chips">
                                {{ nestVal(item, itemText, textFilter) }}
                            </v-chip>
                            <span v-else>
                                {{ nestVal(item, itemText, textFilter) }}
                            </span>
                        </slot>
                    </template>
                </BT-Select>
            </v-list-item-content>
            <v-list-item-action v-if="showAction">
                <slot name="action" v-bind:item="item" />
                <v-btn
                    v-if="fieldAction != null"
                    :disabled="disabled"
                    icon
                    small
                    :title="actionTitle"
                    @click="fieldAction">
                    <v-icon>{{ actionIcon }}</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-slide-x-reverse-transition>
</template>

<script>
export default {
    name: 'BT-Field-Select',
    data: function() {
        return {
            str: null
        }
    },
    props: {
        actionIcon: {
            type: String,
            default: null
        },
        actionTitle: {
            type: String,
            default: null
        },
        navigation: null,
        value: null,
        alternateText: {
            type: String,
            default: null
        },
        canSelect: {
            type: Boolean,
            default: false
        },
        chips: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fieldAction: {
            type: Function,
            default: null
        },
        fieldClass: {
            type: String,
            default: null
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        icon: null,
        ignorePermissions: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        label: null,
        onFilter: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        proxyID: {
            type: String,
            default: null
        },
        returnIndex: {
            type: Boolean,
            default: false
        },
        selectIcon: {
            type: String,
            default: 'mdi-open-in-new'
        },
        showAction: {
            type: Boolean,
            default: false
        },
        single: {
            type: Boolean,
            default: false
        },
        suffix: {
            type: String,
            default: null
        },
        textFilter: {
            type: String,
            default: null
        },
    },
    watch: {
        value: function(val) {
            console.log('comparing: ' + val + ' with ' + this.str);
            this.str = val;
        }
    },
    created() {
        this.str = this.value;
    },
    methods: {
        nestVal(item, path, filter) {
            var t = item;

            if (path != null) {
                t = this.getNestedValue(item, path);
            }
            
            if (filter != null) {
                return this.$options.filters[filter](t);
            }
            else {
                return t;
            }
        },
        select() {
            this.$emit('select');
        },
        update(val) {
            this.$emit('input', val);
            this.$emit('change', val);
        }
    }
}
</script>