import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [ 
    {
      path: '/authentication',
      name: 'authentication',
      component: () => import('~/views/Authentication.vue')
    },
    {
      path: '/error',
      name: 'error',
      component: () => import('~/views/Error.vue')
    },
    {
      path: '/loading',
      name: 'loading',
      component: () => import('~/views/Loading.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('~/views/Login.vue'),
      meta: {
        title: 'Login - BlitzIt Web',
        metaTags: [
          {
            name: 'description',
            content: 'Login to your BlitzIt Web account'
          },
          {
            property: 'og:description',
            content: 'Login to your BlitzIt Web account'
          },
          {
            property: 'og:image',
            content: 'https://blitzittechimages.blob.core.windows.net/blitzit/og-image.png'
          },
          {
            property: 'og:title',
            content: 'Login - BlitzIt Web'
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:url',
            content: 'https://www.blitzitweb.com.au/login'
          }
        ]
      }
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('~/views/Logout.vue')
    },
    {
      path: '/restricted',
      name: 'restricted',
      component: () => import('~/views/Restricted.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('~visit/Register.vue'),
      meta: {
        title: 'Register - BlitzIt Web',
        metaTags: [
          {
            name: 'description',
            content: 'Register to open an account with BlitzIt Web!'
          },
          {
            property: 'og:description',
            content: 'Register to open an account with BlitzIt Web!'
          },
          {
            property: 'og:image',
            content: 'https://blitzittechimages.blob.core.windows.net/blitzit/og-image.png'
          },
          {
            property: 'og:title',
            content: 'Register - BlitzIt Web'
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:url',
            content: 'https://www.blitzitweb.com.au/register'
          }
        ]
      }
    },
    {
      path: '/manage-account-data',
      name: 'manage-account-data',
      component: () => import('~visit/Delete-Account.vue')
    },
    {
      path: '/privacy-policy',
      name: 'privacy-policy',
      component: () => import('~visit/Privacy-Policy.vue'),
      meta: {
        title: 'Privacy Policy - BlitzIt Web',
        metaTags: [
          {
            name: 'description',
            content: 'We respect your privacy and we have developed a policy to protect it.'
          },
          {
            property: 'og:description',
            content: 'We respect your privacy and we have developed a policy to protect it.'
          },
          {
            property: 'og:image',
            content: 'https://blitzittechimages.blob.core.windows.net/blitzit/og-image.png'
          },
          {
            property: 'og:title',
            content: 'Privacy Policy - BlitzIt Web'
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:url',
            content: 'https://www.blitzitweb.com.au/privacy-policy'
          }
        ]
      }
    },
    {
      path: '/terms-and-conditions',
      name: 'terms-and-conditions',
      component: () => import('~visit/Terms-And-Conditions.vue'),
      meta: {
        title: 'Terms & Conditions - BlitzIt Web',
        metaTags: [
          {
            name: 'description',
            content: 'Here are the terms and conditions for using and accessing the website.'
          },
          {
            property: 'og:description',
            content: 'Here are the terms and conditions for using and accessing the website.'
          },
          {
            property: 'og:image',
            content: 'https://blitzittechimages.blob.core.windows.net/blitzit/og-image.png'
          },
          {
            property: 'og:title',
            content: 'Terms & Conditions - BlitzIt Web'
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:url',
            content: 'https://www.blitzitweb.com.au/terms-and-conditions'
          }
        ]
      }
    },
    {
      path: '*',
      name: 'nowhere',
      component: () => import('~visit/Nowhere.vue')
    },
    {
      path: '/',
      alias: '/home',
      name: 'home',
      component: () => import('~visit/Home-Visit.vue'),
      meta: {
        title: 'BlitzIt Web | Cloud-Based Wholesale Logistics Platform',
        metaTags: [
          {
            name: 'description',
            content: 'A cloud-based logistics platform with tools to manage sales, inventory, manufacturing, picking, and delivery.  Customers control their own ordering.'
          },
          {
            property: 'og:description',
            content: 'A cloud-based logistics platform with tools to manage sales, inventory, manufacturing, picking, and delivery.  Customers control their own ordering.'
          },
          {
            property: 'og:image',
            content: 'https://blitzittechimages.blob.core.windows.net/blitzit/og-image.png'
          },
          {
            property: 'og:title',
            content: 'BlitzIt Web | Cloud-Based Wholesale Logistics Platform'
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:url',
            content: 'https://www.blitzitweb.com.au/'
          }
        ]
      }
    },
    {
      path: '/demo',
      component: () => import('~home/portals/Demo-Portal.vue'),
      name: 'demo',
      meta: {
        title: 'Demo - BlitzIt Web',
        metaTags: [
          {
            name: 'description',
            content: 'Try it out.  Demo what it feels like to be a customer.  Test out the various tools.  Train your staff.'
          },
          {
            name: 'og:description',
            content: 'Try it out.  Demo what it feels like to be a customer.  Test out the various tools.  Train your staff.'
          },
          {
            property: 'og:image',
            content: 'https://blitzittechimages.blob.core.windows.net/blitzit/og-image.png'
          },
          {
            property: 'og:title',
            content: 'Demo - BlitzIt Web'
          },
          {
            property: 'og:type',
            content: 'website'
          },
          {
            property: 'og:url',
            content: 'https://www.blitzitweb.com.au/demo'
          }
        ]
      }
    },
    {
      path: '/board',
      component: () => import('~home/portals/Portal.vue'),
      children: [
        {
          path: 'customers',
          name: 'customers-board',
          component: () => import('~home/boards/Customer-Board.vue')
        },
        // {
        //   path: 'orders',
        //   name: 'orders-board',
        //   component: () => import('~home/boards/Order-Board.vue')
        // },
        {
          path: 'products',
          name: 'products-board',
          component: () => import('~home/boards/Product-Board.vue')
        },
        {
          path: 'order-slots',
          name: 'order-slots-board',
          component: () => import('~home/boards/Order-Slot-Board.vue')
        },
        // {
        //   path: 'order-slot-groups',
        //   name: 'order-slot-groups-board',
        //   component: () => import('~home/boards/Order-Slot-Group-Board.vue')
        // },
        {
          path: 'users',
          name: 'users-board',
          component: () => import('~home/boards/Users-Board.vue')
        }
      ]
    },
    {
      path: '/hub',
      component: () => import('~home/portals/Portal.vue'),
      children: [
        {
          path: 'delivery-review',
          name: 'delivery-review-hub',
          component: () => import('~home/hubs/Delivery-Review-Hub.vue')
        },
        {
          path: 'dispatch',
          name: 'dispatch-hub',
          component: () => import('~home/dispatch/Dispatches.vue')
        },
        {
          path: 'driving/:id?',
          name: 'driver-hub',
          component: () => import('~home/hubs/Driver-Hub.vue')
        },
        {
          path: 'template-optimization',
          name: 'template-optimization-hub',
          component: () => import('~home/journey-optimizer/Template-Optimization-Blade.vue')
        },
        {
          path: 'journey-optimization',
          name: 'journey-optimization-hub',
          component: () => import('~home/journey-optimizer/Journey-Optimization-Blade.vue')
        },
        {
          path: 'order-sheets',
          name: 'order-sheets-hub',
          component: () => import('~home/hubs/Order-Sheets-Hub.vue')
        },
        {
          path: 'packing',
          name: 'packing-hub',
          component: () => import('~home/hubs/Packing-Hub.vue')
        },
        {
          path: 'picking',
          name: 'picking-hub',
          component: () => import('~home/picking-slips/Picking-Slips-Blade.vue')
        },
        {
          path: 'production',
          name: 'production-hub',
          component: () => import('~home/hubs/Production-Hub.vue'),
        },
        {
          path: 'sales',
          name: 'sales-hub',
          component: () => import('~home/hubs/Sales-Hub.vue')
        },
        {
          path: 'stock-balancer',
          name: 'stock-balancer-hub',
          component: () => import('~home/hubs/Stock-Balancer-Hub.vue')
        },
        // {
        //   path: 'BETA-stock-balancer',
        //   name: 'beta-stock-balancer-hub',
        //   component: () => import('~home/hubs/Stock-Balancer-Hub.vue')
        // },
        {
          path: 'stock-checker',
          name: 'stock-checker-hub',
          component: () => import('~home/hubs/Stock-Checker-Hub.vue')
        },
        // {
        //   path: 'stock-importer',
        //   name: 'stock-import-hub',
        //   component: () => import('~home/hubs/Stock-Import-Hub.vue')
        // }
      ]
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('~home/portals/Portal.vue'),
      children: [
        {
          path: 'customer',
          name: 'customer-dashboard',
          component: () => import('~home/dashboards/Customer-Dashboard.vue')
        },
        {
          path: 'procurement',
          name: 'procurement-dashboard',
          component: () => import('~home/dashboards/Procurement-Dashboard.vue')
        },
        {
          path: 'supplier',
          name: 'supplier-dashboard',
          component: () => import('~home/dashboards/Supplier-Dashboard.vue')
        }
      ]
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: () => import('~home/portals/Portal.vue'),
      children: [
        {
          path: 'delivery',
          name: 'delivery-statistics',
          component: () => import('~home/statistics/Delivery-Statistics.vue')
        },
        {
          path: 'journey',
          name: 'journey-statistics',
          component: () => import('~home/statistics/Journey-Statistics.vue')
        }
      ]
    },
    {
      path: '/setup',
      name: 'setup',
      component: () => import('~home/setup/Setup.vue')
    },
    {
      path: '/office-portal',
      name: 'office-portal',
      component: () => import('~home/portals/Office-Portal.vue'),
      children: [
        {
          path: 'home',
          name: 'office-portal-home',
          component: () => import('~home/portals/Office-Portal-Home.vue')
        },
        {
          path: '/automatic-line-items',
          name: 'automatic-line-items',
          component: () => import('~home/automatic-line-items/Automatic-Line-Items-Blade.vue')
        },
        {
          path: '/automatic-line-item/:id',
          name: 'automatic-line-item',
          component: () => import('~home/automatic-line-items/Automatic-Line-Item-Blade.vue')
        },
        {
          path: '/automatic-line-item-groups',
          name: 'automatic-line-item-groups',
          component: () => import('~home/automatic-line-item-groups/Automatic-Line-Item-Groups-Blade.vue')
        },
        {
          path: '/automatic-line-item-group/:id',
          name: 'automatic-line-item-group',
          component: () => import('~home/automatic-line-item-groups/Automatic-Line-Item-Group-Blade.vue')
        },
        {
          path: '/courier-actions',
          name: 'courier-actions',
          component: () => import('~home/actions/Courier-Actions-Blade.vue')
        },
        {
          path: '/courier-action/:id',
          name: 'courier-action',
          component: () => import('~home/actions/Courier-Action-Blade.vue')
        },
        {
          path: '/courier-invoicing-actions',
          name: 'courier-invoicing-actions',
          component: () => import('~home/actions/Courier-Invoicing-Actions-Blade.vue')
        },
        {
          path: '/courier-invoicing-action/:id',
          name: 'courier-invoicing-action',
          component: () => import('~home/actions/Courier-Invoicing-Action-Blade.vue')
        },
        {
          path: '/ordering-actions',
          name: 'ordering-actions',
          component: () => import('~home/actions/Ordering-Actions-Blade.vue')
        },
        {
          path: '/ordering-action/:id',
          name: 'ordering-action',
          component: () => import('~home/actions/Ordering-Action-Blade.vue')
        },
        {
          path: '/ordering-user-activities',
          name: 'ordering-user-activities',
          component: () => import('~home/user-activities/Ordering-User-Activities-Blade.vue')
        },
        {
          path: '/order-invoicing-actions',
          name: 'order-invoicing-actions',
          component: () => import('~home/actions/Order-Invoicing-Actions-Blade.vue')
        },
        {
          path: '/order-invoicing-action/:id',
          name: 'order-invoicing-action',
          component: () => import('~home/actions/Order-Invoicing-Action-Blade.vue')
        },
        {
          path: '/batches',
          name: 'batches',
          component: () => import('~home/batches/Batches-Blade.vue'),    
        },
        {
          path: '/batch/:id',
          name: 'batch',
          component: () => import('~home/batches/Batch-Blade.vue')
        },
        {
          path: '/batch-tracking/:id',
          name: 'batch-tracking',
          component: () => import('~home/batches/Batch-Tracking-Blade.vue')
        },
        {
          path: '/batch-archives',
          name: 'batch-archives',
          component: () => import('~home/batches/Batch-Archives-Blade.vue'),
        },
        {
          path: '/batch-archive/:id',
          name: 'batch-archive',
          component: () => import('~home/batches/Batch-Archive-Blade.vue')
        },
        {
          path: '/public-supplier/:id',
          name: 'public-supplier',
          component: () => import('~home/browse/Public-Supplier-Blade.vue'),
        },
        {
          path: '/public-suppliers',
          name: 'public-suppliers',
          component: () => import('~home/browse/Public-Suppliers-Blade.vue'),
        },
        {
          path: '/browse-courier/:id',
          name: 'browse-courier',
          component: () => import('~home/browse/Public-Courier-Blade.vue'),
        },
        {
          path: '/browse-couriers',
          name: 'browse-couriers',
          component: () => import('~home/browse/Public-Couriers-Blade.vue'),
        },            
        {
          path: '/browse-product/:id',
          name: 'browse-product',
          component: () => import('~home/browse/Public-Product.vue')
        },  
        {
          path: '/client/:id',
          name: 'client',
          component: () => import('~home/clients/Client-Blade.vue')
        },
        {
          path: '/clients',
          name: 'clients',
          component: () => import('~home/clients/Clients-Blade.vue')
        },
        {
          path: '/new-clients',
          name: 'new-clients',
          component: () => import('~home/clients/Client-New.vue')
        },
        {
          path: '/client-billable-items',
          name: 'client-billable-items',
          component: () => import('~home/billable-items/Client-Billable-Items-Blade.vue')
        },
        {
          path: '/client-billable-item/:id',
          name: 'client-billable-item',
          component: () => import('~home/billable-items/Client-Billable-Item-Blade.vue')
        },
        {
          path: '/client-invoices',
          name: 'client-invoices',
          component: () => import('~home/client-invoices/Client-Invoices-Blade.vue')
        },
        {
          path: '/client-invoice/:id',
          name: 'client-invoice',
          component: () => import('~home/client-invoices/Client-Invoice-Blade.vue')
        },
        {
          path: '/client-orders',
          name: 'client-orders',
          component: () => import('~home/client-orders/Client-Orders-Blade.vue')
        },
        {
          path: '/client-order/:id',
          name: 'client-order',
          component: () => import('~home/client-orders/Client-Order-Blade.vue')
        }, 
        {
          path: '/client-order-archives',
          name: 'client-order-archives',
          component: () => import('~home/client-orders/Archive-List.vue')
        },
        {
          path: '/client-template/:id',
          name: 'client-template',
          component: () => import('~home/client-templates/Client-Template-Blade.vue'),
        },
        {
          path: '/client-templates',
          name: 'client-templates',
          component: () => import('~home/client-templates/Client-Templates-Blade.vue')
        },
        {
          path: '/couriers',
          name: 'couriers',
          component: () => import('~home/couriers/Couriers-Blade.vue')
        },
        {
          path: '/courier/:id',
          name: 'courier',
          component: () => import('~home/couriers/Courier-Blade.vue')
        },
        {
          path: '/new-couriers',
          name: 'new-couriers',
          component: () => import('~home/couriers/Courier-New.vue')
        },
        {
          path: '/courier-billable-items',
          name: 'courier-billable-items',
          component: () => import('~home/billable-items/Courier-Billable-Items-Blade.vue')
        },
        {
          path: '/courier-billable-item/:id',
          name: 'courier-billable-item',
          component: () => import('~home/billable-items/Courier-Billable-Item-Blade.vue')
        },
        {
          path: '/courier-invoices',
          name: 'courier-invoices',
          component: () => import('~home/courier-invoices/Courier-Invoices-Blade.vue')
        },
        {
          path: '/courier-invoice/:id',
          name: 'courier-invoice',
          component: () => import('~home/courier-invoices/Courier-Invoice-Blade.vue')
        },
        {
          path: '/courier-orders',
          name: 'courier-orders',
          component: () => import('~home/courier-orders/Courier-Orders-Blade.vue')
        },
        {
          path: '/courier-order/:id',
          name: 'courier-order',
          component: () => import('~home/courier-orders/Courier-Order-Blade.vue')
        },
        {
          path: '/courier-order-archives',
          name: 'courier-order-archives',
          component: () => import('~home/courier-orders/Courier-Order-Archives-Blade.vue')
        },
        {
          path: '/courier-order-archive/:id',
          name: 'courier-order-archive',
          component: () => import('~home/courier-orders/Courier-Order-Archive-Blade.vue')
        },
        {
          path: '/courier-pointers',
          name: 'courier-pointers',
          component: () => import('~home/courier-pointers/Courier-Pointers-Hub.vue')
        },
        {
          path: '/customer-consignments',
          name: 'customer-consignments',
          component: () => import('~home/customer-consignments/Customer-Consignments-Blade.vue')
        },
        {
          path: '/customer-consignment/:id',
          name: 'customer-consignment',
          component: () => import('~home/customer-consignments/Customer-Consignment-Blade.vue')
        },
        {
          path: '/customer/:id',
          name: 'customer',
          component: () => import('~home/customers/Customer-Blade.vue'),
        },
        {
          path: '/customers',
          name: 'customers',
          component: () => import('~home/customers/Customers-Blade.vue')
        },
        {
          path: '/customer-locations',
          name: 'customer-locations',
          component: () => import('~home/customers/Customer-Locations.vue')
        },
        {
          path: '/customer-group/:id',
          name: 'customer-group',
          component: () => import('~home/customer-groups/Customer-Group-Blade.vue')
        },
        {
          path: '/customer-groups',
          name: 'customer-groups',
          component: () => import('~home/customer-groups/Customer-Groups-Blade.vue')
        },
        {
          path: '/new-customers',
          name: 'new-customers',
          component: () => import('~home/customers/Customer-New.vue')
        },
        {
          path: '/customer-orders-list',
          name: 'customer-orders-list',
          component: () => import('~home/customer-orders/Customer-Orders-List-Blade.vue')
        },
        {
          path: '/customer-orders',
          name: 'customer-orders',
          component: () => import('~home/customer-orders/Customer-Orders-Blade.vue')
        },
        {
          path: '/customer-order/:id',
          name: 'customer-order',
          component: () => import('~home/customer-orders/Customer-Order-Blade.vue')
        },
        { 
          path: '/customer-orders-import',
          name: 'customer-orders-import',
          component: () => import('~home/customer-orders/Customer-Orders-New-Blade.vue')
        },
        {
          path: '/customer-order-schedule/:id',
          name: 'customer-order-schedule',
          component: () => import('~home/customer-order-schedules/Customer-Order-Schedule-Blade.vue')
        },
        {
          path: '/customer-order-schedules',
          name: 'customer-order-schedules',
          component: () => import('~home/customer-order-schedules/Customer-Order-Schedules-Blade.vue')
        },
        {
          path: '/customer-template/:id',
          name: 'customer-template',
          component: () => import('~home/customer-templates/Customer-Template-Blade.vue')
        },
        {
          path: '/customer-templates',
          name: 'customer-templates',
          component: () => import('~home/customer-templates/Customer-Templates-Blade.vue')
        },
        {
          path: '/deliveries',
          name: 'deliveries',
          component: () => import('~home/deliveries/Deliveries-Blade.vue')
        },
        {
          path: '/delivery/:id',
          name: 'delivery',
          component: () => import('~home/deliveries/Delivery-Blade.vue')
        },
        {
          path: '/delivery-archives',
          name: 'delivery-archives',
          component: () => import('~home/deliveries/Delivery-Archives-Blade.vue')
        },
        {
          path: '/delivery-archive/:id',
          name: 'delivery-archive',
          component: () => import('~home/deliveries/Delivery-Archive-Blade.vue')
        },
        {
          path: '/delivery-fees',
          name: 'delivery-fees',
          component: () => import('~home/delivery-fees/Delivery-Fees-Blade.vue')
        },
        {
          path: '/delivery-fee/:id',
          name: 'delivery-fee',
          component: () => import('~home/delivery-fees/Delivery-Fee-Blade.vue')
        },
        {
          path: '/dispatch/journey/:id',
          name: 'dispatch-journey',
          component: () => import('~home/dispatch/Dispatch-Journey.vue')
        },
        {
          path: '/dispatch/pickup/:id',
          name: 'dispatch-pickup',
          component: () => import('~home/dispatch/Dispatch-Pickup.vue')
        },
        {
          path: '/dispatch/release/:id',
          name: 'dispatch-release',
          component: () => import('~home/dispatch/Dispatch-Release.vue')
        },
        {
          path: '/external-party-options',
          name: 'external-party-options',
          component: () => import('~home/external-parties/External-Parties-Blade.vue')
          //component: () => import('~home/external-parties/External-Party-List.vue'),
        },
        {
          path: '/external-party-integration/:id',
          name: 'external-party-integration',
          component: () => import('~home/external-parties/External-Party-Integration.vue')
        },
        {
          path: '/fulfiller-orders',
          name: 'fulfiller-orders',
          component: () => import('~home/fulfiller-orders/Fulfiller-Orders-Blade.vue')
        },
        {
          path: '/fulfiller-order/:id',
          name: 'fulfiller-order',
          component: () => import('~home/fulfiller-orders/Fulfiller-Order-Blade.vue')
        },
        {
          path: '/fulfiller-pointers',
          name: 'fulfiller-pointers',
          component: () => import('~home/fulfiller-pointers/Fulfiller-Pointers-Hub.vue')
        },
        {
          path: '/customer-invoice/:id',
          name: 'customer-invoice',
          component: () => import('~home/customer-invoices/Customer-Invoice-Blade.vue')
        },
        {
          path: '/customer-invoices',
          name: 'customer-invoices',
          component: () => import('~home/customer-invoices/Customer-Invoices-Blade.vue')
        },
        {
          path: '/subscription-invoice/:id',
          name: 'subscription-invoice',
          component: () => import('~home/subscription-invoices/Subscription-Invoice-Blade.vue')
        },
        // {
        //   path: '/subscription-invoices',
        //   name: 'subscription-invoices',
        //   component: () => import('~home/subscription-invoices/Subscription-Invoices-Blade.vue')
        // },
        {
          path: '/stock-import-archive/:id',
          name: 'stock-import-archive',
          component: () => import('~home/stock-imports/Stock-Import-Archive-Blade.vue')
        },
        {
          path: '/stock-import-archives',
          name: 'stock-import-archives',
          component: () => import('~home/stock-imports/Stock-Import-Archives-Blade.vue')
        },
        {
          path: '/journey/:id',
          name: 'journey',
          component: () => import('~home/journeys/Journey-Blade.vue')
        },
        {
          path: '/journeys',
          name: 'journeys',
          component: () => import('~home/journeys/Journeys-Blade.vue')
        },
        {
          path: '/journey-archive/:id',
          name: 'journey-archive',
          component: () => import('~home/journeys/Journey-Archive-Blade.vue')
        },
        {
          path: '/journey-archives',
          name: 'journey-archives',
          component: () => import('~home/journeys/Journey-Archives-Blade.vue')
        },
        {
          path: '/journey-templates',
          name: 'journey-templates',
          component: () => import('~home/journey-templates/Journey-Templates-Hub.vue')
        },
        {
          path: '/locations',
          name: 'locations',
          component: () => import('~home/locations/Locations-Blade.vue'),
        },
        {
          path: '/location/:id',
          name: 'location',
          component: () => import('~home/locations/Location-Blade.vue')
        },
        {
          path: '/measurements',
          name: 'measurements',
          component: () => import('~home/measurements/Measurements-Blade.vue')
        },   
        {
          path: '/measurement/:id',
          name: 'measurement',
          component: () => import('~home/measurements/Measurement-Blade.vue')
        },
        {
          path: '/courier-movements',
          name: 'courier-movements',
          component: () => import('~home/movements/Movements-Blade.vue')
        },
        {
          path: '/courier-movement/:id',
          name: 'courier-movement',
          component: () => import('~home/movements/Movement-Blade.vue')
        },
        {
          path: '/my-profile',
          name: 'my-profile',
          component: () => import('~home/users/My-Profile.vue')
        },
        {
          path: '/notification-configurations',
          name: 'notification-configurations',
          component: () => import('~home/notification-configurations/Notification-Configurations-Blade.vue')
        },
        {
          path: '/notification-configuration/:id',
          name: 'notification-configuration',
          component: () => import('~home/notification-configurations/Notification-Configuration-Blade.vue')
        },
        {
          path: '/ordering-rules-groups',
          name: 'ordering-rules-groups',
          component: () => import('~home/order-rules/Ordering-Rules-Groups-Blade.vue'),
        },
        {
            path: '/ordering-rules-group/:id',
            name: 'ordering-rules-group',
            component: () => import('~home/order-rules/Ordering-Rules-Group-Blade.vue'),
        },
        {
          path: '/order-sheets',
          name: 'order-sheets',
          component: () => import('~home/order-sheets/Order-Sheets-Blade.vue')
        },
        {
          path: '/order-sheet/:id',
          name: 'order-sheet',
          component: () => import('~home/order-sheets/Order-Sheet-Blade.vue')
        },
        {
            path: '/order-slots',
            name: 'order-slots',
            component: () => import('~home/order-slots/Order-Slots-Blade.vue'),
        },
        {
            path: '/order-slot/:id',
            name: 'order-slot',
            component: () => import('~home/order-slots/Order-Slot-Blade.vue')
        },
        // {
        //   path: 'order-slot-grouper',
        //   name: 'order-slot-grouper',
        //   component: () => import('~home/order-slot-groups/Order-Slot-Grouper-Blade.vue')
        // },
        // {
        //   path: '/order-slot-groups',
        //   name: 'order-slot-groups',
        //   component: () => import('~home/order-slot-groups/Order-Slot-Groups-Blade.vue'),
        // },
        // {
        //     path: '/order-slot-group/:id',
        //     name: 'order-slot-group',
        //     component: () => import('~home/order-slot-groups/Order-Slot-Group-Blade.vue')
        // },
        {
          path: '/supplier-payment-term/:id',
          name: 'supplier-payment-term',
          component: () => import('~home/payment-terms/Supplier-Payment-Term-Blade.vue')
        },
        {
          path: '/supplier-payment-terms',
          name: 'supplier-payment-terms',
          component: () => import('~home/payment-terms/Supplier-Payment-Terms-Blade.vue')
        },
        {
            path: '/supplier-price-tier/:id',
            name: 'supplier-price-tier',
            component: () => import('~home/price-tiers/Supplier-Price-Tier-Blade.vue')
        },
        {
          path: '/supplier-price-tiers',
          name: 'supplier-price-tiers',
          component: () => import('~home/price-tiers/Supplier-Price-Tiers-Blade.vue')
        },
        {
          path: '/courier-payment-term/:id',
          name: 'courier-payment-term',
          component: () => import('~home/payment-terms/Courier-Payment-Term-Blade.vue')
        },
        {
          path: '/courier-payment-terms',
          name: 'courier-payment-terms',
          component: () => import('~home/payment-terms/Courier-Payment-Terms-Blade.vue')
        },
        {
            path: '/courier-price-tier/:id',
            name: 'courier-price-tier',
            component: () => import('~home/price-tiers/Courier-Price-Tier-Blade.vue')
        },
        {
          path: '/courier-price-tiers',
          name: 'courier-price-tiers',
          component: () => import('~home/price-tiers/Courier-Price-Tiers-Blade.vue')
        },
        {
          path: '/products',
          name: 'products',
          component: () => import('~home/products/Products-Blade.vue'),
        },    
        {    
          path: '/product/:id',    
          name: 'product',    
          component: () => import('~home/products/Product-Blade.vue'),
        },
        {
          path: '/new-products',
          name: 'new-products',
          component: () => import('~home/products/Product-New.vue')
        },
        {
          path: '/product-categories',
          name: 'product-categories',
          component: () => import('~home/product-categories/Product-Categories-Blade.vue'),
        },
        {
          path: '/product-category/:id',
          name: 'product-category',
          component: () => import('~home/product-categories/Product-Category-Blade.vue'),
        },
        {    
          path: '/product-groups',
          name: 'product-groups',
          component: () => import('~home/product-groups/Product-Groups-Blade.vue'),
        },    
        {    
          path: '/product-group/:id',
          name: 'product-group',
          component: () => import('~home/product-groups/Product-Group-Blade.vue'),
        },
        {
          path: '/product-tether/:id',
          name: 'product-tether',
          component: () => import('~home/product-tethers/Product-Tether-Blade.vue')
        },
        {
          path: '/product-tethers',
          name: 'product-tethers',
          component: () => import('~home/product-tethers/Product-Tethers-Blade.vue')
        },
        {
          path: '/purchasing-orders',
          name: 'purchasing-orders',
          component: () => import('~home/purchase-orders/Ordering-List.vue')
        },
        {
          path: '/purchasing-order/:id',
          name: 'purchasing-order',
          component: () => import('~home/purchase-orders/Ordering-Item.vue')
        },
        {
          path: '/purchase-orders',
          name: 'purchase-orders',
          component: () => import('~home/purchase-orders/Purchase-Orders-Blade.vue')
        },
        {
          path: '/purchase-order/:id',
          name: 'purchase-order',
          component: () => import('~home/purchase-orders/Purchase-Order-Blade-New.vue')
        },
        {
          path: '/receivals',
          name: 'receivals',
          component: () => import('~home/receivals/Receivals-Blade.vue')
        },
        {
          path: '/receival/:id',
          name: 'receival',
          component: () => import('~home/receivals/Receival-Blade.vue')
        },
        {
          path: '/receival-archives',
          name: 'receival-archives',
          component: () => import('~home/receivals/Receival-Archives-Blade.vue')
        },
        {
          path: '/receival-archive/:id',
          name: 'receival-archive',
          component: () => import('~home/receivals/Receival-Archive-Blade.vue')
        },
        {
          path: '/receival-consignment-archive/:id',
          name: 'receival-consignment-archive',
          component: () => import('~home/receivals/Receival-Consignment-Archive-Blade.vue')
        },
        {
          path: '/releases',
          name: 'releases',
          component: () => import('~home/releases/Releases-Blade.vue')
        },
        {
          path: '/release/:id',
          name: 'release',
          component: () => import('~home/releases/Release-Blade.vue')
        },
        {
          path: '/release-archives',
          name: 'release-archives',
          component: () => import('~home/releases/Release-Archives-Blade.vue')
        },
        {
          path: '/release-archive/:id',
          name: 'release-archive',
          component: () => import('~home/releases/Release-Archive-Blade.vue')
        },
        {
          path: '/client-requests',
          name: 'client-requests',
          component: () => import('~home/requests/Client-Requests-Blade.vue')
        },
        {
          path: '/courier-requests',
          name: 'courier-requests',
          component: () => import('~home/requests/Courier-Requests-Blade.vue')
        },
        {
          path: '/customer-requests',
          name: 'customer-requests',
          component: () => import('~home/requests/Customer-Requests-Blade.vue')
        },
        {
          path: '/supplier-requests',
          name: 'supplier-requests',
          component: () => import('~home/requests/Supplier-Requests-Blade.vue')
        },
        {
          path: '/roles',
          name: 'roles',
          component: () => import('~home/roles/Roles-Blade.vue'),
        },
        {
          path: '/role/:id',
          name: 'role',
          component: () => import('~home/roles/Role-Blade.vue')
        },
        {
          path: '/settings',
          name: 'settings',
          component: () => import('~home/account-settings/Account-Settings.vue')
        },
        {
          path: '/standing-purchase-order/:id',
          name: 'standing-purchase-order',
          component: () => import('~home/standing-orders/Standing-Purchase-Order-Blade.vue')
        },
        {
          path: '/standing-purchase-orders',
          name: 'standing-purchase-orders',
          component: () => import('~home/standing-orders/Standing-Purchase-Orders-Blade.vue')
        },
        {
          path: '/standing-customer-order/:id',
          name: 'standing-customer-order',
          component: () => import('~home/standing-orders/Standing-Customer-Order-Blade.vue')
        },
        {
          path: '/standing-customer-orders',
          name: 'standing-customer-orders',
          component: () => import('~home/standing-orders/Standing-Customer-Orders-Blade.vue')
        },  
        {
          path: '/stock-adjustments',
          name: 'stock-adjustments',
          component: () => import('~home/stock-adjustments/Stock-Adjustments-Blade.vue')
        },
        {
          path: '/stock-adjustment/:id',
          name: 'stock-adjustment',
          component: () => import('~home/stock-adjustments/Stock-Adjustment-Blade.vue')
        },
        {
          path: '/stock-consignments',
          name: 'stock-consignments',
          component: () => import('~home/stock-consignments/Stock-Consignments-Blade.vue'),    
        },
        {
          path: '/stock-consignment/:id',
          name: 'stock-consignment',
          component: () => import('~home/stock-consignments/Stock-Consignment-Blade.vue')
        },
        {
          path: '/stock-imports',
          name: 'stock-imports',
          component: () => import('~home/stock-imports/Stock-Imports-Blade.vue'),    
        },
        {
          path: '/stock-import/:id',
          name: 'stock-import',
          component: () => import('~home/stock-imports/Stock-Import-Blade.vue')
        },
        {
          path: '/stock-items',
          name: 'stock-items',
          component: () => import('~home/stock-items/Stock-Items-Blade.vue'),    
        },
        // {
        //   path: '/stock-item/:id',
        //   name: 'stock-item',
        //   component: () => import('~home/stock-items/Stock-Item-Blade.vue')
        // },
        {
          path: '/stock-levels',
          name: 'stock-levels',
          component: () => import('~home/stock-items/Stock-Levels.vue')
        },
        {
          path: '/supply-lines',
          name: 'supply-lines',
          component: () => import('~home/supply-lines/Supply-Lines-Blade.vue')
        },
        {
          path: '/supply-line/:id',
          name: 'supply-line',
          component: () => import('~home/supply-lines/Supply-Line-Blade.vue')
        },
        {
          path: '/supply-pointers',
          name: 'supply-pointers',
          component: () => import('~home/supply-pointers/Supply-Pointers-Hub.vue')
        },
        {
          path: '/stock-thresholds',
          name: 'stock-thresholds',
          component: () => import('~home/stock-thresholds/Stock-Thresholds-Blade.vue')
        },
        {
          path: '/stock-threshold/:id',
          name: 'stock-threshold',
          component: () => import('~home/stock-thresholds/Stock-Threshold-Blade.vue')
        },
        {
          path: '/stock-tracked-items',
          name: 'stock-tracked-items',
          component: () => import('~home/stock-tracked-items/Stock-Tracked-Item-List.vue')
        },
        {
          path: '/stock-tracked-items-correct',
          name: 'stock-tracked-items-correct',
          component: () => import('~home/stock-tracked-items/Stock-Tracked-Item-List-Correct.vue')
        },
        {
          path: '/suppliers',
          name: 'suppliers',
          component: () => import('~home/suppliers/Suppliers-Blade.vue'),
        },
        {
          path: '/supplier/:id',
          name: 'supplier',
          component: () => import('~home/suppliers/Supplier-Blade.vue')
        },
        {
          path: '/new-suppliers',
          name: 'new-suppliers',
          component: () => import('~home/suppliers/Supplier-New.vue')
        },
        {
          path: '/supplier-consignments',
          name: 'supplier-consignments',
          component: () => import('~home/supplier-consignments/Supplier-Consignments-Blade.vue')
        },
        {
          path: '/supplier-consignment/:id',
          name: 'supplier-consignment',
          component: () => import('~home/supplier-consignments/Supplier-Consignment-Blade.vue')
        },
        {
          path: '/supplier-invoice/:id',
          name: 'supplier-invoice',
          component: () => import('~home/supplier-invoices/Supplier-Invoice-Blade.vue')
        },
        {
          path: '/supplier-invoices',
          name: 'supplier-invoices',
          component: () => import('~home/supplier-invoices/Supplier-Invoices-Blade.vue')
        },
        {
          path: '/transfer-archive/:id',
          name: 'transfer-archive',
          component: () => import('~home/transfer-archives/Transfer-Archive-Blade.vue')
        },
        {
          path: '/transfer-archives',
          name: 'transfer-archives',
          component: () => import('~home/transfer-archives/Transfer-Archives-Blade.vue')
        },
        {
          path: '/users',
          name: 'users',
          component: () => import('~home/users/Users-Blade.vue'),    
        },
        {
          path: '/user/:id',
          name: 'user',
          component: () => import('~home/users/User-Blade.vue')
        },
        {
          path: '/zones',
          name: 'zones',
          component: () => import('~home/zones/Zones-Blade.vue')
        },
        {
          path: '/zone/:id',
          name: 'zone',
          component: () => import('~home/zones/Zone-Blade.vue')
        }]
    },

    
];

export default new Router({
    routes,
    mode: 'history'
});