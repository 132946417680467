import axios from 'axios';
import urlHelpers from '~tools/url-helpers.js';

export default {  
    authURL(state) {                    
        return urlHelpers
            .getURL('Authentication')
            .appendURL(`authorize?response_type=code&client_id=appClient1&redirect_uri=${urlHelpers.getURL('WebApp')}/authentication&state=${state}`);
    },
    token(code) {
        var url = urlHelpers.getURL('Authentication').appendURL('token');
       
        // var formData = new FormData();
        // formData.append('grant_type', 'authorization_token');
        // formData.append('code', code);
        // formData.append('redirect_uri', urlHelpers.getURL('WebApp') + '/authentication');
        // formData.append('client_id', 'appClient1');

        const formData = {}
        formData['grant_type'] = 'authorization_token'
        formData['code'] = code
        formData['redirect_uri'] = `${window.location.origin}/authentication`
        formData['client_id'] = 'appClient1'

        const config = {
            headers: {
              'Content-Type': 'application/json'
            }
        }

        return axios.post(url, formData, config);
    },
    authenticate(authToken) {
        return axios.post(urlHelpers.getURL('Auth').appendURL('account/authenticate?bearer=' + authToken));
    },
    register(payload) {
        return axios.post(urlHelpers.getURL('Auth').appendURL('account/post?companyName=' + payload.companyName + '&userName=' + payload.userName + '&password=' + payload.password + '&subscriptionCode=' + payload.subscriptionCode));
    },
    externalPartyConnectToURL() {
        return urlHelpers.getURL('Auth').appendURL('auth/externalpartyoptions/get/connectto');
    }
}