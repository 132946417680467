<template>
    <BT-Blade
        :addBladeName="addBladeName"
        :addTo="addTo"
        :bladeName="bladeName"
        :bladesData="bladesData"
        :bladesToClear="bladesToClear"
        :canMinimize="canMinimize"
        :canPin="canPin"
        :clearBlades="clearBlades"
        :clearOtherBlades="clearOtherBlades"
        :colClass="colClass"
        :customBladeData="customBladeData"
        :defaultBladeWidth="defaultBladeWidth"
        :defaultPageWidth="defaultPageWidth"
        :dependantBlades="dependantBlades"
        :flat="flat"
        :flexColumn="flexColumn"
        :hideBackButton="hideBackButton"
        :navigation="navigation"
        :title="title"
        :hideBladeHeader="hideBladeHeader"
        :loadingMsg="mLoadingMsg"
        :minimizeOnSelect="minimizeOnSelect"
        :canAdd="canAdd"
        :canClose="canClose"
        :canEdit="canEdit"
        :canRefresh="canRefresh"
        :getNewBladeData="getNewBladeData"
        :getNewQuery="getNewQuery"
        :isChildBlade="isChildBlade"
        :large="large"
        :pageVariant="pageVariant"
        :permanent="permanent"
        :proxyID="proxyID"
        :proxyIDParam="proxyIDParam"
        :resizable="resizable"
        :scroll="false"
        :small="compSmall"
        :toolbarClass="toolbarClass"
        :tourStatus="tourStatus"
        :updateDependants="updateDependants"
        @sourceChanged="updateBlade"
        @refresh="refresh">
        <template v-slot:toolbar="data">
            <slot name="toolbar" v-bind:data="data" v-bind:items="asyncItems" />
        </template>
        <template v-slot:toolbar-right="data">
            <slot name="toolbar-right" v-bind="{ compSmall, data, items: asyncItems }" />
        </template>
        <template v-slot:toolbar-right-far="data">
            <slot name="toolbar-right-far" v-bind="{ compSmall, data, items: asyncItems }" />
        </template>
        <template v-slot:toolbar-left="data">
            <slot name="toolbar-left" v-bind="{ compSmall, data, items: asyncItems }" />
        </template>
        <template v-slot:bladeSettings="data">
            <slot name="bladeSettings" v-bind="{ compSmall, data, items: asyncItems }" />
        </template>
        <template v-slot="data">
            <v-card
                :class="frameClass"
                :dense="dense"
                :flat="flat"
                :style="frameStyle"
                width="100%">
                <!-- :width="computedCardWidth" -->
                <v-slide-y-transition group hide-on-leave>
                    <v-toolbar 
                        v-if="itemsTwo != null && !hideHeaderTwo"
                        :class="toolbarClass"
                        :dark="toolbarClass != null" 
                        :dense="dense"
                        :flat="flat"
                        key="0.5">
                        
                        <v-menu
                            v-if="canExportCSVTwo || showSettingsTwo"
                            offset-y
                            :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    
                                    icon
                                    :large="large"
                                    v-bind="attrs"
                                    v-on="on"
                                    title="Settings"
                                    :small="compSmall">
                                    <v-icon :small="compSmall" :large="large">mdi-cog</v-icon>
                                </v-btn>
                            </template>
                            <v-list :dense="dense">
                                <v-list-item v-if="canExportCSVTwo" @click="exportAsCSVTwo" :dense="dense">
                                        <v-list-item-icon>
                                            <v-icon :small="compSmall" :large="large">mdi-file-delimited-outline</v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-subtitle>Export To CSV</v-list-item-subtitle>
                                        </v-list-item-content>
                                </v-list-item>
                                <slot name="settingsTwo" />
                            </v-list>
                        </v-menu>

                        <slot name="actionsTwo" />

                    </v-toolbar>

                    <v-list v-if="isLengthyArray(itemsTwo) && ((data.data.isMobile && useListItems) || showList) && !showTable" key="0.7" class="mt-0">
                        <v-list-item-group :active-class="activeClass">
                            <template v-for="(item, index) in itemsTwo">
                                <v-list-item
                                    @click="selectItemTwo(item)"
                                    :key="index"
                                    :two-line="twoLine"
                                    :three-line="threeLine"
                                    :dense="dense">
                                    <slot name="listItemTwo" v-bind="{ item, data }">
                                        <v-list-item-icon v-if="mobileFilteredIconTwo != null">
                                            <slot :name="mobileFilteredIconTwo.value" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                <span v-if="mobileFilteredIconTwo.prefix != null">{{ mobileFilteredIconTwo.prefix }}</span>
                                                <span v-if="mobileFilteredIconTwo.bool == true">
                                                    <v-icon 
                                                        v-if="getNestedValue(item, mobileFilteredIconTwo.value) === true"
                                                        icon
                                                        :large="large"
                                                        :small="small">mdi-check</v-icon>
                                                </span>
                                                <BT-Increment-Quantity
                                                    v-else-if="mobileFilteredIconTwo.breakdown === true"
                                                    :spanClass="mobileFilteredIconTwo.class"
                                                    :value="getNestedValue(item, mobileFilteredIconTwo.value)"
                                                    :productID="item.productID" 
                                                    :measurements="computedMeasurements" />
                                                <span
                                                    v-else-if="mobileFilteredIconTwo.textFilter != null"
                                                    :class="mobileFilteredIconTwo.class">{{ $options.filters[mobileFilteredIconTwo.textFilter](getNestedValue(item, mobileFilteredIconTwo.value)) }}</span>
                                                <span
                                                    v-else
                                                    :class="mobileFilteredIconTwo.class">{{ getNestedValue(item, mobileFilteredIconTwo.value) }}</span>
                                            </slot>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <slot name="listItemContentTwo" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                <v-list-item-title v-for="(listItem, index) in mobileFilteredTitlesTwo" :key="index + 'title'">
                                                    <slot :name="listItem.value" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                        <span v-if="listItem.prefix != null">{{ listItem.prefix }}</span>
                                                        <span v-if="listItem.bool == true">
                                                            <v-icon 
                                                                v-if="getNestedValue(item, listItem.value) === true"
                                                                icon
                                                                :large="large"
                                                                :small="small">mdi-check</v-icon>
                                                        </span>
                                                        <BT-Increment-Quantity
                                                            v-else-if="listItem.breakdown === true"
                                                            :spanClass="listItem.class"
                                                            :value="getNestedValue(item, listItem.value)"
                                                            :productID="item.productID" 
                                                            :measurements="computedMeasurements" />
                                                        <BT-Entity
                                                            v-else-if="listItem.navigation != null && (listItem.itemText != null || listItem.textFilter != null)"
                                                            :navigation="listItem.navigation"
                                                            :itemText="listItem.itemText"
                                                            :itemValue="getNestedValue(item, listItem.value)"
                                                            :single="listItem.single"
                                                            :textFilter="listItem.textFilter"
                                                            :useLocalCache="listItem.useLocalCache" />
                                                        <div 
                                                            v-else-if="listItem.textFilter != null && listItem.selfIsValue === true"
                                                            :class="listItem.class">{{ $options.filters[listItem.textFilter](item) }}</div>
                                                        <span 
                                                            v-else-if="listItem.textFilter != null"
                                                            :class="listItem.class">{{ $options.filters[listItem.textFilter](getNestedValue(item, listItem.value)) }}</span>
                                                        <span 
                                                            v-else
                                                            :class="listItem.class">{{ getNestedValue(item, listItem.value) }}</span>
                                                    </slot>
                                                </v-list-item-title>
                                                <v-list-item-subtitle v-for="(listItem, index) in mobileFilteredSubtitlesTwo" :key="index + 'subtitle'">
                                                    <slot :name="listItem.value" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                        <span v-if="listItem.prefix != null">{{ listItem.prefix }}</span>
                                                        <span v-if="listItem.bool == true">
                                                            <v-icon 
                                                                v-if="getNestedValue(item, listItem.value) === true"
                                                                icon
                                                                :large="large"
                                                                :small="small">mdi-check</v-icon>
                                                        </span>
                                                        <BT-Increment-Quantity
                                                            v-else-if="listItem.breakdown === true"
                                                            :spanClass="listItem.class"
                                                            :value="getNestedValue(item, listItem.value)"
                                                            :productID="item.productID" 
                                                            :measurements="computedMeasurements" />
                                                        <BT-Entity
                                                            v-else-if="listItem.navigation != null && (listItem.itemText != null || listItem.textFilter != null)"
                                                            :navigation="listItem.navigation"
                                                            :itemText="listItem.itemText"
                                                            :itemValue="getNestedValue(item, listItem.value)"
                                                            :textFilter="listItem.textFilter"
                                                            :single="listItem.single"
                                                            :useLocalCache="listItem.useLocalCache" />
                                                        <div 
                                                            v-else-if="listItem.textFilter != null && listItem.selfIsValue === true"
                                                            :class="listItem.class">{{ $options.filters[listItem.textFilter](item) }}</div>
                                                        <span 
                                                            v-else-if="listItem.textFilter != null"
                                                            :spanClass="listItem.class">{{ $options.filters[listItem.textFilter](getNestedValue(item, listItem.value)) }}</span>
                                                        <span 
                                                            v-else
                                                            :spanClass="listItem.class">{{ getNestedValue(item, listItem.value) }}</span>
                                                    </slot>
                                                </v-list-item-subtitle>
                                            </slot>
                                        </v-list-item-content>
                                        <v-list-item-action>
                                            <v-row no-gutters :class="hoverItemActions ? 'mouse-over-show' : null">
                                                <slot name="itemActionsTwo"
                                                    v-bind:item="item"
                                                    v-bind:items="itemsTwo"
                                                    v-bind:data="data" />

                                                <v-slide-x-transition group hide-on-leave>
                                                    <v-icon
                                                        v-if="item.errorMsg != null"
                                                        color="warning"
                                                        key="0.5"
                                                        :large="large"
                                                        :small="small"
                                                        :title="item.errorMsg">
                                                        mdi-alert-circle
                                                    </v-icon>
                                                </v-slide-x-transition>
                                            </v-row>
                                        </v-list-item-action>
                                    </slot>
                                </v-list-item>
                                <v-divider v-if="dividers" :key="'d' + index" />
                            </template>
                        </v-list-item-group>
                    </v-list>
                    <v-data-table
                        v-else-if="isLengthyArray(itemsTwo)"
                        :dense="dense"
                        :headers="tableHeadersTwo"
                        :hide-default-footer="hideFooter || useServerPagination"
                        :items="itemsTwo"
                        :items-per-page="itemsPerPage"
                        @click:row="selectItemTwo"
                        key="1"
                        :showSelect="selectMany">

                        <template v-slot:[`item.actions`]="{ item }">
                            <slot name="itemActionsTwo" v-bind:item="item" v-bind:data="data">

                            </slot>
                        </template>

                        <template v-for="(filter, index) in allDisplayFiltersTwo" v-slot:[`item.${filter.value}`]="{ item }">
                            <td :key="index">
                                <slot :name="filter.value" v-bind:item="item" v-bind:data="data">
                                    <div v-if="filter.textFilter != null">{{ $options.filters[filter.textFilter](getNestedValue(item, filter.value)) }}</div>
                                    <div v-else-if="filter.textFunction != null">{{ filter.textFunction(item) }}</div>
                                    <div v-else>Dummy</div>
                                </slot>
                            </td>
                        </template>

                    </v-data-table>

                    <v-toolbar
                        v-if="!hideHeader"
                        :class="toolbarClass"
                        :dark="toolbarClass != null" 
                        :dense="dense"
                        :flat="flat"
                        key="2">
                        <v-menu 
                            v-if="showSettings || canExportCSV || archiveBladeName != null || canShowInactive"
                            offset-y 
                            :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    
                                    icon
                                    :large="large"
                                    v-bind="attrs"
                                    v-on="on"
                                    title="Settings"
                                    :small="compSmall">
                                    <v-icon :small="compSmall" :large="large">mdi-cog</v-icon>
                                </v-btn>
                            </template>
                            <v-list :dense="dense">
                                <v-menu
                                    :close-on-click="false"
                                    :close-on-content-click="false"
                                    open-on-hover
                                    offset-x
                                    right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-list-item
                                            v-bind="attrs"
                                            v-on="on">
                                            <v-list-item-icon>
                                                <v-icon>mdi-view-column</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>Columns</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>

                                    <v-list dense>
                                        <v-list-item v-for="(headerOption, index) in headerOptions" :key="index" @click="toggleHeaderOption(headerOption)">
                                            <v-list-item-icon>
                                                <v-icon v-if="headerOption.selected">mdi-check</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-subtitle>{{ headerOption.text }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                                <v-list-item v-if="archiveBladeName != null" @click="toArchives" :dense="dense">
                                    <v-list-item-icon>
                                        <v-icon :small="small" :large="large">mdi-archive-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>Archives</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="canShowInactive" @click="showInactive = !showInactive" :dense="dense">
                                    <v-list-item-icon>
                                        <v-icon :small="small" :large="large">mdi-eraser</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>Show Inactive</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="canExportCSV" @click="exportAsCSV" :dense="dense">
                                    <v-list-item-icon>
                                        <v-icon :small="small" :large="large">mdi-file-delimited-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>Export To CSV</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item v-if="canBreakdown" @click="breakdownToggle = !breakdownToggle" :dense="dense">
                                    <v-list-item-icon>
                                        <v-icon :small="small" :large="large">mdi-counter</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>Quantity Breakdowns</v-list-item-subtitle>
                                        <BT-Select-List-Dialog
                                            @ok="selectMeasurements"
                                            hideButton
                                            itemText="measurementName"
                                            itemValue="id"
                                            label="Measurements"
                                            multiple
                                            navigation="measurements"
                                            :params="{ tags: measurementType }"
                                            :showToggle="breakdownToggle"
                                            width="350" />
                                    </v-list-item-content>
                                </v-list-item>
                                <slot name="settings"
                                    v-bind:items="filteredItems"
                                    v-bind:allItems="asyncItems"
                                    v-bind:data="data"
                                    v-bind:measurements="mMeasurements" />
                            </v-list>
                        </v-menu>

                        <v-btn
                            v-if="canSearchLocal || canSearch"
                            class="v-step-list-search "
                            :large="large"
                            :small="compSmall"
                            icon 
                            @click="toggleSearch">
                            <v-icon v-if="!showSearch" :small="compSmall" :large="large">mdi-magnify</v-icon>
                            <v-icon v-else :small="compSmall" :large="large">mdi-close</v-icon>
                        </v-btn>

                        <v-slide-x-transition hide-on-leave>
                            <v-text-field
                                v-if="(canSearch || canSearchLocal) && showSearch"
                                ref="searchField"
                                flat
                                dense
                                hide-details
                                hide-no-data
                                :large="large"
                                solo-inverted
                                :small="compSmall"
                                label="Find"
                                v-model="searchInput"
                                @keyup.native.enter="refresh">
                                <template v-slot:append>
                                    <v-btn :small="compSmall" :large="large" @click="refresh" icon>
                                        <v-icon :small="compSmall" :large="large">mdi-magnify</v-icon>
                                    </v-btn>
                                </template>
                            </v-text-field>
                        </v-slide-x-transition>

                        <slot name="actions" v-bind="{ data, items: asyncItems }" />

                        <v-spacer v-if="canSync && asyncExternalParties != null || showFilters"/>

                        <slot name="actionsRight" v-bind="data" v-bind:allItems="asyncItems" />

                        <div v-if="isLengthyArray(chipFilters)" class="v-step-list-filters">
                            <v-menu
                                v-if="showFilters"
                                :close-on-content-click="false"
                                dense
                                left
                                bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        
                                        :small="compSmall"
                                        icon
                                        :large="large"
                                        v-bind="attrs"
                                        v-on="on">
                                        <v-icon :small="compSmall" :large="large">mdi-filter</v-icon>
                                    </v-btn>
                                </template>

                                <v-list>
                                    <v-list-item-group
                                        v-model="selectedFilters"
                                        multiple>
                                        <template v-for="(f, i) in chipFilters">
                                            <v-list-item :key="i">
                                                <template v-slot:default="{ active }">
                                                    <v-list-item-action>
                                                        <v-icon v-if="active" :small="small" :large="large">mdi-check</v-icon>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="f" />
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </template>
                                    </v-list-item-group>
                                    <v-fade-transition hide-on-leave group>
                                        <v-divider v-if="isFilterChanged" key="1"/>
                                        <v-btn
                                            v-if="isFilterChanged"
                                            key="2"
                                            block
                                            @click="refresh">
                                            <v-icon left>mdi-filter</v-icon>Apply
                                        </v-btn>
                                    </v-fade-transition>
                                </v-list>
                            </v-menu>
                        </div>

                        <v-slide-y-transition hide-on-leave>
                            <BT-Select-Dialog
                                v-if="canSync && isLengthyArray(externalOptions)"
                                :buttonClass="currentExternalParty != null ? 'success--text ' : ''"
                                icon="mdi-cloud-sync"
                                label="Sync With"
                                :large="large"
                                :listItemValue="null"
                                :getOnOpenAsync="pullExternalParties"
                                :small="compSmall"
                                :title="currentExternalParty != null ? 'Sync: ' + currentExternalParty.party : 'Sync'"
                                width="400"
                                @change="changeExternalParty">
                                <template slot-scope="{ item }">
                                    <v-container>
                                        <v-card>
                                            <div class="d-flex flex-no-wrap justify-space-between">
                                                <v-card-title>{{ fromCamelCase(item.party)  }}</v-card-title>
                                                <v-avatar size="100">
                                                    <v-img :src="imageURL(item.party + '.png')" />
                                                </v-avatar>
                                            </div>
                                        </v-card>
                                    </v-container>
                                </template>
                            </BT-Select-Dialog>
                        </v-slide-y-transition>
                    </v-toolbar>

                    <v-alert v-model="showError" dismissible type="error" key="3">{{ errorMessage }}</v-alert>

                    <div key="3.4">
                    <slot name="body" v-bind="{ data, items: filteredItems, allItems: asyncItems, save: saveItem, deleteItem: deleteItem }">
                        <slot name="body-top" />
                        <v-slide-x-transition hide-on-leave>
                            <div v-if="!isLengthyArray(asyncItems)" :style="listStyle" class="d-flex flex-column align-center justify-center">
                                <slot name="noItems">
                                    <div v-if="mLoadingMsg != null" style="height: 100px;">
                                    </div>
                                    <div v-else>
                                        <div class="text-h6 text-center my-2">Nothing Found</div>
                                        <div class="text-center my-2">
                                            <v-btn class="primary" @click="refresh">Try Again</v-btn>
                                        </div>
                                    </div>
                                </slot>
                            </div>
                            <v-list 
                                v-else-if="((data.data.isMobile && useListItems) || showList) && !showTable"
                                key="3.5"
                                class="pa-0 overflow-y-auto"
                                :style="listStyle">
                                <slot name="list" v-bind:data="data" v-bind:items="filteredItems" v-bind:allItems="asyncItems">
                                    <v-list-item-group :active-class="activeClass">
                                        <template v-for="(item, index) in filteredItems">
                                            <!-- <slot name="wholeListItem" v-bind:index="index" v-bind:item="item" v-bind:data="data" v-bind:selectItem="selectItem(item)"> -->
                                                <v-list-item
                                                    class="mouse-item"
                                                    @click="selectItem(item)"
                                                    :key="index"
                                                    :two-line="twoLine"
                                                    :three-line="threeLine"
                                                    :dense="dense">
                                                    <slot name="listItem" v-bind="{ item, data }" :deleteItem="deleteItem">
                                                        <v-list-item-icon v-if="mobileFilteredIcon != null">
                                                            <slot :name="mobileFilteredIcon.value" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                                <span v-if="mobileFilteredIcon.prefix != null">{{ mobileFilteredIcon.prefix }}</span>
                                                                <span v-if="mobileFilteredIcon.bool == true">
                                                                    <v-icon 
                                                                        v-if="getNestedValue(item, mobileFilteredIcon.value) === true"
                                                                        icon
                                                                        :large="large"
                                                                        :small="small">mdi-check</v-icon>
                                                                </span>
                                                                <BT-Increment-Quantity
                                                                    v-else-if="mobileFilteredIcon.breakdown === true"
                                                                    :spanClass="mobileFilteredIcon.class"
                                                                    :value="getNestedValue(item, mobileFilteredIcon.value)"
                                                                    :productID="item.productID" 
                                                                    :measurements="computedMeasurements" />
                                                                <span
                                                                    v-else-if="mobileFilteredIcon.textFilter != null"
                                                                    :class="mobileFilteredIcon.class">{{ $options.filters[mobileFilteredIcon.textFilter](getNestedValue(item, mobileFilteredIcon.value)) }}</span>
                                                                <span
                                                                    v-else
                                                                    :class="mobileFilteredIcon.class">{{ getNestedValue(item, mobileFilteredIcon.value) }}</span>
                                                            </slot>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <slot name="listItemContent" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                                <v-list-item-title v-for="(listItem, index) in mobileFilteredTitles" :key="index + 'title'">
                                                                    <slot :name="listItem.value" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                                        <span v-if="listItem.prefix != null">{{ listItem.prefix }}</span>
                                                                        <span v-if="listItem.bool == true">
                                                                            <v-icon 
                                                                                v-if="getNestedValue(item, listItem.value) === true"
                                                                                icon
                                                                                :large="large"
                                                                                :small="small">mdi-check</v-icon>
                                                                        </span>
                                                                        <BT-Increment-Quantity
                                                                            v-else-if="listItem.breakdown === true"
                                                                            :spanClass="listItem.class"
                                                                            :value="getNestedValue(item, listItem.value)"
                                                                            :productID="item.productID" 
                                                                            :measurements="computedMeasurements" />
                                                                        <BT-Entity
                                                                            v-else-if="listItem.navigation != null && (listItem.itemText != null || listItem.textFilter != null)"
                                                                            :navigation="listItem.navigation"
                                                                            :itemText="listItem.itemText"
                                                                            :itemValue="getNestedValue(item, listItem.value)"
                                                                            :textFilter="listItem.textFilter"
                                                                            :single="listItem.single"
                                                                            :useLocalCache="listItem.useLocalCache" />
                                                                        <div 
                                                                            v-else-if="listItem.textFilter != null && listItem.selfIsValue === true"
                                                                            :class="listItem.class">{{ $options.filters[listItem.textFilter](item) }}</div>
                                                                        <span 
                                                                            v-else-if="listItem.textFilter != null"
                                                                            :class="listItem.class">{{ $options.filters[listItem.textFilter](getNestedValue(item, listItem.value)) }}</span>
                                                                        <span 
                                                                            v-else
                                                                            :class="listItem.class">{{ getNestedValue(item, listItem.value) }}</span>
                                                                    </slot>
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle v-for="(listItem, index) in mobileFilteredSubtitles" :key="index + 'subtitle'">
                                                                    <slot :name="listItem.value" v-bind:item="item" v-bind:data="data" v-bind:measurements="computedMeasurements">
                                                                        <span v-if="listItem.prefix != null">{{ listItem.prefix }}</span>
                                                                        <span v-if="listItem.bool == true">
                                                                            <v-icon 
                                                                                v-if="getNestedValue(item, listItem.value) === true"
                                                                                icon
                                                                                :large="large"
                                                                                :small="small">mdi-check</v-icon>
                                                                        </span>
                                                                        <BT-Increment-Quantity
                                                                            v-else-if="listItem.breakdown === true"
                                                                            :spanClass="listItem.class"
                                                                            :value="getNestedValue(item, listItem.value)"
                                                                            :productID="item.productID" 
                                                                            :measurements="computedMeasurements" />
                                                                        <BT-Entity
                                                                            v-else-if="listItem.navigation != null && (listItem.itemText != null || listItem.textFilter != null)"
                                                                            :navigation="listItem.navigation"
                                                                            :itemText="listItem.itemText"
                                                                            :itemValue="getNestedValue(item, listItem.value)"
                                                                            :textFilter="listItem.textFilter"
                                                                            :single="listItem.single"
                                                                            :useLocalCache="listItem.useLocalCache" />
                                                                        <div 
                                                                            v-else-if="listItem.textFilter != null && listItem.selfIsValue === true"
                                                                            :class="listItem.class">{{ $options.filters[listItem.textFilter](item) }}</div>
                                                                        <span 
                                                                            v-else-if="listItem.textFilter != null"
                                                                            :spanClass="listItem.class">{{ $options.filters[listItem.textFilter](getNestedValue(item, listItem.value)) }}</span>
                                                                        <span 
                                                                            v-else
                                                                            :spanClass="listItem.class">{{ getNestedValue(item, listItem.value) }}</span>
                                                                    </slot>
                                                                </v-list-item-subtitle>
                                                            </slot>
                                                        </v-list-item-content>
                                                        <v-list-item-action>
                                                            <v-row no-gutters :class="hoverItemActions ? 'mouse-over-show' : null">
                                                                <v-spacer />
                                                                <slot name="itemActions" v-bind:item="item" v-bind:items="asyncItems" v-bind:data="data" />

                                                                <v-slide-x-transition group hide-on-leave>
                                                                    <v-icon v-if="item.errorMsg != null"
                                                                        color="warning"
                                                                        key="0.5"
                                                                        :large="large"
                                                                        :small="compSmall"
                                                                        :title="item.errorMsg">
                                                                        mdi-alert-circle
                                                                    </v-icon>
                                                                    <v-btn v-if="currentExternalParty != null && (!isLengthyArray(item.externalReferences) || !item.externalReferences.some(ext => ext.externalParty == currentExternalParty.party))"
                                                                        @click.stop="pushToParty(item)"
                                                                        
                                                                        :title="'Upload to ' + fromCamelCase(currentExternalParty.party)"
                                                                        :loading="item.loadingCount > 0"
                                                                        icon
                                                                        key="1">
                                                                        <v-icon :small="compSmall" :large="large" class="primary--text">mdi-cloud-upload</v-icon>
                                                                    </v-btn>
                                                                    <v-btn v-else-if="currentExternalParty != null && isLengthyArray(item.externalReferences) && item.externalReferences.some(ext => ext.externalParty == currentExternalParty.party)"
                                                                        icon
                                                                        key="2"
                                                                        :large="large"
                                                                        :small="compSmall"
                                                                        title="Uploaded">
                                                                        <v-icon :small="compSmall" :large="large" class="success--text">mdi-cloud-check</v-icon>
                                                                    </v-btn>
                                                                    <v-btn v-if="currentExternalParty != null && isLengthyArray(item.externalReferences) && item.externalReferences.some(ext => ext.externalParty == currentExternalParty.party)"
                                                                        @click="removePartyRef(item)"
                                                                        icon
                                                                        key="2.1"
                                                                        :large="large"
                                                                        :small="compSmall"
                                                                        title="Detach">
                                                                        <v-icon :small="compSmall" :large="large" class="error--text">mdi-cloud-remove</v-icon>
                                                                    </v-btn>
                                                                    
                                                                    <v-btn
                                                                        v-if="isDeletableItem(item)"
                                                                        icon
                                                                        :large="large"
                                                                        :small="compSmall"
                                                                        :disabled="!permittedToEdit || item.loadingCount > 0"
                                                                        :loading="item.loadingCount > 0"
                                                                        @click.stop="deleteItem(item)"
                                                                        key="3">
                                                                        <v-icon color="error" :large="large" :small="compSmall">mdi-delete</v-icon>
                                                                    </v-btn>

                                                                    <v-btn
                                                                        v-if="isRestorableItem(item)"
                                                                        icon
                                                                        :large="large"
                                                                        :small="compSmall"
                                                                        title="Restore"
                                                                        :disabled="!permittedToEdit || item.loadingCount > 0"
                                                                        :loading="item.loadingCount > 0"
                                                                        @click.stop="restoreItem(item)"
                                                                        key="4">
                                                                        <v-icon color="primary" :large="large" :small="compSmall">mdi-eraser-variant</v-icon>
                                                                    </v-btn>

                                                                    <v-btn
                                                                        v-if="canOpenInNewTab"
                                                                        icon
                                                                        :large="large"
                                                                        :small="compSmall"
                                                                        title="Open In New Tab"
                                                                        key="5">
                                                                        <v-icon :large="large" :small="compSmall">mdi-open-in-new</v-icon>
                                                                    </v-btn>
                                                                </v-slide-x-transition>
                                                            </v-row>
                                                        </v-list-item-action>
                                                    </slot>
                                                </v-list-item>
                                            <!-- </slot> -->
                                            
                                            <v-divider v-if="dividers" :key="'d' + index" />
                                        </template>
                                    </v-list-item-group>
                                </slot>
                            </v-list>
                            <v-data-table
                                v-else
                                class="overflow-y-auto"
                                :dense="dense"
                                :expanded.sync="expanded"
                                :headers="tableHeaders"
                                :items="filteredItems"
                                :hide-default-footer="hideFooter || useServerPagination"
                                :items-per-page="itemsPerPage"
                                mobile-breakpoint="10"
                                :single-expand="singleExpand"
                                @item-selected="itemSelected"
                                @click:row="selectItem"
                                key="4"
                                :selectable-key="selectKey"
                                :showSelect="selectMany"
                                :style="listStyle">

                                <template v-slot:[`header.data-table-select`]="d">
                                    <slot name="selectHeader" v-bind="d" v-bind:allItems="asyncItems" v-bind:items="filteredItems" />
                                </template>

                                <template v-slot:[`item.data-table-select`]="d">
                                    <slot name="selectItem" v-bind="d" />
                                </template>

                                <template v-slot:[`item.actions`]="{ item }">
                                    <v-fade-transition hide-on-leave>
                                        <v-row no-gutters :class="hoverItemActions ? 'mouse-over-show' : null">
                                            <v-spacer />
                                            <slot name="itemActions" v-bind:item="item" v-bind:items="asyncItems" v-bind:data="data">

                                            </slot>
                                            <v-icon 
                                                v-if="item.errorMsg != null"
                                                color="warning"
                                                key="0.5"
                                                :large="large"
                                                :small="compSmall"
                                                :title="item.errorMsg">mdi-alert-circle
                                            </v-icon>
                                            <v-btn
                                                v-if="currentExternalParty != null && (!isLengthyArray(item.externalReferences) || !item.externalReferences.some(ext => ext.externalParty == currentExternalParty.party))"
                                                @click.stop="pushToParty(item)"
                                                :title="'Upload to ' + fromCamelCase(currentExternalParty.party)"
                                                :loading="item.loadingCount > 0"
                                                icon
                                                key="1">
                                                <v-icon :small="compSmall" :large="large" class="primary--text">mdi-cloud-upload</v-icon>
                                            </v-btn>
                                            <v-btn v-else-if="currentExternalParty != null && isLengthyArray(item.externalReferences) && item.externalReferences.some(ext => ext.externalParty == currentExternalParty.party)"
                                                icon
                                                key="2"
                                                :large="large"
                                                :small="compSmall"
                                                title="Uploaded">
                                                <v-icon :small="compSmall" :large="large" class="success--text">mdi-cloud-check</v-icon>
                                            </v-btn>
                                            <v-btn v-if="currentExternalParty != null && isLengthyArray(item.externalReferences) && item.externalReferences.some(ext => ext.externalParty == currentExternalParty.party)"
                                                @click.stop="removePartyRef(item)"
                                                icon
                                                key="2.1"
                                                :large="large"
                                                :small="compSmall"
                                                title="Detach">
                                                <v-icon :small="compSmall" :large="large" class="error--text">mdi-cloud-remove</v-icon>
                                            </v-btn>
                                            <v-btn
                                                v-if="isDeletableItem(item)"
                                                icon
                                                :large="large"
                                                :small="compSmall"
                                                :disabled="!permittedToEdit || item.loadingCount > 0"
                                                :loading="item.loadingCount > 0"
                                                @click.stop="deleteItem(item)"
                                                key="3">
                                                <v-icon color="error" :large="large" :small="compSmall">mdi-delete</v-icon>
                                            </v-btn>

                                            <v-btn
                                                v-if="isRestorableItem(item)"
                                                icon
                                                :large="large"
                                                :small="compSmall"
                                                title="Restore"
                                                :disabled="!permittedToEdit || item.loadingCount > 0"
                                                :loading="item.loadingCount > 0"
                                                @click.stop="restoreItem(item)"
                                                key="4">
                                                <v-icon :large="large" :small="compSmall">mdi-eraser-variant</v-icon>
                                            </v-btn>

                                            <v-btn
                                                v-if="canOpenInNewTab"
                                                icon
                                                
                                                :large="large"
                                                :small="compSmall"
                                                title="Open In New Tab"
                                                @click.stop="openInNewTab(item)"
                                                key="5">
                                                <v-icon :large="large" :small="compSmall">mdi-open-in-new</v-icon>
                                            </v-btn>
                                        </v-row>
                                    </v-fade-transition>
                                </template>

                                <template v-for="(filter, index) in allDisplayFilters" v-slot:[`item.${filter.value}`]="{ item }">
                                    <span :key="index">
                                        <slot :name="filter.value" v-bind:item="item" v-bind:data="data" v-bind:items="asyncItems" v-bind:measurements="computedMeasurements">
                                            <div v-if="filter.bool == true">
                                                <v-icon 
                                                    v-if="getNestedValue(item, filter.value) === true"
                                                    icon
                                                    :large="large"
                                                    :small="small">mdi-check</v-icon>
                                            </div>
                                            <BT-Increment-Quantity
                                                v-else-if="filter.breakdown === true"
                                                :spanClass="filter.class"
                                                :value="getNestedValue(item, filter.value)"
                                                :productID="item.productID" 
                                                :measurements="computedMeasurements" />
                                            <BT-Entity
                                                v-else-if="filter.navigation != null && (filter.itemText != null || filter.textFilter != null)"
                                                :navigation="filter.navigation"
                                                :itemText="filter.itemText"
                                                :itemValue="getNestedValue(item, filter.value)"
                                                :textFilter="filter.textFilter"
                                                :single="filter.single"
                                                :useLocalCache="filter.useLocalCache" />
                                            <div 
                                                v-else-if="filter.textFilter != null && filter.selfIsValue === true"
                                                :class="filter.class">{{ $options.filters[filter.textFilter](item) }}</div>
                                            <div 
                                                v-else-if="filter.textFilter != null"
                                                :class="filter.class">{{ $options.filters[filter.textFilter](getNestedValue(item, filter.value)) }}</div>
                                            <div v-else-if="filter.textFunction != null">{{ filter.textFunction(item) }}</div>
                                            <div v-else :class="filter.class">Dummy</div>
                                        </slot>
                                    </span>
                                </template>

                            </v-data-table>
                        </v-slide-x-transition>
                        <slot name="bottom" v-bind:measurements="computedMeasurements">
                            
                        </slot>
                    </slot>
                    </div>
                    <div v-if="showBottomNavigation" key="5">
                        <v-divider class="mb-3" />
                        <v-pagination
                            v-model="currentPage"
                            :length="totalPages"
                            @input="changePage" />
                    </div>
                    <slot 
                        name="footer"
                        v-bind:measurements="computedMeasurements"
                        v-bind:items="filteredItems" />

                    <v-dialog v-model="showDelete" max-width="500px" key="7">
                        <v-card>
                            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-slide-y-transition>
                
                <BT-Snack v-model="msg" />

                <!-- <v-overlay :value="mLoadingMsg != null" absolute class="text-center">
                    <v-progress-circular indeterminate size="64" />
                    <p>{{ mLoadingMsg || 'Loading' }}</p>
                </v-overlay> -->
            </v-card>
        </template>
    </BT-Blade>
</template>

<script>
import { roundUp, getBreakdown } from '~helpers';
import { firstBy } from 'thenby';

export default {
    name: 'BT-Blade-Items',
    components: {
        BTIncrementQuantity: () => import('~components/BT-Increment-Quantity.vue'),
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
        // BTSidebarExternalIntegration: () => import('~components/BT-Sidebar-External-Integration.vue'),
        BTSelectListDialog: () => import('~components/BT-Select-List-Dialog.vue')
    },
    data: function() {
        return {
            asyncItems: [],
            asyncExternalParties: null,
            bladeData: null,
            breakdownToggle: false,
            currentExternalParty: null,
            currentPage: 1,
            errorMessage: null,
            headerOptions: [],
            id: null,
            isFilterChanged: false,
            isPinned: false,
            lastSelectedItem: null,
            mLoadingMsg: null,
            mMeasurements: [],
            mParams: { includeDetails: false },
            mProxyID: null,
            msg: null,
            originalFilters: [],
            recentConditions: null, //that last set of conditions to have been pulled ({ ...params, ...navigation, ...bladeData, ...proxyID })
            searchString: null,
            searchInput: null,
            selectedFilters: [],
            select: null,
            selectedItem: null,
            showDelete: false,
            showError: false,
            showInactive: false,
            showSearch: false,
            syncItems: null,
            tableHeaders: [],
            totalPages: 0,
        }
    },
    props: {
        activeClass: {
            type: String,
            default: 'accent--text'
        },
        actualHeight: {
            type: String,
            default: null
        },
        actualUsedHeight: {
            type: Number,
            default: null
        },
        addBladeName: null,
        addTrainingBladeName: null,
        addTo: null,
        actionsWidth: null,
        archiveBladeName: null,
        bladeClass: {
            type: String,
            default: null
        },
        bladeName: null,
        bladesData: {
            type: Object,
            default: null
        },
        bladesToClear: {
            type: Array,
            default: null
        },
        canAdd: {
            type: Boolean,
            default: false
        },
        canBreakdown: {
            type: Boolean,
            default: false
        },
        canClose: {
            type: Boolean,
            default: true
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        canExportCSV: {
            type: Boolean,
            default: true
        },
        canExportCSVTwo: {
            type: Boolean,
            default: false
        },
        canMinimize: {
            type: Boolean,
            default: true
        },
        canOpenInNewTab: {
            type: Boolean,
            default: false
        },
        canPin: {
            type: Boolean,
            default: true
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        canSearch: {
            type: Boolean,
            default: true
        },
        canSearchLocal: {
            type: Boolean,
            default: false
        },
        canSelect: {
            type: Boolean,
            default: true
        },
        canShowInactive: {
            type: Boolean,
            default: false
        },
        canSync: {
            type: Boolean,
            default: true
        },
        canUnselect: {
            type: Boolean,
            default: true
        },
        clearBlades: {
            type: Boolean,
            default: false
        },
        clearOtherBlades: {
            type: Boolean,
            default: false
        },
        closeOnEmpty: {
            type: Boolean,
            default: true
        },
        colClass: {
            type: String,
            default: 'my-0 pa-0 ml-0 mr-1'
        },
        csvIncludeTitle: {
            type: Boolean,
            default: false
        },
        csvIncludeTitleTwo: {
            type: Boolean,
            default: false
        },
        customBladeData: {
            type: Object,
            default: null
        },
        customFilters: { //a list of custom filters which when selected adjust the query parameter
            type: Array,
            default: () => { return [] } //{ filter: String, filterFunction: () => { return String }, paramProp: (default = 'query') }
        },
        customURL: {
            type: String,
            default: null
        },
        defaultFilters: { //a list of strings which are names of filters which start as selected
            type: Array,
            default: null
        },
        defaultBladeWidth: {
            type: Number,
            default: 700
        },
        defaultPageWidth: {
            type: String,
            default: '100%'
        },
        dense: {
            type: Boolean,
            default: true
        },
        dependantBlades: {
            type: Array,
            default: () => { return [] } //[String]
        },
        dividers: {
            type: Boolean,
            default: true
        },
        expanded: {
            type: Array,
            default: () => { return [] }
        },
        filters: {
            type: Array,
            default: null
        },
        flat: {
            type: Boolean,
            default: true
        },
        flexColumn: {
            type: Boolean,
            default: false
        },
        frameVariant: { //default, transparent, opaque, light, dark
            type: String,
            default: 'default'
        },
        getParams: {
            type: Function,
            default: null
        },
        getNewBladeData: {
            type: Function,
            default: null
        },
        getNewQuery: {
            type: Function,
            default: null
        },
        getSelectBladeData: {
            type: Function,
            default: null
        },
        getSelectQuery: {
            type: Function,
            default: null
        },
        headers: {
            type: Array, //{ text, textFilter, value, valueFilter, breakdown, csv, csvArray, csvText, csvFilter, csvBreakdown, csvProductIDProp, hide }
            default: null
        },
        headersTwo: {
            type: Array,
            default: null
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
        hideActionsTwo: {
            type: Boolean,
            default: false,
        },
        hideBackButton: {
            type: Boolean,
            default: false
        },
        hideBladeHeader: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: true
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        hideHeaderTwo: {
            type: Boolean,
            default: false
        },
        hoverItemActions: {
            type: Boolean,
            default: true
        },
        isChildBlade: {
            type: Boolean,
            default: false
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        itemsPerPage: {
            type: Number,
            default: 75
        },
        itemProperties: {
            type: Array,
            default: null
        },
        itemsTwo: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingMsg: {
            type: String,
            default: null
        },
        measurements: {
            type: Array,
            default: null
        },
        measurementType: {
            type: String,
            default: null
        },
        minimizeOnSelect: {
            type: Boolean,
            default: false
        },
        navigation: null,
        onCanDelete: {
            type: Function,
            default: null
        },
        onCanOpenBlade: {
            type: Function,
            default: null
        },
        onCanPull: {
            type: Function,
            default: null
        },
        onCanSelect: {
            type: Function,
            default: null
        },
        onDelete: {
            type: Function,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onInsertDataAsync: {
            type: Function,
            default: null
        },
        onPullAsync: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        onSelect: {
            type: Function,
            default: null
        },
        onSelectTwo: {
            type: Function,
            default: null
        },
        otherUsedHeight: {
            type: Number,
            default: 48
        },
        pageVariant: { //default, transparent, opaque, light, dark
            type: String,
            default: 'opaque'
        },
        params: {
            type: Object,
            default: null
        },
        permanent: {
            type: Boolean,
            default: false
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        resetToggle: { //different to refresh toggle because pagination and default filters are all reset
            type: Boolean,
            default: false
        },
        resizable: {
            type: Boolean,
            default: true
        },
        searchProps: {
            type: Array,
            default: null
        },
        selectKey: {
            type: String,
            default: 'isSelectable'
        },
        selectMany: {
            type: Boolean,
            default: false
        },
        showFilters: {
            type: Boolean,
            default: true
        },
        showList: {
            type: Boolean,
            default: false
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        showSettingsTwo: {
            type: Boolean,
            default: false
        },
        showTable: {
            type: Boolean,
            default: false
        },
        singleExpand: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: null
        },
        startRefresh: {
            type: Boolean,
            default: false
        },
        syncNavigation: null,
        threeLine: {
            type: Boolean,
            default: false
        },
        title: null,
        titleTwo: null,
        toolbarClass: {
            type: String,
            default: 'primary lighten-1'
        },
        tourStatus: {
            type: String,
            default: null
        },
        twoLine: {
            type: Boolean,
            default: false
        },
        updateDependants: {
            type: Boolean,
            default: true
        },
        useListItems: {
            type: Boolean,
            default: true
        },
        useServerFilters: {
            type: Boolean,
            default: true
        },
        useServerPagination: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        items: function() {
            this.pullItems();
        },
        loading: function(val) {
            this.mLoadingMsg = val ? 'Loading' : null;
        },
        loadingMsg(val) {
            this.mLoadingMsg = val;
        },
        params: function(val) {
            if (JSON.stringify(val) !== JSON.stringify(this.mParams)) {
                this.mParams = val;
            }
        },
        refreshToggle: function() {
            this.refresh();
        },
        resetToggle: function() {
            this.reset();
        },
        selectedFilters: function(val) {
            this.isFilterChanged = JSON.stringify(val) != JSON.stringify(this.originalFilters);
        },
        showInactive: function() {
            this.refresh(false);
        }
    },
    created() {
        this.mLoadingMsg = this.loading ? 'Loading' : this.loadingMsg;

        if (this.params != null) {
            this.mParams = this.params;
        }

        if (this.isLengthyArray(this.defaultFilters)) {
            this.defaultFilters.forEach(dFilter => {
                var dInd = this.chipFilters.findIndex(y => y == dFilter);
                if (dInd >= 0) {
                    this.selectedFilters.push(dInd);
                }
            })
            this.originalFilters = this.selectedFilters;
        }
    },
    mounted() {
        if (this.bladesData == null) {
            if (this.$route.query.searchString) {
                this.searchString = this.$route.query.searchString;
                this.searchInput = this.searchString;
                this.showSearch = true;
            }

            if (this.$route.query.filterBy) {
                var fList = this.$route.query.filterBy.split(',');
                fList.forEach(filter => {
                    var fInd = this.chipFilters.findIndex(y => y == filter);
                    if (!this.selectedFilters.some(s => s == fInd)) {
                        this.selectedFilters.push(fInd);
                    }
                })

                this.originalFilters = this.selectedFilters;
            }

            if (this.$route.query.page) {
                this.currentPage = Number.parseInt(this.$route.query.page);
            }
        }
        
        if (this.params != null) {
            this.mParams = this.params;
        }

        this.$emit('mounted');

        this.refreshTableHeaders(true);
    },
    computed: {
        addBladeNameC() {
            if (this.addTrainingBladeName == null || !this.$BlitzIt.navigation.isTraining()) {
                return this.addBladeName;
            }
            else {
                return this.addTrainingBladeName;
            }
        },
        allDisplayFilters() {
            return this.tableHeaders.filter(x => x.textFilter != null || x.textFunction != null || x.display != null || x.breakdown === true || x.bool === true || (x.navigation != null && x.itemText != null)); // || this.displayFilters.some(y => y == x.value));
        },
        allDisplayFiltersTwo() {
            return this.tableHeadersTwo.filter(x => x.textFilter != null || x.textFunction != null || x.display != null);
        },
        frameClass() {
            let e = this.bladeClass ?? ''
            
            if (this.frameVariant == 'transparent') {
                e = `${e} transparent`
            }

            return e
        },
        frameStyle() {
            let e = '';

            if (this.frameVariant == 'opaque') {
                e = `${e} background: ${this.$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.90)' : 'rgba(255, 255, 255, 0.65)'};`;
            }

            return e;
        },
        chipFilters() {
            var filterList = [];
            
            if (this.isLengthyArray(this.customFilters)) {
                filterList.push(...this.customFilters.map(x => x.filter));
            }

            if (this.useServerFilters && this.navigation != null) {
                filterList.push(...this.$BlitzIt.store.filters(this.navigation));
            }

            return filterList;
        },
        compSmall() {
            return this.small != null ? this.small : (this.$vuetify.breakpoint.mobile ? false : true);
        },
        computedMeasurements() {
            return this.measurements != null ? this.measurements : this.mMeasurements;
        },
        csvItems() { //{ csv: true, () => {}, string,  }
            if (!this.isLengthyArray(this.headers)) {
                return [];
            }
            return this.headers
                .filter(y => y.csv != undefined || y.csvText != undefined || y.csvFilter != undefined || y.csvBreakdown != undefined || y.csvArray != undefined)
                .map(z => {
                    return Object.assign({}, z, {
                        breakdown: z.csvBreakdown === true,
                        csvArray: z.csvArray === true,
                        header: z.csvText || z.text,
                        itemText: z.itemText,
                        navigation: z.navigation,
                        value: (z.csv === true || z.csv == undefined) ? z.value : z.csv,
                        valueFilter: z.csvFilter || z.textFilter
                    });
                });
        },
        csvItemsTwo() { //{ csv: true, () => {}, string,  }
            //return this.headers;
            if (!this.isLengthyArray(this.headersTwo)) {
                return [];
            }
            return this.headersTwo
                .filter(y => (y.csvText != null || (y.text != null && y.csv !== false)) && (y.csv != undefined || y.csvFilter != undefined))
                .map(z => {
                    return {
                        header: z.csvText || z.text,
                        value: (z.csv === true || z.csv == undefined) ? z.value : z.csv,
                        valueFilter: z.csvFilter,
                        breakdown: z.csvBreakdown === true,
                        csvArray: z.csvArray === true
                    };
                });
        },
        externalOptions() {
            return this.syncNavigation != null ? this.$BlitzIt.navigation.findExternalParties(this.syncNavigation) : [];
        },
        filteredItems() {
            var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            
            if (this.canSearchLocal && this.searchInput != null) {
                l = l.filter(x => this.hasSearch(x, this.searchInput, this.searchableProperties));
            }

            return l;
        },
        listStyle() {
            if (this.actualHeight != null) {
                return `height: calc(${this.actualHeight})`;
            }
            else if (this.actualUsedHeight != null) {
                return `height: calc(100vh - ${this.actualUsedHeight}px)`;
            }
            else {
                var mUsedHeight = this.otherUsedHeight;

                if (this.bladesData != null) {
                    mUsedHeight += 8;
                }

                if (this.showBottomNavigation) {
                    mUsedHeight += 57;
                }

                if (!this.hideHeader) {
                    mUsedHeight += 48;
                }

                if (!this.hideBladeHeader) {
                    mUsedHeight += 48;
                }

                return `height: calc(100vh - ${mUsedHeight}px)`;
            }
        },
        mobileFilteredIcon() {
            return this.tableHeaders.find(x => x.isIcon);
        },
        mobileFilteredIconTwo() {
            return this.tableHeadersTwo.find(x => x.isIcon);
        },
        mobileFilteredTitles() {
            return this.tableHeaders.filter(x => x.title != null).sort(firstBy(x => x.title));
        },
        mobileFilteredTitlesTwo() {
            return this.tableHeadersTwo.filter(x => x.title != null).sort(firstBy(x => x.title));
        },
        mobileFilteredSubtitles() {
            return this.tableHeaders.filter(x => x.subtitle != null).sort(firstBy(x => x.subtitle));
        },
        mobileFilteredSubtitlesTwo() {
            return this.tableHeadersTwo.filter(x => x.subtitle != null).sort(firstBy(x => x.subtitle));
        },
        permittedToEdit() {
            return this.navigation != null ? this.$BlitzIt.auth.canEdit(this.navigation) : true;
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.mProxyID != null) {
                return this.mProxyID;
            }
            else if (this.proxyIDParam != null && this.bladeData != null && this.bladeData.data != null && this.bladeData.data[this.proxyIDParam] != null) {
                return this.bladeData.data[this.proxyIDParam];
            }
            else if (this.proxyIDParam != null && this.$route.query != null && this.$route.query[this.proxyIDParam] != null) {
                return this.$route.query[this.proxyIDParam];
            }

            return null;
        },
        searchableProperties() {
            var l = this.tableHeaders.filter(x => x.searchable != null).map(x => x.value);
            if (this.isLengthyArray(this.searchProps)) {
                l = [...new Set(l.concat(this.searchProps))];
            }

            return l;
        },
        serverFiltersLoaded() {
            return this.navigation != null ? this.$BlitzIt.store.hasFilters(this.navigation) : false;
        },
        showBottomNavigation() {
            return this.useServerPagination && this.totalPages > 1;
        },
        tableHeadersTwo() {
            var rHeaders = [];
            if (this.isLengthyArray(this.headersTwo)) {
                for (var i = 0; i < this.headersTwo.length; i++) {
                    if (this.headersTwo[i].text != null) {
                        rHeaders.push(this.headersTwo[i]);
                    }
                }
            }
            if (!this.hideActionsTwo) {
                rHeaders.push({ text: 'Actions', value: 'actions', align: 'right', width: this.actionsWidth || 'auto' });
            }
            return rHeaders;
        },
    }, 
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        changeExternalParty(party) {
            this.currentExternalParty = party;
            this.pullItems(true);
        },
        changePage() {
            this.pullItems();
        },
        closeDelete() {
            this.selectedItem = null;
            this.showDelete = false;
        },
        conditionsHaveChanged() { //checks and returns true or false, always ending with updating the recentConditions with the currentConditions
            var currentConditions = {
                ...this.getParamObj(),
                navigation: this.navigation,
                itemID: this.itemID,
                proxyID: this.proxyCompanyID,
                ...this.bladeData != null ? this.bladeData.data : null
            }
            
            var currentJSON = JSON.stringify(currentConditions);
            var isDif = currentJSON != this.recentConditions;
            this.recentConditions = currentJSON;
            return isDif;
        },
        deleteItem(item) {
            this.selectedItem = item;
            this.showDelete = true;
        },
        itemSelected(d) {
            this.$emit('itemSelected', d);
        },
        async deleteItemConfirm() {
            var item = this.selectedItem;
            
            if (this.onDelete != null) {
                item.loadingCount += 1;
                this.$forceUpdate();

                this.onDelete(item);

                if (item != null) {
                    item.loadingCount -= 1;
                }
            }
            else {
                var asyncInd = this.asyncItems.findIndex(y => y.id == item.id);
                if (this.navigation != null) {
                    item.loadingCount += 1;
                    this.$forceUpdate();

                    try {
                        await this.$BlitzIt.store.delete(this.navigation, item.id, this.proxyCompanyID);
                    }
                    catch (err) {
                        this.formError(err);
                    }
                    finally {
                        if (item != null) {
                            item.loadingCount -= 1;
                        }
                        if (asyncInd > -1) {
                            this.asyncItems.splice(asyncInd, 1);
                        }
                    }
                }       
                else {
                    var ind = this.items.findIndex(y => y.id == item.id);
                    if (ind >= 0) {
                        this.items.splice(ind, 1);
                    }
                    if (asyncInd > -1) {
                        this.asyncItems.splice(asyncInd, 1);
                    }
                }
            }
            this.showDelete = false;
        },
        async exportAsCSV() {
            //csvIncludeTitle
            try {
                this.mLoadingMsg = 'Generating...';
                await this.generateCSVFile(this.asyncItems, 'data.csv', this.csvItems, this.csvIncludeTitle ? this.title : null, this.computedMeasurements);
            }
            finally {
                this.mLoadingMsg = null;
            }
        },
        async exportAsCSVTwo() {
            //csvIncludeTitle
            if (this.isLengthyArray(this.itemsTwo)) {
                var csvKeys = this.csvItemsTwo;
                
                if (!this.isLengthyArray(csvKeys)) {
                    csvKeys = Object.keys(this.itemsTwo[0]);
                    csvKeys = csvKeys.map(x => { return { header: this.fromCamelCase(x), value: x }; });
                }

                var increments = [];
                if (csvKeys.some(y => y.breakdown === true)) {
                    try {
                        increments = await this.$BlitzIt.store.getAll('stock-increments');
                    }
                    catch (err) {
                        console.log(this.extractErrorDescription(err));
                    }
                }
                
                var csvList = [];
                this.itemsTwo.forEach(aItem => {
                    var newItem = {};
                    var otherLines = [];

                    csvKeys.forEach(csvDNA => {
                        var v = null;
                        if (typeof(csvDNA.value) == 'function') {
                            //newItem[csvDNA.header] = csvDNA.value(aItem);
                            v = csvDNA.value(aItem);
                        }
                        else if (typeof(csvDNA.value) == 'string') {
                            v = this.getNestedValue(aItem, csvDNA.value);
                        }

                        if (v != null && csvDNA.valueFilter != null) {
                            v = this.$options.filters[csvDNA.valueFilter](v);
                        }

                        if (csvDNA.csvArray == true) {
                            if (this.isLengthyArray(v)) {
                                v.forEach(w => {
                                    var otherNewItem = {};
                                    if (csvDNA.breakdown && w.productID != null) {
                                        otherNewItem[csvDNA.header] = `${getBreakdown(w.quantity, this.computedMeasurements, increments, w.productID)}, ${w.product.productName}`;
                                        otherLines.push(otherNewItem);
                                    }
                                    else {
                                        otherNewItem[csvDNA.header] = w.toString();
                                        otherLines.push(otherNewItem);
                                    }
                                })
                            }
                        }
                        else {
                            if (csvDNA.breakdown) {
                                var prodProp = csvDNA.csvProductIDProp || 'productID';
                                newItem[csvDNA.header] = getBreakdown(v, this.computedMeasurements, increments, aItem[prodProp]);
                            }
                            else {
                                newItem[csvDNA.header] = v;
                            }
                        }
                    });

                    csvList.push(newItem);

                    if (this.isLengthyArray(otherLines)) {
                        otherLines.forEach(l => {
                            csvList.push(l);
                        })
                    }

                    otherLines = [];
                    
                });
                this.generateCSVFile(csvList, 'csvData.csv', csvKeys.map(x => x.header), this.csvIncludeTitleTwo ? this.titleTwo : null);
            }
        },
        getParamObj() {
            var paramObj = this.copyDeep(this.mParams);

            if (this.searchString && this.searchString.length > 0) {
                if (this.canSearch) {
                    paramObj.searchString = this.searchString;
                }
            }
            
            if (this.isLengthyArray(this.selectedFilters)) {
                var serverFilters = [];
                var customFilters = [];
                this.selectedFilters.forEach(selectedIndex => {
                    var sFilter = this.chipFilters[selectedIndex];
                    if (this.isLengthyArray(this.customFilters) && this.customFilters.some(cF => cF.filter == sFilter)) {
                        customFilters.push(this.customFilters.find(cF => cF.filter == sFilter));
                    }
                    else {
                        serverFilters.push(sFilter);
                    }
                });

                if (!this.serverFiltersLoaded && this.isLengthyArray(this.defaultFilters)) {
                    //but there are default filters that are not custom filters
                    this.defaultFilters.forEach(dFilt => {
                        if (!this.customFilters.some(cFilt => cFilt.filter == dFilt)) {
                            serverFilters.push(dFilt);
                        }
                    })
                }

                if (this.isLengthyArray(serverFilters)) {
                    paramObj.filterBy = serverFilters.toString();
                }
                
                if (this.isLengthyArray(customFilters)) {
                    var query = '';
                    customFilters.forEach(cFilter => {
                        if (cFilter.paramProp == null || cFilter.paramProp == 'query') {
                            query = query + (query.length > 0 ? 'ANDALSO' : '') + (cFilter.filterFunction());
                        }
                        else {
                            paramObj[cFilter.paramProp] = cFilter.filterFunction();
                        }
                    })

                    if (query.length > 0) {
                        paramObj.query = query;
                    }
                }
            }
            else if (!this.serverFiltersLoaded && this.isLengthyArray(this.defaultFilters)) {
                var dFilters = [];
                this.defaultFilters.forEach(dFilt => {
                    if (!this.customFilters.some(cFilt => cFilt.filter == dFilt)) {
                        dFilters.push(dFilt);
                    }
                })

                paramObj.filterBy = dFilters.toString();
            }
            
            if (this.isLengthyArray(this.itemProperties)) {
                var props = this.itemProperties.map(x => this.fromCamelCase(x).replaceAll(' ', ''));
                
                if (this.showInactive && !props.some(z => z == 'IsInactive')) {
                    props.push('IsInactive');
                }

                paramObj.properties = props.toString();
            }

            if (this.currentExternalParty != null) {
            
                paramObj.includeProperties = 'ExternalReferences';
            }

            if (this.showInactive) {
                paramObj.includeInactive = true;
            }

            if (this.useServerPagination) {
                paramObj.includeCount = true;
                paramObj.takeAmount = this.itemsPerPage;
                paramObj.takeFrom = (this.currentPage - 1) * this.itemsPerPage;
            }

            if (this.getParams != null) {
                    paramObj = Object.assign({}, { ...paramObj, ...this.getParams(this.bladeData) });
                    
                    // if (JSON.stringify(this.mParams) != JSON.stringify(paramObj)) {
                    //     this.mParams = paramObj;
                    // }
                }
            
            return paramObj;
        },
        isDeletableItem(item) {
            if (!this.canDelete) {
                return false;
            }
            if (this.onCanDelete != null) {
                return this.onCanDelete(item);
            }
            else {
                if (item != null && item.isInactive !== undefined && item.isInactive) {
                    return false;
                }
                
                return this.canDelete;
            }
        },
        isRestorableItem(item) {
            if (!this.showInactive) {
                return false;
            }
            if (item != null && item.isInactive !== undefined && item.isInactive) {
                return true;
            }

            return false;
        },
        loadCount() {
            if (this.navigation != null) {
                var cnt = this.$BlitzIt.store.getLocalCount(this.navigation);
                this.totalPages = roundUp(cnt / this.itemsPerPage);
            }
        },
        async pullExternalParties() {
            var eParties = this.$BlitzIt.navigation.findExternalParties(this.syncNavigation || this.navigation);

            if (this.isLengthyArray(eParties)) {
                var extPath = this.$BlitzIt.navigation.findExternalPartyCredentialNavigation(this.syncNavigation || this.navigation);
                
                if (!extPath) {
                    this.asyncExternalParties = [];
                    return this.asyncExternalParties;
                }
                else {
                    try {
                        this.mLoadingMsg = 'Pulling Data';

                        var extRes = await this.$BlitzIt.store.getAll(extPath);
                        var rList = [];

                        extRes.forEach(extItem => {
                            var cred = eParties.find(x => x.party === extItem.externalPartyOptionID);
                            if (cred != null) {
                                rList.push(cred);
                            }
                        })
                        
                        this.asyncExternalParties = rList;
                        return this.asyncExternalParties;
                    }
                    catch (err) {
                        this.formError(err);
                        this.asyncExternalParties = [];
                    }
                    finally {
                        this.mLoadingMsg = null;
                    }
                }
            }

            return [];
        },
        async pullItems(refresh = false) {
            if (this.onCanPull != null && !this.onCanPull()) {
                return;
            }
            
            if (this.items != null) {
                this.asyncItems = this.items.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));

                if (this.onInsertDataAsync != null) {
                    this.originalDataBag = await this.onInsertDataAsync(this.asyncItems);
                }

                return;
            }

            if (this.navigation == null || this.mParams == null) {
                return;
            }

            try {
                this.mLoadingMsg = 'Getting Data';

                var paramObj = this.getParamObj();

                var cURL = null;
                if (this.customURL != null) {
                    cURL = this.customURL;
                    var cID = this.itemID || this.id;
                    if (cID != null) {
                        cURL = cURL.replace('{id}', cID);
                    }
                }

                var res = null;
                if (this.isSingle) {
                    var idParam = this.itemID != null ? this.itemID : this.id;
                    res = await this.$BlitzIt.store.get(this.navigation, idParam, paramObj, refresh, this.proxyCompanyID, cURL);
                }
                else if (this.onPullAsync != null) {
                    res = await this.onPullAsync(this.navigation, paramObj, refresh, this.proxyCompanyID, cURL);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, paramObj, refresh, this.proxyCompanyID, cURL);
                }
                
                if (Array.isArray(res)) {
                    res = res.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));
                }
                
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh, this.bladeData, paramObj);
                }
                else {
                    this.asyncItems = res;
                }
                
                if (this.onInsertDataAsync != null) {
                    this.originalDataBag = await this.onInsertDataAsync(this.asyncItem);
                }

                this.$emit('fetched', this.asyncItems);
                this.loadCount();
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.mLoadingMsg = null;
            }
        },
        async pushToParty(item) {
            if (this.currentExternalParty == null) {
                return;
            }

            try {
                item.loadingCount += 1;
                item.errorMsg = null;
                this.$forceUpdate();

                if (this.syncNavigation != null) {
                    //push via sync/ep controller
                    var rItem = {
                        localID: item.id,
                        externalItemID: null,
                        externalParty: this.currentExternalParty.party,
                        externalPartyID: this.currentExternalParty.party
                    };

                    //var returnItem = await this.$BlitzIt.store.pushSyncItem(this.syncNavigation, item.id, this.currentExternalParty.party);
                    var r = await this.$BlitzIt.api.patch(this.syncNavigation, [rItem], null, '/patch?partyID=' + this.currentExternalParty.party);

                    r.data.data.forEach(returnItem => {
                        if (returnItem.message != null) {
                            item.errorMsg = returnItem.message;
                        }
                        else {
                            item.externalReferences.push(rItem);
                        }
                    })

                    // r.data.data.forEach(rItem => {
                    //     if (rItem.externalItemID != null) {

                    //     }
                    // })
                    // if (returnItem.message != null) {
                    //     item.errorMsg = returnItem.message;
                    // }
                    // else {
                    //     item.externalReferences.push({ localID: item.id, externalParty: this.currentExternalParty.party });
                    // }
                    
                }
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                item.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        async removePartyRef(item) {
            if (this.currentExternalParty == null) {
                return;
            }

            try {
                item.loadingCount += 1;
                item.errorMsg = null;
                this.$forceUpdate();

                if (this.syncNavigation != null) {
                    // //push via sync/ep controller
                    // var rItem = {
                    //     localID: item.id,
                    //     externalItemID: null,
                    //     externalParty: this.currentExternalParty.party,
                    //     externalPartyID: this.currentExternalParty.party
                    // };
                    var extInd = item.externalReferences.findIndex(x => x.externalParty == this.currentExternalParty.party);

                    if (extInd >= 0) {
                        await this.$BlitzIt.api.patch(this.syncNavigation, [item.externalReferences[extInd].externalID], null, '/patch/unsync?partyID=' + this.currentExternalParty.party);
                        item.externalReferences.splice(extInd, 1);
                    }

                    //var returnItem = await this.$BlitzIt.store.pushSyncItem(this.syncNavigation, item.id, this.currentExternalParty.party);
                    

                    // var refInd = item.externalReferences.findIndex(x => x.)
                    // r.data.data.forEach(returnItem => {
                    //     if (returnItem.message != null) {
                    //         item.errorMsg = returnItem.message;
                    //     }
                    //     else {
                    //         item.externalReferences.push(rItem);
                    //     }
                    // })
                }
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                item.loadingCount -= 1;
                this.$forceUpdate();
            }
        },
        refresh(refresh = true) {
            this.currentPage = 1;
            this.totalPages = 0;
            this.originalFilters = this.selectedFilters;
            this.isFilterChanged = JSON.stringify(this.selectedFilters) != JSON.stringify(this.originalFilters);
            this.searchString = this.searchInput;
            this.showError = false;
            this.errorMessage = null;

            //reset syncing
            this.currentExternalParty = null;
            this.syncItems = null;

            this.pullItems(refresh);
        },
        refreshTableHeaders(reset = false) {
            if (reset && this.isLengthyArray(this.headers)) {
                this.headerOptions.length = 0;
                this.headers.forEach(h => {
                    var opt = Object.assign({ selected: false }, h);
                    if (opt.text != null) {
                        opt.class = 'v-step-' + opt.text.toLowerCase().replaceAll(' ', '');
                        opt.selected = opt.hide !== true;

                        this.headerOptions.push(opt);
                    }

                    if (h.class != null) {
                        opt.class = opt.class + ' ' + h.class;
                    }
                });

                if (!this.hideActions) {
                    this.headerOptions.push({ text: 'Actions', value: 'actions', align: 'right', width: this.actionsWidth || 'auto', selected: true });
                }
            }

            this.tableHeaders.length = 0;
            this.tableHeaders = this.headerOptions.filter(z => z.selected);
        },
        reset() {
            this.currentPage = 1;
            this.totalPages = 0;
            this.originalFilters = [];
            this.selectedFilters = [];
            this.searchString = null;
            this.searchInput = null;

            if (this.isLengthyArray(this.defaultFilters)) {
                this.defaultFilters.forEach(dFilter => {
                    this.selectedFilters.push(this.chipFilters.findIndex(y => y == dFilter));
                })
                this.originalFilters = this.selectedFilters;
            }
            
            this.$BlitzIt.store.clear(this.navigation);
            this.pullItems(true);
        },
        async restoreItem(item) {
            if (this.navigation == null) {
                return;
            }

            item.loadingCount += 1;
            
            try {
                await this.$BlitzIt.store.restore(this.navigation, item.id, this.proxyCompanyID);
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                item.loadingCount -= 1;
                item.isInactive = false;
            }
        },
        openInNewTab(item) {
            var selectBlade = { 
                bladeName: this.addBladeNameC, 
                data: { id: item.id },
                query: {},
                clearBlades: this.clearBlades, 
                clearOtherBlades: this.clearOtherBlades ? this.bladeName: null,
                bladesToClear: this.bladesToClear
            };

            if (this.getSelectBladeData != null) {
                selectBlade.data = Object.assign({}, selectBlade.data, this.getSelectBladeData(this.bladeData, item, this.computedMeasurements));
            }

            if (this.proxyCompanyID != null) {
                selectBlade.query.proxyID = this.proxyCompanyID;
            }

            if (this.getSelectQuery != null) {
                selectBlade.query = Object.assign({}, selectBlade.query, this.getSelectQuery(this.bladeData, item, this.computedMeasurements));
            }
            
            if (this.onCanOpenBlade != null) {
                var str = this.onCanOpenBlade(selectBlade);
                if (str === false) {
                    return;
                }
                else if (typeof(str) === 'string') {
                    this.msg = str;
                    return;
                }
            }

            //open in new tab
            var routeData = this.$router.resolve({
                name: selectBlade.bladeName, 
                params: selectBlade.data, 
                query: selectBlade.query 
            });

            window.open(routeData.href, '_blank');
        },
        saveHistoryWithFilters() {
            if (this.bladesData == null) {
                var paramObj = this.getParamObj();

                if (paramObj.searchString) {
                    this.$route.query.searchString = paramObj.searchString;
                }
                else {
                    delete this.$route.query.searchString;
                }

                if (paramObj.filterBy) {
                    this.$route.query.filterBy = paramObj.filterBy;
                }
                else {
                    delete this.$route.query.filterBy;
                }

                // if (paramObj.includeCount) {
                //     this.$route.query.includeCount = paramObj.includeCount;
                // }
                
                if (this.currentPage > 1) {
                    this.$route.query.page = this.currentPage;
                }
                else {
                    delete this.$route.query.page;
                }

                var path = this.$router.resolve(this.$route);
                history.replaceState({}, null, path.href);
            }
        },
        async saveItem(itemToSave) {
            this.showError = false;

            this.mLoadingMsg = 'Saving';

                // var itemToSave = this.onGetSave(this.bladeData, this.asyncItem);
                
                try {
                    if (this.isNew) {
                        await this.$BlitzIt.store.post(this.navigation, itemToSave, this.proxyCompanyID);
                    }
                    else {
                        await this.$BlitzIt.store.patch(this.navigation, itemToSave, this.proxyCompanyID)
                    }
                }
                catch (err) {
                    this.formError(err);
                }
                finally {
                    this.mLoadingMsg = null;
                }
        },
        selectItem(item) {
            if (item != null && item === this.lastSelectedItem && this.canUnselect) {
                this.lastSelectedItem = null;
            }
            else {
                this.lastSelectedItem = item;
            }
            
            this.$emit('select', this.lastSelectedItem);

            if (!this.canSelect || (this.onCanSelect != null && !this.onCanSelect(item))) {
                return;
            }
            
            if (this.onSelect != null) {
                this.onSelect(this.bladeData, item);
            }
            else if (item == null) {
                if (this.bladesData != null) {
                    this.bladesData.closeBlade({ bladeName: this.addBladeNameC });
                }
            }
            else {
                var selectBlade = { 
                    bladeName: this.addBladeNameC, 
                    data: { id: item.id },
                    query: {},
                    clearBlades: this.clearBlades, 
                    clearOtherBlades: this.clearOtherBlades ? this.bladeName: null,
                    bladesToClear: this.bladesToClear
                };

                if (this.getSelectBladeData != null) {
                    selectBlade.data = Object.assign({}, selectBlade.data, this.getSelectBladeData(this.bladeData, item, this.computedMeasurements));
                }

                if (this.proxyCompanyID != null) {
                    selectBlade.query.proxyID = this.proxyCompanyID;
                }

                if (this.getSelectQuery != null) {
                    selectBlade.query = Object.assign({}, selectBlade.query, this.getSelectQuery(this.bladeData, item, this.computedMeasurements));
                }
                
                if (this.onCanOpenBlade != null) {
                    var str = this.onCanOpenBlade(selectBlade);
                    if (str === false) {
                        return;
                    }
                    else if (typeof(str) === 'string') {
                        this.msg = str;
                        return;
                    }
                }

                if (this.bladesData == null) {
                    //save filters, etc.
                    this.saveHistoryWithFilters();
                    this.$router.push({ 
                        name: selectBlade.bladeName, 
                        params: selectBlade.data, 
                        query: selectBlade.query 
                    });
                }
                else { 
                    this.bladesData.openBlade(selectBlade);

                    if (this.minimizeOnSelect == true && !this.isPinned) {
                        this.bladesData.minimizeBlade({ bladeName: this.bladeName });
                    }
                }
            }
            
            if (this.bladesData != null && this.closeOnEmpty === true && !this.isLengthyArray(this.filteredItems)) {
                this.bladesData.closeBlade(this.bladeData);
            }
        },
        selectItemTwo(item) {
            if (this.onSelectTwo != null) {
                this.onSelectTwo(this.bladeData, item);
            }
        },
        selectMeasurements(mList) {
            this.mMeasurements = mList;
            this.$emit('selectMeasurements', this.mMeasurements);
        },
        toArchives() {
            if (this.bladesData != null) {
                this.bladesData.openBlade({ bladeName: this.archiveBladeName });
            }
            else {
                //save filters, etc.
                this.saveHistoryWithFilters();
                this.$router.push({ name: this.archiveBladeName });
            }
        },
        toggleHeaderOption(opt) {
            if (opt != null) {
                opt.selected = !opt.selected;

                this.refreshTableHeaders();
            }
        },
        async toggleSearch() {
            if (this.showSearch) {
                this.searchInput = null;
                this.showSearch = false;
                this.refresh(false);
            }
            else {
                this.showSearch = true;
                this.$nextTick(() => {
                    this.$refs.searchField.focus();
                });
            }
        },
        updateBlade(bladeData) {
            this.bladeData = bladeData;

            if (bladeData != null) {
                if (bladeData.data.searchString) {
                    this.searchString = bladeData.data.searchString;
                    this.searchInput = this.searchString;
                    this.showSearch = true;
                }
                // if (bladeData.data.filterBy) {
                //     params.filterBy = bladeData.data.filterBy;
                // }
                if (bladeData.data.page) {
                    this.currentPage = Number.parseInt(bladeData.data.page);
                }
                
                // if (JSON.stringify(this.mParams) != JSON.stringify(params)) {
                //     this.mParams = params;
                // }

                if (this.getParams != null) {
                    var params = Object.assign({}, { ...params, ...this.getParams(bladeData) });
                    
                    if (JSON.stringify(this.mParams) != JSON.stringify(params)) {
                        this.mParams = params;
                    }
                }
                
                //update pinned
                if (bladeData.isPinned != undefined) {
                    this.isPinned = bladeData.isPinned;
                }

                if (bladeData.data != null) {
                    this.id = bladeData.data.id;

                    if (bladeData.data.filterBy != null) {
                        var fList = bladeData.data.filterBy.split(',');
                        //this.selectedFilters = fList.map(x => this.chipFilters.findIndex(y => y == x)).filter(y => y >= 0);
                        fList.forEach(filter => {
                            var fInd = this.chipFilters.findIndex(y => y == filter);
                            if (!this.selectedFilters.some(s => s == fInd)) {
                                this.selectedFilters.push(fInd);
                            }
                        })

                        this.originalFilters = this.selectedFilters;
                    }
                }
            }

            if (this.conditionsHaveChanged()) {
                this.pullItems(this.startRefresh);
            }

            this.$emit('sourceChanged', bladeData);
        }
    }
}
</script>