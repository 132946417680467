<template>
    <v-card :disabled="disabled || !isEditing" :ripple="ripple" :outlined="outlined" class="text-center" :height="height" :width="width">
        <p class="my-2 text-center">{{ label }}</p>
        <v-text-field
            v-model.number="v"
            class="centered-input"
            :disabled="disabled || !isEditing"
            flat
            hide-details
            single-line
            solo
            :style="'font-size: ' + fontSize + 'px;'"
            @change="update"
            type="number" />
        <!-- <v-slide-x-transition hide-on-leave>
            <v-icon v-if="v" :size="iconSize" :class="disabled ? '' : 'success--text'">{{ trueIcon }}</v-icon>
            <v-icon v-else :size="iconSize" :class="disabled ? '' : 'error--text'">{{ falseIcon }}</v-icon>
        </v-slide-x-transition> -->
    </v-card>
</template>

<script>
export default {
    name: 'BT-Field-Check-Button',
    data: function() {
        return {
            v: null
        }
    },
    props: {
        dense: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fontSize: {
            type: String,
            default: '25'
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        label: null,
        height: {
            type: String,
            default: '100'
        },
        width: {
            type: String,
            default: 'auto'
        },
        outlined: {
            type: Boolean,
            default: true
        },
        reverse: {
            type: Boolean,
            default: false
        },
        ripple: {
            type: Boolean,
            default: true
        },
        value: {
            type: Number,
            default: null
        }
    },
    watch: {
        value: function(val) {
            if (this.v !== val) {
                this.v = this.reverse ? 0 - val : val;
            }
        }
    },
    mounted() {
        this.v = this.reverse ? 0 - this.value : this.value;
    },
    methods: {
        update(v) {
            var e = this.reverse ? 0 - v : v;

            this.$emit('input', e);
            this.$emit('change', e);
        }
    }
}
</script>

<style scoped>

    .centered-input >>> input {
        text-align: center;
    }

</style>