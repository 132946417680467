<template>
    <v-btn 
        @click.stop="showDialog = true"
        :class="buttonClass"
        :block="block"
        :fab="fab"
        :large="large"
        :right="right"
        :small="small"
        :icon="icon != null && text == null"
        :text="text != null && !isButton">
        <v-dialog v-model="showDialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{ msg }}</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click.stop="showDialog = false">{{ cancelText }}</v-btn>
                    <v-btn color="blue darken-1" text @click.stop="confirm">{{ confirmText }}</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <slot>
            <v-icon v-if="icon != null" :large="large" :left="text != null" :small="small">{{ icon }}</v-icon>
            {{ text }}
        </slot>
    </v-btn>
</template>

<script>
export default {
    name: 'BT-Button-Confirm',
    data: function() {
        return {
            showDialog: false
        }
    },
    props: {
        buttonClass: {
            type: String,
            default: null //'primary--text'
        },
        msg: {
            type: String,
            default: 'Are you sure?'
        },
        block: {
            type: Boolean,
            default: false
        },
        cancelText: {
            type: String,
            default: 'Cancel'
        },
        confirmText: {
            type: String,
            default: 'OK'
        },
        fab: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: null
        },
        isButton: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: false
        },
        right: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: null
        }
    },
    methods: {
        confirm() {
            this.$emit('click');
            this.showDialog = false;
        }
    }
}
</script>