<template>
    <v-bottom-sheet v-model="show" :persistent="persistent" scrollable :close-delay="delay">
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                :class="buttonClass"
                v-bind="attrs"
                v-on="on"
                :block="block"
                :icon="icon != null && text == null"
                :large="large"
                :small="small"
                :text="text != null">
                <v-icon 
                    v-if="icon != null"
                    :class="buttonClass"
                    :large="large"
                    :left="text != null"
                    :small="small">{{ icon }}</v-icon>
                <span :class="buttonClass">{{ text }}</span>
            </v-btn>
        </template>
        <v-card>
            <slot name="header" v-bind:close="close" v-bind:cancel="cancel">
                <v-subheader v-if="header != null">
                    {{ header }}
                    <v-spacer />
                    <v-btn v-if="persistent" :large="large" icon @click="close">
                        <v-icon>mdi-check</v-icon>
                    </v-btn>
                </v-subheader>
            </slot>
            <v-card-text>
                <slot />
            </v-card-text>
        </v-card>
    </v-bottom-sheet>
</template>

<script>
export default {
    name: 'BT-Popup',
    data: function() {
        return {
            show: false,
        }
    },
    props: {
        block: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null
        },
        delay: {
            type: Number,
            default: 0
        },
        header: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        persistent: {
            type: Boolean,
            default: false
        },
        scrollable: {
            type: Boolean,
            default: false
        },
        showToggle: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: null
        }
    },
    watch: {
        showToggle() {
            this.show = true;
        }
    },
    methods: {
        cancel() {
            this.$emit('cancel');
            this.show = false;
        },
        close() {
            this.$emit('close');
            this.show = false;
        }
    }
}
</script>