<template>
    <v-dialog 
      v-model="shown"
      @click:outside="cancelIfNotRequired"
      :max-width="325"
      :persistent="required">
      <v-card>
        <v-responsive>
          <v-card-title v-if="label != null">{{ label }}</v-card-title>
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                @changed="unitChanged"
                autofocus
                v-model.number="currentVal"
                :rules="rules"
                type="number"
                class="shrink text-center">
            </v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn v-if="!required" @click="cancel">Cancel</v-btn>
            <v-btn @click="accept" color="primary">OK</v-btn>
          </v-card-actions>
        </v-responsive>
        <v-overlay :value="loadingMsg != null" absolute class="text-center">
          <v-progress-circular indeterminate />
          <p>{{ loadingMsg }}</p>
      </v-overlay>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
      export default {
          name : "SelectQuantityDialog",
          props : {
              message : Object,
              resolve : Function
          },
          data() {
              return {
                canNegate: null,
                currentVal: null,
                increment: null,
                label: null,
                large: null,
                loadingMsg: null,
                min: null,
                max: null,
                prependIcon: null,
                required: false,
                shown: false,
                suffix: null,
                textFilter: null,
                value: null
              }
          },
          computed: {
            rules() {
                var l = [];

                if (this.min != null && !Number.isNaN(this.min)) {
                    l.push(v => v >= this.min || `Too low.  Minimum is ${this.min}`);
                }

                if (this.max != null && !Number.isNaN(this.max)) {
                    l.push(v => v <= this.max || `Too high.  Maximum is ${this.max}`);
                }

                if (this.increment != null && !Number.isNaN(this.increment)) {
                    l.push(v => v % this.increment == 0 || `Must be in increments of ${this.increment}`);
                }

                return l;
            }
          },
          methods : {
              accept() {
                if (this.$refs.form.validate() && (!this.required || this.currentVal != null)) {
                    this.shown = false;
                    this.resolve(this.currentVal);
                }
              },
              cancelIfNotRequired() {
                if (!this.required) {
                  this.shown = false;
                  this.resolve(false);
                }
              },
              cancel() {
                  this.shown = false;
                  this.resolve(false);
              },
              addUnit() {
                  this.currentVal += this.increment;
                  this.unitChanged();
              },
              removeUnit() {
                  this.currentVal -= this.increment;
                  
                  if (!this.canNegate && this.currentVal < 0) {
                      this.currentVal = 0;
                  }

                  this.unitChanged();
              },
              unitChanged() {
                  if (typeof(this.currentVal) === 'number') {
                      this.$emit('input', this.currentVal);
                      this.$emit('change', this.currentVal);
                  }
              }        
          },
          beforeMount() {
            this.canNegate = this.message.canNegate || false;
            this.increment = this.message.increment || null;
            this.label = this.message.label || 'Select Number';
            this.large = this.message.large || false;
            this.max = this.message.max || null;
            this.min = this.message.min || null;
            this.prependIcon = this.message.prependIcon || null;
            this.required = this.message.required || false;
            this.suffix = this.message.suffix || null;
            this.textFilter = this.message.textFilter || 'toDisplayNumber';
            this.value = this.message.originalValue || null;
            this.theme = this.message.theme || {};
            this.breakpoint = {};
          },
          mounted() {
              this.shown = true;
              this.currentVal = this.value;
              console.log(this.rules);
          }
      }
  </script>
  
  <style scoped>
    .vdp-message {
      white-space: pre-wrap;
    }
  </style>