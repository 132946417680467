import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';
import 'vue-draggable-resizable/dist/VueDraggableResizable.css';
import 'vue-tour/dist/vue-tour.css';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store/index.js';
import BlitzIt from './tools/blitzit/blitzit.js';
import VueCookies from 'vue-cookies';
import VueSignaturePad from 'vue-signature-pad';
import VueGeolocation from 'vue-browser-geolocation';
import { saveLocally, removeLocally, getLocally, baseLogoURL, twiddleThumbs, containsWeekday, companyBannerURL, companyOrderingBackgroundURL, companyLogoURL, isAreaOfSize, getAreaToLeft, getAreaToRight, getAreaAround, capitalizeWords, getLocationLine, measurementArea, isMinDate, compareString, nestedValue, extractErrorDescription, csvContains, toggleCSV, getBreakdown } from '~helpers';
import { v4 as uuidv4 } from 'uuid';
import './registerServiceWorker';
import particles from '~components/particles/vue-particles.vue';
import VueTour from 'vue-tour';
import * as GmapVue from 'gmap-vue';
import { DateTime } from 'luxon/src/luxon';
import VuetifyDialogPromise from '~components/dialog-promise/DialogPromise.js';
import VueCountdown from '@chenfengyuan/vue-countdown';
// import VueHtml2pdf from 'vue-html2pdf';

// import localforage from 'localforage';
// import urlHelpers from '~tools/url-helpers.js';

// optionally import default styles

//import Stripe from 'stripe';

Vue.config.productionTip = false

Vue.config.errorHandler = function(err, vm, info) {
    console.log(err);
    // console.log(vm);
    console.log(info);
}

Vue.use(VueRouter);
Vue.use(VueTour);
Vue.use(BlitzIt, { router, store });
Vue.use(VueCookies);
Vue.use(VueSignaturePad);
Vue.use(VueGeolocation);
// Vue.use(VueHtml2pdf);

Vue.use(GmapVue, {
    load: {
        key: process.env.VUE_APP_GOOGLE_KEY,
        libraries: 'places,drawing'
    },
    installComponents: true
})

//Vue.use(VueParticles);
Vue.component('vue-particles', particles);
Vue.component('countdown', VueCountdown);

Vue.use(VuetifyDialogPromise, {
    locale: 'en',
    snackbarX: 'left',
    snackbarY: 'bottom'
});

// localforage.config({
//     name: 'blitzitweb_db' //urlHelpers.getDbName()
// });

// Vue.use((Vue) => {
//     Vue.prototype.$bubble = function $bubble(eventName, ...args) {
//         //Emit the event on all parent components
//         let component = this;
//         do {
//             console.log('bubbling');
//             console.log(component);
//             component.$emit(eventName, ...args);
//             component = component.$parent;
//         } while (component);
//     }
// })

//Vue.use(Stripe);

Vue.mixin({
    methods: {
        removeLocally(key) {
            removeLocally(key);
            // localStorage.removeItem('BT' + key);
        },
        saveLocally(key, value) {
            saveLocally(key, value);
            // if (value == null) {
            //     localStorage.removeItem('BT' + key);
            // }
            // else {
            //     localStorage.setItem('BT' + key, JSON.stringify(value));
            // }
        },
        getLocally(key) {
            return getLocally(key);
            // try {
            //     return JSON.parse(localStorage.getItem('BT' + key));
            // }
            // catch {
            //     return null;
            // }
        },
        weekdayOptions() {
            return ['Always', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
        },
        async copyToClipboard(txt) {
            await navigator.clipboard.writeText(txt);
        },
        twiddleThumbs(mSec = 2000) {
            return twiddleThumbs(mSec);
        },
        emitExtensionEvent(evName, data) {
            var cEv = new CustomEvent('extensionEvent', { detail: { eventName: evName, data: data } });
            document.dispatchEvent(cEv);
        },
        capitalizeWords(val) {
            return capitalizeWords(val);
        },
        fromCamelCase(val) {
            return val
                .replace(/([A-Z])/g, ' $1')
                .replace(/^./, (str) => {
                    return str.toUpperCase();
                })
        },
        removeSpaces(val) {
            return val.replaceAll(' ', '');
        },
        getUniqueID() {
            return uuidv4();
        },
        companyAccountID() {
            if (BlitzIt.navigation.isTraining()) {
                return 'comp1';
            }
            else {
                return BlitzIt.auth.session?.data?.companyAccountID;
            }
        },
        CSVIsTrue(val) {
            return val != null && (val == 'true' || val == 'True' || val == 'Yes' || val == 'yes');
        },
        csvContains(val, tag) {
            return csvContains(val, tag);
        },
        toggleCSV(val, tag) {
            return toggleCSV(val, tag);
        },
        selectedLocationID() {
            return BlitzIt.auth.session.selectedLocationID;
        },
        defaultUserLocationID() {
            return BlitzIt.auth.session?.data?.defaultLocationID;
        },
        isAreaOfSize(boundary, dif) {
            return isAreaOfSize(boundary, dif);
        },
        getAreaAround(location, dif = null) {
            return getAreaAround(location, dif);
        },
        getAreaToLeft(location, dif = null) {
            return getAreaToLeft(location, dif);
        },
        getAreaToRight(location, dif = null) {
            return getAreaToRight(location, dif);
        },
        getCronExpression(str) {
            if (str == null) {
                return str;
            }

            var split = str.split(/\s+/);
            split = split.slice(0, 5);

            if (split[2] == '*') {
                split[2] = '?';
            }

            return split.join(' ');
        },
        toCompareString(str) {
            return compareString(str);
        },        
        doShow(subCodes, permissions, action) {
            return BlitzIt.auth.doShow(subCodes, permissions, action);
        },   
        doShowByNavName(navName) {            
            return BlitzIt.auth.doShowByNavName(navName, false);
        },
        isPast(val) {
            return BlitzIt.auth.isPast(val);
        },
        toTimeSpan(val, spanName) {
            return BlitzIt.auth.getDiffFromNow(val, spanName);
        },
        getToday() {
            return BlitzIt.auth.getToday();
        },
        getTomorrow() {
            return BlitzIt.auth.getTomorrow();
        },
        getDayAfterTomorrow() {
            return BlitzIt.auth.getDayAfterTomorrow();
        },
        generateProductCSVFile() {
            var file = new Blob(['Product Name,Abbreviation,Is Public,Public Description,Sort Number,Tax Type,Sellable,Buyable,Manufactured,Perishable,Expiry Days,Temperature Sensitive,Length,Width,Height,Weight,Volume'], { type: "text/plain;charset=utf-8" });
            var fileName = 'product-import-template.csv';
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, fileName);
            }
            else {
                var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0);
            }
        },
        generateCustomerCSVFile() {
            var file = new Blob(['Company Name,Address Line One,Street Number,Street Name, Suburb,State,Postcode,Email,Phone Number'], { type: "text/plain;charset=utf-8" });
            var fileName = 'customer-import-template.csv';
            if (window.navigator.msSaveOrOpenBlob) {                    
                window.navigator.msSaveOrOpenBlob(file, fileName);
            }
            else {                    
                var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a);
                a.click();
                setTimeout(function () {
                    document.body.removeChild(a);
                    window.URL.revokeObjectURL(url);
                }, 0);
            }
        },
        generateFile(data, fileName = "csvData.csv") {
            var file = new Blob([data], { type: "text/plain;charset=utf-8" });
            
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, fileName);
            }
            else {
                var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
        },
        async generateCSVFile(list, fileName = "csvData.csv", dnaArray = null, docTitle = null, measurements = []) {
            if (!this.isLengthyArray(list)) {
                return;
            }

            console.log(dnaArray);

            var lineArray = [];

            if (dnaArray == null) {
                dnaArray = Object.keys(list[0]).map(x => { return { header: this.fromCamelCase(x), value: x }; });
            }

            var increments = [];
            if (dnaArray.some(y => y.breakdown === true)) {
                try {
                    increments = await BlitzIt.store.getAll('stock-increments');
                }
                catch (err) {
                    console.log('generating csv file could not pull increments for breakdown');
                    console.log(this.extractErrorDescription(err));
                }
            }

            // if (docTitle != null) {
            //     lineArray.push(docTitle);
            // }

            dnaArray = dnaArray.filter(x => x.header != null);

            //print header row
            // lineArray.push(dnaArray.map(x => x.header))

            for (let i = 0; i < list.length; i++) {
                const d = list[i];

                var newItem = {};
                var extraLines = [];

                for (let ii = 0; ii < dnaArray.length; ii++) {
                    const dna = dnaArray[ii];
                    var v = null;
                    if (typeof(dna.value) == 'function') {
                        v = dna.value(d);
                    }
                    else if (typeof(dna.value) == 'string') {
                        v = this.getNestedValue(d, dna.value);

                        if (dna.navigation != null && v != null) {
                            //search from local storage
                            try {
                                var res = await BlitzIt.store.get(dna.navigation, v, null, false, null, null, true);
                                if (res != null) {
                                    v = res;
                                }
                            }
                            catch (err) {
                                console.log(err);
                            }
                        }
                    }

                    if (v != null && dna.valueFilter != null) {
                        console.log('aa');
                        v = this.$options.filters[dna.valueFilter](v);
                        console.log(v);
                    }

                    if (dna.csvArray) {
                        if (this.isLengthyArray(v)) {
                            v.forEach(w => {
                                var otherNewItem = {};
                                // otherNewItem[dna.header] = w.toString();
                                // extraLines.push(otherNewItem);
                                if (dna.breakdown && w.productID != null) {
                                    otherNewItem[dna.header] = `${getBreakdown(w.quantity, measurements, increments, w.productID)}, ${w.product?.productName}`;
                                    extraLines.push(otherNewItem);
                                }
                                else {
                                    otherNewItem[dna.header] = w.toString();
                                    extraLines.push(otherNewItem);
                                }
                            })
                        }
                    }
                    else {
                        if (dna.breakdown) {
                            var prodProp = dna.csvProductIDProp || 'productID';
                            newItem[dna.header] = getBreakdown(v, measurements, increments, d[prodProp]);
                        }
                        else if (dna.itemText != null) {
                            newItem[dna.header] = nestedValue(v, dna.itemText);
                        }
                        else {
                            newItem[dna.header] = v;
                        }
                    }
                }

                lineArray.push(newItem);

                if (this.isLengthyArray(extraLines)) {
                    extraLines.forEach(e => {
                        lineArray.push(e);
                    })
                }

                extraLines = [];
            }

            var resArray = [];

            if (docTitle != null) {
                resArray.push(docTitle);
            }

            //print header row
            resArray.push(dnaArray.map(x => x.header))

            lineArray.forEach(obj => {
                var propArray = [];
                dnaArray.forEach(function(k) {
                    var v = obj[k.header];
                    propArray.push(v != null ? v : '');
                });
                
                resArray.push(propArray.join(","));
            })
console.log(resArray);
            var csvContent = resArray.join("\n");
            var file = new Blob([csvContent], { type: "text/plain;charset=utf-8" });
            
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, fileName);
            }
            else {
                var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
        },
        // generateCSVFile(list, fileName = "csvData.csv", keyNames = null, title = null) {
        //     if (!this.isLengthyArray(list)) {
        //         return;
        //     }
        //     var lineArray = [];
        //     if (keyNames == null) {
        //         keyNames = Object.keys(list[0]);
        //     }            

        //     var keyArray = [];
        //     keyNames.forEach(k => {
        //         keyArray.push(k);
        //     });

        //     if (title != null) {
        //         lineArray.push(title);
        //     }

        //     lineArray.push(keyArray.join(","));

        //     list.forEach(function(obj) {
        //         var propArray = [];
        //         keyNames.forEach(function(k) {
        //             var v = obj[k];
        //             propArray.push(v != null ? v : '');
        //         });
                
        //         lineArray.push(propArray.join(","));
        //     })
        //     var csvContent = lineArray.join("\n");
        //     var file = new Blob([csvContent], { type: "text/plain;charset=utf-8" });
            
        //     if (window.navigator.msSaveOrOpenBlob) {
        //         window.navigator.msSaveOrOpenBlob(file, fileName);
        //     }
        //     else {
        //         var a = document.createElement("a"),
        //             url = URL.createObjectURL(file);
        //             a.href = url;
        //             a.download = fileName;
        //             document.body.appendChild(a);
        //             a.click();
        //             setTimeout(function () {
        //                 document.body.removeChild(a);
        //                 window.URL.revokeObjectURL(url);
        //             }, 0);
        //         }
        // },
        generateCSV(list, keys, fileName = "csvData.csv", title = null) { //keys = [{ title, key, valueFunction }]
            if (!this.isLengthyArray(list)) {
                return;
            }
            var lineArray = [];
            if (keys == null) {
                keys = Object.keys(list[0]).map(x => { return { title: x, key: x }; });
            }            

            // var keyArray = [];
            // keys.forEach(k => {
            //     keyArray.push(k.key);
            // });

            if (title != null) {
                lineArray.push(title);
            }

            lineArray.push(keys.map(z => z.title).join(","));

            list.forEach(function(obj) {
                var propArray = [];
                keys.forEach(function(k) {
                    var v = null;
                    if (k.valueFunction != null) {
                        v = k.valueFunction(nestedValue(obj, k.key));
                    }
                    else {
                        v = nestedValue(obj, k.key);
                    }
                    propArray.push(v != null ? v : '');
                });
                
                lineArray.push(propArray.join(","));
            })
            var csvContent = lineArray.join("\n");
            var file = new Blob([csvContent], { type: "text/plain;charset=utf-8" });
            
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveOrOpenBlob(file, fileName);
            }
            else {
                var a = document.createElement("a"),
                    url = URL.createObjectURL(file);
                    a.href = url;
                    a.download = fileName;
                    document.body.appendChild(a);
                    a.click();
                    setTimeout(function () {
                        document.body.removeChild(a);
                        window.URL.revokeObjectURL(url);
                    }, 0);
                }
        },
        async importDataFromFile(file) {
            let res = await new Promise((resolve) => {
                let reader = new FileReader();

                reader.onload = () => resolve(reader.result);
                reader.readAsText(file);
            })

            return res;
        },
        async importCustomersFromFile(file) {
            var txt = await this.importDataFromFile(file);
            var customers = [];
            if (txt.length) {
                var items = txt.split('\n');
                
                for (let i = 0; i < items.length; i++) {
                    var nItem = {
                        companyName: null,
                        addressLineOne: null,
                        streetNumber: null,
                        streetName: null,
                        suburb: null,
                        state: null,
                        postcode: null,
                        email: null,
                        phoneNumber: null,
                        lat: null,
                        lng: null                                
                    };

                    var nItemProps = items[i].split(',');

                    if (nItemProps.length > 0) {
                        nItem.companyName = nItemProps[0];
                    }
                    if (nItemProps.length > 1) {
                        nItem.addressLineOne = nItemProps[1];
                    }
                    if (nItemProps.length > 2) {
                        nItem.streetNumber = nItemProps[2];
                    }
                    if (nItemProps.length > 3) {
                        nItem.streetName = nItemProps[3];
                    }
                    if (nItemProps.length > 4) {
                        nItem.suburb = nItemProps[4];
                    }
                    if (nItemProps.length > 5) {
                        nItem.state = nItemProps[5];
                    }
                    if (nItemProps.length > 6) {
                        nItem.postcode = nItemProps[6];
                    }
                    if (nItemProps.length > 7) {
                        nItem.email = nItemProps[7];
                    }
                    if (nItemProps.length > 8) {
                        nItem.phoneNumber = nItemProps[8];
                    }
                    
                    if (nItem.companyName != null && nItem.companyName.length > 0 && nItem.companyName != 'Company Name') {
                        var existing = customers.find(x => x.companyName == nItem.companyName);
                        if (existing == null) {
                            customers.push(nItem);
                        }
                    }
                }

                return customers;
            }
        },
        async importProductsFromFile(file) {
            var txt = await this.importDataFromFile(file);
            var products = [];
            if (txt.length) {
                var items = txt.split('\n');
                for (let i = 0; i < items.length; i++) {
                    const nItem = {};
                    
                    var nItemProps = items[i].split(',');

                    if (nItemProps.length > 0) {
                        nItem.productName = nItemProps[0];
                    }
                    if (nItemProps.length > 1) {
                        nItem.abbreviation = nItemProps[1];
                    }
                    if (nItemProps.length > 2) {
                        nItem.isPublicProduct = this.CSVIsTrue(nItemProps[2]);
                    }
                    if (nItemProps.length > 3) {
                        nItem.publicDescription = nItemProps[3];
                    }
                    if (nItemProps.length > 4) {
                        nItem.sortNumber = Number.parseFloat(nItemProps[4]) || 0;
                    }
                    if (nItemProps.length > 5) {
                        nItem.taxType = nItemProps[5];
                    }
                    if (nItemProps.length > 6) {
                        nItem.isSold = this.CSVIsTrue(nItemProps[6]);
                    }
                    if (nItemProps.length > 7) {
                        nItem.isAcquired = this.CSVIsTrue(nItemProps[7]);
                    }
                    if (nItemProps.length > 8) {
                        nItem.isManufactured = this.CSVIsTrue(nItemProps[8]);
                    }
                    if (nItemProps.length > 9) {
                        nItem.isPerishable = this.CSVIsTrue(nItemProps[9]);
                    }
                    if (nItemProps.length > 10) {
                        nItem.expiryDays = Number.parseInt(nItemProps[10]) || 0;
                    }
                    if (nItemProps.length > 11) {
                        nItem.isTemperatureSensitive = this.CSVIsTrue(nItemProps[11]);
                    }
                    if (nItemProps.length > 12) {
                        nItem.length = Number.parseFloat(nItemProps[12]) || null;
                    }
                    if (nItemProps.length > 13) {
                        nItem.width = Number.parseFloat(nItemProps[13]) || null;
                    }
                    if (nItemProps.length > 14) {
                        nItem.height = Number.parseFloat(nItemProps[14]) || null;
                    }
                    if (nItemProps.length > 15) {
                        nItem.weight = Number.parseFloat(nItemProps[15]) || null;
                    }
                    if (nItemProps.length > 16) {
                        nItem.volume = Number.parseFloat(nItemProps[16]) || null;
                    }
                    
                    if (nItem.productName != null && nItem.productName.length > 0 && nItem.productName != 'Product Name') {
                        var existing = products.find(x => x.productName == nItem.productName);
                        if (existing == null) {
                            products.push(nItem);
                        }
                    }
                }

                return products;
            }
        },
        isSameDownToHour(d1, d2) {
            if (d1 == null || d2 == null) {
                return false;
            }

            return d1.split(':')[0] == d2.split(':')[0];
        },
        timeZone() {
            return BlitzIt.auth.session?.data?.companyAccount?.defaultTimeZone;
        },
        isMinDate(d) {       
            return '0001-01-01T00:00:00Z' == d;
        },
        minNumber(numberList) {
            var minN = 0;
            for (var i = 0; i < numberList.length; i++) {
                if (numberList[i] < minN) {
                    minN = numberList[i];
                }
                else if (i === 0) {
                    minN = numberList[i];
                }
            }
        
            return minN;
        },      
        getNestedValue(obj, path) {
            return nestedValue(obj, path);
        },
        extractErrorDescription(error) {
            return extractErrorDescription(error);
        },
        extractErrorResponseCode(err) {
            if (err != null) {
                return err.response.status;
            }

            return null;
        },
        baseLogoURL() {
            return baseLogoURL();
        },
        imageURL(imgName) {
          return this.baseLogoURL() + 'blitzit/' + imgName;
        },
        companyBannerURL(company) {
            return companyBannerURL(company);
        },
        companyOrderingBackgroundURL(company) {
            return companyOrderingBackgroundURL(company);
        },
        companyLogoURL(company) {
            return companyLogoURL(company);
        },
        measurementArea(p) {
            return measurementArea(p);
        },
        productLogoURL(company) {
            if (company == null) {
                return null;
            }
          else if (typeof company == 'string') {
              return this.baseLogoURL() + 'product-logos/' + company + '-logo-lg';
          }
          else if (typeof company == 'object' && company.id != undefined && company.id != null) {
              return this.baseLogoURL() + 'product-logos/' + company.id + '-logo-lg';
          }
          else {
              return '';
          }
        },
        async login() {
          await BlitzIt.auth.login()
        },
        logout() {          
          BlitzIt.auth.logout()
        },
        requiredRules() {
          return [v => !!v || 'Field is required'];
        },
        emailRules() {
        return [
            v => !!v || 'Email is required',
            v => this.validEmail(v) || 'Email must be valid'
        ]
        },
        passwordRules() {
            return [
                p => !!p || 'Password is required',
                p => p != null && p.length > 9 || 'Password must be 10 or more characters',
                p => /^(.*[a-z].*)$/.test(p) || 'Password must contain a lowercase letter',
                p => /^(.*[A-Z].*)$/.test(p) || 'Password must contain an uppercase letter',
                p => /^(.*\d.*)$/.test(p) || 'Password must contain a number',
                p => /^(.*[@$!%*?&+].*)$/.test(p) || 'Password must contain a special character: @$!%*?&+'
            ];
        },
        validEmail(email) {              
            return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email);
        },
        // validPassword(pwd) {
        //     return /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{10,}$/.test(pwd);
        // },
        isGlobalSetting(v) {
            return v == null || v === 'Settings'
        },        
        copyDeep(aObject) {
            if (!aObject) {
                return aObject;
            }
            let v;
            let bObject = Array.isArray(aObject) ? [] : {};
            for (const k in aObject) {
                v = aObject[k];
                bObject[k] = (typeof v === 'object') ? this.copyDeep(v) : v;
            }
            return bObject;
        },
        isNewItem() {
            return router.currentRoute.params['id'] === 'new';
        },   
        percent(vOne, vTwo, inDecimalForm = false) {
            var res = vOne / vTwo;
            return inDecimalForm ? res : res * 100;
        },
        percentWhole(vOne, vTwo) {
            if (vOne == null || vTwo == null || (vOne == 0 && vTwo == 0)) {
                return 0;
            }

            var formatter = new Intl.NumberFormat('en-US', {
                style: 'percent',        
                minimumFractionDigits: 0
            });
            return formatter.format(Math.round(vOne / vTwo));
        },
        divide(vOne, vTwo, digits = 0) {
            var e = vOne / vTwo;
            if (Number.isNaN(e)) {
                return 0;
            }

            return e.toFixed(digits);
        },
        round(vOne, digits = 0) {
            if (Number.isNaN(vOne)) {
                return 0;
            }

            return vOne.toFixed(digits);
        },
        isLengthyArray(val, lng = 0) {
            return val != null && Array.isArray(val) && val.length > lng;
        },
        isArrayOfLength(val, lng = 0) {
            return val != null && Array.isArray(val) && val.length == lng;
        },
        goHome() {
            var subscription = BlitzIt.auth.session?.company?.subscriptionCode;
            if (!subscription) {
                if (router.history.current.name != 'home') {
                    router.push({ name: 'home' });
                }
            }
            else {
                if (router.currentRoute.fullPath.includes('-portal')) {
                    var portalName = this.$router.currentRoute.fullPath.split('/').find(x => x.includes('-portal'));
                    if (router.history.current.name != portalName) {
                        router.push({ name: portalName });
                    }
                    else {
                        if (router.history.current.name != 'home') {
                            router.push({ name: 'home' });
                        }
                    }
                }
                else {
                    if (router.history.current.name != 'home') {
                        router.push({ name: 'home' });
                    }
                }
            }
        },
        containsSearch(value, str) {
            if (str == null) {
                return true;
            }
            if (value == null) {
                return false;
            }
            var valOne = value.toLowerCase().replaceAll(' ', '');
            var valTwo = str.toLowerCase().replaceAll(' ', '');
            return valOne.includes(valTwo);
        },
        containsWeekday(weekdays, weekday) {
            return containsWeekday(weekdays, weekday);
        },
        pinSVG() {
            //return 'M99.279238,96.741516q19.110775,1.522125,1.860341,40.59-22.831457-40.082625-1.860341-40.59Z';
            return 'M 12,2 C 8.1340068,2 5,5.1340068 5,9 c 0,5.25 7,13 7,13 0,0 7,-7.75 7,-13 0,-3.8659932 -3.134007,-7 -7,-7 z';
        },
        hasSearch(value, str, props) {
            if (str == null) {
                return true;
            }
            if (value == null || !this.isLengthyArray(props)) {
                return false;
            }
        
            for (let i = 0; i < props.length; i++) {
                const propName = props[i];
                var propVal = this.getNestedValue(value, propName);
                if (propVal != undefined) {
                    if (typeof(propVal) === 'string') {
                        if (this.containsSearch(propVal, str)) {
                            return true;
                        }
                    }
                    // else {
                    //     return propVal.includes(str);
                    // }
                }
            }
            return false;
        },
        // updatePageTheme(vuetify, color) {
        //     BlitzIt.style.setTemporaryColor(vuetify, color);
        //     document.querySelector('meta[name="theme-color"]').setAttribute("content", color);
        // }
        
    }  
})

Vue.directive('repeat', {
    bind: function (el, binding) {
        let pressTimer = null;
        let repeatTimer = null;

        let start = (e) => {
            if (e.type === 'click' && e.button !== 0) {
                return;
            }

            if (pressTimer === null) {
                pressTimer = setTimeout(() => {
                    handler()
                }, 500)
            }
        }

        let cancel = () => {
            if (pressTimer !== null) {
                clearTimeout(pressTimer);
                pressTimer = null
            }

            if (repeatTimer !== null) {
                clearInterval(repeatTimer);
                repeatTimer = null
            }
        }

        const handler = (e) => {
            console.log('handling');
            binding.value(e);

            repeatTimer = setInterval(() => {
                binding.value(e);
            }, 50)
        }

        console.log('binding')
        el.addEventListener('mousedown', start);
        el.addEventListener('touchstart', start);

        el.addEventListener('click', cancel);
        el.addEventListener('mouseout', cancel);
        el.addEventListener('touchend', cancel);
        el.addEventListener('touchcancel', cancel);
    }
})

const requireComponent = require.context(
    // Look for files in the current directory
    './components',
    // Do not look in subdirectories
    false,
    // Only include "_base-" prefixed .vue files
    /Global-[\w-]+\.vue$/
  )
  
  // For each matching file name...
  requireComponent.keys().forEach((fileName) => {
    // Get the component config
    const componentConfig = requireComponent(fileName)
    // Get the PascalCase version of the component name
    const componentName = fileName
      // Remove the "./_" from the beginning
      .replace(/^\.\/Global-/, '')
      // Remove the file extension from the end
      .replace(/\.\w+$/, '')
      // Split up kebabs
    //   .split('-')
    //   // Upper case
    //   .map((kebab) => kebab.charAt(0).toUpperCase() + kebab.slice(1))
    //   // Concatenated
    //   .join('')
  
    // Globally register the component
    Vue.component(componentName, componentConfig.default || componentConfig)
  })

Vue.filter('toFilter', function (value, filterType) {
    if (typeof value !== 'number') {
        return value;
    }

    if (filterType == null) {
        return value;
    }
    else if (filterType == 'currency') {
        
        var formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'AUD',
            minimumFractionDigits: 2
        });
        return formatter.format(value);
    }
    else if (filterType == 'number') {
        return new Intl.NumberFormat().format(value);
    }
    else if (filterType == 'percent') {
        var formatterP = new Intl.NumberFormat('en-US', {
            style: 'percent',
            minimumFractionDigits: 2
        });
        return formatterP.format(value);
    }
    else {
        return value;
    }

   
})

Vue.filter('toCurrency', function (value) {
    if (typeof value !== 'number') {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
})

Vue.filter('toPercent', function (value) {
    if (typeof value !== 'number') {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'percent',        
        minimumFractionDigits: 2
    });
    return formatter.format(value);
})

Vue.filter('toWholePercent', function (value) {
    if (typeof value !== 'number') {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'percent',        
        minimumFractionDigits: 0
    });
    return formatter.format(value);
})

Vue.filter('toFormat', function(val, str) {
    if (val == null) { return val; }
    return BlitzIt.auth.formRawTZ(val).toFormat(str);
})

Vue.filter('toTimeOfDay', function(mins) {
    if (mins == null) {
        return null;
    }

    if (typeof mins ==  'number') {
        var d = DateTime.utc(2000, 1, 1, 0, 0, 0);
        d = d.plus({ minutes: mins });
        return d.toFormat('T');
    }
    else if (typeof mins == 'string') {
        return BlitzIt.auth.formRawTZ(mins).toFormat('t');
    }
    else {
        return 'unknown';
    }
})

Vue.filter('toPrettyCSV', function(val) {
    if (typeof val !== 'string') {
        return val;
    }

    return val.replaceAll(',', ' ');
})

Vue.filter('toDisplayNumber', function (value) {
    if (typeof value !== 'number') {
        return value;
    }

    return new Intl.NumberFormat().format(value);     
})

Vue.filter('toDisplayNumberOver', function (value) {
    if (typeof value !== 'number') {
        return value;
    }

    if (value == 0) {
        return '';
    }

    return new Intl.NumberFormat().format(value);     
})

Vue.filter('toDisplayNumberSigned', function (value) {
    if (typeof value !== 'number') {
        return value;
    }

    if (value == 0) {
        return '';
    }

    var r = new Intl.NumberFormat().format(value);

    if (value > 0) {
        return '+' + r;
    }
    else {
        return r;
    }
})

Vue.filter('toDayOfWeek', function (value, placeholder) {    
    if (value == null) {        
        return placeholder != undefined ? placeholder : value;
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';        
    }

    return BlitzIt.auth.formTZ(value, 'ccc'); //'D MMM');
})

Vue.filter('toDayMonth', function (value, placeholder) {    
    if (value == null) {
        return placeholder != undefined ? placeholder : value;
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';        
    }

    return BlitzIt.auth.formTZ(value, 'd LLL'); //'D MMM');
})

Vue.filter('toTimeSpan', function (value, placeholder) {
    if (value == null) {        
        return placeholder != undefined ? placeholder : value;
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';
    }

    return BlitzIt.auth.getDiffFromNow(value); // 'ddd DD MMM YYYY');
})

Vue.filter('toCustomRelativeTime', function (value, placeholder, includeTime = false) {
    if (value == null) {        
        return placeholder != undefined ? placeholder : value;
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';
    }

    return BlitzIt.auth.getCustomRelativeTime(value, includeTime); // 'ddd DD MMM YYYY');
})

Vue.filter('toRelativeTime', function (value, placeholder) {
    if (value == null) {        
        return placeholder != undefined ? placeholder : value;
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';
    }

    return BlitzIt.auth.getRelativeTime(value); // 'ddd DD MMM YYYY');
})

Vue.filter('toDayDate', function (value, placeholder) {    
    if (value == null) {        
        return placeholder != undefined ? placeholder : value;
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';
    }

    return BlitzIt.auth.formTZ(value, 'ccc | d LLL'); // 'ddd DD MMM YYYY');
})

Vue.filter('toDayShortDate', function (value, placeholder) {    
    if (value == null) {        
        return placeholder != undefined ? placeholder : value;
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';
    }

    return BlitzIt.auth.formTZ(value, 'ccc dd LLL yyyy'); // 'ddd DD MMM YYYY');
})

Vue.filter('toDayShortDateAndTime', function (value, placeholder) {    
    if (value == null) {        
        return placeholder != undefined ? placeholder : value;
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';
    }

    return BlitzIt.auth.formTZ(value, 'ccc dd LLL @ hh:mm a'); // 'ddd DD MMM YYYY');
})

Vue.filter('toLongDate', function (value, placeholder) {
    if (value == null) {
        return placeholder != undefined ? placeholder : value;
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';        
    }

    return BlitzIt.auth.formTZ(value, 'ddd DD MMM YYYY');       
})

Vue.filter('toShortDate', function (value, placeholder) {    
    if (value == null) {
        return placeholder != undefined ? placeholder : value;
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';        
    }

    return BlitzIt.auth.formTZ(value, 'dd LLL yyyy');
})

Vue.filter('toTime', function (value, placeholder) {    
    if (value == null) {
        return placeholder != undefined ? placeholder : null;        
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';        
    }

    return BlitzIt.auth.formTZ(value, 'hh:mm a');
})

Vue.filter('toShortDateAndTime', function (value, placeholder) {    
    if (value == null) {
        return placeholder != undefined ? placeholder : null;        
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';        
    }

    return BlitzIt.auth.formTZ(value, 'dd LLL yyyy hh:mm a');
})

Vue.filter('toLongDateAndTime', function (value, placeholder) {
    if (value == null) {
        return placeholder != undefined ? placeholder : null;        
    }

    if (isMinDate(value)) {
        return placeholder != undefined ? placeholder : '';        
    }

    return BlitzIt.auth.formTZ(value, 'ccc dd LLL yyyy hh:mm a');
})

Vue.filter('toMeasurementLine', function (value) {
    if (!value) {
        return '';
    }

    if (typeof value !== 'object' || typeof value === 'string') {
        return value;
    }

    var rStr = '';
    
    if (value.length || value.width || value.height) {
        rStr = `${value.length ? value.length : 0}mm x ${value.width ? value.width : 0}mm x ${value.height ? value.height : 0}mm`;
    }
    else if (value.weight) {
        rStr = `${value.weight}kg`
    }
    else if (value.volume) {
        rStr = `${value.volume}ltrs`
    }
    
    return rStr;
})

Vue.filter('toMeasurementString', function (value) {
    if (!value) {
        return '';
    }

    if (typeof value !== 'object') {
        return value;
    }

    var rStr = '';
    var obj = value;    
    
        if (obj.length || obj.width || obj.height) {
            rStr = `${obj.length ? obj.length : 0}mm x ${obj.width ? obj.width : 0}mm x ${obj.height ? obj.height : 0}mm`;
        }
        else if (obj.weight) {
            rStr = `${obj.weight}kg`
        }
        else if (obj.volume) {
            rStr = `${obj.volume}ltrs`
        }    

    return rStr;
})

Vue.filter('toFamiliarLocationLine', function (value) {
    if (!value) {
        return '';
    }

    if (typeof value !== 'object') {
        return value;
    }

    var companyID = null;
    if (BlitzIt.auth.session.isTraining) {
        companyID = 'comp1';
    }
    else {
        companyID = BlitzIt.auth.session?.data?.companyAccountID;
    }

    if (value.companyAccountID != null && value.companyAccountID == companyID) {
        return value.locationName;
    }
    // if (BlitzIt.auth.session.companyAccountID != null) {
        
    //     if (value.companyAccountID != null && value.companyAccountID == BlitzIt.auth.session.companyAccountID) {
    //         return value.locationName;
    //     }
    // }

    var rStr = '';

    if (value.companyAccount != null) {
        rStr = rStr + value.companyAccount.companyName + ' | ';
    }    
    if (value.addressLineOne != null) {
        rStr = rStr + value.addressLineOne + ' ';
    }
    if (value.streetNumber != null) {
        rStr = rStr + value.streetNumber + ' ';
    }
    if (value.streetName != null) {
        rStr = rStr + value.streetName + ', ';
    }
    if (value.suburb != null) {
        rStr = rStr + value.suburb + ' ';
    }
    if (value.state != null) {
        rStr = rStr + value.state + ' ';
    }
    if (value.postcode != null) {
        rStr = rStr + value.postcode;
    }
    
    return rStr;
})

Vue.filter('toCompanyNameAndLocationLine', function (value) {
    if (!value) {
        return '';
    }

    if (typeof value !== 'object') {
        return value;
    }    
    
    var rStr = '';

    if (value.companyAccount != null) {
        rStr = value.companyAccount.companyName + ' | ';
    }
    
    if (value.addressLineOne != null) {
        rStr = rStr +  value.addressLineOne + ' ';
    }
    if (value.streetNumber != null) {
        rStr = rStr + value.streetNumber + ' ';
    }
    if (value.streetName != null) {
        rStr = rStr + value.streetName + ', ';
    }
    if (value.suburb != null) {
        rStr = rStr + value.suburb + ' ';
    }
    if (value.state != null) {
        rStr = rStr + value.state + ' ';
    }
    if (value.postcode != null) {
        rStr = rStr + value.postcode;
    }
    
    return rStr;
})

Vue.filter('toLocationLineNoCommas', function (value) {
    if (!value) {
        return '';
    }

    if (typeof value !== 'object') {
        return value;
    }    
    // if (BlitzIt.auth.session.companyAccountID != null) {
        
    //     if (value.companyAccountID != null && value.companyAccountID == BlitzIt.auth.session.companyAccountID) {
    //         return value.locationName;
    //     }
    // }

    var rStr = '';

    if (value.addressLineOne != null) {
        rStr = value.addressLineOne + ' ';
    }
    if (value.streetNumber != null) {
        rStr = rStr + value.streetNumber + ' ';
    }
    if (value.streetName != null) {
        rStr = rStr + value.streetName + ' ';
    }
    if (value.suburb != null) {
        rStr = rStr + value.suburb + ' ';
    }
    if (value.state != null) {
        rStr = rStr + value.state + ' ';
    }
    if (value.postcode != null) {
        rStr = rStr + value.postcode;
    }
    
    rStr = rStr.replaceAll(',', ' ');

    return rStr;
})

Vue.filter('toUserLine', function (value) {
    if (!value) {
        return '';
    }

    if (value.userName != null) {
        return value.userName;
    }

    return `${value.firstName} ${value.lastName}`;
})

Vue.filter('toLocationLine', function (value) {
    return getLocationLine(value, false);
})

Vue.filter('toLocationLineOne', function (value) {
    if (!value) {
        return '';
    }

    if (typeof value !== 'object') {
        return value;
    }

    var rStr = '';

    if (value.addressLineOne != null) {
        rStr = value.addressLineOne + ' ';
    }
    if (value.streetNumber != null) {
        rStr = rStr + value.streetNumber + ' ';
    }
    if (value.streetName != null) {
        rStr = rStr + value.streetName + ', ';
    }
    
    return rStr;
})

Vue.filter('toLocationLineTwo', function (value) {
    if (!value) {
        return '';
    }

    if (typeof value !== 'object') {
        return value;
    }

    var rStr = '';
    
    if (value.suburb != null) {
        rStr = rStr + value.suburb + ' ';
    }
    if (value.state != null) {
        rStr = rStr + value.state + ' ';
    }
    if (value.postcode != null) {
        rStr = rStr + value.postcode;
    }
    
    return rStr;
})

Date.prototype.toLongDate = function (value) {
    return BlitzIt.auth.formTZ(value, 'ccc dd LLL yyyy');
}

Date.prototype.toShortDateAndTime = function (value) {
    return BlitzIt.auth.formTZ(value, 'dd LLL yyyy hh:mm a');
}

Date.prototype.toShortDate = function (value) {
    return BlitzIt.auth.formTZ(value, 'dd LLL yyyy');
}

Date.prototype.addHours = function(h) {
    this.setTime(this.getTime() + (h*60*60*1000));
    return this;
}

Array.prototype.groupBy = function(key = x => x) {
    if (typeof(key) === 'string') {
        return this.reduce(function(rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
          }, {})
    }    
    else {
        return this.reduce(function(rv, x) {
            (rv[key(x)] = rv[key(x)] || []).push(x);
            return rv;
          }, {})
    }
}

Array.prototype.orderByDate = function(prop, asc = true) {
    return this.sort(function (aVal, bVal) {
        var a = null;
        var b = null;

        if (!prop) {
            a = aVal != null ? DateTime.fromISO(aVal).toSeconds() : null;
            b = bVal != null ? DateTime.fromISO(bVal).toSeconds() : null;
        }
        else if (typeof(prop) === 'string') {
            a = aVal != null ? DateTime.fromISO(nestedValue(aVal, prop)).toSeconds() : null;
            b = bVal != null ? DateTime.fromISO(nestedValue(bVal, prop)).toSeconds() : null;
        }
        else {
            a = prop(aVal);
            b = prop(bVal);
        }

        if (a > b) {
            return asc === true ? 1 : -1;
        }
        else if (a < b) {
            return asc === true ? -1 : 1;
        }
        else {
            return 0;
        }
    })
}

Array.prototype.orderBy = function (prop, asc=true) {    
    return this.sort(function (a, b) {
        if (!prop) {
            if (a > b) {
                return asc === true ? 1 : -1;
            }
            else if (a < b) {
                return asc === true ? -1 : 1;
            }
            else {
                return 0;
            }
        }
        else if (typeof(prop) === 'string') {            
            if (nestedValue(a, prop) > nestedValue(b, prop)) {
                return asc === true ? 1 : -1;
            }
            else if (nestedValue(a, prop) < nestedValue(b, prop)) {
                return asc === true ? -1 : 1;
            }
            else {
                return 0;
            }
        }
        else {
            if (prop(a) > prop(b)) {
                return asc === true ? 1 : -1;
            }
            else if (prop(a) < prop(b)) {
                return asc === true ? -1 : 1;
            }
            else {
                return 0;
            }
        }
    })
}

Array.prototype.orderByPropTwo = function (prop, propTwo, asc = true) {
    return this.sort(function (a, b) {
        if (a[prop][propTwo] > b[prop][propTwo]) {
            return asc === true ? 1 : -1;
        }
        else if (a[prop][propTwo] < b[prop][propTwo]) {
            return asc === true ? -1 : 1;
        }
        else {
            return 0;
        }
    })
}

Array.prototype.distinct = function (propSelector = obj => obj) {
    return [...new Set(this.map(item => propSelector(item)))];
}

Array.prototype.take = function (count) {
    return this.slice(0, count);
}

Array.prototype.sum = function (propSelector = obj => obj) {
    const initialValue = 0;
    
    if (typeof(propSelector) === 'string') {
        return this.reduce((sum, obj) => sum + Number.parseFloat(nestedValue(obj, propSelector)), initialValue);
    }
    else {
        return this.reduce((sum, obj) => sum + propSelector(obj), initialValue);
    }    
}

Array.prototype.firstOrDefault = function (fn) {
    var res = this.find(fn);
    if (res == undefined || res == null) {
        return null;
    }
    else {
        return res;
    }
}

Array.prototype.copy = function (aObject) {        
    if (!aObject) {              
        return aObject;
    }
    
    let v;
    let bObject = Array.isArray(aObject) ? [] : {};
    
    for (const k in aObject) {
        v = aObject(k);
        bObject[k] = (typeof v === 'object') ? v.copy() : v;
    }

    return bObject;
}

String.prototype.csvContains = function (value, tag) {    
    if (!value) {
        return false;
    }

    if (!tag) {
        return true;
    }

    var csvList = value.split(',');
    var tagList = tag.split(',');

    return csvList.some(x => tagList.some(y => y == x));
}

String.prototype.toCSVList = function (value) {
    if (value == null) {
        return [];
    }

    return value.split(',');
}

String.prototype.appendURL = function(value) {    
    var returnValue = this[this.length - 1] === '/' ? this : this + '/';    
    var newValue = value[0] === '/' ? value.slice(1, value.length) : value;
    return returnValue + newValue;
}

Number.prototype.toCurrency = function (value) {    
    if (typeof value !== 'number') {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2
    });
    return formatter.format(value);    
}

Number.prototype.toDisplayNumber = function (value) {
    if (typeof value !== 'number') {
        return value;
    }

    return new Intl.NumberFormat().format(value);
}

router.beforeEach(async (to, from, next) => {
    // var isTraining = BlitzIt.auth.findIsTraining(to.name);

    // if (isTraining) {
    //     BlitzIt.auth.logout(false);
    // }

    BlitzIt.navigation.updateCustomHistory(to, from);

    //#region BEGIN DOCUMENT META ADJUSTMENTS

    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    
      // Find the nearest route element with meta tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if(nearestWithTitle) {
        document.title = nearestWithTitle.meta.title;
    }
    else {
        if (process.env.VUE_APP_LOCAL_VAR == 'staging' || window.location.origin == 'https://staging.blitzitweb.com.au') { //'https://proud-pond-0fadfc600.1.azurestaticapps.net') {
            document.title = "BlitzIt Sandpit";
        }
        else if (BlitzIt.auth.session.isTraining) { //isTraining
            document.title = "BlitzIt Training";
        }
        else {
            document.title = "BlitzIt Web";
        }
    }
    
    // Remove any stale meta tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
    
    // Skip rendering meta tags if there are none.
    //if(!nearestWithMeta) return next();

    if (nearestWithMeta) {
        // Turn the meta tag definitions into actual elements in the head.
        nearestWithMeta.meta.metaTags.map(tagDef => {
            const tag = document.createElement('meta');

            Object.keys(tagDef).forEach(key => {
                var v = tagDef[key];
                // console.log(key);
                // if (key == 'og:url') {
                //     //add origin
                //     v = `${window.location.origin}${v}`;
                // }

                tag.setAttribute(key, v);
            });

            // We use this to track which meta tags we create so we don't interfere with other ones.
            tag.setAttribute('data-vue-router-controlled', '');

            return tag;
        })
        // Add the meta tags to the document head.
        .forEach(tag => document.head.appendChild(tag));
    }

    //#endregion END DOCUMENT META ADJUSTMENTS
    
    var isAuth = BlitzIt.navigation.findIsAuth(to.name);
    if (!isAuth) {
        BlitzIt.navigation.updateBackground(to.name);
        BlitzIt.navigation.updateHesitation(to.name);
        next();
    }
    else {
        var isTraining = to?.query?.isTraining === 'true';
        if (await BlitzIt.auth.tryLogin(to.name != 'home', isTraining)) {
            
            //is logged in
            var subCodes = BlitzIt.navigation.findSubscriptions(to.name);
            var permissions = BlitzIt.navigation.findPermissions(to.name);
            
            if (BlitzIt.auth.doShow(subCodes, permissions, 'view')) {
                if (!BlitzIt.navigation.session.hesitate || await router.app.$confirm({ text: 'Are you sure you want to leave?  Make sure everything is saved before leaving.', cancelText: 'No', acceptText: 'Yes' })) {
                    BlitzIt.navigation.updateBackground(to.name);
                    BlitzIt.navigation.updateHesitation(to.name);
                    next();
                }
            }
            else {
                BlitzIt.navigation.updateBackground(to.name);
                BlitzIt.navigation.updateHesitation(to.name);
                next({ name: 'restricted' });
            }
        }
        else {
            //not logged in
            await BlitzIt.auth.login(window.location.pathname);
            BlitzIt.navigation.updateBackground(to.name);
            BlitzIt.navigation.updateHesitation(to.name);
            next({ name: 'restricted' });
        }
        //couldn't login via cookie
        
        //login or go to restricted?

    }
})

var v = new Vue({
  vuetify,
  render: h => h(App),
  store: store,
  router
}).$mount('#app');

export default v;
