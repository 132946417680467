<template>
  <v-dialog 
    v-model="shown"
    :max-width="message.dialogMaxWidth || 350"
    persistent>
    <v-card :min-height="height">
      <v-responsive>
        <v-card-title v-if="title != null || multiple">
            <div class="d-flex justify-space-between">
                <span v-if="title != null">{{ title }}</span>
                <v-spacer />
                <v-btn v-if="multiple" small @click="selectAllToggle" class="ml-3">Select All</v-btn>
            </div>
        </v-card-title>
        <v-card-text>
            <v-text-field
                v-if="showSearch"
                dense
                hide-details
                outlined
                placeholder="Search"
                single-line
                v-model="searchInput" />
            <v-list v-if="isLengthyArray(filteredItems)" class="overflow-y-auto" :height="height">
                <v-list-item-group
                    active-class="accent--text"
                    v-model="selection"
                    :multiple="multiple"
                    @change="maybeAccept">
                    <template v-for="(fItem, index) in filteredItems">
                        <v-list-item
                            :key="index"
                            :value="fItem">
                            <slot v-bind:item="fItem">
                                <v-list-item-content>
                                    <v-list-item-title>{{ textFunction != null ? textFunction(fItem) : nestVal(fItem, itemText, textFilter) }}</v-list-item-title>
                                    <v-list-item-subtitle v-if="subTextFunction != null">{{ subTextFilter != null ? nestVal(subTextFunction(fItem), null, subTextFilter) : subTextFunction(fItem) }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </slot>
                        </v-list-item>
                    </template>
                </v-list-item-group>
            </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn v-if="!required" @click="cancel">{{ message.cancelText }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="multiple" @click="accept" color="primary">{{ message.acceptText }}</v-btn>
        </v-card-actions>
      </v-responsive>
      <v-overlay :value="loadingMsg != null" absolute class="text-center">
        <v-progress-circular indeterminate />
        <p>{{ loadingMsg }}</p>
    </v-overlay>
    </v-card>
  </v-dialog>
</template>

<script>
    export default {
        name : "SelectDialog",
        props : {
            message : Object,
            resolve : Function
        },
        data() {
            return {
                asyncItems: [],
                height: '400',
                items: null,
                itemText: null,
                itemValue: null,
                label: null,
                loadingMsg: null,
                multiple: false,
                navigation: null,
                onFilter: null,
                params: null,
                required: false,
                searchInput: null,
                selection: null,
                shown : false,
                showSearch: true,
                subTextFilter: null,
                subTextFunction: null,
                textFilter: null,
                textFunction: null
            }
        },
        computed: {
            filteredItems() {
                var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
                
                if (this.searchInput != null) {
                    return l.filter(x => this.hasSearch(x, this.searchInput, [this.itemText]));
                }

                return l;
            },
            title() {
                if (this.label != null) {
                    return this.label;
                }
                if (this.navigation != null) {
                    return this.$BlitzIt.navigation.findDisplayName(this.navigation);
                }
                return null;
            }
        },
        methods : {
            accept() {
                if (!(this.required && (this.selection == null || (this.multiple && !this.isLengthyArray(this.selection))))) {
                    this.shown = false;
                    if (this.selection == null) {
                        this.resolve(null);
                    }

                    if (this.multiple) {
                        this.resolve(this.itemValue != null ? this.selection.map(x => this.getNestedValue(x, this.itemValue)) : this.selection)
                    }
                    else {
                        this.resolve(this.itemValue ? this.getNestedValue(this.selection, this.itemValue) : this.selection);
                    }
                }
            },
            cancel() {
                this.shown = false;
                this.resolve(false);
            },
            maybeAccept() {
                if (!this.multiple && this.selection != null) {
                    this.shown = false;

                    this.resolve(this.itemValue ? this.getNestedValue(this.selection, this.itemValue) : this.selection);
                }

                console.log(this.selection);
            },
            nestVal(item, path, filter) {
                var t = item;

                if (path != null) {
                    t = this.getNestedValue(item, path);
                }
                
                if (filter != null) {
                    return this.$options.filters[filter](t);
                }
                else {
                    return t;
                }
            },
            async pullItems(refresh = false) {
                if (this.items != null) {
                    this.asyncItems = this.items;
                    return;
                }

                if (this.navigation == null) {
                    return;
                }

                try {
                    this.loadingMsg = 'Loading';
                    this.asyncItems = await this.$BlitzIt.store.getAll(this.navigation, this.params, refresh);
                }
                finally {
                    this.loadingMsg = null;
                }
            },
            selectAllToggle() {
                try {
                    if (this.isLengthyArray(this.filteredItems) && this.filteredItems?.length == this.selection?.length) {
                        this.selection = null;
                    }
                    else {
                        this.selection = this.filteredItems;
                    }
                    // if (this.isLengthyArray(this.filteredItems)) {
                        
                    // }
                }
                catch {
                    console.log('selection error');
                }
            }
        },
        beforeMount() {
            this.height = this.message.height || '400';
            this.items = this.message.items || null;
            this.itemText = this.message.itemText || null;
            this.itemValue = this.message.itemValue || null;
            this.label = this.message.label || 'Select';
            this.multiple = this.message.multiple === true;
            this.navigation = this.message.navigation;
            this.onFilter = this.message.onFilter;
            this.params = this.message.params;
            this.required = this.message.required;
            this.showSearch = this.message.showSearch;
            this.subTextFilter = this.message.subTextFilter;
            this.subTextFunction = this.message.subTextFunction || null;
            this.textFilter = this.message.textFilter || null;
            this.textFunction = this.message.textFunction || null;
            this.theme = this.message.theme || {};
            this.breakpoint = {};
        },
        async mounted() {
            this.pullItems();
            this.shown = true;
        }
    }
</script>

<style scoped>
  .vdp-message {
    white-space: pre-wrap;
  }
</style>