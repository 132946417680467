<template>
    <v-select
        :chips="chips"
        :multiple="multiple"
        :loading="isLoading"
        :item-value="itemValue"
        :items="filteredItems"
        :prepend-icon="prependIcon"
        v-model="select"
        @change="update"
        :return-object="returnObject"
        :label="label"
        :prefix="prefix"
        :suffix="suffix"
        :disabled="disabled"
        :clearable="canSelectNone"
        :outlined="outlined"
        :hide-details="hideDetails ? 'auto' : false"
        :append-outer-icon="showRefreshButton ? 'mdi-refresh' : null"
        :solo="solo"
        @click:append-outer="pullItems(true)">
        <template v-slot:item="{ item }">
            <slot name="item" v-bind:item="item">{{ nestVal(item, itemText, textFilter) }}</slot>
        </template>
        <template v-slot:selection="{ item }">
            <slot name="selection" v-bind:item="item">
                <v-chip v-if="chips">
                   {{ nestVal(item, itemText, textFilter) }}
                </v-chip>
                <span v-else>
                    {{ nestVal(item, itemText, textFilter) }}
                </span>
            </slot>
        </template>
    </v-select>
</template>

<script>
export default {
    name: 'BT-Select',
    data: function() {
        return {
            asyncItems: [],
            select: null,
            errorMessage: null,
            isLoading: false,
            showError: false,
        }
    },
    props: {
        canSelectNone: {
            type: Boolean,
            default: true
        },
        chips: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        hideRefresh: {
            type: Boolean,
            default: false
        },
        ignorePermissions: {
            type: Boolean,
            default: false
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: null
        },
        itemID: {
            type: String,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        multiple: {
            type: Boolean,
            default: false
        },
        navigation: {
            type: String,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        outlined: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        prefix: {
            type: String,
            default: null
        },
        prependIcon: {
            type: String,
            default: null
        },
        proxyID: {
            type: String,
            default: null
        },
        returnCSV: {
            type: Boolean,
            default: false
        },
        returnIndex: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        suffix: {
            type: String,
            default: null
        },
        textFilter: {
            type: String,
            default: null
        },
        value: null, 
    },  
    async mounted() {
        await this.pullItems();

        if (this.returnCSV) {
            this.select = this.value ? this.value.split(',') : null;
        }
        else {
            this.select = this.value;
        }
    },
    watch: {
        value: function(val) {
            if (val != this.select) {
                if (this.returnCSV) {
                    this.select = val ? val.split(',') : null;
                }
                else {
                    this.select = val;
                }
            }
        },
        items: function() {
            this.pullItems();
        },
        proxyID() {
            this.pullItems();
        }
    },
    computed: {
        filteredItems() {
            return this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
        },
        showRefreshButton() {
            return !this.hideRefresh && !this.isLengthyArray(this.asyncItems) && this.navigation != null; //make sure it is navigation based
        }
    },
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        nestVal(item, path, filter) {
            var t = item;

            if (path != null) {
                t = this.getNestedValue(item, path);
            }
            
            if (filter != null) {
                return this.$options.filters[filter](t);
            }
            else {
                return t;
            }
        },
        async pullItems(refresh = false) {
            if (this.items != null) {
                this.asyncItems = this.items;
                return;
            }

            if (this.navigation == null) {
                return;
            }

            try {
                this.isLoading = true;
                this.$forceUpdate();

                var res = null;
                if (this.isSingle) {
                    res = await this.$BlitzIt.store.get(this.navigation, this.itemID, this.params, refresh, this.proxyID);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, this.params, refresh, this.proxyID);
                }
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh);
                }
                else {
                    this.asyncItems = res;
                }

                this.$emit('fetched', this.asyncItems);
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        update() {
            if (this.select == null) {
                this.$emit('input', null)
                this.$emit('change', null);
            }
            else if (this.returnCSV) {
                if (this.itemValue != null) {
                    this.$emit('input', this.select.map(x => x[this.itemValue]).toString())
                    this.$emit('change', this.select.map(x => x[this.itemValue]).toString());
                }
                else {
                    this.$emit('input', this.select.toString())
                    this.$emit('change', this.select.toString());
                }
            }
            else if (this.itemValue != null && typeof(this.select) == 'object') {
                this.$emit('input', this.select[this.itemValue]);
                this.$emit('change', this.select[this.itemValue]);
            }
            else if (this.returnIndex) {
                this.$emit('input', this.items.findIndex(x => x == this.select));
                this.$emit('change', this.items.findIndex(x => x == this.select));
            }
            else {
                this.$emit('input', this.select)
                this.$emit('change', this.select);
            }            
        }
    }
}
</script>