
export const microservices = {
    AUTH: 'Auth',
    BILLING: 'Billing',
    COURIERING: 'Couriering',
    COURIERARCHIVING: 'CourierArchiving',
    COURIERINVOICING: 'CourierInvoicing',
    ORDERING: 'Ordering',
    ORDERINVOICING: 'OrderInvoicing',
    STOCKARCHIVING: 'StockArchiving',
    STOCKMANAGING: 'StockManaging',
    STOCKTRACKING: 'StockTracking'
}
