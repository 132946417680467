<template>
    <BT-Blade
        :addBladeName="addBladeName"
        :addTo="addTo"
        :bladeName="bladeName"
        :bladesData="bladesData"
        :bladesToClear="bladesToClear"
        :canAdd="canAdd"
        :canClose="canClose"
        :canDelete="canDelete"
        :canEdit="canEdit"
        :canMinimize="canMinimize"
        :canPin="canPin"
        :canRefresh="canRefresh"
        :clearBlades="clearBlades"
        :clearOtherBlades="clearOtherBlades"
        :defaultBladeWidth="defaultBladeWidth"
        :defaultPageWidth="defaultPageWidth"
        :dependantBlades="dependantBlades"
        :flexColumn="flexColumn"
        :getNewBladeData="getNewBladeData"
        :getNewQuery="getNewQuery"
        :hideBackButton="hideBackButton"
        :hideBladeHeader="hideBladeHeader"
        :isChildBlade="isChildBlade"
        :loading="isLoading"
        :minimizeOnSelect="minimizeOnSelect"
        :navigation="navigation"
        :permanent="permanent"
        :title="title"
        :updateDependants="updateDependants"
        @sourceChanged="updateBlade"
        @refresh="refresh">
        <template v-slot:toolbar>
            <slot name="toolbar" v-bind="{ items: asyncItems }" />
        </template>
        <template v-slot:toolbar-right="{ add }">
            <slot 
                name="toolbar-right" 
                v-bind:add="add"
                v-bind:items="asyncItems"
                v-bind:allItems="asyncItems" />
        </template>
        <template v-slot="{ data, add, refresh }">
            <v-card
                :loading="isLoading"
                :dense="dense"
                :tile="tile"
                width="100%">
                <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
                <slot name="search">
                    <v-toolbar 
                        v-if="canSearchLocal || canSearch || canExportCSV || showSettings || showFilters || showSubtoolbar"
                        dense
                        flat>
                        <v-menu 
                            v-if="showSettings || canExportCSV"
                            offset-y 
                            :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    title="Settings"
                                    small>
                                    <v-icon small>mdi-cog</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <v-list-item v-if="canExportCSV" @click="exportAsCSV" dense>
                                    <v-list-item-icon>
                                        <v-icon small>mdi-file-delimited-outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-subtitle>Export To CSV</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <slot name="settings" />
                            </v-list>
                        </v-menu>

                        <v-spacer />
                        
                        <v-menu
                            v-if="showFilters"
                            offset-y 
                            :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    title="Filters"
                                    small>
                                    <v-icon small>mdi-filter</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-list dense>
                                    <v-subheader><v-icon left>mdi-filter</v-icon>Filters</v-subheader>
                                    <v-list-item-group
                                        multiple
                                        v-model="selectedFilters">
                                        <template v-for="(filter, index) in chipFilters">
                                            <v-list-item
                                                :key="index"
                                                :value="filter"
                                                dense>
                                                {{ filter }}
                                            </v-list-item>
                                            <v-divider :key="'filter' + index" />
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                                <v-slide-x-transition>
                                    <v-btn
                                        v-if="isFilterChanged"
                                        text
                                        small
                                        @click="refresh">
                                        <v-icon small left>mdi-filter</v-icon>Apply
                                    </v-btn>
                                </v-slide-x-transition>
                            </v-card>
                        </v-menu>
                        
                        <v-btn
                            v-if="canSearchLocal || canSearch"
                            small
                            icon 
                            @click="showSearch = !showSearch">
                            <v-icon v-if="!showSearch" small>mdi-magnify</v-icon>
                            <v-icon v-else small>mdi-close</v-icon>
                        </v-btn>

                        <slot 
                            name="actions" 
                            v-bind:add="add" 
                            v-bind:refresh="refresh" 
                            v-bind:allItems="asyncItems" />

                        <v-slide-x-reverse-transition>
                            <v-text-field
                                v-if="canSearchLocal && showSearch"
                                flat
                                dense
                                hide-details
                                hide-no-data
                                solo-inverted
                                single-line
                                label="Find"
                                v-model="searchInput" />
                            <v-text-field
                                v-else-if="canSearch && showSearch"
                                flat
                                dense
                                hide-details
                                hide-no-data
                                solo-inverted
                                single-line
                                prepend-inner-icon="mdi-close"
                                append-icon="mdi-magnify"
                                label="Find"
                                v-model="searchInput"
                                @click:prepend-inner="searchInput = null"
                                @click:append="refresh" />
                        </v-slide-x-reverse-transition>
                    </v-toolbar>
                </slot>
                <slot name="top" v-bind:top="data" />
                <slot name="items" v-bind:items="filteredItems" v-bind:allItems="asyncItems">
                    <v-list v-if="isLengthyArray(filteredItems)" class="overflow-y-auto pa-0 ma-0" :class="listClass" :style="listStyle">
                        <v-list-item-group
                            :active-class="activeClass"
                            v-model="select"
                            :multiple="multiple"
                            @change="update">
                            <template v-for="(item, index) in filteredItems">
                                <v-list-item 
                                    :key="index"
                                    :two-line="twoLine"
                                    :three-line="threeLine"
                                    :class="listItemClass"
                                    :value="item"
                                    :dense="dense">
                                    <slot v-bind="{ item, data }" v-bind:deleteItem="deleteItem" v-bind:allItems="asyncItems">
                                        <div>{{ itemText ? getNestedValue(item, itemText) : item }}</div>
                                    </slot>
                                    <v-list-item-action v-if="canDelete">
                                        <v-btn small icon @click.stop="deleteItem(item)">
                                            <v-icon small>mdi-delete</v-icon>
                                        </v-btn>
                                    </v-list-item-action>
                                </v-list-item>
                                <v-divider v-if="dividers" :key="'d' + index" />
                            </template>
                        </v-list-item-group>
                        <slot name="bottom" />
                    </v-list>
                    <v-toolbar v-if="showBottomNavigation" bottom>
                        <v-pagination
                            v-model="currentPage"
                            :length="totalPages" />
                    </v-toolbar>
                </slot>
                <BT-Snack v-model="msg" />
            </v-card>
        </template>
    </BT-Blade>
</template>

<script>
import { roundUp } from '~helpers';

export default {
    name: 'BT-Blade-List',
    data: function() {
        return {
            asyncItems: [],
            bladeData: null,
            currentPage: 1,
            errorMessage: null,
            isFilterChanged: false,
            isLoading: false,
            isPinned: false,
            originalFilters: [],
            mParams: { includeDetails: false },
            msg: null,
            recentConditions: null,
            searchString: null,
            searchInput: null,
            selectedFilters: [],
            select: null,
            showError: false,
            showSearch: false,
            totalPages: 0,
        }
    },
    props: {
        addBladeName: null,
        addTo: null,
        activeClass: {
            type: String,
            default: 'accent--text'
        },
        actualUsedHeight: {
            type: Number,
            default: null
        },
        bladeName: null,
        bladesData: {
            type: Object,
            default: null
        },
        bladesToClear: {
            type: Array,
            default: null
        },
        canAdd: {
            type: Boolean,
            default: false
        },
        canClose: {
            type: Boolean,
            default: true
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        canExportCSV: {
            type: Boolean,
            default: false
        },
        canMinimize: {
            type: Boolean,
            default: false
        },
        canPin: {
            type: Boolean,
            default: false
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        canSearch: {
            type: Boolean,
            default: false
        },
        canSearchLocal: {
            type: Boolean,
            default: false
        },
        canSelect: {
            type: Boolean,
            default: true
        },
        clearBlades: {
            type: Boolean,
            default: false
        },
        clearOtherBlades: {
            type: Boolean,
            default: false
        },
        closeOnEmpty: {
            type: Boolean,
            default: true
        },
        csvIncludeTitle: {
            type: Boolean,
            default: false
        },
        customFilters: { //a list of custom filters which when selected adjust the query parameter
            type: Array,
            default: () => { return [] } //{ filter: String, filterFunction: () => { return String }, paramProp: (default = 'query') }
        },
        defaultFilters: { //a list of strings which are names of filters which start as selected
            type: Array,
            default: null
        },
        defaultBladeWidth: {
            type: Number,
            default: 700
        },
        defaultPageWidth: {
            type: String,
            default: '100%'
        },
        dense: {
            type: Boolean,
            default: false
        },
        dependantBlades: {
            type: Array,
            default: () => { return [] } //[String]
        },
        dividers: {
            type: Boolean,
            default: true
        },
        filters: {
            type: Array,
            default: null
        },
        flexColumn: {
            type: Boolean,
            default: false
        },
        getParams: {
            type: Function,
            default: null
        },
        getNewBladeData: {
            type: Function,
            default: null
        },
        getNewQuery: {
            type: Function,
            default: null
        },
        getSelectBladeData: {
            type: Function,
            default: null
        },
        getSelectQuery: {
            type: Function,
            default: null
        },
        hideBackButton: {
            type: Boolean,
            default: false
        },
        hideBladeHeader: {
            type: Boolean,
            default: false
        },
        isChildBlade: {
            type: Boolean,
            default: false
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        itemsPerPage: {
            type: Number,
            default: 75
        },
        itemProperties: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        listClass: {
            type: String,
            default: null, //'bg-list lighten-1'
        },
        listItemClass: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        minimizeOnSelect: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        navigation: null,
        onCanOpenBlade: {
            type: Function,
            default: null
        },
        onCanPull: {
            type: Function,
            default: null
        },
        onCanSelect: {
            type: Function,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onInsertDataAsync: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        onSelect: {
            type: Function,
            default: null
        },
        otherUsedHeight: {
            type: Number,
            default: 48
        },
        params: {
            type: Object,
            default: null
        },
        permanent: {
            type: Boolean,
            default: false
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: null
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        searchProps: {
            type: Array,
            default: null
        },
        selectBladeName: {
            type: String,
            default: null
        },
        showFilters: {
            type: Boolean,
            default: false
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        showSubtoolbar: {
            type: Boolean,
            default: false
        },
        threeLine: {
            type: Boolean,
            default: false
        },
        tile: {
            type: Boolean,
            default: true
        },
        title: null,
        twoLine: {
            type: Boolean,
            default: false
        },
        updateDependants: {
            type: Boolean,
            default: true
        },
        useServerFilters: {
            type: Boolean,
            default: true
        },
        useServerPagination: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        currentPage: function() {
            this.pullItems();
        },
        items: function() {
            this.pullItems();
        },
        itemID: function() {
            this.refresh();
        },
        loading: function(val) {
            this.isLoading = val;
            this.$forceUpdate();
        },
        mParams: function() {
            this.refresh(false);
        },
        params: function(val) {
            if (JSON.stringify(val) !== JSON.stringify(this.mParams)) {
                this.mParams = val;
            }
        },
        proxyCompanyID: function() {
            this.refresh();
        },
        refreshToggle: function() {
            this.refresh();
        },
        selectedFilters: function(val) {
            this.isFilterChanged = JSON.stringify(val) != JSON.stringify(this.originalFilters);
        },
    },
    created() {
        this.isLoading = this.loading;
    },
    mounted() {
        if (this.params != null && this.mParams != this.params) {
            this.mParams = this.params;
        }
        this.$emit('mounted');
        
        if (this.isLengthyArray(this.defaultFilters)) {
            this.defaultFilters.forEach(dFilter => {
                this.selectedFilters.push(this.chipFilters.findIndex(y => y == dFilter));
            })
            this.originalFilters = this.selectedFilters;
        }
    },
    computed: {
        listStyle() {
            if (this.actualUsedHeight != null) {
                return `height: calc(100vh - ${this.actualUsedHeight}px)`;
            }
            else {
                var mUsedHeight = this.otherUsedHeight;

                if (this.bladesData != null) {
                    mUsedHeight += 8;
                }

                if (this.showBottomNavigation) {
                    mUsedHeight += 57;
                }

                // if (!this.hideHeader) {
                //     mUsedHeight += 48;
                // }

                if (!this.hideBladeHeader) {
                    mUsedHeight += 48;
                }

                return `height: calc(100vh - ${mUsedHeight}px)`;
            }
        },
        chipFilters() {
            var filterList = [];
            
            if (this.isLengthyArray(this.customFilters)) {
                filterList.push(...this.customerFilters.map(x => x.filter));
            }

            if (this.useServerFilters && this.navigation != null) {
                filterList.push(...this.$BlitzIt.store.filters(this.navigation));
            }

            return filterList;
        },
        filteredItems() {
            var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            
            if (this.canSearchLocal && this.searchInput != null) {
                l = l.filter(x => this.hasSearch(x, this.searchInput, this.searchProps));
            }
            
            return l;
        },
        isChildTab() {
            return this.bladeData != null && this.bladeData.isChild;
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.proxyIDParam != null && this.bladeData != null && this.bladeData.data != null && this.bladeData.data[this.proxyIDParam] != null) {
                return this.bladeData.data[this.proxyIDParam];
            }

            return null;
        },
        showBottomNavigation() {
            return this.useServerPagination && this.totalPages > 1;
        }
    }, 
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.isLoading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.isLoading = false;
            this.$forceUpdate();
        },
        conditionsHaveChanged() { //checks and returns true or false, always ending with updating the recentConditions with the currentConditions
            var currentConditions = {
                ...this.getParamObj(),
                navigation: this.navigation,
                itemID: this.itemID,
                proxyID: this.proxyCompanyID,
                ...this.bladeData != null ? this.bladeData.data : null
            }
            
            var currentJSON = JSON.stringify(currentConditions);
            var isDif = currentJSON != this.recentConditions;
            this.recentConditions = currentJSON;
            return isDif;
        },
        async deleteItem(item) {
            var proceed = await this.$confirm('Are you sure you want to delete this item?');
            if (proceed === true) {
                var asyncInd = this.asyncItems.findIndex(y => y.id == item.id);
                if (this.navigation != null) {
                    item.loadingCount += 1;
                    this.$forceUpdate();

                    try {
                        await this.$BlitzIt.store.delete(this.navigation, item.id, this.proxyCompanyID);
                    }
                    catch (err) {
                        this.formError(err);
                    }
                    finally {
                        if (item != null) {
                            item.loadingCount -= 1;
                        }
                        if (asyncInd > -1) {
                            this.asyncItems.splice(asyncInd, 1);
                        }
                        
                        this.$emit('deleted', item);
                    }
                }
                else {
                    var ind = this.items.findIndex(y => y.id == item.id);
                    if (ind >= 0) {
                        this.items.splice(ind, 1);
                    }
                    if (asyncInd > -1) {
                        this.asyncItems.splice(asyncInd, 1);
                    }

                    this.$emit('deleted', item);
                }
               
            }
        },
        exportAsCSV() {
            if (this.isLengthyArray(this.asyncItems)) {
                var csvKeys = [];

                if (!this.isLengthyArray(csvKeys)) {
                    csvKeys = Object.keys(this.asyncItems[0]);
                    csvKeys = csvKeys.map(x => { return { header: this.fromCamelCase(x), value: x }; });
                }

                var csvList = [];
                this.filteredItems.forEach(aItem => {
                    var newItem = {};                    
                    csvKeys.forEach(csvDNA => {
                        var v = null;
                        if (typeof(csvDNA.value) == 'function') {
                            //newItem[csvDNA.header] = csvDNA.value(aItem);
                            v = csvDNA.value(aItem);
                        }
                        else if (typeof(csvDNA.value) == 'string') {
                            v = this.getNestedValue(aItem, csvDNA.value);
                        }

                        if (v != null && csvDNA.valueFilter != null) {
                            v = this.$options.filters[csvDNA.valueFilter](v);
                        }

                        newItem[csvDNA.header] = v;
                    });
                    csvList.push(newItem);
                });
                this.generateCSVFile(csvList, 'csvData.csv', csvKeys.map(x => x.header), this.csvIncludeTitle ? this.title : null);
            }
        },
        getParamObj() {
            var paramObj = this.copyDeep(this.mParams);

            if (this.searchString && this.searchString.length > 0) {
                if (this.canSearch) {
                    paramObj.searchString = this.searchString;
                }
            }
            
            if (this.isLengthyArray(this.selectedFilters)) {
                var serverFilters = [];
                var customFilters = [];
                this.selectedFilters.forEach(selectedIndex => {
                    var sFilter = this.chipFilters[selectedIndex];
                    if (this.isLengthyArray(this.customFilters) && this.customFilters.some(cF => cF.filter == sFilter)) {
                        customFilters.push(this.customFilters.find(cF => cF.filter == sFilter));
                    }
                    else {
                        serverFilters.push(sFilter);
                    }
                });

                if (this.isLengthyArray(serverFilters)) {
                    paramObj.filterBy = serverFilters.toString();
                }
                
                if (this.isLengthyArray(customFilters)) {
                    var query = '';
                    customFilters.forEach(cFilter => {
                        if (cFilter.paramProp == null || cFilter.paramProp == 'query') {
                            query = query + (query.length > 0 ? 'ANDALSO' : '') + (cFilter.filterFunction());
                        }
                        else {
                            paramObj[cFilter.paramProp] = cFilter.filterFunction();
                        }
                    })

                    if (query.length > 0) {
                        paramObj.query = query;
                    }
                }
            }
            
            if (this.isLengthyArray(this.itemProperties)) {
                paramObj.properties = this.copyDeep(this.itemProperties).toString();
            }

            if (this.useServerPagination) {
                paramObj.includeCount = true;
                paramObj.takeAmount = this.itemsPerPage;
                paramObj.takeFrom = (this.currentPage - 1) * this.itemsPerPage;
            }

            return paramObj;
        },
        loadCount() {
            if (this.navigation != null) {
                var cnt = this.$BlitzIt.store.getLocalCount(this.navigation);
                this.totalPages = roundUp(cnt / this.itemsPerPage);
            }
        },
        async pullItems(refresh = false) {
            if (this.onCanPull != null && !this.onCanPull()) {
                return;
            }
            
            if (this.items != null) {
                this.asyncItems = this.items.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));

                if (this.onInsertDataAsync != null) {
                    this.originalDataBag = await this.onInsertDataAsync(this.asyncItems);
                }

                return;
            }

            if (this.navigation == null || this.mParams == null) {
                return;
            }

            try {
                this.startLoading();
                
                var paramObj = this.getParamObj();
              
                var res = null;
                if (this.isSingle) {
                    res = await this.$BlitzIt.store.get(this.navigation, this.itemID, paramObj, refresh);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, paramObj, refresh, this.proxyCompanyID);
                }
                
                res = res.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));

                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh, this.bladeData);
                }
                else {
                    this.asyncItems = res;
                }

                if (this.onInsertDataAsync != null) {
                    this.originalDataBag = await this.onInsertDataAsync(this.asyncItem);
                }

                this.$emit('fetched', this.asyncItems);
                this.loadCount();
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
        },
        refresh(refresh = true) {
            console.log('r');
            this.currentPage = 1;
            this.totalPages = 0;
            this.originalFilters = this.selectedFilters;
            this.isFilterChanged = JSON.stringify(this.selectedFilters) != JSON.stringify(this.originalFilters);
            this.searchString = this.searchInput;
            this.showSearch = false;
            this.showError = false;
            this.errorMessage = null;

            this.pullItems(refresh);
        },
        selectItem(item) {
            this.$emit('select', item);
            
            if (!this.canSelect || (this.onCanSelect != null && !this.onCanSelect(item))) {
                return;
            }
            
            if (this.onSelect != null) {
                this.onSelect(this.bladeData, item);
            }
            else if (item == null) {
                if (this.bladesData != null) {
                    this.bladesData.closeBlade({ bladeName: this.selectBladeName || this.addBladeName });
                }
            }
            else {
                var selectBlade = { 
                    bladeName: this.selectBladeName || this.addBladeName,
                    data: { id: item.id }, 
                    query: {},
                    clearBlades: this.clearBlades, 
                    clearOtherBlades: this.clearOtherBlades ? this.bladeName: null, 
                    bladesToClear: this.bladesToClear 
                };

                if (this.getSelectBladeData != null) {
                    selectBlade.data = Object.assign({}, selectBlade.data, this.getSelectBladeData(this.bladeData, item));
                }

                if (this.proxyCompanyID != null) {
                    selectBlade.query.proxyID = this.proxyCompanyID;
                }

                if (this.getSelectQuery != null) {
                    selectBlade.query = Object.assign({}, selectBlade.query, this.getSelectQuery(this.bladeData, item, this.computedMeasurements));
                }

                if (this.onCanOpenBlade != null) {
                    var str = this.onCanOpenBlade(selectBlade);
                    if (str === false) {
                        return;
                    }
                    else if (typeof(str) === 'string') {
                        this.msg = str;
                        return;
                    }
                }

                if (this.bladesData == null) {
                    this.$router.push({ 
                        name: selectBlade.bladeName,
                        params: selectBlade.data,
                        query: selectBlade.query
                    });
                }
                else {
                    this.bladesData.openBlade(selectBlade);

                    if (this.minimizeOnSelect && !this.isPinned) {
                        this.bladesData.minimizeBlade({ bladeName: this.bladeName });
                    }
                }
            }

            if (this.bladesData != null && this.closeOnEmpty === true && !this.isLengthyArray(this.filteredItems)) {
                this.bladesData.closeBlade(this.bladeData);
            }
        },
        update() {
            var v = null;
            if (this.select == null) {
                v = null;
            }
            else if (this.itemValue) {
                v = this.select[this.itemValue];
            }
            else {
                v = this.select;
            }

            this.$emit('input', v);
            this.$emit('change', v);

            if (!this.multiple) {
                this.selectItem(v);
            }
        },
        updateBlade(bladeData) {
            this.bladeData = bladeData;

            if (bladeData != null) {
                if (this.getParams != null) {
                    var params = this.getParams(bladeData);
                    if (JSON.stringify(this.mParams) != JSON.stringify(params)) {
                        this.mParams = params;
                    }
                }

                //update pinned
                if (bladeData.isPinned != null) {
                    this.isPinned = bladeData.isPinned;
                }

                if (bladeData.data != null && bladeData.data.filterBy != null) {
                    var fList = bladeData.data.filterBy.split(',');
                    fList.forEach(filter => {
                        if (!this.selectedFilters.some(y => y == filter)) {
                            this.selectedFilters.push(filter);
                        }
                    })
                }
            }

            if (this.conditionsHaveChanged()) {
                this.pullItems();
            }
            
            this.$emit('sourceChanged', bladeData);
        }
    }
}
</script>