<template>
    <span>{{ displayText }}</span>
</template>

<script>
export default {
    name: 'BT-Relative-Time',
    data: function() {
        return {
            displayText: null,
            displayTimer: null,
        }
    },
    props: {
        value: null
    },
    watch: {
        value: function(v) {
            this.updateDisplay(v);
        }
    },
    mounted() {
        //set update timer?
        this.updateDisplay();

        this.displayTimer = setInterval(this.updateDisplay, 60000);
    },
    beforeUnmount() {
        //remove timer
        if (this.displayTimer != null) {
            clearInterval(this.displayTimer);
        }
    },
    methods: {
        updateDisplay() {
            this.displayText = this.$BlitzIt.auth.getRelativeTime(this.value);
        }
    }
}
</script>