import Vue from 'vue'
import { isLengthyArray } from '~helpers';

export default {
    install(vue) {

        const root = new Vue({});

        this.customData = {
            tours: []
        }

        this.data = null;

        this.existingBladeTours = [
            'all',
            'batch.new',
            'batch.edit',
            'batches',
            'customer-order',
            'customer-orders',
            'sales-hub',
        ];

        this.findHelpByNavigation = (navNames) => {
            if (this.data != null) {
                return this.data.findHelpByNavigation(navNames);
            }
        }

        this.loadData = async () => {
            if (this.data == null) {
                var res = await import('../training/blitzithelpdata.js');
                this.data = res.default;
            }
        }

        vue.prototype.$tourExists = (bladeName) => { //bladename.edit bladename.new, etc
            if (bladeName == null) {
                return false;
            }

            var bladeNameSplit = bladeName.split('.');
            var exists = this.existingBladeTours.some(z => z == bladeName);
            if (exists) {
                return exists;
            }

            this.existingBladeTours.forEach(z => {
                var zSplit = z.split('.');
                if (zSplit[0] == bladeNameSplit[0]) {
                    exists = true;
                }
            });

            return exists;
        }

        vue.prototype.$startTour = async (bladeName, status = null) => { //bladename.edit bladename.new, etc
            await this.loadData();
            
            if (this.data != null) {
                var id = bladeName;
                if (status == null) {
                    var idSplit = id.split('.');
                    if (idSplit.length > 1) {
                        status = idSplit[1];
                        id = idSplit[0];
                    }
                }

                var tour = this.data.tours.find(z => z.id == bladeName || isLengthyArray(z.navigations) && z.navigations.some(nav => nav == id));

                if (tour != null) {
                    var t = root.$tours['tour'];
                    var steps = [];
                    var purge = false;

                    tour.steps.forEach(step => {
                        if (status == null) {
                            if (!isLengthyArray(step.only) && (!isLengthyArray(step.status) || step.status.some(z => z.length == 0))) {
                                steps.push(step);
                            }
                            // if (!isLengthyArray(step.status) || step.status.some(z => z.length == 0)) {
                            //     steps.push(step);
                            // }
                        }
                        else if (isLengthyArray(step.only) && step.only.some(z => z == status)) {
                            purge = true;
                            steps.push(step);
                        }
                        else {
                            if (!isLengthyArray(step.only) && (!isLengthyArray(step.status) || step.status.some(z => z.length == 0 || z == status))) {
                                steps.push(step);
                            }
                        }
                    })

                    if (purge) {
                        steps = steps.filter(x => isLengthyArray(x.only) && x.only.some(z => z == status));
                    }

                    t.steps.length = 0;
                    steps.forEach(step => {
                        t.steps.push(step);
                    })

                    t.start();
                }
            }
        }
    }
}