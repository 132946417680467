<template>
    <v-slide-x-reverse-transition group hide-on-leave>
        <v-list-item 
            v-if="!isEditing && !isNew" 
            :dense="dense" 
            key="1"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>
                <v-list-item-title v-if="!horizontal">{{ str }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action-text v-if="horizontal">{{ str }}</v-list-item-action-text>
        </v-list-item>
        <v-list-item 
            v-else
            :dense="dense" 
            key="2"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-textarea
                    v-model="str"
                    :class="fieldClass"
                    :disabled="disabled"
                    :hide-details="hideDetails"
                    @input="update"
                    :label="label"
                    :dense="dense"
                    :rules="rules" />
            </v-list-item-content>
        </v-list-item>
    </v-slide-x-reverse-transition>
</template>

<script>
export default {
    name: 'BT-Field-Text-Area',
    data: function() {
        return {
            str: null
        }
    },
    props: {
        value: null,
        dense: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fieldClass: {
            type: String,
            default: null
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        horizontal: {
            type: Boolean,
            default: false
        },
        icon: null,
        isEditing: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        label: null,
        rules: {
            type: Array,
            default: () => { return [] }
        }
    },
    watch: {
        value: function(val) {
            this.str = val;
        }
    },
    created() {
        this.str = this.value;
    },
    methods: {
        update(val) {
            this.$emit('input', val);
            this.$emit('change', val);
        }
    }
}
</script>