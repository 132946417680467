<template>
    <v-snackbar
        v-model="showSnack"
        :timeout="cTimeout"
        app
        :bottom="bottom"
        color="bg-primary lighten-1"
        dark
        :top="top"
        @input="change">
        {{ value }}
    </v-snackbar>
</template>

<script>
export default {
    name: 'BT-Snack',
    data: function() {
        return {
            showSnack: false
        }
    },
    props: {
        bottom: {
            type: Boolean,
            default: true
        },
        top: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: null
        },
        isTimed: {
            type: Boolean,
            default: true
        },
        shaped: {
            type: Boolean,
            default: true
        },
        timeout: {
            type: String,
            default: '5000'
        }
    },
    watch: {
        value: function(val) {
            if (val != null) {
                this.showSnack = true;
            }
        }
    },
    computed: {
        cTimeout() {
            return this.isTimed ? this.timeout : -1;
        }
    },
    methods: {
        change(val) {
            if (!val) {
                this.$emit('input', null);
            }
            // if (!val) {
            //     this.showText = null;
            // }
        }
    }
}
</script>