// import URLProductionConstants from '~tools/url-constants-production.js';
// import URLDevConstants from '~tools/url-constants-development.js';
// import URLStagingConstants from '~tools/url-constants-staging.js';

const urls = {
    Authentication: {
        production: 'https://api.blitzitweb.com.au/auth/v1',
        staging: 'https://blitzitapisstaging.azurewebsites.net/auth/v1',
        development: 'https://localhost:44311/auth/v1'
    },
    Default: {
        production: 'https://api.blitzitweb.com.au/api/v1',
        staging: 'https://blitzitapisstaging.azurewebsites.net/api/v1',
        development: 'https://localhost:44311/api/v1'
    },
    WebApp: {
        production: 'https://www.blitzitweb.com.au',
        staging: 'https://staging.blitzitweb.com.au',
        development: 'http://localhost:8080'
    },
    DbName: {
        production: 'blitzitweb_db',
        staging: 'blitzitweb_staging_db',
        development: 'blitzitweb_development_db'
    }
}

export default {
    getURL(microservice) {
       return urls?.[microservice]?.[process.env.NODE_ENV] ?? urls.Default?.[process.env.NODE_ENV]
    },
    
    // getBaseWebAppURL() {
    //     if (process.env.URL_GROUP != undefined && process.env.URL_GROUP != null) {
    //         var urlSet = process.env.URL_GROUP;
    //         if (urlSet == 'production') {
    //             return URLProductionConstants.WebApp;
    //         }
    //         else if (urlSet == 'staging') {
    //             return URLStagingConstants.WebApp;
    //         }
    //     }

    //     if (process.env.VUE_APP_LOCAL_VAR == 'staging' || window.location.origin == 'https://proud-pond-0fadfc600.1.azurestaticapps.net') {
    //         return URLStagingConstants.WebApp;
    //     }
    //     else if (process.env.VUE_APP_LOCAL_VAR == 'production') {
    //         return URLProductionConstants.WebApp;
    //     }
    //     else {
    //         return URLDevConstants.WebApp;
    //     }
    //     //return process.env.VUE_APP_LOCAL_VAR == 'true' ? URLLocalConstants.WebApp : URLConstants.WebApp;        
    // },
    
    // getAuthURL() {
    //     if (process.env.URL_GROUP != undefined && process.env.URL_GROUP != null) {
    //         var urlSet = process.env.URL_GROUP;
    //         if (urlSet == 'production') {
    //             return URLProductionConstants.Authentication;
    //         }
    //         else if (urlSet == 'staging') {
    //             return URLStagingConstants.Authentication;
    //         }
    //     }

    //     if (process.env.VUE_APP_LOCAL_VAR == 'staging' || window.location.origin == 'https://proud-pond-0fadfc600.1.azurestaticapps.net') {
    //         return URLStagingConstants.Authentication;
    //     }
    //     else if (process.env.VUE_APP_LOCAL_VAR == 'production') {
    //         return URLProductionConstants.Authentication;
    //     }
    //     else {
    //         return URLDevConstants.Authentication;
    //     }
    //     //return process.env.VUE_APP_LOCAL_VAR == 'true' ? URLLocalConstants.Authentication : URLConstants.Authentication;
    // },

    formLinkURL(linkID) {
        return `${this.getURL('Auth')}/Links/get/${linkID}`;
    },

    // getDbName() {
    //     if (process.env.URL_GROUP != undefined && process.env.URL_GROUP != null) {
    //         var urlSet = process.env.URL_GROUP;
    //         if (urlSet == 'production') {
    //             return URLProductionConstants.DbName;
    //         }
    //         else if (urlSet == 'staging') {
    //             return URLStagingConstants.DbName;
    //         }
    //     }

    //     if (process.env.VUE_APP_LOCAL_VAR == 'staging' || window.location.origin == 'https://proud-pond-0fadfc600.1.azurestaticapps.net') {
    //         return URLStagingConstants.DbName;
    //     }
    //     else if (process.env.VUE_APP_LOCAL_VAR == 'production') {
    //         return URLProductionConstants.DbName;
    //     }
    //     else {
    //         return URLDevConstants.DbName;
    //     }
    // }

}