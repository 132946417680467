<template>
    <BT-Blade
        :bladeName="bladeName"
        :bladesToClear="bladesToClear"
        :bladesData="bladesData"
        :canClose="canClose"
        :canEdit="showEdit"
        :canDelete="showDelete"
        :canMinimize="canMinimize"
        :canPin="canPin"
        :canRefresh="canRefresh"
        :canRestore="showRestore"
        :customBladeData="customBladeData"
        :defaultBladeWidth="defaultBladeWidth"
        :defaultPageWidth="defaultPageWidth"
        :dependantBlades="dependantBlades"
        :editToggle="mEditToggle"
        :flat="flat"
        :flexColumn="flexColumn"
        :hideBackButton="hideBackButton"
        :hideBladeHeader="hideBladeHeader"
        :isChildBlade="isChildBlade"
        :large="large"
        :loadingMsg="mLoadingMsg"
        :navigation="navigation"
        :pageColor="pageColor"
        :pageVariant="pageVariant"
        :permanent="permanent"
        :resetToggle="mResetToggle"
        :resizable="resizable"
        :scroll="scroll"
        :showSettings="showSettings"
        :small="compSmall"
        :startEditing="startEditing"
        :tile="tile"
        :title="pageTitle"
        :toolbarClass="toolbarClass"
        :tourStatus="tourStatus"
        :updateDependants="updateDependants"
        @edit="edit"
        @refresh="refresh"
        @restore="restoreItem"
        @delete="deleteItem"
        @sourceChanged="updateBlade">
        <template v-slot:page="{ data, bladeHeight }">
            <slot name="page" v-bind:item="asyncItem" v-bind:data="data" v-bind:bladeHeight="bladeHeight" v-bind:save="saveItem" />
        </template>
        <template v-slot:toolbar="data">
            <slot name="toolbar" v-bind="{ item: asyncItem, isNew, data }" />
        </template>
        <template v-slot:toolbar-right="{ data }">
            <slot name="toolbar-right" v-bind="{ item: asyncItem, isNew, data }" />
        </template>
        <template v-slot:bladeSettings="{ data }">
            <slot name="bladeSettings" v-bind="{ item: asyncItem, isNew, data }" />
        </template>
        <template v-slot="{ data }">
            <slot name="raw"
                v-bind:item="asyncItem" 
                v-bind:isNew="isNew" 
                :msg="msg"
                v-bind:save="saveItem" 
                v-bind:data="data"
                v-bind:deleteItem="deleteItem"
                v-bind:isChanged="isChanged"
                v-bind:proxyID="proxyCompanyID">
                <v-card
                    class="mx-auto"
                    :class="frameClass"
                    :flat="flat"
                    :height="height"
                    :style="frameStyle"
                    :tile="tile"
                    :width="computedCardWidth">
                    <v-card-text v-if="asyncItem == null && mLoadingMsg == null" class="pa-0" fill-height full-width>
                        <slot name="notFound" v-bind="{ isNew, data, refresh }">
                            <v-card-text class="text-center">
                                <h2 class="mt-5 warning--text">Not Found</h2>
                            </v-card-text>
                        </slot>
                    </v-card-text>
                    <v-card-text v-else class="pa-0" fill-height full-width>

                        <BT-Snack v-model="msg" />
                        <slot name="everything"
                            v-bind:item="asyncItem" 
                            v-bind:isNew="isNew" 
                            v-bind:save="saveItem" 
                            v-bind:data="data"
                            v-bind:deleteItem="deleteItem"
                            :errorMsg="errorMessage"
                            :showError="showError"
                            v-bind:isChanged="isChanged"
                            v-bind:proxyID="proxyCompanyID">
                            <v-form v-if="asyncItem" ref="form" :dense="dense">
                                <slot 
                                    v-bind:item="asyncItem" 
                                    v-bind:isNew="isNew" 
                                    v-bind:save="saveItem" 
                                    v-bind:data="data"
                                    v-bind:deleteItem="deleteItem"
                                    v-bind:isChanged="isChanged"
                                    v-bind:proxyID="proxyCompanyID" />
                                <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
                            </v-form>
                        </slot>
                    </v-card-text>
                    <v-card-actions v-if="showSave">
                        <slot
                            name="save"
                            v-bind:save="saveItem"
                            v-bind:showSave="showSave"
                            v-bind:hideSaveAndClose="hideSaveAndClose"
                            v-bind:item="asyncItem"
                            v-bind:isNew="isNew"
                            v-bind:isChanged="isChanged">
                            <v-slide-x-transition group>
                                <v-btn v-if="showSave" class="mx-1" color="primary" @click="saveItem(true)" key="a"><v-icon left>mdi-content-save</v-icon>Save</v-btn>
                                <v-btn v-if="showSave && !hideSaveAndClose" class="mx-1" color="primary" @click="saveItem(false)" key="b"><v-icon left>mdi-content-save</v-icon>Save And Close</v-btn>
                            </v-slide-x-transition> 
                        </slot>
                    </v-card-actions>
                </v-card>
            </slot>
        </template>
    </BT-Blade>
</template>

<script>
export default {
    name: 'BT-Blade-Item',
    data: function() {
        return {
            asyncItem: null,
            bladeData: null,
            mEditToggle: false,
            errorMessage: null,
            id: null,
            isPinned: false,
            itemJSON: null,
            mLoadingMsg: null,
            msg: null,
            mProxyID: null,
            mResetToggle: false,
            originalDataBag: null,
            showError: false,
            snapshotItemJSON: null,
        }
    },
    props: {
        actualUsedHeight: {
            type: Number,
            default: null
        },
        bladeName: null,
        bladeClass: {
            type: String,
            default: null
        },
        bladesData: {
            type: Object,
            default: null
        },
        bladesToClear: {
            type: Array,
            default: null
        },
        canClose: {
            type: Boolean,
            default: true
        },
        canCreate: {
            type: Boolean,
            default: true
        },
        canDelete: {
            type: Boolean,
            default: true
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
        canMinimize: {
            type: Boolean,
            default: true
        },
        canPin: {
            type: Boolean,
            default: true
        },
        canRefresh: {
            type: Boolean,
            default: true
        },
        canRestore: {
            type: Boolean,
            default: false
        },
        canSave: {
            type: Boolean,
            default: true
        },
        cardWidth: {
            type: String,
            default: '900'
        },
        changeToggle: {
            type: Boolean,
            default: false
        },
        closeOnDelete: {
            type: Boolean,
            default: true
        },
        customBladeData: {
            type: Object,
            default: null
        },
        customURL: {
            type: String,
            default: null
        },
        defaultBladeWidth: {
            type: Number,
            default: 700
        },
        defaultFrameWidth: {
            type: String,
            default: '900'
        },
        defaultPageWidth: {
            type: String,
            default: '100%'
        },
        dense: {
            type: Boolean,
            default: true
        },
        dependantBlades: {
            type: Array,
            default: () => { return [] } //[String]
        },
        editToggle: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: true
        },
        flexColumn: {
            type: Boolean,
            default: false
        },
        frameVariant: { //default, transparent, opaque, light, dark
            type: String,
            default: 'default'
        },
        height: {
            type: String,
            default: null
        },
        hideBackButton: {
            type: Boolean,
            default: false
        },
        hideBladeHeader: {
            type: Boolean,
            default: false
        },
        hideSaveAndClose: {
            type: Boolean,
            default: false
        },
        ignoreID: {
            type: Boolean,
            default: false
        },
        ignorePermissions: {
            type: Boolean,
            default: false
        },
        isChildBlade: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: null
        },
        itemID: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingMsg: {
            type: String,
            default: null
        },
        navigation: null,
        noHeight: {
            type: Boolean,
            default: false
        },
        onCanDelete: {
            type: Function,
            default: null
        },
        onCanEdit: {
            type: Function,
            default: null
        },
        onCanPull: {
            type: Function,
            default: null
        },
        onCanSave: {
            type: Function,
            default: null
        },
        onFinalize: {
            type: Function,
            default: null
        },
        onGetItem: {
            type: Function,
            default: null
        },
        onGetLocalUpdateAsync: {
            type: Function,
            default: null
        },
        onGetSave: {
            type: Function,
            default: (bladeData, item) => { return item }
        },
        onGetSaveAsync: {
            type: Function,
            default: null
        },
        onIsNew: {
            type: Function,
            default: null
        },
        onNew: {
            type: Function,
            default: () => { return { }}
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        otherUsedHeight: {
            type: Number,
            default: 48
        },
        pageColor: {
            type: String,
            default: null
        },
        pageVariant: { //default, transparent, opaque, light, dark
            type: String,
            default: 'opaque'
        },
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        permanent: {
            type: Boolean,
            default: false
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        resizable: {
            type: Boolean,
            default: true
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        saveToggle: {
            type: Boolean,
            default: false
        },
        scroll: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: null
        },
        startEditing: {
            type: Boolean,
            default: false
        },
        startNew: {
            type: Boolean,
            default: false
        },
        tile: {
            type: Boolean,
            default: true
        },
        title: null,
        titleProp: {
            type: String,
            default: null
        },
        toolbarClass: {
            type: String,
            default: 'primary lighten-1'
        },
        tourStatus: {
            type: String,
            default: null
        },
        trackChanges: {
            type: Boolean,
            default: true
        },
        trackIgnore: {
            type: Array,
            default: null
        },
        updateDependants: {
            type: Boolean,
            default: true
        },
        useCopy: {
            type: Boolean,
            default: false
        },
        width: {
            type: Number,
            default: 350
        },
    },
    watch: {
        asyncItem: {
            deep: true,
            handler() {
                this.updateChange();
            }
        },
        editToggle() {
            this.mEditToggle = !this.mEditToggle
        },
        loading: function(val) {
            this.mLoadingMsg = val ? 'Loading' : null;
        },
        loadingMsg(val) {
            this.mLoadingMsg = val;
        },
        proxyCompanyID: function() {
            this.refresh();
        },
        changeToggle() {
            this.snapshotItemJSON = this.createSnapshot(this.copyDeep(this.asyncItem));
            this.itemJSON = this.snapshotItemJSON;
        },
        refreshToggle: function() {
            this.refresh();
        },
        saveToggle: function() {
            this.saveItem();
        }
    },
    created() {
        this.mLoadingMsg = this.loading ? 'Loading' : this.loadingMsg;
    },
    mounted() {
        this.$emit('mounted');

        this.mProxyID = this.proxyID;
    },
    computed: {
        canAuthEdit() {
            return this.$BlitzIt.auth.canEdit(this.navigation);
        },
        computedCardWidth() {
            if (this.bladesData != null) {
                return '100%';
            }
            else {
                return this.defaultFrameWidth;
            }
        },
        compSmall() {
            return this.small != null ? this.small : (this.$vuetify.breakpoint.mobile ? false : true);
        },
        frameClass() {
            let e = this.bladeClass ?? ''

            if (this.frameVariant == 'transparent') {
                e = `${e} transparent`
            }

            return e
        },
        frameStyle() {
            let e = '';

            if (this.frameVariant == 'opaque') {
                e = `${e} background: ${this.$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.90)' : 'rgba(255, 255, 255, 0.65)'};`;
            }

            if (this.scroll) {
                if (this.actualUsedHeight != null) {
                    e =  `${e} height: calc(100vh - ${this.actualUsedHeight}px);`;
                }
                else {
                    var mUsedHeight = this.otherUsedHeight;

                    if (this.bladesData != null) {
                        mUsedHeight += 8;
                    }

                    if (!this.hideBladeHeader) {
                        mUsedHeight += 48;
                    }

                    e = `${e} height: calc(100vh - ${mUsedHeight}px);`;
                }
            }

            return e;
        },
        isChanged() {
            var res = this.itemJSON != this.snapshotItemJSON;
            this.$emit('changed', res);
            return res;
        },
        isNew() {
            return this.id == 'new' || this.startNew;
        },
        pageIcon() {
            var e = this.$BlitzIt.navigation.findItem(this.navigation);
            return e ? e.icon : null;
        },
        pageTitle() {
            if (this.title) {
                return this.title;
            }
            else if (this.titleProp && !this.isNew && this.asyncItem) {
                var title = this.getNestedValue(this.asyncItem, this.titleProp);
                if (title) {
                    return title;
                }
            }

            var e = this.$BlitzIt.navigation.findSingleDisplayName(this.navigation);
            return this.isNew ? 'New ' + e : e;
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.mProxyID != null) {
                return this.mProxyID;
            }
            else if (this.proxyIDParam != null && this.bladeData != null && this.bladeData.data != null && this.bladeData.data[this.proxyIDParam] != null) {
                return this.bladeData.data[this.proxyIDParam];
            }
            else if (this.proxyIDParam != null && this.$route.query != null && this.$route.query[this.proxyIDParam] != null) {
                return this.$route.query[this.proxyIDParam];
            }

            return null;
        },
        showDelete() {
            return this.canDelete && (this.onCanDelete == null || this.onCanDelete(this.asyncItem)); // && (this.asyncItem == null); // || !this.asyncItem.isInactive);
        },
        showEdit() {
            return this.canEdit && (this.onCanEdit == null || this.onCanEdit(this.asyncItem));
        },
        showRestore() {
            return this.canRestore && this.asyncItem != null && this.asyncItem.isInactive;
        },
        showSave() {
            return this.canSave && this.mLoadingMsg == null && ((this.canEdit || this.startEditing) && (this.canAuthEdit || this.ignorePermissions ) && (!this.trackChanges || this.isChanged) || (this.canCreate && this.isNew));
        }
    },
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        createSnapshot(data) {
            var copy = this.copyDeep(data);
            
            if (this.isLengthyArray(this.trackIgnore)) {
                this.trackIgnore.forEach(x => {
                    delete copy[x];
                });
            }

            return JSON.stringify(copy);
        },
        edit(bladeData) {
            this.$emit('edit', bladeData, this.asyncItem);
        },
        async deleteItem() {
            this.showError = false;
            this.errorMessage = null;

            this.mLoadingMsg = 'Deleting';

            try {
                var proceed = await this.$confirm('Are you sure you want to delete this item?');
                if (proceed) {
                    await this.$BlitzIt.store.delete(this.navigation, this.asyncItem.id, this.proxyCompanyID);
                }
                
                this.$emit('deleted');

                if (this.closeOnDelete) {
                    if (this.bladesData != null) {
                        this.bladesData.closeBlade({ bladeName: this.bladeName });
                    }
                    else {
                        this.$BlitzIt.navigation.navBack();
                    }
                }
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.mLoadingMsg = null;
            }
        },
        renav(item) {
            var selectBlade = {
                bladeName: this.bladeName,
                data: { id: item.id },
                query: { }
            };

            if (this.proxyCompanyID != null) {
                selectBlade.query.proxyID = this.proxyCompanyID;
                selectBlade.data.proxyID = this.proxyCompanyID;
            }

            //this.$BlitzIt.store.deleteLocal(this.navigation, item.id);
            //this.asyncItem = null;

            if (this.bladesData == null) {
                //save filters, etc.
                this.$router.push({ 
                    name: selectBlade.bladeName,
                    params: selectBlade.data,
                    query: selectBlade.query
                });
            }
            else { 
                this.bladesData.openBlade(selectBlade);
            }
        },
        async finalize(item, stayHere = false) {
            if (this.onFinalize != null) {
                await this.onFinalize(item);
                this.mEditToggle = !this.mEditToggle;
            }
            else {
                this.msg = 'Saved';
                
                if (this.isNew && this.bladesData == null && this.customBladeData == null) {
                    if (!stayHere) {
                        this.$BlitzIt.navigation.navBack();
                    }
                    else {
                        this.renav(item);
                    }
                }
                else if (this.isNew && this.bladesData != null) {
                    //close?
                    if (!stayHere) {
                        this.bladesData.closeBlade({ bladeName: this.bladeName });
                    }
                    else {
                        this.renav(item);
                    }
                }
                else if (item != null) {
                    this.mEditToggle = !this.mEditToggle;
                    this.asyncItem.id = item.id;
                    this.asyncItem.rowVersion = item.rowVersion;
                    this.snapshotItemJSON = this.createSnapshot(this.asyncItem); //JSON.stringify(this.asyncItem);
                    this.itemJSON = this.snapshotItemJSON;
                    if (!stayHere) {
                        if (this.bladesData != null) {
                            this.bladesData.closeBlade({ bladeName: this.bladeName });
                        }
                        else {
                            this.$BlitzIt.navigation.navBack();
                        }
                    }
                }

                this.$emit('saved', this.asyncItem, item);
            }

            this.$BlitzIt.tracker.reset(this.asyncItem);
        },
        async pullItem(refresh = false, changed = false) {
            if (this.onCanPull != null && !this.onCanPull()) {
                return;
            }

            if (this.item != null || this.onGetLocalUpdateAsync != null) {
                if (this.onGetLocalUpdateAsync != null) {
                    this.asyncItem = await this.onGetLocalUpdateAsync(this.bladeData, this.item);
                }
                else {
                    this.asyncItem = this.item;
                }
                
                return;
            }

            this.mLoadingMsg = 'Getting Data';

            try {
                var idParam = this.itemID != null ? this.itemID : this.id;
                
                if (idParam == 'new') {
                    this.asyncItem = null;
                }
                if ((this.asyncItem == null || refresh == true || changed == true) && (idParam != null || this.ignoreID)) {
                    var res = null;
                    if (idParam === 'new') {
                        res = await this.onNew(this.bladeData);
                    }
                    else if (this.onGetItem != null) {
                        res = this.onGetItem(this.bladeData);
                    }
                    else {
                        if (this.navigation == null) {
                            return;
                        }

                        res = await this.$BlitzIt.store.get(this.navigation, this.ignoreID ? null : idParam, this.params, refresh, this.proxyCompanyID, this.customURL);
                    }
                    if (this.onPullSuccessAsync != null) {
                        this.asyncItem = await this.onPullSuccessAsync(this.useCopy ? this.copyDeep(res) : res, refresh, this.bladeData);
                    }
                    else {
                        this.asyncItem = this.useCopy ? this.copyDeep(res) : res;
                    }

                    this.snapshotItemJSON = this.createSnapshot(this.asyncItem);
                    this.itemJSON = this.snapshotItemJSON;

                    this.$emit('fetched', this.asyncItem, this.bladeData);
                }
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.mLoadingMsg = null;
            }
        },
        refresh() {
            this.showError = false;
            this.errorMessage = null;
            this.pullItem(true);
        },
        async restoreItem() {
            if (this.navigation == null || this.asyncItem == null) {
                return;
            }

            this.mLoadingMsg = 'Restoring';
            
            try {
                await this.$BlitzIt.store.restore(this.navigation, this.asyncItem.id, this.proxyCompanyID);
                this.asyncItem.isInactive = false;
                this.$BlitzIt.navigation.navBack();
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.mLoadingMsg = null;
            }
        },
        async saveItem(stayHere = true) {
            this.showError = false;

            if (!this.isNew && (this.trackChanges && !this.isChanged)) {
                return;
            }

            if (this.$refs.form == null || this.$refs.form.validate()) {
                this.mLoadingMsg = 'Saving';
                var itemToSave = this.onGetSave(this.bladeData, this.asyncItem);
                
                try {
                    var warning = null;
                    if (this.onCanSave != null) {
                        warning = this.onCanSave(itemToSave);
                    }
                    console.log('can save')
                    console.log(warning)
                    if (warning != null) {
                        this.msg = warning;
                    }
                    else {
                        if (this.onGetSaveAsync != null) {
                            itemToSave = await this.onGetSaveAsync(itemToSave);
                        }

                        if (this.isNew || (this.onIsNew != null && this.onIsNew(itemToSave))) {
                            var postedItem = await this.$BlitzIt.store.post(this.navigation, itemToSave, this.proxyCompanyID);
                            this.$BlitzIt.store.clear(this.navigation);
                            await this.finalize(postedItem, stayHere);
                        }
                        else {
                            await this.finalize(await this.$BlitzIt.store.patch(this.navigation, itemToSave, this.proxyCompanyID), stayHere);
                        }
                    }
                }
                catch (err) {
                    this.formError(err);
                }
                finally {
                    this.mLoadingMsg = null;
                }
            }
        },
        updateChange() {
            this.itemJSON = this.createSnapshot(this.asyncItem);
        },
        updateBlade(bladeData) {
            this.bladeData = bladeData;

            var isChanged = false;

            if (bladeData != null) {
                if (bladeData.data != null) {
                    isChanged = this.id != bladeData.data.id;
                    this.id = bladeData.data.id;

                    if (bladeData.data.proxyID != null) {
                        this.mProxyID = bladeData.data.proxyID;
                    }
                }

                if (bladeData.isPinned != undefined) {
                    this.isPinned = bladeData.isPinned;
                }
            }
            
            if (bladeData != null && !isChanged) {
                 isChanged = JSON.stringify(this.originalDataBag) != JSON.stringify(bladeData.data);
            }

            this.pullItem(false, isChanged);
            this.$emit('sourceChanged', bladeData);

            if (bladeData != null) {
                this.originalDataBag = bladeData.data;
            }
        }
    }
}
</script>