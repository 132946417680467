<template>
    <BT-Select
        v-model="v"
        label="Tax Type"
        :disabled="disabled"
        hide-details
        :items="['GST']"
        @change="emit" />
</template>

<script>
export default {
    name: 'Tax-Type',
    data: function() {
        return {
            v: null,
        }
    },
    props: {
        value: null,
        disabled: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        value: function(val) {
            this.v = val;
        }
    },
    mounted() {
        this.v = this.value;
    },
    methods: {
        emit(val) {
            this.v = val;
            this.$emit('input', this.v);
            this.$emit('change', this.v);
        }
    }
}
</script>