<template>
    <v-badge
        :color="color"
        :content="filteredItems.length"
        :overlap="overlap"
        :value="isLengthyArray(filteredItems)">
        <v-icon :small="small" :large="large">{{ icon }}</v-icon>
    </v-badge>
</template>

<script>
export default {
    name: 'BT-Badge',
    data: function() {
        return {
            asyncItems: [],
            id: null,
            isLoading: false,
            mParams: { includeDetails: false },
            mProxyID: null,
            msg: null,
        }
    },
    props: {
        color: {
            type: String,
            default: 'primary'
        },
        customURL: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        itemProperties: {
            type: Array,
            default: () => { return ['ID'] }
        },
        items: {
            type: Array,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        navigation: null,
        onCanPull: {
            type: Function,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullAsync: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        overlap: {
            type: Boolean,
            default: false
        },
        params: {
            type: Object,
            default: null
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        resetToggle: { //different to refresh toggle because pagination and default filters are all reset
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        value: null
    },
    watch: {
        items: function() {
            this.pullItems();
        },
        loading: function(val) {
            this.isLoading = val;
            this.$forceUpdate();
        },
        params: function(val) {
            if (JSON.stringify(val) !== JSON.stringify(this.mParams)) {
                this.mParams = val;
            }
        },
        refreshToggle: function() {
            this.refresh();
        },
        resetToggle: function() {
            this.reset();
        },
    },
    created() {
        this.isLoading = this.loading;

        if (this.params != null) {
            this.mParams = this.params;
        }
    },
    mounted() {
        if (this.params != null) {
            this.mParams = this.params;
        }

        this.pullItems();

        this.$emit('mounted');
    },
    computed: {
        filteredItems() {
            var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;

            return l;
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.mProxyID != null) {
                return this.mProxyID;
            }
            else if (this.proxyIDParam != null && this.$route.query != null && this.$route.query[this.proxyIDParam] != null) {
                return this.$route.query[this.proxyIDParam];
            }

            return null;
        }
    }, 
    methods: {
        getParamObj() {
            var paramObj = this.copyDeep(this.mParams);

            if (this.isLengthyArray(this.itemProperties)) {
                var props = this.copyDeep(this.itemProperties);
                paramObj.properties = props.toString();
            }

            return paramObj;
        },
        async pullItems(refresh = false) {
            if (this.onCanPull != null && !this.onCanPull()) {
                return;
            }
            
            if (this.items != null) {
                this.asyncItems = this.items;
                return;
            }

            if (this.navigation == null || this.mParams == null) {
                return;
            }

            try {
                this.isLoading = true;

                var paramObj = this.getParamObj();

                var cURL = null;
                if (this.customURL != null) {
                    cURL = this.customURL;
                    var cID = this.itemID || this.id;
                    if (cID != null) {
                        cURL = cURL.replace('{id}', cID);
                    }
                }

                var res = null;
                if (this.isSingle) {
                    var idParam = this.itemID != null ? this.itemID : this.id;
                    res = await this.$BlitzIt.store.get(this.navigation, idParam, paramObj, refresh, this.proxyCompanyID, cURL);
                }
                else if (this.onPullAsync != null) {
                    res = await this.onPullAsync(this.navigation, paramObj, refresh, this.proxyCompanyID, cURL);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, paramObj, refresh, this.proxyCompanyID, cURL);
                }
                
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh);
                }
                else {
                    this.asyncItems = res;
                }

                this.$emit('fetched', this.asyncItems);
            }
            catch (err) {
                console.log(this.extractErrorDescription(err));
            }
            finally {
                this.isLoading = false;
            }
        },
        refresh(refresh = true) {
            this.pullItems(refresh);
        },
    }
}
</script>