<template>
    <v-list>
        <v-subheader>Adjustments</v-subheader>
        <template v-for="(item, index) in filteredAdjustments">
            <v-list-item :key="index" dense>
                <v-list-item-icon>
                    <v-btn 
                        v-if="isEditing && item.isAdjusting" 
                        icon 
                        small
                        title="Remove Adjustment"
                        @click="clear(item)">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </v-list-item-icon>
                <v-list-item-content>
                    <v-list-item-title>{{ item.dateTrigger | toLongDateAndTime }}</v-list-item-title>
                    <v-list-item-subtitle>
                        <span v-if="item.isAdjusting && item.replacingDate != null">Order --> {{ item.replacingDate | toLongDateAndTime }}</span>
                        <span v-else-if="item.isAdjusting">(Cancelled)</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="item.isAdjusting && item.leadTimeLeft > 0 && item.replacingDate != null">
                        Deliver --> {{ item.leadDateLeft | toLongDateAndTime  }}
                    </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                    <v-row class="my-0 py-0">
                        <v-btn
                            v-if="isEditing && (!item.isAdjusting || item.replacingDate != null)"
                            @click="cancel(item)"
                            icon 
                            small 
                            class="error--text" 
                            title="Cancel">
                            <v-icon small>mdi-cancel</v-icon>
                        </v-btn>
                        <BT-Dialog
                            v-if="isEditing"
                            @ok="data => { makeAdjustment(data, item) }"
                            :getOnOpen="openAdjustment"
                            icon="mdi-calendar-edit"
                            :item="item"
                            label="Adjustment"
                            small
                            title="Adjust"
                            width="350">
                            <template v-slot="{ item }">
                                <BT-Date-Picker
                                    label="Adjustment Date"
                                    v-model="item.replacingDate" />

                                <BT-Time-Picker
                                    label="Cut-Off Time"
                                    v-model="item.replacingTime" />

                                <BT-Field-Number
                                    v-if="leadTimeLeftLabel != null"
                                    v-model.number="item.leadTimeLeft"
                                    isEditing
                                    :label="leadTimeLeftLabel" />

                                <BT-Field-Number
                                    v-if="leadTimeRightLabel != null"
                                    v-model.number="item.leadTimeRight"
                                    isEditing
                                    :label="leadTimeRightLabel" />

                            </template>
                        </BT-Dialog>
                    </v-row>
                </v-list-item-action>
            </v-list-item>
            <v-divider :key="'d' + index" />
        </template>
    </v-list>
</template>

<script>
import { firstBy } from 'thenby';
import * as cronjsMatcher from '@datasert/cronjs-matcher';
import { Duration } from 'luxon/src/luxon';
import { cancelAdjustment, packArrayToAdjustmentString, unpackAdjustmentStringToArray } from '~helpers';

export default {
    name: 'BT-Cron-Adjustments',
    components: {
        BTDialog: () => import('~components/BT-Dialog.vue'),
        BTTimePicker: () => import('~components/BT-Time-Picker.vue')
    },
    data: function() {
        return {
            adjustments: [],
            adjustmentsExpression: null,
            filteredAdjustments: [],
        }
    },
    props: {
        cronExpression: {
            type: String,
            default: null
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        leadTimeLeftLabel: {
            type: String,
            default: null
        },
        leadTimeRightLabel: {
            type: String,
            default: null
        },
        value: null
    },
    mounted() {
        this.unpack();
    },
    watch: {
        cronExpression() {
            this.unpack();
        },
        isEditing() {
            this.toggleAdjustments();
        },
        value(val) {
            if (this.adjustmentsExpression != val) {
                this.unpack();
            }
        }
    },
    methods: {
        makeAdjustment(res, item) {
            var rDate = this.$BlitzIt.auth.formRawTZ(res.replacingDate).startOf('day');
            rDate = rDate.plus(Duration.fromISOTime(res.replacingTime).toObject());

            item.replacingDate = this.$BlitzIt.auth.formTZtoUTC(rDate.toString());
            item.isAdjusting = true;
            item.leadTimeLeft = res.leadTimeLeft;
            item.leadTimeRight = res.leadTimeRight;

            item.leadDateLeft = this.$BlitzIt.auth.formUTCDateTime(item.replacingDate).plus({ minutes: item.leadTimeLeft }),
            item.leadDateRight = this.$BlitzIt.auth.formUTCDateTime(item.replacingDate).plus({ minutes: item.leadTimeRight }),

            this.update();
        },
        cancel(item) {
            cancelAdjustment(item);
            this.update();
        },
        clear(item) {
            item.replacingDate = null;
            item.isAdjusting = false;

            this.update();
        },
        openAdjustment(item) {
            var c = this.copyDeep(item);
            c.replacingTime = c.replacingDate != null ? this.$BlitzIt.auth.formTZ(c.replacingDate, 'HH:mm') : '00:00';
            return c;
        },
        toggleAdjustments() {
            var fList = this.adjustments.map(x => 
                Object.assign({}, x, { 
                    isAdjusting: true, 
                    isInSchedule: false, 
                    replacingTime: x.replacingDate != null ? this.$BlitzIt.auth.formTZ(x.replacingDate, 'HH:mm') : '00:00',
                    leadDateLeft: x.replacingDate != null ? this.$BlitzIt.auth.formUTCDateTime(x.replacingDate).plus({ minutes: x.leadTimeLeft }) : null,
                    leadDateRight: x.replacingDate != null ? this.$BlitzIt.auth.formUTCDateTime(x.replacingDate).plus({ minutes: x.leadTimeRight }) : null,
                }));

                var cronExpression = this.getCronExpression(this.cronExpression);

                if (cronExpression != null) {
                    var future = cronjsMatcher.getFutureMatches(cronExpression, { matchCount: 15, formatInTimeZone: true });

                    future.forEach(f => {
                        f = this.$BlitzIt.auth.isNotUTC(f);

                        var futureDate = this.$BlitzIt.auth.formISO(f);
                        futureDate = this.$BlitzIt.auth.formTZtoUTC(futureDate);
                        var existing = fList.find(x => this.isSameDownToHour(x.dateTrigger, futureDate));

                        if (existing == null) {
                            //only add of not already in list
                            fList.push({
                                dateTrigger: futureDate,
                                replacingDate: null,
                                replacingTime: null,
                                isAdjusting: false,
                                isInSchedule: true,
                                leadTimeLeft: 0,
                                leadTimeRight: 0,
                                leadDateLeft: null,
                                leadDateRight: null
                            })
                        }
                        else {
                            existing.isInSchedule = true;
                        }
                    })
                }
                

            fList.sort(firstBy(x => x.dateTrigger));
            
            this.filteredAdjustments = fList;
        },
        unpack() {
            this.adjustmentsExpression = this.value;
            this.adjustments = unpackAdjustmentStringToArray(this.adjustmentsExpression);

            this.toggleAdjustments();
        },
        pack() {
            this.adjustments = this.filteredAdjustments.filter(x => x.isAdjusting);
            this.adjustmentsExpression = packArrayToAdjustmentString(this.adjustments);
            //this.adjustmentsExpression = this.adjustments.map(x => this.packAdjustmentString(x)).toString();
        },
        update() {
            this.pack();

            this.$emit('input', this.adjustmentsExpression);
            this.$emit('change', this.adjustmentsExpression);
        },
    }
}
</script>