import { firstBy } from 'thenby';
import axios from 'axios';

export async function getImageData(url, throwErrorOnFail = true) {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute('crossOrigin', 'anonymous');
        img.onload = function () {
            var canvas = document.createElement('canvas');
            canvas.width = this.width;
            canvas.height = this.height;

            var ctx = canvas.getContext('2d');
            ctx.drawImage(this, 0, 0);

            resolve(canvas.toDataURL('image/png'));
        };

        img.onerror = function () {
            console.log('errr');
            if (throwErrorOnFail) {
                reject('image could not be loaded for some reason');
            }
            else {
                resolve(null);
            }
        };

        img.src = url;
    })
}

export function saveLocally(key, value) {
    if (value == null) {
        localStorage.removeItem('BT' + key);
    }
    else {
        localStorage.setItem('BT' + key, JSON.stringify(value));
    }
}

export function removeLocally(key) {
    localStorage.removeItem('BT' + key);
}

export function getLocally(key) {
    try {
        return JSON.parse(localStorage.getItem('BT' + key));
    }
    catch {
        return null;
    }
}

export function checkImage(url, good, bad) {
    var img = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = url;
}

export function toCamelCase(key, value) { //for JSON parse
    if (value && typeof value === 'object'){
      for (var k in value) {
        if (/^[A-Z]/.test(k) && Object.hasOwnProperty.call(value, k)) {
          value[k.charAt(0).toLowerCase() + k.substring(1)] = value[k];
          delete value[k];
        }
      }
    }
    return value;
  }

export function twiddleThumbs(mSec = 2000) {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), mSec);
    })
}

export async function urlExists(url) {
    var res = await axios.get(url);
    console.log(res);

}

export function extensionExists() {
    try {
        var el = document.getElementById('blitzItExtensionExists');
        console.log('el');
        console.log(el);
        return el != null;
        // console.log('docuemnt:');
        // console.log(document);
        // var res = await axios.get('chrome-extension://nimnofpfkheldmiflbfbpepafjlmbnko/icons/16.png'); //manifest.json');
        // console.log('res');
        // console.log(res.status);
        // return res.status == 200;
    }
    catch (ex) {
        console.log('res ex');
        console.log(extractErrorDescription(ex));
        return false;
    }
}

export function getTetheredAmount(tethers, quantity, tetheredProductID, triggeringProductID) {
    var amount = 0;

    if (!isLengthyArray(tethers) || quantity == 0) {
        return amount;
    }

    tethers = tethers.filter(x => x.triggeringProductID == triggeringProductID && x.tetheredProductID == tetheredProductID);

    tethers.forEach(tether => {
        if (tether.factor > 0) {
            var factor = Math.ceil(quantity / tether.factor);
            if (factor != 0) {
                var adjQty = factor * tether.quantity;

                if (adjQty != 0) {
                    amount += adjQty;
                }
            }
        }
    })

    return amount;
}

// export function getTetheredAdjustments(tethers, triggeringProductID, quantity) {
//     var list = [];

//     if (!isLengthyArray(tethers) || quantity == 0) {
//         return list;
//     }

//     tethers.forEach(tether => {
//         if (tether.factor > 0 && tether.triggeringProductID == triggeringProductID) {
//             var factor = Math.ceil(quantity / tether.factor);
//             if (factor != 0) {
//                 var adjQty = factor * tether.quantity;

//                 if (adjQty != 0) {
//                     list.push({

//                     });
//                 }
//             }
//         }
//     })
// }

export function getAlphabet() {
    return ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
}

export function chr (codePt) {
    if (codePt > 0xFFFF) { 
        codePt -= 0x10000;
        return String.fromCharCode(0xD800 + (codePt >> 10), 0xDC00 + (codePt & 0x3FF));
    }
    return String.fromCharCode(codePt);
}

export function getCellColumnRef(number) {
    var numeric = (number) % 26;
    var letter = chr(65 + numeric);
    var number2 = parseInt((number) / 26);
    if (number2 > 0) {
        return getCellColumnRef(number2) + letter;
    } else {
        return letter;
    }
}

export function getColors() {
    return [
        '#870000',
        '#874800',
        '#858700',
        '#3d8700',
        '#008734',
        '#006f87',
        '#004587',
        '#000f87',
        '#2c0087',
        '#870071',
        '#a752e2',
        '#e252cb',
        '#e2527f',
        '#e25252',
        '#e25252',
        '#e28b52',
    ]
}

export function getOtherColors() {
    return [
        '#134f5c',
        '#351c75',
        '#741b47',
        '#783f04',
        '#0c343d',
        '#c27ba0',
        '#660000',
        '#93c47d',
        '#4c1130',
        '#990000',
        '#b45f06',
        '#38761d',
        '#000000'
    ]
}

export function weekdaysValue(wkDay) {
    if (wkDay == null || containsWeekday(wkDay, 'Always')) {
        return 0;
    }
    else if (containsWeekday(wkDay, 'Sun')) {
        return 1;
    }
    else if (containsWeekday(wkDay, 'Mon')) {
        return 2;
    }
    else if (containsWeekday(wkDay, 'Tue')) {
        return 3;
    }
    else if (containsWeekday(wkDay, 'Wed')) {
        return 4;
    }
    else if (containsWeekday(wkDay, 'Thu')) {
        return 5;
    }
    else if (containsWeekday(wkDay, 'Fri')) {
        return 6;
    }
    else if (containsWeekday(wkDay, 'Sat')) {
        return 7;
    }

    return 8;
}

export function weekdayValue(wkDay) {
    if (wkDay == null || wkDay == 'Always') {
        return 0;
    }
    else if (wkDay == 'Sun') {
        return 1;
    }
    else if (wkDay == 'Mon') {
        return 2;
    }
    else if (wkDay == 'Tue') {
        return 3;
    }
    else if (wkDay == 'Wed') {
        return 4;
    }
    else if (wkDay == 'Thu') {
        return 5;
    }
    else if (wkDay == 'Fri') {
        return 6;
    }
    else if (wkDay == 'Sat') {
        return 7;
    }

    return 8;
}

export function containsWeekday(weekdays, wkDay) {
    if (weekdays == null || wkDay == null) {
        return true;
    }

    var weekdayList = weekdays.split(',');

    return weekdayList.some(z => z == 'Always' || z == wkDay);
}

export function toCurrency(value) {
    if (typeof value !== 'number') {
        return value;
    }
    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 2
    });
    return formatter.format(value);
}

export function toDisplayNumber(value) {
    if (typeof value !== 'number') {
        return value;
    }

    return new Intl.NumberFormat().format(value);
}

export function baseLogoURL() {
    return 'https://blitzittechimages.blob.core.windows.net/';
    // return 'https://images.blitzitweb.com.au/';
}

export function companyLogoURL(company) {
    if (typeof company == 'string') {
        return baseLogoURL() + 'company-logos/' + company + '-logo-lg';
    }
    else if (typeof company == 'object' && company != null && company.id != undefined && company.id != null) {
        return baseLogoURL() + 'company-logos/' + company.id + '-logo-lg';
    }
    else {
        return '';
    }
}

export function companyBannerURL(company) {
    if (typeof company == 'string') {
        return baseLogoURL() + 'company-logos/' + company + '-banner';
    }
    else if (typeof company == 'object' && company != null && company.id != undefined && company.id != null) {
        return baseLogoURL() + 'company-logos/' + company.id + '-banner';
    }
    else {
        return '';
    }
}

export function companyOrderingBackgroundURL(company) {
    if (typeof company == 'string') {
        return baseLogoURL() + 'company-logos/' + company + '-ordering-background';
    }
    else if (typeof company == 'object' && company != null && company.id != undefined && company.id != null) {
        return baseLogoURL() + 'company-logos/' + company.id + '-ordering-background';
    }
    else {
        return '';
    }
}

export function isAreaOfSize(boundary, dif) {
    if (boundary == null || dif == null) {
        return false;
    }
    if (boundary.length != 4) {
        return false;
    }

    var middleLat = boundary[0].lat + dif;
    var middleLng = boundary[0].lng - dif;

    if ((boundary[1].lat + dif) != middleLat) {
        return false;
    }
    if ((boundary[1].lng + dif) != middleLng) {
        return false;
    }

    if ((boundary[2].lat - dif) != middleLat) {
        return false;
    }
    if ((boundary[2].lng + dif) != middleLng) {
        return false;
    }

    if ((boundary[3].lat - dif) != middleLat) {
        return false;
    }
    if ((boundary[3].lng - dif) != middleLng) {
        return false;
    }

    return true;
}

export function getAreaAround(location, dif = null) {
    if (dif == null) {
        dif = 1;
    }

    return [
        { lat: location.lat - dif, lng: location.lng + dif },
        { lat: location.lat - dif, lng: location.lng - dif },
        { lat: location.lat + dif, lng: location.lng - dif },
        { lat: location.lat + dif, lng: location.lng + dif }
    ];
}

export function getAreaToLeft(location, dif = null) {
    if (dif == null) {
        dif = 1;
    }

    return [
        { lat: location.lat - dif, lng: location.lng },
        { lat: location.lat - dif, lng: location.lng - (dif * 2) },
        { lat: location.lat + dif, lng: location.lng - (dif * 2) },
        { lat: location.lat + dif, lng: location.lng }
    ];
}

export function getAreaToRight(location, dif = null) {
    if (dif == null) {
        dif = 1;
    }

    return [
        { lat: location.lat - dif, lng: location.lng + (dif * 2) },
        { lat: location.lat - dif, lng: location.lng },
        { lat: location.lat + dif, lng: location.lng },
        { lat: location.lat + dif, lng: location.lng + (dif * 2) }
    ];
}

export function getGoogleMapsLocationLine(value) {
    var str = getLocationLine(value, true);

    str = str.toLowerCase(); //str.replaceAll(' ', '').toLowerCase();
    //replace values
    str = str.replace(' victoria ', 'vic');
    str = str.replace(' queensland ', 'qld');
    str = str.replace(' new south wales ', 'nsw');
    str = str.replace(' northern territory ', 'nt');
    str = str.replace(' western australia ', 'wa');
    str = str.replace(' tasmania ', 'tas');
    str = str.replace(' south australia ', 'sa');
    str = str.replace(' australian captial territory ', 'act');

    str = str.replace(' & ', ' and ');
    str = str.replace(' road', ' rd');
    str = str.replace(' street', ' st');
    str = str.replace(' lane', ' ln');
    str = str.replace(' alley', ' aly');
    str = str.replace(' arcade', ' arc');
    str = str.replace(' boulevard', ' blvd');
    str = str.replace(' court', ' ct');
    str = str.replace(' cove', ' cv');
    str = str.replace(' highway', ' hwy');

    return str.replaceAll(' ', '');
}

export function getLocationLine(value, forGoogle = false) {
    if (!value) {
        return '';
    }

    if (typeof value !== 'object') {
        return value;
    }    
    // if (BlitzIt.auth.session.companyAccountID != null) {
        
    //     if (value.companyAccountID != null && value.companyAccountID == BlitzIt.auth.session.companyAccountID) {
    //         return value.locationName;
    //     }
    // }

    var rStr = '';

    if (value.addressLineOne != null && !forGoogle) {
        rStr = value.addressLineOne + ' ';
    }
    if (value.streetNumber != null) {
        rStr = rStr + value.streetNumber + ' ';
    }
    if (value.streetName != null) {
        rStr = rStr + value.streetName + ', ';
    }
    if (value.suburb != null) {
        rStr = rStr + value.suburb + ' ';
    }
    if (value.state != null) {
        rStr = rStr + value.state + ' ';
    }
    if (value.postcode != null) {
        rStr = rStr + value.postcode;
    }
    
    return rStr;
}

export function getGoogleMapsURL(journey) {
    if (journey == null || journey.movements == null) {
        return null;
    }

    var url = 'https://www.google.com/maps/dir';

    if (journey.startLocation != null) {
        url = `${url}/${getLocationLine(journey.startLocation, true).replaceAll('/', ' ')}`;
    }

    journey.movements.sort(firstBy(x => x.arrivalSortNumber));

    var lastID = null;
    journey.movements.forEach(mov => {
        if (lastID != mov.destinationLocationID) {
            lastID = mov.destinationLocationID;

            url = `${url}/${getLocationLine(mov.destinationLocation, true).replaceAll('/', ' ')}`;
        }
    })

    if (journey.endLocation != null) {
        url = `${url}/${getLocationLine(journey.endLocation, true).replaceAll('/', ' ')}`;
    }

    return url;
}

export function capitalizeWords(val) {
    return val.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
}

export function isLengthyArray(val, length = 0) {
    return val != null && Array.isArray(val) && val.length > length;
}

export function isArrayOfLength(val, l) {
    return val != null && Array.isArray(val) && val.length == l;
}

export function isMinDate(d) {       
    return '0001-01-01T00:00:00Z' == d;
}

export function getMinDate() {
    return new Date('0001-01-01T00:00:00Z').getTime();
}

export function hasSamePackageDimensions(pOne, pTwo) {
    return pOne.height == pTwo.height && pOne.width == pTwo.width && pOne.length == pTwo.length && pOne.weight == pTwo.weight && pOne.volume == pTwo.volume;
}

export function packageMatchesMeasurement(pkg, measurement) {
    return (pkg.measurementID != null && pkg.measurementID == measurement.id) || 
            (pkg.measurementStandard != null && pkg.measurementStandard == measurement.measurementStandard) ||
            ((pkg.height != null || pkg.width != null || pkg.length != null || pkg.weight != null || pkg.volume != null ||
                measurement.height != null || measurement.width != null || measurement.length != null || measurement.weight != null || measurement.volume != null) && hasSamePackageDimensions(pkg, measurement));
}

export function packagesMatch(pOne, pTwo) {
    return (pOne.measurementID != null && pOne.measurementID == pTwo.measurementID) || 
            (pOne.measurementStandard != null && pOne.measurementStandard == pTwo.measurementStandard) ||
            (pOne.code != null && pOne.code == pTwo.code) ||
            ((pOne.height != null || pOne.width != null || pOne.length != null || pOne.weight != null || pOne.volume != null ||
                pTwo.height != null || pTwo.width != null || pTwo.length != null || pTwo.weight != null || pTwo.volume != null) && hasSamePackageDimensions(pOne, pTwo));
}

export function roundTo(v, dPlaces = 0) {
    var m = '1';
    var i = 0;

    if (i < dPlaces) {
        do {
            m = m + '0';
            i += 1;
        } while (i < dPlaces);
    }

    var d = Number.parseInt(m);
    
    return Math.round(v * d) / d;
}

export function taxAmount(lineItems) {
    if (Array.isArray(lineItems)) { //} typeof lineItems == 'array') {
        var total = 0;
        lineItems.forEach(line => {
            total += taxAmount(line);
        });

        return roundTo(total, 2);
    }
    else if (typeof lineItems == 'object') {
        if (lineItems.taxType == 'GST') {
            return roundTo(subAmount(lineItems) * 0.1, 2);
        }
    }

    return 0;
}

export function subAmount(lineItems) {
    if (Array.isArray(lineItems)) { //} typeof lineItems == 'array') {
        var total = 0;
        lineItems.forEach(line => {
            total += subAmount(line);
        });

        return roundTo(total, 2);
    }
    else if (typeof lineItems == 'object') {
        return roundTo(lineItems.quantity * lineItems.unitPrice, 2);
    }

    return 0;
}

export function totalAmount(lineItems) {
    return subAmount(lineItems) + taxAmount(lineItems);
    // if (Array.isArray(lineItems)) { //} typeof lineItems == 'array') {
    //     return subAmount(lineItems) + taxAmount(lineItems);
    // }
    // else if (typeof lineItems == 'object') {
    //     return subAmount(lineItems) + taxAmount(lineItems);
    //     var totalL = lineItems.quantity * lineItems.unitPrice;
    //     return totalL;
    // }

    // return 0;
}

// export function getTotalLineValue(line) {
//     var v = line.quantity * line.unitPrice;

//     if (line.discount !== undefined && line.discount !== null) {
//         return v - (v * line.discount);
//     }
    
//     return v;
// }


export function extractErrorDescription(error) {
    var msg = '';
    if (error) {
        if (error.message) {
            msg = error.message;
        }

        if (error.response && error.response.data) {
            if (error.response.data.errors) {
                console.log('error 2');
                for (var i = 0; i < error.response.data.errors.length; i++) {
                    msg = msg + ' | ' + error.response.data.errors[i];
                }
            }
            if (error.response.data.message) {
                console.log('error 3');
                msg = msg + ' | ' + error.response.data.message;
            }

            console.log('error 4');
            msg = msg + ' | ' + JSON.stringify(error.response.data);
            
        }
        
        return msg;
    }

    return 'hmmm no error was supplied';
}

export function nestedValue(obj, path) {    
    if (obj == null || obj == undefined || !path) {
        return null;
    }
    
    var props = path.split('.');
    let propCnt = props.length;
    var r = obj;

    for (var i = 0; i < propCnt; i++) {
        r = r[props[i]]
        if (r == null) {
            return null;
        }
    }

    return r;
}

export function getUnitPrice(priceIncrements, qty) {
    if (!priceIncrements || !priceIncrements.length) {
        return 0;
    }

    var p = 0;
    for (var i = 0; i < priceIncrements.length; i++) {
        var inc = priceIncrements[i];

        if (inc.unitCountTrigger <= qty) {
            p = inc.unitValue;
        }
    }

    return p;    
}

export function DataURIToBlob(dataURI) {    
        const splitDataURI = dataURI.split(',')        
        const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])        
        const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

        const ia = new Uint8Array(byteString.length)
        for (let i = 0; i < byteString.length; i++)
            ia[i] = byteString.charCodeAt(i)

        return new Blob([ia], { type: mimeString })
}

export function errorMessage(error) {    
    var msg = '';    
    if (error) {
        if (error.message) {                     
            msg = error.message;
        }

        if (error.response && error.response.data) {
            if (error.response.data.errors) {                
                for (var i = 0; i < error.response.data.errors.length; i++) {
                    msg = msg + ' | ' + error.response.data.errors[i];
                }
            }
            else if (error.response.data.message) {                
                msg = msg + ' | ' + error.response.data.message;
            }
            else {                
                msg = msg + ' | ' + error.response.data;
            }                        
        }
        
        return msg;
    }

    return 'hmmm no error was supplied';
}

export function getPurchaseOrderStatus(po) {
    if (po != null) {
        if (po.isConfirmed == null) {
            return 'Pending';
        }
        else if (po.isConfirmed == false) {
            return 'Declined';
        }
        else if (po.isConfirmed == true) {
            return 'Confirmed';
        }
    }

    return null;
}

export function getCourierOrderStatus(po) {
    if (po != null) {
        if (po.fulfilledOn != null) {
            return 'Completed';
        }
        else if (po.isApproved == null) {
            return 'Pending';
        }
        else if (po.isApproved == false) {
            return 'Declined';
        }
        else if (po.isApproved == true) {
            if (po.isPlanned) {
                return 'Planned';
            }
            else {
                return 'Ready To Plan';
            }            
        }        
    }

    return null;
}

export function compareString(str) {
    if (str) {
        return str.replaceAll(' ', '').toLowerCase();
    }
    else {
        return null;
    }
}

export function getStreetNumber(str) {
    var l = str.split(' ');

    if (l.length > 1) {
        return l[0];
        // if (!isNaN(l[0])) {
        //     //is number
        //     return l[0];
        // }
    }
    else {
        return null;
    }
}

export function getStreetName(str) {
    var l = str.split(' ');
    if (l.length > 1) {
        var left = l[0];
        return str.substring(left.length);
    }
    else {
        return str;
    }
}

export function getThresholdNeed(threshold, inStock) {
    var deficit = 0;

    if (threshold != null && threshold.lowerThreshold > inStock)
    {
        deficit = threshold.upperThreshold - inStock;

        if (deficit < threshold.minimumOrderAmount) {
            deficit = threshold.minimumOrderAmount;
        }

        if (threshold.orderIncrement > 0 && deficit % threshold.orderIncrement != 0) {
            do {
                deficit += 1;
            } while (deficit % threshold.orderIncrement != 0);            
        }
    }   
    return deficit;
}

export function toggleCSV(value, tag) {
    if (tag != null) {
        if (csvContains(value, tag)) {
            //remove
            return value.split(',').filter(x => x != tag).toString();
        }
        else {
            //add
            if (value != null) {
                return `${value},${tag}`;
            }
            else {
                return tag;
            }
        }
    }
    else {
        return value;
    }
}

export function csvContains(value, tag) {
    if (value == undefined || value == null || value.length == 0) {
        return false;
    }

    if (!tag) {
        return true;
    }

    var csvList = value.split(',');
    var tagList = tag.split(',');

    return csvList.some(x => tagList.some(y => y == x));
}

// export function containsWeekday(value, day) {
//     var fullList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
//     var potList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
//     if (day == null || day.length < 3) {
//         return false;
//     }


//     if (value == null || value.length == 0) {
//         return day == null;
//     }
//     else {        
        
//         var abbWeekday = day.substring(0, 3);                
//         var ind = potList.indexOf(abbWeekday);    
//         var fullWeekday = fullList[ind];

//         return value.split(',').some(y => y == fullWeekday);
//     }        
// }

export function addWeekday(value, day) {
    var fullList = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var potList = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    if (day == null) {
        return value;
    }
    var abbWeekday = day.substring(0, 3);                
    var ind = potList.indexOf(abbWeekday);
    var fullWeekday = fullList[ind];

    if (value == null) {
        return fullWeekday;
    }
    else {        
        var wList = value.split(',');                        
        wList.push(fullWeekday);              
        var list = [];
        for (let i = 0; i < fullList.length; i++) {
            const fDay = fullList[i];
            if (wList.some(y => y == fDay)) {
                list.push(fDay);
            }
        }
        return list.toString();
    }
}

export function removeWeekday(value, day) {
    if (value != null) {        
        return value.split(',').filter(y => y != day && y.substring(0, 3) != day).toString();
    }

    return value.toString();
}

export function getCompanyNameAndLocationLine(value) {
    if (!value) {
        return '';
    }

    if (typeof value !== 'object') {
        return value;
    }    
    
    var rStr = '';

    if (value.companyAccount != null) {
        rStr = value.companyAccount.companyName + ' | ';
    }
    
    if (value.addressLineOne != null) {
        rStr = rStr +  value.addressLineOne + ' ';
    }
    if (value.streetNumber != null) {
        rStr = rStr + value.streetNumber + ' ';
    }
    if (value.streetName != null) {
        rStr = rStr + value.streetName + ', ';
    }
    if (value.suburb != null) {
        rStr = rStr + value.suburb + ' ';
    }
    if (value.state != null) {
        rStr = rStr + value.state + ' ';
    }
    if (value.postcode != null) {
        rStr = rStr + value.postcode;
    }
    
    return rStr;
}

// export function getLocationLine(value, includeLineOne = true) {
//     var rStr = '';
    
//     if (value.addressLineOne != null && includeLineOne) {
//         rStr = value.addressLineOne + ' ';
//     }
//     if (value.streetNumber != null) {
//         rStr = rStr + value.streetNumber + ' ';
//     }
//     if (value.streetName != null) {
//         rStr = rStr + value.streetName + ', ';
//     }
//     if (value.suburb != null) {
//         rStr = rStr + value.suburb + ' ';
//     }
//     if (value.state != null) {
//         rStr = rStr + value.state + ' ';
//     }
//     if (value.postcode != null) {
//         rStr = rStr + value.postcode;
//     }
       
//     return rStr;    
// }

export function getRandomColor() {
    //return '#' + Math.floor(Math.random() * 16).toString(16);
    // return size => [...Array(size || 6)]
    //     .map(() => Math.floor(Math.random() * 16).toString(16)).join('');


    
    const rColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
    if (rColor.length !== 7) {
        return getRandomColor();
    } else {
        return rColor;
    }
}

export function copyDeep(aObject) {
    if (!aObject) {
        return aObject;
    }    
    let v;
    let bObject = Array.isArray(aObject) ? [] : {};
    for (const k in aObject) {
        v = aObject[k];
        bObject[k] = (typeof v === 'object') ? copyDeep(v) : v;
    }    
    return bObject;
}

export function copyItemByAlphabet(aObject) {
    //copy jsObject and reorder properties by alphabet name
    if (!aObject) {
        return aObject;
    }    
    return Object.keys(aObject)
    .sort()
    .reduce(function (acc, key) {         
        let v = aObject[key];
        acc[key] = (typeof v === 'object' && !v === null) ? copyItemByAlphabet(v) : v;
        return acc;
    }, Array.isArray(aObject) ? [] : {});
}

export function roundUp(val, precision = 0) {
    precision = Math.pow(10, precision);
    return Math.ceil(val * precision) / precision;
}


//order calculation functions
export function applyGlobalRules() {

}

//Sets subTotal, taxTotal, and amountTotal
export function calculateAndSetTotalPrice(order, orderItems, autoOrderItems) {
    var prodTotal = orderItems.sum(x => x.amountTotal);
    var autoTotal = autoOrderItems.sum(x => x.amountTotal);
    console.log('pTotal');
    console.log(prodTotal);
    console.log('aTotal');
    console.log(autoTotal);

    order.subTotal = prodTotal + autoTotal;
    order.taxTotal = taxAmount(orderItems) + taxAmount(autoOrderItems);
    order.amountTotal = order.subTotal + order.taxTotal;
}

//Returns auto items
export function calculateAutoItems(policy, allOrderItems, order) {
    var autoOrderItems = [];
    var oItems = [];
    oItems.push.apply(oItems, allOrderItems);

    if (!policy.automaticLineItems) {
        return autoOrderItems;
    }

    var location = policy.possibleLocations.find(x => x.id == order.locationID);

    var autoItems = policy.automaticLineItems.sort(firstBy(x => x.calcOrder).thenBy(x => x.quantity));

    autoItems = autoItems.filter(x => location == null || x.boundary == null || !isLengthyArray(x.boundary) || pointInPolygon(x.boundary, { x: location.lat, y: location.lng }));

    var optionIDsUsed = [];

    if (order.isRequestPickup || csvContains(order.tagsCSV, 'IgnoreDeliveryFee')) {
        optionIDsUsed.push('Delivery Fee');
    }

    for (var i = 0; i < autoItems.length; i++) {
        var autoItem = copyDeep(autoItems[i]);
        if (autoItem.optionGroupID == null || !optionIDsUsed.some(z => z == autoItem.optionGroupID)) {
            if (autoItem.optionGroupID != null) {
                optionIDsUsed.push(autoItem.optionGroupID);
            }

            var relevantValue = null;
            var newItem = {
                data: null,
                id: null,
                rowVersion: null,
                description: autoItem.lineItemName,
                unitPrice: 0,
                discount: 0,
                amountTotal: 0,
                quantity: 1,
                productID: null,
                tags: autoItem.tags,
                taxType: autoItem.taxType,
                isClosedOrdering: true,
                lineItemCode: autoItem.id,
            }

            if (autoItem.rule) {
                var rule = autoItem.rule;

                var relevantProductItems = oItems.filter(x => x.data && x.data.productID);
                var relevantOrderItems = oItems.filter(x => x.tags && x.tags.csvContains(autoItem.linesWithTags));
                
                var totalValue = relevantOrderItems.sum(x => x.amountTotal);
                var productValue = relevantProductItems.sum(x => x.amountTotal);
                var productQty = relevantProductItems.sum(x => x.quantity);

                if (rule === 'ProductValueGreaterThan' && productValue > autoItem.quantity ||
                    rule === 'ProductValueLessThan' && productValue < autoItem.quantity ||
                    rule === 'ProductValueEqualTo' && productValue === autoItem.quantity ||
                    rule === 'TotalQuantityGreaterThan' && productQty > autoItem.quantity ||
                    rule === 'TotalQuantityLessThan' && productQty < autoItem.quantity ||
                    rule === 'TotalQuantityEqualTo' && productQty === autoItem.quantity) {
                    
                    relevantValue = productValue;
                }
                else if (rule === 'TotalValueGreaterThan' && totalValue > autoItem.quantity ||
                    rule === 'TotalValueLessThan' && totalValue < autoItem.quantity ||
                    rule === 'TotalValueEqualTo' && totalValue === autoItem.quantity) {

                    relevantValue = totalValue;
                }
                
                if (relevantValue) {
                    var unitVal = autoItem.lineValue;
                    var unitType = autoItem.lineValueType;

                    if (unitType != null) {
                        if (unitType === 'Percent') {
                            newItem.unitPrice = unitVal * relevantValue;
                        }
                        else if (unitType === 'FixedValue') {
                            newItem.unitPrice = unitVal;
                        }
                        else {
                            newItem.unitPrice = unitVal;
                        }
                    }
                    else {
                        newItem.unitPrice = unitVal;
                    }

                    newItem.amountTotal = totalAmount(newItem); //newItem.unitPrice * newItem.quantity;
                    autoOrderItems.push(newItem);
                    oItems.push(newItem);
                }

            }
        }
    }
    
    return autoOrderItems;
}



//Updates order
export function updateOrder(order, policy, allOrderItems, autoOrderItems = null) {
    if (autoOrderItems == null) {
        autoOrderItems = calculateAutoItems(policy, allOrderItems, order);
    }
    
    //set total amount
    // var prodTotal = allOrderItems.sum(x => x.amountTotal);
    // var autoTotal = autoOrderItems.sum(x => x.amountTotal);

    order.subTotal = subAmount(allOrderItems) + subAmount(autoOrderItems); //prodTotal + autoTotal;
    order.taxTotal = taxAmount(allOrderItems) + taxAmount(autoOrderItems);
    order.amountTotal = order.subTotal + order.taxTotal;

    applyGlobalRules();

    order.orderItems = allOrderItems
        .filter(x => x.quantity != 0)
        .map(x => { 
            return { 
                id: x.id, 
                rowVersion: x.rowVersion, 
                productID: x.productID, 
                quantity: x.quantity,
                description: x.description,
                lineItemCode: x.lineItemCode,
                unitPrice: x.unitPrice,
                discount: x.discount,
                taxType: x.taxType,
                placeholderProductID: x.placeholderProductID,
                isPlaceholder: x.isPlaceholder
            }});
    
    if (isLengthyArray(autoOrderItems)) {
        autoOrderItems.forEach(x => {
            order.orderItems.push({
                id: x.id,
                rowVersion: x.rowVersion,
                productID: x.productID,
                quantity: x.quantity,
                description: x.description,
                lineItemCode: x.lineItemCode,
                unitPrice: x.unitPrice,
                discount: x.discount,
                taxType: x.taxType
            });
        });
    }

}

export function recalculateOrder(order, policy) {
    var copy = copyDeep(order);
    var oldAutoOrderItems = copy.orderItems.filter(x => x.productID == null);
    copy.orderItems = copy.orderItems.filter(x => x.productID != null);
    var allOrderItems = loadOrderItems(copy, policy);
    var autoOrderItems = calculateAutoItems(policy, allOrderItems, copy);
    updateOrder(copy, policy, allOrderItems, autoOrderItems);
    
    copy.orderItems.forEach(x => {
        if (x.productID == null && x.lineItemCode != null) {
            var oldAutoItem = oldAutoOrderItems.find(y => y.lineItemCode == x.lineItemCode);
            if (oldAutoItem != null) {
                x.id = oldAutoItem.id;
                x.rowVersion = oldAutoItem.rowVersion;
            }
        }
    })

    return copy;
}

//GETs a list of order items with rules, tags, tax types and all of it loaded into an array of lineitems
export function loadOrderItems(order, policy) {
    var allOrderItems = [];

    for (var a = 0; a < policy.orderItems.length; a++) {
        var oItem = Object.assign({}, copyDeep(policy.orderItems[a]), { isPlaceholder: false });

        oItem.description = oItem.product.productName;
        oItem.placeholderProductID = oItem.product.placeholderProductID;
        oItem.quantity = 0;
        oItem.unitPrice = getUnitPrice(oItem.unitPriceIncrements, 0);
        oItem.amountTotal = 0;
        oItem.taxType = oItem.product.taxType;

        if (isLengthyArray(order.orderItems)) {
            var eItem = order.orderItems.find(x => x.productID == oItem.productID);
            if (eItem) {
                oItem.id = eItem.id;
                oItem.rowVersion = eItem.rowVersion;
                oItem.quantity = eItem.quantity;
                oItem.unitPrice = getUnitPrice(oItem.unitPriceIncrements, oItem.quantity);
                oItem.tags = eItem.tags;
                oItem.sortNumber = eItem.sortNumber;
                oItem.taxType = eItem.taxType;
                oItem.discount = eItem.discount;
                oItem.lineItemCode = eItem.lineItemCode;
                oItem.isPlaceholder = eItem.isPlaceholder;
                oItem.placeholderProductID = eItem.placeholderProductID;
            }
        }

        oItem.amountTotal = totalAmount(oItem); //oItem.unitPrice * oItem.quantity;

        allOrderItems.push({
            data: oItem,
            id: oItem.id ? oItem.id : null,
            rowVersion: oItem.rowVersion ? oItem.rowVersion : null,
            description: oItem.description,
            unitPrice: oItem.unitPrice,
            discount: oItem.discount,
            amountTotal: oItem.amountTotal,
            quantity: oItem.quantity,
            productID: oItem.productID,
            tags: oItem.tags ? oItem.tags : oItem.product.tags,
            taxType: oItem.taxType ? oItem.taxType : oItem.product.taxType,
            isClosedOrdering: oItem.rules && oItem.rules.some(x => x.rule === 'QuantityInIncrements'),
            lineItemCode: oItem.lineItemCode,
            placeholderProductID: oItem.placeholderProductID,
            isPlaceholder: oItem.isPlaceholder
        });
    }

    //sort?

    return allOrderItems;
}

export function updateOrderWithoutUI(order, policy) {
    if (order == null || policy == null) {
        return false;
    }

    order.orderItems = order.orderItems.filter(x => x.productID != null);

    var allOrderItems = loadOrderItems(order, policy);

    var autoOrderItems = calculateAutoItems(policy, allOrderItems, order);

    updateOrder(order, policy, allOrderItems, autoOrderItems);

    return true;
}

export function measurementArea(measurement) {
    return (measurement.height || 1) * (measurement.weight || 1) * (measurement.length || 1);
}

export function getBreakdown(quantity, measurements, increments, productID, abbreviate = true, filter = false, asString = true) {
    if (isNaN(quantity)) {
        return [];
    }

    var iList = [];
    var iStr = '';
    var remaining = quantity;
    var used = 0;

    if (isLengthyArray(increments) && isLengthyArray(measurements)) {
        var l = increments.filter(y => measurements.some(m => m.id == y.measurementID) && y.productID == productID);

        l.sort(firstBy(x => x.units, 'desc'));

        l.forEach(increment => {
            if (!filter || increment.units <= remaining) {
                var m = measurements.find(y => y.id == increment.measurementID);
                if (m != null) {
                    var tRemaining = (remaining % increment.units);
                    var newInc = Object.assign({}, {
                        text: abbreviate ? m.abbreviation : m.measurementName,
                        value: (remaining - tRemaining) / increment.units,
                        unitsPerPackage: increment.units,
                        startValue: 0,
                        unitValue: 0
                    })

                    newInc.startValue = used;
                    used += (newInc.value * newInc.unitsPerPackage);
                    newInc.unitValue = used;
                    iList.push(newInc);
                    
                    iStr = iStr + (iStr.length > 0 ? ' ' : '') + iList[iList.length - 1].value + iList[iList.length - 1].text;
                    remaining = tRemaining;
                }
            }
        })
    }

    if (!filter || remaining > 0) {
        iList.push(Object.assign({}, {
            text: abbreviate ? 'u' : 'Unit',
            value: remaining,
            unitsPerPackage: 1,
            startValue: used,
            unitValue: quantity
        }))
        iStr = iStr + (iStr.length > 0 ? ' ' : '') + iList[iList.length - 1].value + (iList[iList.length - 1].text || '');
    }
    
    return asString ? iStr : iList;
}

export function pointInPolygon (polygon, point) {
    if (polygon == null || point == null || point.x == null || point.y == null) {
        return false;
    }
    var pointsList = polygon.map(x => {
        return { x: x.lat, y: x.lng };
    });
    //A point is in a polygon if a line from the point to infinity crosses the polygon an odd number of times
    let odd = false;
    //For each edge (In this case for each point of the polygon and the previous one)
    for (let i = 0, j = pointsList.length - 1; i < pointsList.length; i++) {
        if ((pointsList[i].y > point.y) !== (pointsList[j].y > point.y)) {
            if (point.x < ((pointsList[j].x - pointsList[i].x) * (point.y - pointsList[i].y) / (pointsList[j].y - pointsList[i].y) + pointsList[i].x)) {
                odd = !odd;
            }
        }
        //If a line from the point into infinity crosses this edge
        // if (((pointsList[i][1] > point[1]) !== (pointsList[j][1] > point[1])) // One point needs to be above, one below our y coordinate
        //     // ...and the edge doesn't cross our Y corrdinate before our x coordinate (but between our x coordinate and infinity)
        //     && (point[0] < ((pointsList[j][0] - pointsList[i][0]) * (point[1] - pointsList[i][1]) / (pointsList[j][1] - pointsList[i][1]) + pointsList[i][0]))) {
        //     // Invert odd
        //     odd = !odd;
        // }
        j = i;

    }
    //If the number of crossings was odd, the point is in the polygon
    return odd;
}

export function packAdjustmentString(adj) {
    return `${adj.dateTrigger}|${adj.replacingDate != null ? adj.replacingDate : ''}|${adj.leadTimeLeft}-${adj.leadTimeRight}`;
}

export function unpackAdjustmentString(adjStr) {
    if (adjStr != null && adjStr.length > 0) {
        if (adjStr.includes('undefined')) {
            return null;
        }

        var strSplit = adjStr.split('|');

        if (isLengthyArray(strSplit, 2)) {
            var leftLead = 0;
            var rightLead = 0;
            var leadSplit = strSplit[2].split('-');
            
            if (leadSplit.length > 0) {
                leftLead = Number.parseInt(leadSplit[0]);
            }
            if (leadSplit.length > 1) {
                rightLead = Number.parseInt(leadSplit[1]);
            }

            return {
                dateTrigger: strSplit[0],
                replacingDate: strSplit[1].length > 1 ? strSplit[1] : null,
                leadTimeLeft: leftLead,
                leadTimeRight: rightLead,
                isAdjusting: true,
            }
        }
    }
    
    return null;
}

export function unpackAdjustmentStringToArray(adjStr) {
    var adjustments = [];
    if (adjStr != null) {
        var adjSplit = adjStr.split(',');
        adjSplit.forEach(str => {
            var adj = unpackAdjustmentString(str);
            if (adj != null) {
                adjustments.push(adj);
            }
        })
    }

    return adjustments;
}

export function packArrayToAdjustmentString(arr) {
    if (!isLengthyArray(arr)) {
        return null;
    }

    return arr
        .filter(x => x.isAdjusting)
        .map(x => packAdjustmentString(x))
        .filter(x => x != null && !x.includes('undefined'))
        .toString();
}

export function cancelAdjustment(adjustmentObj) {
    if (adjustmentObj != null) {
        adjustmentObj.replacingDate = null;
        adjustmentObj.isAdjusting = true;
    }

    return adjustmentObj;
}

export function addCancellation(adjustmentsStr, cancelledDate) {
    var adjustments = unpackAdjustmentStringToArray(adjustmentsStr);

    var existingCancellation = adjustments.find(x => x.dateTrigger == cancelledDate);
    if (existingCancellation == null) {
        existingCancellation = {
            dateTrigger: cancelledDate,
            replacingDate: null,
            leadTimeLeft: 0,
            leadTimeRight: 0,
            isAdjusting: true
        }
        adjustments.push(existingCancellation);
    }

    cancelAdjustment(existingCancellation);

    return packArrayToAdjustmentString(adjustments);
}

export function removeCancellation(adjustmentsStr, cancelledDate) {
    var adjustments = unpackAdjustmentStringToArray(adjustmentsStr);

    adjustments = adjustments.filter(x => !(x.dateTrigger == cancelledDate && x.replacingDate == null));
    
    return packArrayToAdjustmentString(adjustments);
}