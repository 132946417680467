<template>
    <v-combobox 
        v-model="selection" 
        :label="label" 
        :items="csvValues" 
        multiple
        :disabled="disabled"
        small-chips
        hide-details
        @change="update"
        outlined/>
</template>

<script>
export default {
    name: 'BT-Tags',
    data: function() {
        return {
            csvValues: [],
            selection: [],
        }
    },
    props: {
        //csv string
        value: null,
        disabled: {
            type: Boolean,
            default: false
        },
        options: {
            type: Array,
            default: null
        },
        label: null,
    },
    mounted() {
        var r = [];

        if (this.isLengthyArray(this.options)) {
            this.options.forEach(opt => {
                r.push(opt);
            })
        }

        if (this.value != null && this.value.length > 0) {
            var vList = this.value.split(',');
            this.selection = vList;
            vList.forEach(v => {
                r.push(v);
            })
        }

        this.csvValues = r;
        //if (this.options) {
            // for (var i = 0; i < this.options.length; i++) {
            //     if (!r.find(x => x == this.options[i])) {
            //         r.push(this.options[i]);
            //     }
            // }
        //}
        
        //this.csvValues = this.selection;
    },    
    methods: {
        update() {
            //console.log('tagging ' + this.selection.toString());
            var r = null;
            if (this.isLengthyArray(this.selection)) {
                r = this.selection.toString();
            }
            this.$emit('input', r);
            this.$emit('change', r);
        }
    }
}
</script>