import Vue from "vue";
import { copyDeep, isLengthyArray } from '~helpers';
import { v4 as uuidv4 } from 'uuid';

export default {
    
    //trackedItems: [], //{ id: String, json: String }

    install() {
        const root = new Vue({
            data: {
                trackedItems: []
            },
            methods: {
                createSnapshotJSON(trackInfo) {
                    var copy = copyDeep(trackInfo.data);

                    if (isLengthyArray(trackInfo.trackProps)) {
                        Object.keys(trackInfo.data).forEach(k => {
                            if (!trackInfo.trackProps.some(z => z == k)) {
                                delete copy[k];
                            }
                        })
                    }
                    else if (isLengthyArray(trackInfo.ignoreProps)) {
                        trackInfo.ignoreProps.forEach(x => {
                            delete copy[x];
                        })
                    }

                    return JSON.stringify(copy);
                },
                handleChange (v) {
                    if (v != null && v._btID != null) {
                        var trackerInfo = this.trackedItems.find(z => (z._btID == v._btID) || (z.id != null && v.id != null && z.id == v.id));
                        if (trackerInfo != null) {
                            var json = this.createSnapshotJSON(trackerInfo);
                            trackerInfo.isChanged = trackerInfo.json != json;
                        }
                    }
                },
                reset(item) {
                    if (Array.isArray(item)) {
                        return item.map(d => {
                            return this.reset(d);
                        })
                    }
                    else if (item != null) {
                        var trackerInfo = root.trackedItems.find(z => (item._btID != null && z._btID == item._btID) || (z.id != null && item.id != null && z.id == item.id));
                        if (trackerInfo != null) {
                            trackerInfo.json = this.createSnapshotJSON(trackerInfo);
                            trackerInfo.isChanged = false;
                            trackerInfo.id = item.id;
                            
                            trackerInfo.unwatch();
                            trackerInfo.unwatch = this.$watch(() => trackerInfo.data, this.handleChange, { deep: true });
                            
                            return trackerInfo.data;
                        }
                        // else {
                        //     console.log('tracker info lost');
                        //     console.log(item);
                        // }
                        
                        return null;
                    }
                },
                track(item, { assignTracked, assignIgnored, trackProps, ignoreProps }) {
                    if (Array.isArray(item)) {
                        return item.map(d => {
                            return this.track(d, { assignTracked, assignIgnored, trackProps, ignoreProps });
                        });
                    }
                    else if (item != null) {
                        //mmmm not sure if it truly replaces item
                        //otherwise Object.assign(item, {...}) doesn't work
                        
                        //item = Object.assign({}, { ...item }, assignTracked || {}, assignIgnored || {}, { _btID: uuidv4() });

                        // console.log('tracking: ' + item._btID);
                        //if trackProps, then only track these props
                        var trackedProperties = null;
                        var ignoredProperties = ['_btID'];
                        
                        var trackerInfo = root.trackedItems.find(z => (item._btID != null && z._btID == item._btID) || (z.id != null && item.id != null && z.id == item.id));

                        if (trackerInfo == null) {
                            if (assignTracked != null) {
                                Object.keys(assignTracked).forEach(tKey => {
                                    this.$set(item, tKey, assignTracked[tKey]);
                                });
                            }
    
                            if (assignIgnored != null) {
                                Object.keys(assignIgnored).forEach(iKey => {
                                    this.$set(item, iKey, assignIgnored[iKey]);
                                });
                            }
    
                            if (item._btID == null) {
                                item._btID = uuidv4();
                            }

                            if (trackProps != null) {
                                if (typeof(trackProps) === 'string') {
                                    trackedProperties = [trackProps];
                                }
                                else if (Array.isArray(trackProps)) {
                                    trackedProperties = trackProps;
                                }
                            }
                            else if (ignoreProps != null || assignIgnored != null) {
                                if (ignoreProps != null) {
                                    if (typeof(ignoreProps) === 'string') {
                                        ignoredProperties.push(ignoreProps);
                                    }
                                    else if (Array.isArray(ignoreProps)) {
                                        ignoredProperties.push(...ignoreProps);
                                    }
                                }
    
                                if (assignIgnored != null) {
                                    Object.keys(assignIgnored).forEach(igProp => {
                                        ignoredProperties.push(igProp);
                                    })
                                }
                            }

                            //set up watcher
                            trackerInfo = {
                                id: item.id,
                                _btID: item._btID,
                                data: item,
                                ignoreProps: ignoredProperties,
                                isChanged: false,
                                json: null,
                                trackProps: trackedProperties,
                                unwatch: null
                            };

                            trackerInfo.unwatch = this.$watch(() => trackerInfo.data, this.handleChange, { deep: true });
                            root.trackedItems.push(trackerInfo);
                        }
                        else {
                            trackerInfo.data = item;
                        }

                        trackerInfo.json = this.createSnapshotJSON(trackerInfo);

                        return trackerInfo.data;
                    }

                    return null;
                },
                untrack(item) {
                    if (Array.isArray(item)) {
                        item.forEach(d => {
                            this.untrack(d);
                        })
                    }
                    else if (item != null) {
                        //remove anything already being tracked
                        var ind = root.trackedItems.findIndex(z => (item._btID != null && z._btID == item._btID) || (z.id != null && item.id != null && z.id == item.id));
                        if (ind >= 0) {
                            root.trackedItems[ind].unwatch();
                            root.trackedItems.splice(ind, 1);
                        }
                    }
                }
            }
        });

        this.root = root;

        this.track = (item, obj) => {
            return root.track(item, obj);
        }

        this.isChanged = (item) => {
            // console.log('is changed?');
            if (Array.isArray(item)) {
                return item.every(x => this.isChanged(x));
            }

            if (item == null || item._btID == null) {
                return false;
            }

            var trackedItem = root.trackedItems.find(z => (item._btID != null && z._btID == item._btID) || (z.id != null && item.id != null && z.id == item.id));
            if (trackedItem == null) {
                return false;
            }

            // console.log(trackedItem);
            // console.log(trackedItem.isChanged ? 'changed' : 'unchanged');
            return trackedItem.isChanged;
        }

        this.isTracking = (item) => {
            // console.log('is tracking?');
            if (item == null || item._btID == null) {
                return false;
            }

            return root.trackedItems.find(z => (item._btID != null && z._btID == item._btID) || (z.id != null && item.id != null && z.id == item.id)) != null;
        }

        this.reset = (item) => {
            return root.reset(item);
        }

        this.untrack = (item) => {
            root.untrack(item);
        }

    }
}