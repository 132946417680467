import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const store = new Vuex.Store({   
    modules: {
        // Auth: AuthStore,
        // Supplier: SupplierStore,
        // Customer: CustomerStore,
        // Account: AccountStore,
        // Courier: CourierStore,
        // Driver: DriverStore,
        // Shared: SharedStore,
        // Public: PublicStore,
        // ShoppingCart: ShoppingCart,
        // ProxyShoppingCart: CustomerShoppingCart,
        // Breadcrumbs: BreadcrumbStore,
    },
    state: {                   
        
    },
    mutations: {               
        
    },
    getters: {        
        
    },
    actions: {                                   
        // clearData({ state, dispatch }) {
        //     if (state != undefined && state != null) {
        //         var keys = Object.keys(state);
        //         for (var i = 0; i < keys.length; i++) {
        //             //console.log(typeof state[keys[i]]);
        //             if (typeof state[keys[i]] == 'object') {
        //                 //console.log(state[keys[i]]);
        //                 dispatch(keys[i] + '/clearData');
        //                 //state[keys[i]].clearData;
        //             }
        //         }
        //     }
        // },
    }
})

export default store;