<template>    
    <v-text-field
        :autofocus="autofocus"
        :prepend-inner-icon="prependIcon"
        hide-details
        v-model.number="currentVal"
        @change="unitChanged"
        :label="label"
        :singleLine="singleLine"
        :disabled="isDisabled"
        :rules="rules"
        :style="mStyle"
        :suffix="suffix"
        type="number"
        class="shrink text-center"
        :class="fieldClass">
        <template v-slot:prepend>
            <v-btn :large="large" @click.stop="removeUnit" icon>
                <v-icon>mdi-minus</v-icon>
            </v-btn>
        </template>
        <template v-slot:append-outer>
            <v-btn :large="large" @click.stop="addUnit" icon>
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
    </v-text-field>
</template>

<script>
export default {
    name: 'BT-Number-Edit',
    data: function() {
        return {
            currentVal: null
        }
    },
    props: {
        value: {
            type: Number,
            default: 0
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        canNegate: {
            type: Boolean,
            default: false
        },
        depressed: {
            type: Boolean,
            default: false
        },
        fieldClass: {
            type: String,
            default: null
        },
        increment: {
            type: Number,
            default: 1
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        negate: {
            type: Boolean,
            default: false
        },
        prependIcon: {
            type: String,
            default: null
        },
        rules: {
            type: Array,
            default: () => { return [] }
        },
        singleLine: {
            type: Boolean,
            default: true
        },
        suffix: {
            type: [String, Number],
            default: null
        },
        width: {
            type: Number,
            default: null
        }
    },
    computed: {
        bubbleVal() {
            if (this.negate) {
                return 0 - this.currentVal;
            }
            else {
                return this.currentVal;
            }
        },
        mStyle() {
            if (this.width) {
                return `width: ${this.width}px`;
            }

            return null;
        }
    },
    mounted() {
        if (this.negate) {
            this.currentVal = (0 - this.value);
        }
        else {
            this.currentVal = this.value;
        }
    },
    watch: {
        value: function(val) {
            if (this.negate) {
                this.currentVal = (0 - val);
            }
            else {
                this.currentVal = val;
            }
        }
    },
    methods: {
        addUnit() {
            this.currentVal += this.increment;
            this.unitChanged();            
        },
        removeUnit() {            
            this.currentVal -= this.increment;
            
            if (!this.canNegate && this.currentVal < 0) {
                this.currentVal = 0;             
            }            

            this.unitChanged();            
        },
        unitChanged() {
            if (typeof(this.bubbleVal) === 'number') {
                this.$emit('input', this.bubbleVal);
                this.$emit('change', this.bubbleVal);
            }
        }        
    }
}
</script>

<style scoped>
    /* .zoomIgnore {
        -webkit-touch-callout: none;
    } */
</style>