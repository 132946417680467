import axios from 'axios';
import Vue from 'vue';
import trainingData from '~tools/training/blitzitdata.js';
import { DataURIToBlob } from '~tools/helpers.js';
import urlHelpers from '~tools/url-helpers.js';
import localforage from 'localforage';

localforage.config({
    name: urlHelpers.getURL('DbName') //'blitzitweb_db' //urlHelpers.getDbName()
});

export default {
    install() {
        const root = new Vue({ })
        var localDb = null;
        // var localDbName = null;

        axios.defaults.headers['Content-Type'] = 'application/json';

        axios.interceptors.request.use(res => {
            if (root.$BlitzIt.auth.session.isLoggedIn) {
                res.headers.authorization = 'bearer ' + root.$BlitzIt.auth.session.token;
            }

            return res;
        })

        //handle the response
        axios.interceptors.response.use(
            res => res,
            async (err) => {
                if (err.response != null && err.response.status === 401) {
                    //check if token has expired and if so then redirect to login
                    
                    var sesh = root.$BlitzIt.auth.session;
                    
                    if (sesh == null || !sesh.isLoggedIn) {
                        //redirect to error
                        root.$router.push({ name: 'error', params: { msg: 'Not Authorized To Access' }});
                    }
                    else if (root.$BlitzIt.auth.tokenExpired()) {
                        //redirect to login
                        root.$BlitzIt.auth.redirectToLogin(window.location.pathname);
                    }
                    else {
                        //do nothing
                        console.log('error but no redirect');
                    }
                }
                throw err;
            }
        )

        // this.rawGet = (url, params) => { 
        //     if (root.$BlitzIt.auth.session.isTraining) {
        //         return null;
        //     }
        //     else {
        //         return axios.get(url, { params: params })
        //     }
        // }

        // this.rawPost = (url, data) => { 
        //     if (root.$BlitzIt.auth.session.isTraining) {
        //         return null;
        //     }
        //     else {
        //         return axios.post(url, data)
        //     }
        // }

        this.getLocalDb = () => {
            var storeName = 'blitzitweb_db'; //, root.$BlitzIt.auth.session.userID || 'no-user-id';
            if (localDb == null || localDb._config.name != storeName) {
                localDb = localforage.createInstance({
                    name: storeName
                });
            }
            
            return localDb;
        }

        this.getLocalDbKey = (navName, params, proxyID, url, action, id = null) => {
            // //[userID || 'no_user_id']-[proxyID || 'no_proxy_id']-[navName || 'no_nav_name']-[path || 'no_path']-[params || 'no_params']
            // var key = `${root.$BlitzIt.auth.session.userID || 'no_user_id'}-${proxyID || 'no_proxy_id'}-${navName || 'no_nav_name'}`;

            //[navName || 'no-nav-name']_[userID || 'no-user-id']_[proxyID || 'no-proxy-id']_[path || 'no-path']_[params || 'no-params']
            var key = `${navName || 'no-nav-name'}_${root.$BlitzIt.auth.session.userID || 'no-user-id'}_${proxyID || 'no-proxy-id'}`;
            
            var path = root.$BlitzIt.navigation.findPath(navName);

            if (url != null) {
                path = `${path}${url}`;
            }
            else if (action != null) {
                path = `${path}${action}`;
            }

            if (id != null) {
                path = `${path}/${id}`;
            }

            key = `${key}_${path || 'no-path'}_${params == null ? 'no-params' : new URLSearchParams(params).toString()}`;

            return key;
        }

        this.get = (navName, url, params, proxyID, useLocalCache = false) => {
            var path = root.$BlitzIt.navigation.findPath(navName);
            if (!path) {
                throw 'Path: ' + navName + ' not found in navigation for get';
            }

            path = path + url;
            
            var headers = { 'Content-Type': 'application/json' };

            if (proxyID) {
                headers.ManagedCompanyAccountID = proxyID;
            }
            
            //if (root.$BlitzIt.navigation.isTraining()) {
            if (root.$BlitzIt.auth.session.isTraining) {
                //return training data
                console.log('TRAINING: get ' + navName + ' | ' + JSON.stringify(params));
                return trainingData.get(navName, null, path);
            }
            else {
                if (useLocalCache) {
                    var key = this.getLocalDbKey(navName, params, proxyID, url, null, null);
                    var db = this.getLocalDb();
                    console.log('LOCAL CACHE: get ' + navName + ' | ' + JSON.stringify(params));
                    return db.getItem(key);
                }
               
                console.log('SERVER: get ' + navName + ' | ' + JSON.stringify(params));
                return axios.get(path, { params, headers });
            }
        }

        this.getById = (navName, id, params, proxyID, url, useLocalCache = false) => {
            var path = root.$BlitzIt.navigation.findPath(navName);
            if (!path) {
                throw 'Path: ' + navName + ' not found in navigation for getById';
            }

            if (url != null) {
                path = path + url;
            }
            else {
                path = path + '/get';
            }

            if (id) {
                path = path + '/' + id;
            }

            var headers = { 'Content-Type': 'application/json' };

            if (proxyID) {
                headers.ManagedCompanyAccountID = proxyID;
            }

            //if (root.$BlitzIt.navigation.isTraining()) {
            if (root.$BlitzIt.auth.session.isTraining) {
                //return training data
                console.log('TRAINING: getById ' + navName + ' | ' + JSON.stringify(params));
                return trainingData.get(navName, id, path);
            }
            else {
                if (useLocalCache) {
                    var key = this.getLocalDbKey(navName, params, proxyID, url, '/get', id);
                    var db = this.getLocalDb();
                    console.log('LOCAL CACHE: getById ' + navName + ' | ' + JSON.stringify(params));
                    return db.getItem(key);
                }
               
                console.log('SERVER: getById ' + navName + ' | ' + JSON.stringify(params));
                return axios.get(path, { params, headers });
            }
        }

        this.getAll = (navName, params, proxyID, url, useLocalCache = false) => {
            var path = root.$BlitzIt.navigation.findPath(navName);
            if (!path) {
                throw 'Path: ' + navName + ' not found in navigation for getAll';
            }

            if (url != null) {
                path = path + url;
            }
            else {
                path = path + '/getAll';
            }
            
            var headers = { 'Content-Type': 'application/json' };

            if (proxyID) {
                headers.ManagedCompanyAccountID = proxyID;
            }
            
            //if (root.$BlitzIt.navigation.isTraining()) {
            if (root.$BlitzIt.auth.session.isTraining) {
                //return training data
                console.log('TRAINING: getAll ' + navName + ' | ' + JSON.stringify(params));
                return trainingData.getAll(navName, path, params);
            }
            else {
                if (useLocalCache) {
                    var key = this.getLocalDbKey(navName, params, proxyID, url, '/getAll', null);
                    var db = this.getLocalDb();
                    console.log('LOCAL CACHE: getAll ' + navName + ' | ' + JSON.stringify(params));
                    return db.getItem(key);
                }
                
                console.log('SERVER: getAll ' + navName + ' | ' + JSON.stringify(params));
                return axios.get(path, { params, headers });
            }
        }

        this.getCount = (navName, params, proxyID) => {
            console.log('getCount ' + navName + ' | ' + JSON.stringify(params));
            var path = root.$BlitzIt.navigation.findPath(navName);
            if (!path) {
                throw 'Path: ' + navName + ' not found in navigation for getCount';
            }

            path = path + '/get/count';
            
            var headers = { 'Content-Type': 'application/json' };

            if (proxyID) {
                headers.ManagedCompanyAccountID = proxyID;
            }
            
            //if (root.$BlitzIt.navigation.isTraining()) {
            if (root.$BlitzIt.auth.session.isTraining) {
                return 0;
                //return training data
                //return trainingData.getAll(navName, path, params);
            }
            else {
                return axios.get(path, { params, headers });
            }
        }

        this.delete = (navName, data, proxyID, url) => {
            console.log('delete ' + navName);
            var path = root.$BlitzIt.navigation.findPath(navName);
            if (!path) {
                throw 'Path: ' + navName + ' not found in navigation for delete';
            }

            if (url) {
                path = path + url;
            }
            else {
                path = path + '/delete';
            }
            
            var headers = { 'Content-Type': 'application/json' };

            if (proxyID) {
                headers.ManagedCompanyAccountID = proxyID;
            }
            
            //if (root.$BlitzIt.navigation.isTraining()) {
            if (root.$BlitzIt.auth.session.isTraining) {
                //return training data
                return trainingData.delete(navName, data);
            }
            else {
                return axios.delete(path, { data, headers });
            }

        }

        this.post = (navName, data, proxyID, url) => {
            console.log('post ' + navName);
            var path = root.$BlitzIt.navigation.findPath(navName);
            if (!path) {
                throw 'Path: ' + navName + ' not found in navigation for post';
            }

            if (url) {
                path = path + url
            }
            else {
                path = path + '/post';
            }

            var headers = { 'Content-Type': 'application/json' };

            if (proxyID) {
                headers.ManagedCompanyAccountID = proxyID;
            }
            console.log(path);
            //if (root.$BlitzIt.navigation.isTraining()) {
            if (root.$BlitzIt.auth.session.isTraining) {
                //return training data
                return trainingData.post(navName, data);
            }
            else {
                return axios.post(path, data, { headers });
            }
            //return axios.post(path, data, { headers });
    
        }

        this.patch = (navName, data, proxyID, url) => {
            console.log('patch ' + navName);
            var path = root.$BlitzIt.navigation.findPath(navName);
            if (!path) {
                throw 'Path: ' + navName + ' not found in navigation for patch';
            }

            if (url) {
                path = path + url
            }
            else {
                path = path + '/patch';
            }   

            var headers = { 'Content-Type': 'application/json' };

            if (proxyID) {
                headers.ManagedCompanyAccountID = proxyID;
            }
            
            //if (root.$BlitzIt.navigation.isTraining()) {
            if (root.$BlitzIt.auth.session.isTraining) {
                //return training data
                return trainingData.patch(navName, data);
            }
            else {
                return axios.patch(path, data, { headers });
            }
        }

        this.uploadImage = (navName, fileData, id, proxyID) => {
            return new Promise((resolve, reject) => {
                if (fileData != null) {
                    var files = new FormData();
                    const file = DataURIToBlob(fileData);
                    files.append('file', file, 'image.jpg');

                    var basePath = root.$BlitzIt.navigation.findPath(navName);

                    var headers = { 'Content-Type': 'multipart/form-data' };
                    
                    if (proxyID) {
                        headers.ManagedCompanyAccountID = proxyID;
                    }                                                
                    if (id) {
                        basePath = basePath + '?ID=' + id;
                    }
                    //if (root.$BlitzIt.navigation.isTraining()) {
                    if (!root.$BlitzIt.auth.session.isTraining) {
                        axios.post(basePath, files, { headers })
                            .then(res => { resolve(res); })
                            .catch(error => { reject(error); })
                    }
                }
            })
        }
    },
}