<template>
    <v-expansion-panel v-if="$canView(navigation)">
        <v-expansion-panel-header>
            <template v-slot:default="{ open }">
                <v-row class="mr-2">
                    <span class="ml-3">{{ pageTitle }}</span>
                    <v-spacer />
                    <v-slide-y-transition group>
                        <slot name="toolbar-right" />
                        <v-btn
                            v-if="open && showRefreshButton"
                            icon
                            small
                            title="Refresh"
                            @click.stop="refresh"
                            key="5">
                            <v-icon small>mdi-refresh</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="open && showEditButton"
                            :disabled="!$canEdit(navigation)"
                            icon
                            small
                            title="Edit"
                            @click.stop="toggleEdit"
                            key="7">
                            <v-icon v-if="isEditing" small>mdi-pencil-off</v-icon>
                            <v-icon v-else small>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="open && showDeleteButton"
                            :disabled="!$canEdit(navigation)"
                            icon
                            small
                            title="Delete"
                            @click.stop="deleteItem"
                            key="8">
                            <v-icon small>mdi-delete</v-icon>
                        </v-btn>
                        <v-menu 
                            v-if="open && showSettings"
                            offset-y 
                            :close-on-content-click="false"
                            key="9">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    title="Settings"
                                    small
                                    key="10">
                                    <v-icon small>mdi-cog</v-icon>
                                </v-btn>
                            </template>
                            <v-list dense>
                                <slot name="bladeSettings" v-bind="data" />
                            </v-list>
                        </v-menu>
                    </v-slide-y-transition>
                </v-row>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
            <BT-Snack v-model="msg" />
            <slot name="everything" v-bind:item="asyncItem">
                <v-form v-if="asyncItem != null" ref="form" dense>
                    <slot v-bind="{ item: asyncItem, isNew, data }" />
                    <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
                </v-form>
            </slot>
            <v-btn v-if="showSave" color="primary" @click="saveItem"><v-icon left>mdi-content-save</v-icon>{{ isNew ? 'Create' : 'Save' }}</v-btn>
            <v-overlay :value="isLoading" absolute class="text-center">
                <v-progress-circular indeterminate size="64" />
                <p>Loading</p>
            </v-overlay>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
export default {
    name: 'BT-Blade-Expansion-Item',
    data: function() {
        return {
            asyncItem: null,
            bladeData: null,
            errorMessage: null,
            id: null,
            isEditing: false,
            isLoading: false,
            itemJSON: null,
            mProxyID: null,
            msg: null,
            originalDataBag: null,
            showDelete: false,
            showError: false,
            snapshotItemJSON: null,
        }
    },
    props: {
        bladesData: {
            type: Object,
            default: null
        },
        canCreate: {
            type: Boolean,
            default: true
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: true,
        },
        canRefresh: {
            type: Boolean,
            default: true
        },
        canSave: {
            type: Boolean,
            default: true
        },
        changeToggle: {
            type: Boolean,
            default: false
        },
        closeOnDelete: {
            type: Boolean,
            default: true
        },
        customURL: {
            type: String,
            default: null
        },
        editToggle: {
            type: Boolean,
            default: false
        },
        ignoreID: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: null
        },
        itemID: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        navigation: null,
        onCanPull: {
            type: Function,
            default: null
        },
        onCanSave: {
            type: Function,
            default: null
        },
        onFinalize: {
            type: Function,
            default: null
        },
        onGetItem: {
            type: Function,
            default: null
        },
        onGetLocalUpdateAsync: {
            type: Function,
            default: null
        },
        onGetSave: {
            type: Function,
            default: (bladeData, item) => { return item }
        },
        onInsertDataAsync: {
            type: Function,
            default: null
        },
        onNew: {
            type: Function,
            default: () => { return { }}
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        title: null,
        titleProp: {
            type: String,
            default: null
        },
        saveToggle: {
            type: Boolean,
            default: false
        },
        trackChanges: {
            type: Boolean,
            default: true
        },
        trackIgnore: {
            type: Array,
            default: null
        },
        useCopy: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        asyncItem: {
            deep: true,
            handler() {
                this.updateChange();
            }
        },
        editToggle: function() {
            this.toggleEdit();
        },
        id: function() {
            this.refresh();
        },
        loading: function(val) {
            this.isLoading = val;
            this.$forceUpdate();
        },
        proxyCompanyID: function() {
            this.refresh();
        },
        changeToggle() {
            this.snapshotItemJSON = this.createSnapshot(this.copyDeep(this.asyncItem));
            this.itemJSON = this.snapshotItemJSON;
        },
        refreshToggle: function() {
            this.refresh();
        },
        saveToggle: function() {
            this.saveItem();
        },
    },
    created() {
        this.isLoading = this.loading;
        this.$forceUpdate();
    },
    mounted() {
        this.$emit('mounted');
        
        this.pullItem();
    },
    computed: {
        canAuthEdit() {
            return this.$BlitzIt.auth.canEdit(this.navigation);
        },
        computedBladeData() {
            return {
                data: {
                    id: this.$route.params['id'],
                    ...this.originalDataBag
                }
            }
        },
        data() {
            return {
                isEditing: this.isEditing,
                isMobile: this.isMobileWidth,
                isNew: this.isNew,
                ...this.computedBladeData,
            }
        },
        isChanged() {
            return this.itemJSON != this.snapshotItemJSON;
        },
        isMobileWidth() {
            return this.$vuetify.breakpoint.mobile;
        },
        isNew() {
            return this.id == 'new';
        },
        pageTitle() {
            if (this.title) {
                return this.title;
            }
            else if (this.titleProp && !this.isNew && this.asyncItem) {
                var title = this.getNestedValue(this.asyncItem, this.titleProp);
                if (title) {
                    return title;
                }
            }

            var e = this.$BlitzIt.navigation.findSingleDisplayName(this.navigation);
            return this.isNew ? 'New ' + e : e;
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.mProxyID != null) {
                return this.mProxyID;
            }
            else if (this.proxyIDParam != null && this.bladeData != null && this.bladeData.data != null && this.bladeData.data[this.proxyIDParam] != null) {
                return this.bladeData.data[this.proxyIDParam];
            }
            else if (this.proxyIDParam != null && this.$route.query != null && this.$route.query[this.proxyIDParam] != null) {
                return this.$route.query[this.proxyIDParam];
            }

            return null;
        },
        showSave() {
            return this.canSave && (this.canEdit && this.canAuthEdit && (!this.trackChanges || this.isChanged) || (this.canCreate && this.isNew));
        },
        showDeleteButton() {
            return this.canDelete && this.$BlitzIt.auth.canEdit(this.navigation) && !this.isNew;
        },
        showEditButton() {
            return this.canEdit && this.$BlitzIt.auth.canEdit(this.navigation) && !this.isNew;
        },
        showRefreshButton() {
            return this.canRefresh && !this.isNew;
        }
    },
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.isLoading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.isLoading = false;
            this.$forceUpdate();
        },
        closeDelete() {            
            this.showDelete = false;
        },
        createSnapshot(data) {
            var copy = this.copyDeep(data);
            
            if (this.isLengthyArray(this.trackIgnore)) {
                this.trackIgnore.forEach(x => {
                    delete copy[x];
                });
            }

            return JSON.stringify(copy);
        },
        async deleteItem() {
            this.showDelete = false;
            this.showError = false;
            this.errorMessage = null;

            this.startLoading();
            try {
                var proceed = await this.$confirm('Are you sure you want to delete this item?');
                if (proceed) {
                    await this.$BlitzIt.store.delete(this.navigation, this.asyncItem.id, this.proxyCompanyID);
                }
                
                this.$emit('deleted');
                
                if (this.closeOnDelete) {
                    if (this.bladesData != null) {
                        this.bladesData.closeBlade({ bladeName: this.bladeName });
                    }
                    else {
                        this.$BlitzIt.navigation.navBack();
                    }
                }
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
        },
        finalize(item) {
            this.isEditing = false;

            console.log('finalize');

            if (this.onFinalize != null) {
                this.onFinalize(item);
            }
            else {
                this.msg = 'Saved';
                
                if (this.isNew && this.bladesData == null) {
                    this.$BlitzIt.navigation.navBack();
                }
                else if (this.isNew && this.bladesData != null) {
                    //close?
                    this.bladesData.closeBlade({ bladeName: this.bladeName });
                }
                else if (item != null) {
                    this.asyncItem.id = item.id;
                    this.asyncItem.rowVersion = item.rowVersion;
                    this.snapshotItemJSON = this.createSnapshot(this.asyncItem); //JSON.stringify(this.asyncItem);
                    this.itemJSON = this.snapshotItemJSON;
                    if (this.bladesData != null) {
                        this.bladesData.toggleBlade({ bladeName: this.bladeName, toggle: 'edit' });
                    }
                }

                this.$emit('saved', this.asyncItem);
            }

            this.$BlitzIt.tracker.reset(this.asyncItem);
        },
        async pullItem(refresh = false) {
            if (this.onCanPull != null && !this.onCanPull()) {
                return;
            }
            
            if (this.item != null || this.onGetLocalUpdateAsync != null) {
                if (this.onGetLocalUpdateAsync != null) {
                    this.asyncItem = await this.onGetLocalUpdateAsync(this.bladeData, this.item);
                }
                else {
                    this.asyncItem = this.item;
                }
                
                if (this.onInsertDataAsync != null) {
                    this.originalDataBag = await this.onInsertDataAsync(this.asyncItem);
                }

                return;
            }
            
            this.startLoading();
            try {
                var idParam = this.itemID != null ? this.itemID : this.id;
                console.log(idParam);
                if ((this.asyncItem == null || refresh == true) && (idParam != null || this.ignoreID)) {
                    var res = null;
                    if (idParam === 'new') {
                        res = await this.onNew(this.bladeData);
                    }
                    else if (this.onGetItem != null) {
                        res = this.onGetItem(this.bladeData);
                    }
                    else {
                        if (this.navigation == null) {
                            return;
                        }

                        res = await this.$BlitzIt.store.get(this.navigation, this.ignoreID ? null : idParam, this.params, refresh, this.proxyCompanyID, this.customURL);
                    }

                    if (this.onPullSuccessAsync != null) {
                        this.asyncItem = await this.onPullSuccessAsync(this.useCopy ? this.copyDeep(res) : res, refresh, this.bladeData);
                    }
                    else {
                        this.asyncItem = this.useCopy ? this.copyDeep(res) : res;
                    }
                    
                    if (this.onInsertDataAsync != null) {
                        this.originalDataBag = await this.onInsertDataAsync(this.asyncItem);
                    }

                    this.snapshotItemJSON = this.createSnapshot(this.copyDeep(this.asyncItem));
                    this.itemJSON = this.snapshotItemJSON;

                    this.$emit('fetched', this.asyncItem);
                }
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
        },
        refresh() {
            this.showError = false;
            this.errorMessage = null;
            this.pullItem(true);
        },
        async saveItem() {
            this.showError = false;
            if (this.$refs.form.validate()) {
                this.startLoading();
                var itemToSave = this.onGetSave(this.bladeData, this.asyncItem);
                try {
                    var warning = null;
                    if (this.onCanSave != null) {
                        warning = this.onCanSave(itemToSave);
                    }
                    
                    if (warning != null) {
                        this.msg = warning;
                    }
                    else {
                        if (this.isNew) {
                            this.finalize(await this.$BlitzIt.store.post(this.navigation, itemToSave, this.proxyCompanyID));
                        }
                        else {
                            this.finalize(await this.$BlitzIt.store.patch(this.navigation, itemToSave, this.proxyCompanyID));
                        }
                    }
                }
                catch (err) {
                    this.formError(err);
                }
                finally {
                    this.endLoading();
                }
            }
        },
        toggleEdit() {
            console.log('toggling edit');
            this.isEditing = !this.isEditing;
            if (this.isEditing) {
                this.$emit('edit', this.computedBladeData);
            }
        },
        updateChange() {
            this.itemJSON = this.createSnapshot(this.asyncItem);
        },

        // updateBlade(bladeData) {
        //     this.bladeData = bladeData;

        //     if (bladeData != null) {
        //         if (bladeData.data != null) {
        //             this.id = bladeData.data.id;
        //         }
        //     }

        //     var isChanged = false;
        //     if (bladeData != null) {
        //         isChanged = JSON.stringify(this.originalDataBag) != JSON.stringify(bladeData.data);
        //     }
        //     this.pullItem(isChanged);
        //     this.$emit('sourceChanged', bladeData);

        //     if (bladeData != null) {
        //         this.originalDataBag = bladeData.data;
        //     }
        // }
    }
}
</script>