<template>
    <v-card @click="toggle" 
        :disabled="disabled || !isEditing" 
        :ripple="ripple" 
        :outlined="outlined" 
        class="text-center" 
        :height="height" 
        :width="width">
        <slot name="content" v-bind:v="v" v-bind:label="label" v-bind:iconSize="iconSize">
            <v-slide-x-transition group hide-on-leave>
                <p v-if="label != null" class="my-2 text-center" key="1">{{ label }}</p>
                <p v-else class="my-2 text-center" key="2">{{ v ? trueLabel : falseLabel }}</p>
                
                <v-icon v-if="v" :size="iconSize" :class="disabled ? '' : trueClass" key="3">{{ trueIcon }}</v-icon>
                <v-icon v-else :size="iconSize" :class="disabled ? '' : falseClass" key="4">{{ falseIcon }}</v-icon>
            </v-slide-x-transition>
        </slot>
    </v-card>
</template>

<script>
export default {
    name: 'BT-Square-Check',
    data: function() {
        return {
            v: false,
            actualValue: null,
        }
    },
    props: {
        dense: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        falseClass: {
            type: String,
            default: 'error--text'
        },
        falseIcon: {
            type: String,
            default: 'mdi-close'
        },
        falseLabel: {
            type: String,
            default: null
        },
        falseValue: {
            type: [String, Boolean],
            default: false
        },
        iconSize: {
            type: String,
            default: '50'
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        label: null,
        height: {
            type: String,
            default: '100'
        },
        width: {
            type: String,
            default: 'auto'
        },
        outlined: {
            type: Boolean,
            default: false
        },
        ripple: {
            type: Boolean,
            default: true
        },
        rounded: {
            type: Boolean,
            default: false
        },
        trueClass: {
            type: String,
            default: 'success--text'
        },
        trueIcon: {
            type: String,
            default: 'mdi-check'
        },
        trueLabel: {
            type: String,
            default: null
        },
        trueValue: {
            type: [String, Boolean],
            default: true
        },
        value: null,
    },
    watch: {
        value: function(val) {
            if (this.actualValue !== val) {
                this.v = val == this.trueValue;
            }
            this.actualValue = val;
        }
    },
    created() {
        this.actualValue = this.value;
        this.v = this.actualValue == this.trueValue;
    },
    methods: {
        toggle() {
            this.v = !this.v;
            this.actualValue = this.v ? this.trueValue : this.falseValue;
            
            this.$emit('input', this.actualValue);
            this.$emit('change', this.actualValue);
        }
    }
}
</script>