import Vue from 'vue';
import colors from 'vuetify/lib/util/colors';
import { copyDeep } from '~helpers';

const cosmeticKey = 'bt-cosmetics'

const defaultLight = {
    primary: '#192233', //#192233
    secondary: '#192233',
    accent: '#2a76a2', //'#82B1FF',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    // 'bg-btn': '#192233',
    // 'fg-btn': '#ffffff',
    // 'bg-list': colors.white,
    // 'fg-list': null,
    // 'bg-list-item': null,
    // 'fg-list-item': '#192233',
    'bg-primary': '#192233',
    'fg-primary': '#ffffff',
    'bg-toolbar': '#192233',
    'fg-toolbar': '#ffffff',
    'faded-text': colors.grey.darken2
}

const defaultDark = {
    primary: '#1d5474', //#1d5474?
    secondary: '#192233',
    accent: '#7fcbf7', //'#2a76a2', //'#FF4081',
    error: '#FF5252',
    info: '#2196F3',
    success: '#4CAF50',
    warning: '#FB8C00',
    // 'bg-btn': '#192233',
    // 'fg-btn': '#ffffff',
    // 'bg-list': '#192233',
    // 'fg-list': colors.white,
    // 'bg-list-item': '#192233',
    // 'fg-list-item': colors.white,
    'bg-primary': '#192233',
    'fg-primary': '#ffffff',
    'bg-toolbar': '#192233',
    'fg-toolbar': '#ffffff',
    'faded-text': colors.grey
}

export function getCosmetics() {
    let r = null
    let cosmeticStr = localStorage.getItem(cosmeticKey)
    if (cosmeticStr != null) {
        r = JSON.parse(cosmeticStr)
    }

    if (r == null) {
        r = {
            dark: defaultDark,
            light: defaultLight,
            locationID: null,
            drawerPermanent: false,
            subscriptionView: null,
            theme: {
                dark: false
            }
        }
    }

    return r
}

export const plugin = {
    install() {
        this.root = new Vue({
            data: {
                cosmetics: {
                    dark: copyDeep(defaultDark),
                    drawerPermanent: false,
                    light: copyDeep(defaultLight),
                    locationID: null,
                    subscriptionView: null,
                    temporaryColor: null,
                    theme: {
                        dark: false
                    }
                }
            }
        });

        this.root.cosmetics = getCosmetics()

        this.resetColors = (vuetify) => {
            console.log('resetting colors');
            vuetify.theme.themes.light = copyDeep(this.root.cosmetics.light);
            vuetify.theme.themes.dark = copyDeep(this.root.cosmetics.dark);

            let color = vuetify.theme.dark ? vuetify.theme.themes.dark.primary : vuetify.theme.themes.light.primary;
            document.querySelector('meta[name="theme-color"]').setAttribute("content", color);

            this.save();
        }

        this.resetToDefaultColors = (vuetify) => {
            if (vuetify.theme.dark) {
                this.root.cosmetics.dark = copyDeep(defaultDark);
            }
            else {
                this.root.cosmetics.light = copyDeep(defaultLight);
            }

            this.resetColors(vuetify);
        }

        this.save = () => {
            localStorage.setItem(cosmeticKey, JSON.stringify(this.root.cosmetics))
        }

        this.setLocation = (id) => {
            this.root.cosmetics.locationID = id;
            this.save();
        }

        this.setSubscription = (val) => {
            this.root.cosmetics.subscriptionView = val;
            this.save();
        }

        this.setTemporaryColor = (vuetify, color) => {
            console.log('setting temp');
            console.log(color);

            vuetify.theme.themes.light.primary = color;
            vuetify.theme.themes.dark.primary = color;
            document.querySelector('meta[name="theme-color"]').setAttribute("content", color);
        }

        this.undoTemporaryColor = (vuetify) => {
            vuetify.theme.themes.light = copyDeep(this.root.cosmetics.light);
            vuetify.theme.themes.dark = copyDeep(this.root.cosmetics.dark);
            
            let color = vuetify.theme.dark ? vuetify.theme.themes.dark.primary : vuetify.theme.themes.light.primary;
            document.querySelector('meta[name="theme-color"]').setAttribute("content", color);
        }

        this.toggleDrawerPermanent = () => {
            this.root.cosmetics.drawerPermanent = !this.root.cosmetics.drawerPermanent
            this.save();
        }

        this.toggleLightDark = (vuetify) => {
            this.root.cosmetics.theme.dark = !this.root.cosmetics.theme.dark;
            vuetify.theme.dark = this.root.cosmetics.theme.dark;
            this.save();
        }
    }
}