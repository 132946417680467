<template>
    <v-menu        
        v-model="modal"
        :close-on-content-click="closeOnClick"
        transition="scale-transition"
        offset-y
        min-width="auto">
        <template v-slot:activator="{ on, attrs }">
            <span 
                v-if="dense && disabled"
                v-bind="attrs">
                {{ computedDateFormattedMomentJS }}
            </span>
            <!-- <span 
                v-else-if="dense && computedDateFormattedMomentJS != null && computedDateFormattedMomentJS.length > 0"
                v-bind="attrs"
                v-on="on">
                {{ computedDateFormattedMomentJS }}
            </span> -->
            <v-text-field
                v-else
                v-model="computedDateFormattedMomentJS"
                :color="color"
                :dense="dense"
                :filled="filled"
                :label="label"
                :prepend-icon="prependIcon"
                readonly
                :hide-details="hideDetails"
                :single-line="singleLine"
                :solo="solo"
                :disabled="disabled"
                v-bind="attrs"
                v-on="on">
                <template v-slot:prepend>
                    <v-icon v-if="prependIcon" :size="prependIconSize">{{ prependIcon }}</v-icon>
                </template>
                <template v-slot:append>
                    <v-btn v-if="clearable" small icon @click="clear">
                        <v-icon small>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-text-field>
        </template>
        <v-date-picker
            :color="color"
            v-model="date"
            @input="update"
            :allowed-dates="dateRules"
            :min="startDate">
        </v-date-picker>
        <!-- :min="dateFrom" -->
    </v-menu>
</template>

<script>
export default {
    name: 'BT-Date-Picker',
    data: function() {
        return {
            //TZ
            date: null, //timezone date // new Date().toISOString().substr(0, 10),            
            showDate: null,
            modal: false,
            startDate: null,
        }
    },
    props: {
        clearable: {
            type: Boolean,
            default: false
        },
        closeOnClick: {
            type: Boolean,
            default: true
        },
        //UTC
        color: {
            type: String,
            default: null
        },
        //UTC
        dateFrom: {
            type: String,
            default: null
        },
        dateRules: {
            type: Function,
            default: null
        },
        dense: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        filled: {
            type: Boolean,
            default: false
        },
        format: {
            type: String,
            default: 'ccc d-LL-yyyy' //'ddd D-MMM-YYYY'//'ddd D-MMM-YYYY HH:mm:ss A'
        },
        fromNow: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        hideTitle: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        prependIcon: {
            type: String,
            default: 'mdi-calendar'
        },
        prependIconSize: {
            type: String,
            default: null
        },
        singleLine: {
            type: Boolean,
            default: false
        },
        solo: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: "500"
        },
        value: null  ///UTC Format Date
    },
    watch: {
        //UTC
        value: function(val) {            
            this.date = this.$BlitzIt.auth.formTZ(val, 'yyyy-LL-dd');
        },
        //UTC
        dateFrom: function(val) {
            if (val) {
                this.startDate = val;
            }
        }
    },
    mounted() {
        if (this.value) {
            this.date = this.$BlitzIt.auth.formTZ(this.value, 'yyyy-LL-dd');
        }  
        
        if (this.dateFrom == null && this.fromNow) {            
            this.startDate = this.$BlitzIt.auth.createUTC();
        }
        else {
            this.startDate = this.dateFrom;
        }
    },
    computed: {
        computedDateFormattedMomentJS() {
            return this.date ? this.$BlitzIt.auth.formatDate(this.date, this.format) : '';
        }
    },
    methods: {
        clear() {
            this.date = null;
            this.$emit('input', null);
            this.$emit('change', null);
        },
        update(nv) {
            var res = this.$BlitzIt.auth.formTZtoUTC(nv);
            this.date = nv;
            this.$emit('input', res);
            this.$emit('change', res);
        }
    }
}
</script>