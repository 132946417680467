<template>
    <v-list-item-avatar :rounded="rounded">
        <v-img :src="url" class="my-auto">
            <template v-slot:placeholder>
                <v-icon size="40">{{ compIcon }}</v-icon>
            </template>
        </v-img>
    </v-list-item-avatar>
</template>

<script>
export default {
    name: 'BT-List-Item-Avatar',
    props: {
        avatarType: {
            type: String,
            default: 'company', //product
        },
        icon: {
            type: String,
            default: 'default'
        },
        rounded: {
            type: Boolean,
            default: true
        },
        value: null
    },
    computed: {
        compIcon() {
            if (this.icon == 'default') {
                if (this.avatarType == 'company') {
                    return 'mdi-account-outline';
                }
                else if (this.avatarType == 'product') {
                    return 'mdi-cube-outline';
                }
            }

            return this.icon;
        },
        url() {
            if (this.avatarType == 'company') {
                return this.companyLogoURL(this.value);
            }
            else if (this.avatarType == 'product') {
                return this.productLogoURL(this.value);
            }
            else {
                return null;
            }
        }
    }
}
</script>