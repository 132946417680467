<template>
    <v-row :class="noGutters ? null : 'mx-0 my-3'" :no-gutters="noGutters">
        <slot>
            <slot name="left" />
            <v-spacer />
            <slot name="center" />
            <v-spacer />
            <slot name="right" />
        </slot>
    </v-row>
</template>

<script>
export default {
    name: 'BT-Btn-Row',
    props: {
        noGutters: {
            type: Boolean,
            default: false
        }
    }
}
</script>