<template>
    <BT-Select
        navigation="measurement-standards"
        itemText="standard"
        itemValue="standard"
        v-model="standard"
        @input="update"
        label="Measurement Standard" />
</template>

<script>
export default {
    name: 'BT-Measurement-Standard',
    data: function() {
        return {
            standard: null
        }
    },
    props: {
        value: null
    },
    mounted() {
        this.standard = this.value;
    },
    watch: {
        value: function(val) {
            this.standard = val;
        }
    },
    methods: {
        update() {
            this.$emit('input', this.standard);
            this.$emit('change', this.standard);
        }
    }
}
</script>