<template>
    <v-list-item
        :dense="dense"
        :class="fieldClass">
        <v-list-item-icon v-if="icon != null">
            <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>
            <v-list-item-title v-if="!horizontal">
                <BT-Entity
                    :entityProp="entityProp"
                    :navigation="navigation"
                    :inline="inline"
                    :itemProperties="itemProperties"
                    :itemValue="itemValue"
                    :itemText="itemText"
                    :single="single"
                    :textFilter="textFilter"
                    :useLocalCache="useLocalCache"
                    :proxyID="proxyID" />
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action-text v-if="horizontal">
            <BT-Entity
                :entityProp="entityProp"
                :navigation="navigation"
                :inline="inline"
                :itemProperties="itemProperties"
                :itemValue="itemValue"
                :itemText="itemText"
                :single="single"
                :textFilter="textFilter"
                :useLocalCache="useLocalCache"
                :proxyID="proxyID" />
        </v-list-item-action-text>
    </v-list-item>
</template>

<script>
export default {
    name: 'BT-Field-Entity',
    props: {
        alternateText: {
            type: String,
            default: null
        },
        dense: {
            type: Boolean,
            default: true
        },
        entityProp: {
            type: String,
            default: 'id'
        },
        fieldClass: {
            type: String,
            default: null
        },
        horizontal: {
            type: Boolean,
            default: false
        },
        icon: null,
        inline: {
            type: Boolean,
            default: false
        },
        label: null,
        itemProperties: {
            type: Array,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        navigation: {
            type: String,
            default: null
        },
        proxyID: {
            type: String,
            default: null
        },
        single: {
            type: Boolean,
            default: false
        },
        textFilter: {
            type: String,
            default: null
        },
        useLocalCache: {
            type: Boolean,
            default: false
        }
    }
}
</script>