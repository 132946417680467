<template>
    <v-list-item 
        :dense="dense" 
        key="2"
        :class="fieldClass">
        <v-list-item-icon v-if="icon != null">
            <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>
            <v-switch
                v-else
                v-model="tValue"
                @change="update"
                class="ml-1"
                :dense="dense"
                :disabled="!isEditing && !isNew"
                :hide-details="hideDetails"
                :inset="inset">
                <template v-slot:label>
                    {{ tValue ? trueText : falseText }}
                </template>
            </v-switch>
        </v-list-item-content>
        <v-list-item-action v-if="label != null">
            <v-switch 
                v-model="tValue"
                @change="update"
                :disabled="!isEditing && !isNew"
                :hide-details="hideDetails"
                :inset="inset"
                :dense="dense">
                <template v-slot:label>
                    {{ tValue ? trueText : falseText }}
                </template>
            </v-switch>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'BT-Field-Switch',
    data: function() {
        return {
            tValue: this.value
        }
    },
    props: {
        value: null,
        dense: {
            type: Boolean,
            default: true
        },
        fieldClass: {
            type: String,
            default: 'mb-2'
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        icon: null,
        isEditing: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        label: null,
        trueText: { 
            type: String,
            default: 'On'
        },        
        falseText: {
            type: String,
            default: 'Off'
        },
        inset: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        value: function(val) {
            if (!this.tValue === val) {
                this.tValue = val;
            }
        }
    },
    methods: {
        update(v) {
            console.log(v);
            this.$emit('input', v);
            this.$emit('change', v);
        }
    }
}
</script>