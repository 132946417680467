<template>
<v-card flat :width="width" class="mx-auto">
    <v-toolbar v-if="showToolbar && !hideHeader && !isSimple" :flat="!isMobile">
        <v-toolbar-title v-if="pageTitle">{{ pageTitle }}</v-toolbar-title>
        <v-divider v-if="pageTitle" class="ml-4" inset vertical />

        <v-spacer v-if="isMobile" />

        <v-menu v-if="showSettings || canExportCSV" offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon color="primary" v-bind="attrs" v-on="on" title="Settings">
                    <v-icon>mdi-cog</v-icon>
                </v-btn>
            </template>
            <v-list v-if="canExportCSV">
                <v-list-item v-if="canExportCSV" @click="exportAsCSV">
                    <v-list-item>
                        <v-list-item-icon><v-icon>mdi-file-delimited-outline</v-icon></v-list-item-icon>    
                        <v-list-item-content>Export To CSV</v-list-item-content>
                    </v-list-item>
                </v-list-item>
                <v-list-item v-if="displayIsChangeable" @click="toggleDisplayMode">
                    <v-list-item>
                        <v-list-item-icon><v-icon>{{ currentDisplay == 'grid' ? 'mdi-table' : 'mdi-grid' }}</v-icon></v-list-item-icon>
                        <v-list-item-content>{{ currentDisplay == 'grid' ? 'Show Table' : 'Show Grid' }}</v-list-item-content>
                    </v-list-item>
                </v-list-item>
                <slot name="settings" />
            </v-list>
        </v-menu>
        <!--<v-btn icon @click="toggleDisplayMode" color="primary"><v-icon>mdi-grid</v-icon></v-btn>-->
        <v-btn v-if="canRefresh" :small="isMobile" color="primary" icon @click="reset"><v-icon>mdi-refresh</v-icon></v-btn>                
        <v-btn v-if="showArchives && archiveName != null" :small="isMobile" color="primary" icon @click="navToArchives" title="Archives"><v-icon>mdi-archive</v-icon></v-btn>
        <v-btn v-if="canSearch && isMobile" :small="isMobile" icon @click="showSearch = !showSearch"><v-icon class="primary--text">mdi-magnify</v-icon></v-btn>

        <slot name="actions" />

        <v-spacer v-if="!isMobile && (canSearch || canSync && isLengthyArray(asyncExternalParties) || canCreateDialog || canCreate)" />

        <v-text-field
            v-if="canSearch && !isMobile"
            flat     
            hide-details
            hide-no-data     
            solo-inverted
            append-icon="mdi-magnify"                                                            
            class="mx-4"                    
            label="Find"
            v-model="searchInput"                    
            @click:append="refresh" />

        <BT-Select-Dialog
            v-if="canSync && isLengthyArray(asyncExternalParties)"
            :items="asyncExternalParties"
            label="Sync With"                    
            v-model="currentExternalParty"
            icon="mdi-cloud-sync"
            :small="isMobile"
            :listItemValue="null"
            :text="currentExternalParty ? 'Sync: ' + currentExternalParty : 'Sync'"
            width="400"
            @input="loadSyncItems">
            <template slot-scope="{ item }">
                <v-container>
                    <v-card class="primary--text">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <v-card-title>{{ item }}</v-card-title>
                                <v-card-subtitle></v-card-subtitle>
                            </div>

                            <v-avatar size="100">
                                <v-img :src="imageURL(item + '.png')" />
                            </v-avatar>
                        </div>
                    </v-card>
                </v-container>
            </template>
        </BT-Select-Dialog>

        <!--filter menu if mobile-->
        <v-menu 
            v-if="showToolbar && isLengthyArray(chipFilters) && isMobile && !hideFilters"
            :close-on-content-click="false"
            dense
            left
            bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                small
                icon                
                v-bind="attrs"
                v-on="on">
                    <v-icon class="primary--text">mdi-filter</v-icon>
                </v-btn>
            </template>

            <v-list>
                <v-chip-group 
                    v-model="selectedFilters"
                    multiple
                    color="primary">
                    <v-chip
                        v-for="(filter, index) in chipFilters"
                        filter
                        outlined
                        small
                        :key="index">
                        {{ filter }}
                    </v-chip>               
                </v-chip-group>                    
                <v-fade-transition hide-on-leave group>
                    <v-divider v-if="isFilterChanged" key="1"/>
                    <v-btn
                        v-if="isFilterChanged"
                        key="2"
                        text
                        small   
                        class="mx-auto primary--text"         
                        @click="refresh">
                        <v-icon small left>mdi-filter</v-icon>Apply
                    </v-btn>                                                                     
                </v-fade-transition>   
                <!-- <v-list-item v-for="(filter, index) in chipFilters" :key="index">
                    <v-list-item-title>Toggle Display Mode</v-list-item-title>
                </v-list-item> -->
            </v-list>
        </v-menu>

        <BT-Dialog
            v-if="canCreateDialog"
            :show="showCreateDialog"
            v-model="selectedItem"
            :disabled="!permittedToEdit"
            @open="openDialog"
            @ok="saveDialogItem"
            @close="closeDialog"
            :okText="isNewDialog ? 'Create' : 'Save & Update'"
            :text="!isMobile ? createButtonText : null"
            :icon="isMobile ? 'mdi-plus' : null"
            :small="isMobile">
            <template slot-scope="{ item }">
                <slot name="dialog" v-bind:item="item">

                </slot>
            </template>
        </BT-Dialog>
        <v-btn v-else-if="canCreate" :disabled="!permittedToEdit" :small="isMobile" :icon="isMobile" class="primary" @click="navToNew">{{ isMobile ? null : createButtonText }}<v-icon v-if="isMobile" class="primary--text">mdi-plus</v-icon></v-btn>
    </v-toolbar>
    <v-toolbar v-if="showToolbar && isLengthyArray(chipFilters) && !isMobile && !hideFilters" dense flat>
        <BT-Select-Dialog
            v-if="canSync && isLengthyArray(asyncExternalParties)"
            :items="asyncExternalParties"
            label="Sync With"
            v-model="currentExternalParty"
            icon="mdi-cloud-sync"
            :small="true"
            :listItemValue="null"
            :text="currentExternalParty ? 'Sync: ' + currentExternalParty : 'Sync'"
            width="400"
            @input="loadSyncItems">
            <template slot-scope="{ item }">
                <v-container>
                    <v-card class="primary--text">
                        <div class="d-flex flex-no-wrap justify-space-between">
                            <div>
                                <v-card-title>{{ item }}</v-card-title>
                                <v-card-subtitle></v-card-subtitle>
                            </div>

                            <v-avatar size="100">
                                <v-img :src="imageURL(item + '.png')" />
                            </v-avatar>
                        </div>
                    </v-card>
                </v-container>
            </template>
        </BT-Select-Dialog>
        <v-spacer />
        <v-chip-group 
            v-model="selectedFilters"
            multiple>   
            <v-fade-transition hide-on-leave>
                <v-btn
                v-if="isFilterChanged"
                text
                small            
                @click="refresh">
                <v-icon small left>mdi-filter</v-icon>
                Apply
            </v-btn>                                                 
            <v-icon v-else class="mr-2" small>mdi-filter</v-icon>                    
            </v-fade-transition>            
            <v-chip
                v-for="(filter, index) in chipFilters"
                filter
                outlined
                small
                :key="index">
                {{ filter }}
            </v-chip>               
        </v-chip-group>
    </v-toolbar>
    <v-slide-y-transition>
        <v-toolbar 
            v-if="isMobile && showToolbar && !hideHeader && !isSimple && canSearch && showSearch"
            dense
            class="mx-0 px-0">
            <v-text-field
                flat
                hide-details
                hide-no-data
                solo-inverted
                append-icon="mdi-magnify"
                label="Find"
                v-model="searchInput"
                @click:append="refresh" />
            </v-toolbar>
    </v-slide-y-transition>

    <v-slide-y-transition group>
    <v-alert v-model="showError" dismissible type="error" key="1">{{ errorMessage }}</v-alert>
    <v-container v-if="currentDisplay==='grid'" fluid key="2">
        <v-row v-if="isLengthyArray(filteredItems)" dense>
            <v-col
                v-for="(item, index) in filteredItems"
                :key="index"
                :cols="cols" :sm="sm" :md="md" :lg="lg">
                <slot name="grid-item" v-bind:item="item"></slot>
            </v-col>
        </v-row>
        <v-row v-else>
            <v-card class="mx-auto mt-5">
                <v-card-title>No Items Found</v-card-title>
            </v-card>
        </v-row>
    </v-container>
    
    <v-data-table
        v-else-if="currentDisplay==='table' && !isMobile"
        :headers="tableHeaders"
        :dense="dense"
        :items="filteredItems"
        :loading="isLoading"
        :expanded.sync="mobileExpandedItems"
        :hide-default-footer="hideFooter || useServerPagination"
        :hide-default-header="hideTableHeader"
        :single-expand="true"
        :show-expand="canExpandItems"
        :item-key="itemKey"
        :items-per-page="itemsPerPage"
        @click:row="rowSelected"
        key="3">

        <template v-slot:[`item.actions`]="{ item }">
            <slot name="itemActions" v-bind:item="item" v-bind:items="asyncItems">

            </slot>
            <v-slide-x-transition group>
                <v-icon 
                    v-if="item.errorMsg != null"
                    :title="item.errorMsg"
                    color="warning"
                    key="0.5">
                    mdi-alert-circle
                </v-icon>
                <v-btn
                    v-if="currentExternalPartyLoaded != null && !syncLoading && !syncItemExists(currentExternalPartyLoaded, item.id, syncItems) && canSync"
                    @click.stop="pushToParty(item)"
                    :title="'Upload to ' + currentExternalPartyLoaded"
                    :disabled="item.loadingCount > 0"
                    :loading="item.loadingCount > 0"
                    icon
                    key="1">
                    <v-icon
                        color="primary" 
                        small>mdi-cloud-upload</v-icon>
                </v-btn>
                <v-icon 
                    v-else-if="currentExternalPartyLoaded && !syncLoading && canSync"
                    color="primary"
                    small
                    title="Uploaded"
                    key="2"
                    class="mx-1">mdi-check</v-icon>
            
                <v-btn
                    v-if="isDeletableItem(item)"
                    icon
                    :disabled="!permittedToEdit || item.loadingCount > 0"
                    :loading="item.loadingCount > 0"
                    @click.stop="deleteItem(item)"
                    key="3">
                    <v-icon
                        color="primary" 
                        small>mdi-delete</v-icon>
                </v-btn>
            </v-slide-x-transition>
        </template>

        <template v-for="(filter, index) in displayFilters" v-slot:[`item.${filter}`]="{ item }">
            <td :key="index">
                <slot :name="filter" v-bind:item="item"></slot>
            </td>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="mz-0 pa-0">
                <slot name="expandedItem" v-bind:item="item"></slot>
            </td>
        </template>

    </v-data-table>
    <v-data-table          
        v-else-if="currentDisplay==='table'"
        :dense="dense"
        :items="filteredItems"
        :loading="isLoading"
        :hide-default-footer="hideFooter || useServerPagination"
        :hide-default-header="true"
        :expanded.sync="mobileExpandedItems"
        :single-expand="true"        
        :show-expand="canExpandMobileItems"
        :item-key="itemKey"
        :items-per-page="itemsPerPage"
        key="4">

        <template v-slot:item="{ item }">
            <v-list-item @click="rowSelected(item)">
                <slot name="mobileItem" v-bind:item="item"></slot>
                <v-list-item-action>
                    <slot name="itemActions" v-bind:item="item" />
                    <v-btn
                        v-if="currentExternalPartyLoaded && !syncLoading && !syncItemExists(currentExternalPartyLoaded, item.id, syncItems) && canSync"
                        @click.stop="pushToParty(item)"
                        :title="'Upload to ' + currentExternalPartyLoaded"
                        :disabled="item.loadingCount > 0"
                        :loading="item.loadingCount > 0">
                    <v-icon                 
                        color="primary" 
                        small>mdi-cloud-upload</v-icon>
                    </v-btn>                        
                    <v-icon 
                        v-else-if="currentExternalPartyLoaded && !syncLoading && canSync"
                        color="primary"
                        small
                        class="mx-1">mdi-check</v-icon>
                    <v-btn
                        v-if="isDeletableItem(item)"
                        icon
                        :disabled="!permittedToEdit || item.loadingCount > 0"
                        :loading="item.loadingCount > 0"
                        @click.stop="deleteItem(item)">
                        <v-icon                  
                            color="primary" 
                            small>mdi-delete</v-icon>
                    </v-btn>            
                </v-list-item-action>
            </v-list-item>
        </template>

        <template v-for="(filter, index) in displayFilters" v-slot:[`item.${filter}`]="{ item }">
            <td :key="index"><slot :name="filter" v-bind:item="item"></slot></td>            
        </template>

        <template v-slot:expanded-item="{ item }">
            <slot name="expandedMobileItem" v-bind:item="item"></slot>
        </template>

    </v-data-table>
    </v-slide-y-transition>

    <div v-if="useServerPagination && totalPages > 1">
        <v-divider class="mb-3" />
        <v-pagination            
            v-model="currentPage"
            :length="totalPages" />        
    </div>

    <v-overlay :value="isLoading" class="text-center">
      <v-progress-circular indeterminate size="64" />
      <p>Loading</p>
    </v-overlay>

    <v-dialog v-model="showDelete" max-width="500px">
        <v-card>
            <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-spacer></v-spacer>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-card>
</template>

<script>
import { roundUp } from '~helpers';

export default {
    name: 'BT-Items',
    components: {
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
        BTDialog: () => import('~components/BT-Dialog.vue')
    },
    data: function() {
        return {
            asyncItems: [],
            asyncExternalParties: [],
            selectedItem: null,
            showDelete: false,
            errorMessage: null,
            showError: false,     
            currentDisplay: null, // table/grid            
            isLoading: true,
            syncLoading: false,            
            currentExternalParty: null,  
            currentExternalPartyLoaded: null,
            availableExternalParties: [],
            syncItems: [],
            isNewDialog: false,
            showCreateDialog: false,
            searchString: null,
            searchInput: null,
            selectedFilters: [],
            originalFilters: [],
            isFilterChanged: false,
            currentPage: 1,
            totalPages: 0,
            showSearch: false,
            mobileExpandedItems: [],
        }
    },
    props: {
        //store-specific
        navigation: null,  
        syncNavigation: null, 
        
        //global-specific
        canDelete: {
            type: Boolean,
            default: true,
        },
        canCreate: {
            type: Boolean,
            default: true
        },
        canCreateDialog: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: true
        },
        canEditDialog: {
            type: Boolean,
            default: false
        },
        canChangeDisplay: {
            type: Boolean,
            default: true
        },
        canAct: {
            type: Boolean,
            default: false
        },
        
        canRefresh: {
            type: Boolean,
            default: true
        },
        canSearch: {
            type: Boolean,
            default: true
        },
        canSync: {
            type: Boolean,
            default: true
        },
        canExportCSV: {
            type: Boolean,
            default: true
        },
        confirmDelete: {
            type: Boolean,
            default: true
        },
        createButtonText: {
            type: String,
            default: 'New'
        },
        csvIncludeTitle: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: false
        },
        filters: {
            type: Array,
            default: null
        },
        displayMode: {
            type: String,
            default: 'table'
        },
        hideActions: {
            type: Boolean,
            default: false,
        },
        hideFilters: {
            type: Boolean,
            default: false
        },
        hideFooter: {
            type: Boolean,
            default: false
        },
        hideHeader: {
            type: Boolean,
            default: false
        },
        itemKey: {
            type: String,
            default: 'id'
        },
        items: {
            type: Array,
            default: null
        },
        label: {
            type: String,
            default: null
        },     
        loading: {
            type: Boolean,
            default: false,
        },  
        onCanDelete: {
            type: Function,
            default: null
        },
        onCanEdit: {
            type: Function,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },   
        onEdit: {
            type: Function,
            default: null
        },
        onDelete: {
            type: Function,
            default: null
        },
        onCreate: {
            type: Function,
            default: () => { return {} }
        },     
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        textCenter: {
            type: Boolean,
            default: false
        },
        useServerFilters: {
            type: Boolean,
            default: true
        },
        useServerPagination: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: 'auto'
        },
                                       

        //table-specific
        headers: {
            type: Array,
            default: null
        },
        displayFilters: { //for display column data
            type: Array,
            default: () => { return [] }
        },
        canExpandItems: {
            type: Boolean,
            default: false
        },
        canExpandMobileItems: {
            type: Boolean,
            default: false
        },
        hideTableHeader: {
            type: Boolean,
            default: false
        },
        itemsPerPage: {
            type: Number,
            default: 25
        },
        isSimple: {
            type: Boolean,
            default: false
        },
        showArchives: {
            type: Boolean,
            default: false
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        showToolbar: {
            type: Boolean,
            default: true
        },

        returnSelection: {
            type: Boolean,
            default: false
        },

        //grid-specific
        cols: {
            type: String,
            default: '12'
        },
        sm: {
            type: String,
            default: '4'
        },
        md: {
            type: String,
            default: '3'
        },
        lg: {
            type: String,
            default: '2'
        },
    },
    watch: {
        loading: function(val) {
            this.isLoading = val;
            this.$forceUpdate();
        },
        displayMode: function(val) {
            this.currentDisplay = val;
        },
        selectedFilters: function(val) {                
            this.isFilterChanged = JSON.stringify(val) != JSON.stringify(this.originalFilters);
        },
        currentPage: function() {
            this.pullItems();
        },
        items: function() {
            this.pullItems();
        },
        params: function() {
            //refresh when params are changed
            this.refresh();
        },
        proxyID: function() {
            this.refresh();
        }
    },
    mounted() {
        this.isLoading = this.loading;
        this.currentDisplay = this.displayMode;

        this.pullItems();
        this.pullExternalParties();
    },
    computed: {
        pageTitle() {
            if (this.label) {
                return this.label;
            }   
            else {
                return this.$BlitzIt.navigation.findDisplayName(this.navigation);
            }
        },
        filteredItems() {
            if (this.onFilter == null && this.asyncItems == null) {
                return [];
            }
            return this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            // var fList = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            // return fList.map(x => Object.assign(x, { loadingCount: 0 } ));
        },
        // displayFilters() {
        //     return this.headers.map(x => x.value);
        // },
        tableHeaders() {
            var rHeaders = [];
            if (this.isLengthyArray(this.headers)) {
                for (var i = 0; i < this.headers.length; i++) {
                    if (this.headers[i].text != null) {
                        rHeaders.push(this.headers[i]);
                    }
                }
            }
            
            //rHeaders = rHeaders.map(x => Object.assign(x, { value: 'data.' + x.value }));

            if (this.includeActions) {
                rHeaders.push({ text: 'Actions', value: 'actions', align: 'right' });
            }
            return rHeaders;
        },
        csvItems() {
            //return this.headers;
            return this.headers
                .filter(y => (y.csvText != null || y.text != null) && (y.csv != undefined || y.csvFilter != undefined))
                .map(z => { 
                    return { 
                        header: z.csvText != null ? z.csvText : z.text, 
                        value: (z.csv === true || z.csv == undefined) ? z.value : z.csv, 
                        valueFilter: z.csvFilter,
                        csvArray: z.csvArray === true ? true : false
                    }; 
                });
        },
        includeActions() {            
            if (this.hideActions) {
                return false;
            }

            return this.canDelete || this.canCreate || this.canAct || this.canEdit;
        },
        syncItemExists: () => (partyID, itemID, syncItems) => {
            if (syncItems != undefined && syncItems != null) {
                return syncItems.find(x => x.externalPartyID == partyID && x.localID == itemID) != null;
            }
            else {
                return false;
            }
        },
        chipFilters() {
            if (this.useServerFilters && this.navigation != null) {
                return this.$BlitzIt.store.filters(this.navigation);
            }
            else {
                return this.filters ? this.filters : [];
            }            
        },
        proxyCompanyID() {
            if (this.proxyID) {
                return this.proxyID;
            }
            else if (this.proxyIDParam && this.$route.params[this.proxyIDParam]) {
                return this.$route.params[this.proxyIDParam];
            }

            return null;
        },
        displayIsChangeable() {
            return this.canChangeDisplay; // && !this.isMobile;
        },
        isMobile() {
            return this.$vuetify.breakpoint.mobile;
        },
        archiveName() {
            return this.$BlitzIt.navigation.findArchiveName(this.navigation);
        },
        permittedToEdit() {
            return this.navigation != null ? this.$BlitzIt.auth.canEdit(this.navigation) : true;
        }
    },
    methods: {
        exportAsCSV() {
            if (this.isLengthyArray(this.asyncItems)) {
                var csvKeys = this.csvItems;

                if (!this.isLengthyArray(csvKeys)) {
                    csvKeys = Object.keys(this.asyncItems[0]);
                    csvKeys = csvKeys.map(x => { return { header: this.fromCamelCase(x), value: x }; });
                }

                var csvList = [];
                this.filteredItems.forEach(aItem => {
                    var newItem = {};
                    csvKeys.forEach(csvDNA => {
                        var v = null;
                        if (typeof(csvDNA.value) == 'function') {
                            //newItem[csvDNA.header] = csvDNA.value(aItem);
                            v = csvDNA.value(aItem);
                        }
                        else if (typeof(csvDNA.value) == 'string') {
                            v = this.getNestedValue(aItem, csvDNA.value);
                        }

                        if (v != null && csvDNA.valueFilter != null) {
                            v = this.$options.filters[csvDNA.valueFilter](v);
                        }

                        newItem[csvDNA.header] = v;
                    });
                    csvList.push(newItem);
                });
                this.generateCSVFile(csvList, 'csvData.csv', csvKeys.map(x => x.header), this.csvIncludeTitle ? this.pageTitle : null);
            }
        },
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.isLoading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.isLoading = false;
            this.$forceUpdate();
        },
        pullExternalParties() {
            if (!this.canSync || !this.syncNavigation) {
                this.asyncExternalParties = [];
                return;
            }

            var eParties = this.$BlitzIt.navigation.findExternalParties(this.syncNavigation);

            if (this.isLengthyArray(eParties)) {
                var self = this;
                self.syncLoading = true;

                var extPath = this.$BlitzIt.navigation.findExternalPartyCredentialNavigation(this.syncNavigation);

                if (!extPath) {
                    this.asyncExternalParties = [];
                }

                    this.$BlitzIt.store.getAll(extPath)
                        .then(res => {
                            var rList = [];                           
                            for (let i = 0; i < res.length; i++) {
                                const element = res[i];
                                var cred = eParties.find(x => x === element.externalPartyOptionID);
                                if (cred) {
                                    rList.push(cred);
                                }
                            }

                            self.asyncExternalParties = rList;
                        })   
                        .catch(err => {
                            self.formError(err);
                            self.asyncExternalParties = null;
                        })                         
                        .finally(() => {
                            self.syncLoading = false;
                        })
                }
                else {
                    return [];
                }
        },
        pullItems(refresh = false) {
            if (this.items != null) {
                this.asyncItems = this.items.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));
                return;
            }

            var paramObj = this.params ? this.copyDeep(this.params) : {};
            if (this.useServerPagination) {
                paramObj.includeCount = true;
            }

            if (this.searchString && this.searchString.length > 0) {
                paramObj.searchString = this.searchString;
            }
            if (this.selectedFilters && this.selectedFilters.length > 0) {
                var fList = [];                    
                for (let i = 0; i < this.selectedFilters.length; i++) {
                    const fItem = this.selectedFilters[i];
                    fList.push(this.chipFilters[fItem]);
                }
                paramObj.filterBy = fList.toString();
            }

            if (this.useServerPagination) {
                //add takeAmount, etc
                paramObj.takeAmount = this.itemsPerPage;
                paramObj.takeFrom = (this.currentPage - 1) * this.itemsPerPage;
            }

            var self = this;
            self.startLoading();
            this.$BlitzIt.store.getAll(this.navigation, paramObj, refresh, self.proxyCompanyID)
                .then(res => {
                    self.asyncItems = res.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));
                    self.$emit('fetched', self.asyncItems);
                    self.loadCount();
                })
                .catch(err => { self.formError(err); })
                .finally(() => { self.endLoading(); })
        },
        rowSelected(item) {            
            if (this.isEditableItem(item)) {
                this.navToEdit(item);
            }
            else if (this.returnSelection) {
                this.$emit('select', item);
            }
            else {
                //maybe try expand?
                if (this.isMobile && this.canExpandMobileItems || this.canExpandItems) {
                    var ind = this.mobileExpandedItems.findIndex(y => y.productID == item.productID);
                    if (ind >= 0) {
                        this.mobileExpandedItems.splice(ind, 1);
                    }
                    else {
                        this.mobileExpandedItems = [item];
                    }
                }
            }
        },
        loadCount() {
            var cnt = this.$BlitzIt.store.getLocalCount(this.navigation);
            this.totalPages = roundUp(cnt / this.itemsPerPage);
        },
        toggleDisplayMode() {
            this.currentDisplay = this.currentDisplay === 'grid' ? 'table' : 'grid';
        },
        syncExists(partyID, itemID) {
            if (this.syncItems != undefined && this.syncItems != null) {
                return this.syncItems.find(x => x.externalPartyID == partyID && x.localID == itemID) != null;
            }
            else {
                return false;
            }
        },
        isEditableItem(item) {            
            if (!this.canEdit) {
                return false;
            }
            if (this.onCanEdit) {                
                return this.onCanEdit(item);
            }
            else {
                return this.canEdit;
            }
        },
        isDeletableItem(item) {
            if (!this.canDelete) {
                return false;
            }
            if (this.onCanDelete) {
                return this.onCanDelete(item);
            }
            else {
                return this.canDelete;
            }
        },
        navToArchives() {
            if (this.navigation != null) {
                var archiveName = this.$BlitzIt.navigation.findArchiveName(this.navigation);                
                if (archiveName != null) {
                    this.$router.push({ name: archiveName });
                }
            }            
        },
        navToNew() {
            var params = { id: 'new' };
            if (this.proxyCompanyID != null) {
                params[this.proxyIDParam] = this.proxyCompanyID;
            }
            
            this.$router.push({ name: this.$BlitzIt.navigation.findSingleName(this.navigation), params });
        },        
        navToEdit(item) {
            if (this.canEditDialog) {
                this.openDialog(this.copyDeep(item));
            }
            else if (this.onEdit) {
                this.onEdit(item);
            }
            else {
                var params = { id: item.id };
                if (this.proxyCompanyID != null) {
                    params[this.proxyIDParam] = this.proxyCompanyID;
                }
            
                var singleName = this.$BlitzIt.navigation.findSingleName(this.navigation);
                if (singleName != this.navigation) {
                    this.$router.push({ name: singleName, params});
                }
            }
        },
        deleteItem(item) {
            console.log('dd');
            this.selectedItem = item;
            if (this.confirmDelete) {
                this.showDelete = true;
            }
            else {
                this.deleteItemConfirm();
            }
        },
        deleteItemConfirm() {
            var self = this;
            var item = this.selectedItem;
            
            if (this.onDelete) {
                item.loadingCount += 1;
                this.$forceUpdate();
                this.onDelete(item);
                if (item != null) {
                    item.loadingCount -= 1;
                }
            }
            else {
                if (this.navigation != null) {
                    var asyncInd = this.asyncItems.findIndex(y => y.id == item.id);
                    item.loadingCount += 1;
                    this.$forceUpdate();

                    this.$BlitzIt.store.delete(this.navigation, item.id, this.proxyCompanyID)
                        .then(() => {
                            self.asyncItems.splice(asyncInd, 1);
                        })
                        .catch(err => { 
                            self.formError(err); 
                        })
                        .finally(() => { 
                            if (item != null) {
                                item.loadingCount -= 1; 
                            }
                        })
                }       
                else {
                    var ind = this.items.findIndex(y => y.id == item.id);
                    if (ind >= 0) {
                        this.items.splice(ind, 1);
                    }
                }
            }
            this.showDelete = false;
        },
        closeDelete() {
            this.selectedItem = null;
            this.showDelete = false;
        },
        reset() {
            this.currentPage = 1;
            this.totalPages = 0;
            this.originalFilters = [];
            this.selectedFilters = [];
            this.searchString = null;
            this.searchInput = null;
            this.$BlitzIt.store.clear(this.navigation);
            this.pullItems(true);
        },
        refresh() {
            this.currentPage = 1;
            this.totalPages = 0;
            this.originalFilters = this.selectedFilters;
            this.isFilterChanged = JSON.stringify(this.selectedFilters) != JSON.stringify(this.originalFilters);
            this.searchString = this.searchInput;
            this.showSearch = false;
            this.showError = false;
            this.errorMessage = null;

            //reset syncing
            this.currentExternalPartyLoaded = null;
            this.currentExternalParty = null;
            this.syncItems = null;

            this.pullItems(true);
        },
        loadSyncItems() {
            if (this.currentExternalParty != this.currentExternalPartyLoaded) {
                //change to current external party
                if (this.syncItems == null || this.syncItems.length == 0) {
                    var itemIDs = this.asyncItems.map(x => x.id);

                    this.$BlitzIt.store.getSyncItems(this.syncNavigation, [this.currentExternalParty], itemIDs)
                        .then(res => {                            
                            this.syncItems = res;
                            this.currentExternalPartyLoaded = this.currentExternalParty;
                        })

                }
            }
        },
        async pushToParty(item) {
            item.loadingCount += 1;
            this.$forceUpdate();
            try {
                var returnItem = await this.$BlitzIt.store.pushSyncItem(this.syncNavigation, item.id, this.currentExternalPartyLoaded);
                console.log(returnItem);
                if (returnItem.message != null) {
                        item.errorMsg = returnItem.message;
                    }
                    else {
                        this.syncItems.push({ localID: item.id, externalPartyID: this.currentExternalPartyLoaded });
                    }
                    console.log('t');
                    console.log(item);
            }
            catch (err) {
                this.formError(err);
            }
            
            item.loadingCount -= 1;
            this.$forceUpdate();
        },
        openDialog(item) {
            if (!item) {
                this.isNewDialog = true;
                item = this.onCreate();
                this.selectedItem = item;
            }
            else {
                this.isNewDialog = false;
                this.selectedItem = item;
                this.showCreateDialog = true;
            }            
        },
        closeDialog() {
            this.showCreateDialog = false;
        },
        saveDialogItem(itemToSave) {
            var self = this;
            self.startLoading();

             if (this.isNewDialog) {
                    this.$BlitzIt.store.post(this.navigation, itemToSave, this.proxyCompanyID)
                        .then(res => { self.asyncItems.unshift(res); })
                        .catch(err => { self.formError(err); })
                        .finally(() => { 
                            self.closeDialog();
                            self.endLoading();
                        })
                }
                else {                    
                    this.$BlitzIt.store.patch(this.navigation, itemToSave, this.proxyCompanyID)
                        .then(res => { itemToSave.rowVersion = res.rowVersion; })
                        .catch(err => { self.formError(err); })
                        .finally(() => { 
                            self.closeDialog();
                            self.endLoading();
                        })
                }
        }
    }
}
</script>