<template>
  <v-container 
    fluid 
    :no-gutters="noGutters"
    :height="height"
    class="overflow-y-auto"
    :class="containerClass">
    <v-toolbar v-if="showToolbar" flat>
      <v-toolbar-title>{{ pageTitle }}</v-toolbar-title>
      <v-divider class="ml-4" inset vertical />
      <slot name="actions" />
      <v-spacer />
      <v-btn v-if="canCreate" color="primary" @click="navToNew"><v-icon>mid-plus</v-icon></v-btn>
    </v-toolbar>
    <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
    <div v-if="componentData != null && componentData.data != null">
      <slot v-bind:item="componentData" />
    </div>
    <div v-else>
      <v-card class="mx-auto mt-5">
        <v-card-title>Nothing Found</v-card-title>
      </v-card>
    </div>
    <v-overlay :value="loading" absolute class="text-center">
      <v-progress-circular indeterminate size="64" />
      <p>Loading</p>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: "BT-Component",
  data: function () {
    return {
      asyncItem: null,
      isLoading: false,
      dataVersion: 0,
      errorMessage: null,
      showError: false
    };
  },
  props: {
    containerClass: {
      type: String,
      default: null
    },
    itemID: {
        type: String,
        default: null
    },
    height: {
      type: String,
      default: '100%'
    },
    isIndependent: { //whether to retrieve from the store or raw api
      type: Boolean,
      default: true
    },
    isSingle: {
        type: Boolean,
        default: false
    },
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    showToolbar: {
      type: Boolean,
      default: false,
    },
    onFilter: {
      type: Function,
      default: null,
    },
    navigation: {
      type: String,
      default: null,
    },
    noGutters: {
      type: Boolean,
      default: false
    },
    params: {
      type: Object,
      default: null
    },
    canCreate: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.pullItem();
  },
  watch: {
    loading: function (val) {
      this.isLoading = val;
      this.$forceUpdate();
    },
    itemID: function() {
      this.pullItem(true);
    },
    params: function() {
      this.pullItem(true);
    }
  }, 
  computed: {
    filteredItems() {
        //return [];    
        if (this.asyncItem) {
          return this.onFilter ? this.onFilter(this.asyncItem) : this.asyncItem;
        } 
        else {
          return this.isSingle ? null : [];
        }         
    },
    componentData() {
      return {
        data: this.filteredItems,
      };
    },
    pageTitle() {
      if (this.title) {
        return this.title;
      } else {
        return this.$BlitzIt.navigation.findDisplayName(this.navigation);
      }
    },
  },
  methods: {
    formError(err) {
      this.showError = true;
      this.errorMessage = this.extractErrorDescription(err);
    },
    startLoading() {
        this.isLoading = true;
        this.$forceUpdate();
    },
    endLoading() {
        this.isLoading = false;
        this.$forceUpdate();
    },
    refresh() {
      this.dataVersion = this.dataVersion + 1;
    },
    navToNew() {
      this.$router.push({
        name: this.$BlitzIt.navigation.findSingleName(this.navigation),
        params: { id: "new" },
      });
    },
    async pullItem(refresh = false) {
      if (!refresh && this.asyncItem != null) {
        return;
      }

      this.startLoading();
      try {
        if (this.itemID != null) {
          if (this.isIndependent) {
            var res = await this.$BlitzIt.api.getById(this.navigation, this.itemID, this.params)
            this.asyncItem = res.data.data;
            //this.$emit('fetched', this.asyncItem);
          }
          else {
            this.asyncItem = await this.$BlitzIt.store.get(this.navigation, this.itemID, this.params, refresh);
          }
        }
        else if (!this.isSingle) {
          if (this.isIndependent) {
            var resOne = await this.$BlitzIt.api.getAll(this.navigation, this.params);
            this.asyncItem = resOne.data.data;
          }
          else {
            this.asyncItem = await this.$BlitzIt.store.getAll(this.navigation, this.params, refresh);
          }
        }
        else {
          this.asyncItem = null;
        }

        if (this.asyncItem != null) {
          this.$emit('fetched', this.asyncItem);
        }
      }
      catch (err) {
        this.formError(err);
      }
      finally {
        this.endLoading();
      }
    },
    // pullItem() {   
    //     var self = this;
    //     self.isLoading = true;   
        
    //     if (self.itemID) {          
    //       this.$BlitzIt.api.getById(self.navigation, self.itemID, { includeDetails: true })
    //         .then(res => { 
    //           self.asyncItem = res.data.data;
    //           this.$emit('fetched', res.data.data);              
    //         })
    //         .catch(err => { self.formError(err); })
    //         .finally(() => { self.isLoading = false; });                    
    //     }
    //     else if (!self.isSingle) {            
    //       this.$BlitzIt.api.getAll(self.navigation, self.params)
    //         .then(res => {               
    //           self.asyncItem = res.data.data;
    //           this.$emit('fetched', res.data.data);              
    //         })
    //         .catch(err => { self.formError(err); })
    //         .finally(() => { self.isLoading = false; });
    //     }   
    //     else {
    //       this.asyncItem = null;
    //       this.isLoading = false;
    //     }           
    // }
  }
}
</script>