import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import colors from 'vuetify/lib/util/colors';
import { getCosmetics } from '../tools/blitzit/blitzitstyles';

Vue.use(Vuetify);

const cosmetics = getCosmetics()

export default new Vuetify({
    icons: {
      iconfont: 'mdiSvg'
    },
    // treeShake: true,
    // customVariables: [
    //   '~/scss/variables.scss'
    // ],
    
    theme: {
        dark: cosmetics.theme.dark,
        disable: false,
        options: {
          cspNonce: undefined,
          customProperties: undefined,
          minifyTheme: undefined,
          themeCache: undefined,
          variations: true,
        },
        themes: {
          light: cosmetics.light,
          dark: cosmetics.dark,
          // // options: { customProperties: true },
          // light: {
          //   primary: '#2a76a2', //#1f5a7c
          //   secondary: '#192233',
          //   accent: '#82B1FF',
          //   error: '#FF5252',
          //   info: '#2196F3',
          //   success: '#4CAF50',
          //   warning: '#FB8C00',
          //   'bg-btn': '#192233',
          //   'fg-btn': '#ffffff',
          //   'bg-list': colors.white,
          //   'fg-list': null,
          //   'bg-list-item': null,
          //   'fg-list-item': '#192233',
          //   'bg-primary': '#192233',
          //   'fg-primary': '#ffffff',
          //   'bg-toolbar': '#192233',
          //   'fg-toolbar': '#ffffff',
          //   'faded-text': colors.grey.darken2 //'#555555'
          // },
          // dark: {
          //   primary: '#2a76a2', //'#1f5a7c',
          //   secondary: '#192233', //'#424242',
          //   accent: '#FF4081',
          //   error: '#FF5252',
          //   info: '#2196F3',
          //   success: '#4CAF50',
          //   warning: '#FB8C00',
          //   'bg-btn': '#192233',
          //   'fg-btn': '#ffffff',
          //   'bg-list': '#192233',
          //   'fg-list': colors.white,
          //   'bg-list-item': '#192233',
          //   'fg-list-item': colors.white,
          //   'bg-primary': '#192233',
          //   'fg-primary': '#ffffff',
          //   'bg-toolbar': '#192233',
          //   'fg-toolbar': '#ffffff',
          //   'faded-text': colors.grey //'#757575'
          // },
        }
      },
    breakpoint: {
      mobileBreakpoint: 580
    }
});
