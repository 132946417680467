<template>
    <v-expansion-panel @change="maybeRefresh">
        <v-expansion-panel-header>
            <template v-slot:default="{ open }">
                <v-row class="mr-2">
                    {{ label }}
                    <slot name="header">
                    <v-btn
                        v-if="open && canRefresh"
                        icon
                        @click.stop="refresh">
                        <v-icon small>mdi-refresh</v-icon>
                    </v-btn>
                </slot>
                </v-row>
            </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="ma-0 pa-0">
            <v-card
                class="overflow-y-auto mx-auto ma-0 pa-0"
                :width="width"
                :max-height="height"
                :loading="isLoading"
                :flat="flat"
                :dense="dense">
                <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
                <slot name="items" v-bind:items="filteredItems">
                    <v-slide-x-transition group>
                    <v-list 
                        v-if="isLengthyArray(filteredItems)" 
                        :flat="flat" 
                        key="kk" 
                        :dense="dense">
                        <v-list-item-group
                            :active-class="activeClass"
                            @change="update">
                            <template v-for="(item, index) in filteredItems">
                                <v-list-item
                                    v-if="item != null"
                                    :active-class="activeClass"
                                    :class="listItemClass"
                                    :key="index"
                                    :two-line="twoLine"
                                    :three-line="threeLine"
                                    :dense="dense"
                                    :value="item">
                                    <slot v-bind:item="item" />
                                </v-list-item>
                                <v-divider v-if="dividers" :key="'d' + index" />
                            </template>
                        </v-list-item-group>
                    </v-list>
                    </v-slide-x-transition>
                </slot>
                
                <v-dialog v-model="showDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

            </v-card>
        </v-expansion-panel-content>
        
    </v-expansion-panel>
</template>

<script>
export default {
    name: 'BT-Expansion-Panel-List',
    data: () => {
        return {
            asyncItems: [],
            errorMessage: null,
            isLoading: false,
            mCustomUrl: null,
            mItemID: null,
            mItems: null,
            mParams: { includeDetails: false },
            showError: false,
            showDelete: false,
        }
    },
    props: {
        activeClass: {
            type: String,
            default: null
        },
        bladesData: {
            type: Object,
            default: null
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        canRefresh: {
            type: Boolean,
            default: false
        },
        canSelect: {
            type: Boolean,
            default: true
        },
        currentPanel: null,
        customURL: {
            type: String,
            default: null
        },
        dense: {
            type: Boolean,
            default: false
        },
        dividers: {
            type: Boolean,
            default: true
        },
        flat: {
            type: Boolean,
            default: true
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        items: {
            type: Array,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        itemID: {
            type: String,
            default: null
        },
        height: {
            type: String,
            default: 'auto'
        },
        listItemClass: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        navigation: {
            type: String,
            default: null
        },
        onCanPull: {
            type: Function,
            default: null
        },
        onCanSelect: {
            type: Function,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        onSelect: {
            type: Function,
            default: null
        },
        panel: {
            type: String,
            default: null
        },
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        twoLine: {
            type: Boolean,
            default: false
        },
        threeLine: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: 'auto'
        },
    },
    watch: {
        items: function() {
            console.log('toggling items');
            this.maybeRefresh();
        },
        itemID: function() {
            console.log('toggling itemID');
            this.maybeRefresh();
        },
        loading: function(val) {
            this.isLoading = val;
            this.$forceUpdate();
        },
        customURL() {
            this.maybeRefresh();
        },
        panel() {
            this.maybeRefresh();
        },
        params: function() {
            this.maybeRefresh();
        },
        refreshToggle: function() {
            console.log('toggling refresh');
            this.refresh();
        },
    },
    created() {
        this.mParams = this.params;
    },
    mounted() {
        this.maybeRefresh();
    },
    computed: {
        filteredItems() {
            return this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
        },
        isOpen() {
            return this.currentPanel == this.panel;
        }
    },   
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.isLoading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.isLoading = false;
            this.$forceUpdate();
        },
        closeDelete() {
            this.selectedItem = null;
            this.showDelete = false;
        },
        deleteItem(item) {
            this.selectedItem = item;
            this.showDelete = true;
        },
        async deleteItemConfirm() {
            var item = this.selectedItem;

            var asyncInd = this.asyncItems.findIndex(y => y.id == item.id);
            if (this.navigation != null) {
                try {
                    this.startLoading();
                    await this.$BlitzIt.store.delete(this.navigation, item.id, null);
                }
                catch (err) {
                    this.formError(err);
                }
                finally {
                    if (asyncInd > -1) {
                        this.asyncItems.splice(asyncInd, 1);
                    }
                    this.endLoading();
                }
            }
            else {
                var ind = this.items.findIndex(y => y.id == item.id);
                if (ind >= 0) {
                    this.items.splice(ind, 1);
                }
                if (asyncInd > -1) {
                    this.asyncItems.splice(asyncInd, 1);
                }
            }

            this.showDelete = false;
        },
        maybeRefresh() {
            console.log('maybe refresh');
            if (JSON.stringify(this.params) != JSON.stringify(this.mParams) ||
                this.mCustomUrl != this.customURL ||
                this.mItemID != this.itemID ||
                JSON.stringify(this.mItems) != JSON.stringify(this.items)) {
                if (this.isOpen) {
                    console.log('pulling');
                    this.pullItems(false);
                }
                else {
                    console.log('no open');
                }
            }
            else {
                console.log('no');
            }
        },
        async pullItems(refresh = false) {
            if (this.onCanPull != null && !this.onCanPull()) {
                return;
            }

            if (this.items != null) {
                this.asyncItems = this.items.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));
                return;
            }

            if (this.navigation == null) {
                return;
            }

            try {
                this.startLoading();
                var res = null;

                if (this.isSingle) {
                    res = await this.$BlitzIt.store.get(this.navigation, this.itemID, this.params, refresh, null, this.customURL);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, this.params, refresh, null, this.customURL);
                }
                
                res = res.map(x => Object.assign(x, { loadingCount: 0, errorMsg: null }));

                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res);
                }
                else {
                    this.asyncItems = res;
                }

                this.mParams = this.copyDeep(this.params);
                this.mItems = this.copyDeep(this.items);
                this.mItemID = this.copyDeep(this.itemID);
                this.mCustomUrl = this.copyDeep(this.customURL);

                this.$emit('fetched', this.asyncItems);
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
        },
        refresh() {
            this.pullItems(true);
            this.showError = false;
            this.errorMessage = null;
        },
        selectItem(item) {
            console.log('select item');
            if (!this.canSelect || (this.onCanSelect != null && !this.onCanSelect(item))) {
                return;
            }
            console.log('yes');
            if (this.onSelect != null) {
                this.onSelect(item);
            }
        },
        update(item) {
            if (item == null) {
                return;
            }
            
            var v = null;
            if (this.returnObject) {
                v = item;
            }
            else if (this.itemValue) {
                v = item[this.itemValue];
            }
            else {
                v = item;
            }

            this.$emit('input', v);
            this.$emit('change', v);
            this.selectItem(v);
        }
    } 
}
</script>