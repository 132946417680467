<template>
    <div
        v-if="bladesData == null"
        style="height: 100%"
        :width="defaultPageWidth">
        <v-card
            class="ma-0 pa-0"
            :class="pageCardClass"
            :dark="isBladeDark"
            :flat="flat"
            :light="isBladeLight"
            :style="pageCardStyle"
            :tile="tile"
            :width="defaultPageWidth">
                <v-toolbar v-if="!hideBladeHeader" :class="toolbarClass" :dark="toolbarClass != null" :dense="dense" :flat="flat">
                    <v-btn
                        v-if="!hideBackButton"
                        icon
                        :large="large"
                        :small="compSmall"
                        title="Back"
                        @click="$BlitzIt.navigation.navBack()"><v-icon :large="large" :small="compSmall">mdi-arrow-left</v-icon>
                    </v-btn>
                    <slot name="toolbar" v-bind:data="data">
                        <v-toolbar-title key="3">{{ title }}</v-toolbar-title>
                    </slot>
                    <slot name="toolbar-left" v-bind:data="data">

                    </slot>
                    <v-spacer key="4" />
                    <slot name="toolbar-right" v-bind:data="data" v-bind:add="add">

                    </slot>
                    <v-btn
                        v-if="showRefreshButton"
                        class="v-step-blade-actions "
                        icon
                        :large="large"
                        :small="compSmall"
                        title="Refresh"
                        @click="toggleRefresh"
                        key="5">
                        <v-icon :small="compSmall" :large="large">mdi-refresh</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="showAddButton"
                        
                        :disabled="!$canEdit(navigation)"
                        icon
                        :small="compSmall"
                        :large="large"
                        title="Add"
                        @click="add"
                        key="6">
                        <v-icon :small="compSmall" :large="large">mdi-plus</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="showEditButton"
                        
                        :disabled="!$canEdit(navigation)"
                        icon
                        :large="large"
                        :small="compSmall"
                        title="Edit"
                        @click="toggleEdit"
                        key="7">
                        <v-icon :small="compSmall" :large="large">{{ isEditing ? 'mdi-pencil-off' : 'mdi-pencil' }}</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="showDeleteButton"
                        
                        :disabled="!$canEdit(navigation)"
                        icon
                        :small="compSmall"
                        :large="large"
                        title="Delete"
                        @click="toggleDelete"
                        key="8">
                        <v-icon :small="compSmall" :large="large">mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="showRestoreButton"
                        
                        :disabled="!$canEdit(navigation)"
                        icon
                        :small="compSmall"
                        :large="large"
                        title="Restore"
                        @click="toggleRestore"
                        key="9">
                        <v-icon :small="compSmall" :large="large">mdi-eraser-variant</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="tourExists"
                        
                        icon
                        :large="large"
                        :small="compSmall"
                        title="Guided Tour"
                        @click="$startTour(tourName)">
                        <v-icon :large="large" :small="compSmall">mdi-help-circle</v-icon>
                    </v-btn>
                    <v-menu 
                        v-if="showSettings"
                        offset-y 
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                icon
                                v-bind="attrs"
                                v-on="on"
                                :large="large"
                                title="Settings"
                                :small="compSmall">
                                <v-icon :large="large" :small="compSmall">mdi-cog</v-icon>
                            </v-btn>
                        </template>
                        <v-list :dense="dense">
                            <slot name="bladeSettings" v-bind:data="data" />
                        </v-list>
                    </v-menu>
                    <slot name="toolbar-right-far" v-bind:data="data" v-bind:add="add">

                    </slot>
                </v-toolbar>
                <v-card :class="pageInnerCardClass">
                    <slot v-bind:data="data" :editToggle="editToggle" />
                </v-card>
            <v-overlay :value="isLoading || loadingMsg != null" absolute class="text-center">
                <v-progress-circular indeterminate size="64" />
                <p>{{ loadingMsg || 'Loading' }}</p>
            </v-overlay>
        </v-card>
        <slot name="page" v-bind:data="data" />
    </div>
    <v-col 
        v-else-if="bladesData != null && showBlade"
        :class="bladeColClass"
        :cols="flexColumn ? null : 'auto'"
        :dense="dense"
        :style="bladeColStyle">
        <v-card
            class="minimizable my-0 pa-0"
            :dark="isBladeDark"
            :flat="flat"
            :light="isBladeLight"
            :style="bladeCardStyle"
            :tile="tile">
            <!-- :class="bladeCardClass" -->
            <!-- width="auto" -->
            <v-slide-x-transition>
                <v-card-text v-if="isMinimized" class="pa-0" fill-height>
                    <v-toolbar :dense="dense" :class="toolbarClass" :dark="toolbarClass != null" class="v-step-blade-actions" :flat="flat">
                        <v-btn 
                            icon
                            :large="large"
                            :small="compSmall"
                            title="Open"
                            @click="maximize">
                            <v-icon :large="large" :small="compSmall">mdi-arrow-right</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <div class="vertical-txt" @click="maximize">
                        {{ title }}
                    </div>

                    <v-toolbar :dense="dense" bottom absolute  :class="toolbarClass" :dark="toolbarClass != null" :flat="flat">
                        <v-btn 
                            v-if="showCloseButton"
                            
                            icon
                            :large="large"
                            :small="compSmall"
                            @click="close">
                            <v-icon :large="large" :small="compSmall">mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                    
                </v-card-text>
                <v-card-text v-else class="pa-0" full-width>
                    <v-toolbar v-if="!hideBladeHeader && !isChildTab" :class="toolbarClass" :dark="toolbarClass != null" :dense="dense" :flat="flat">
                        <v-slide-x-transition>
                            <v-btn 
                                v-if="bladesData != null && !cIsPinned && canMinimize"
                                
                                icon
                                :large="large"
                                :small="compSmall"
                                title="Minimize Blade"
                                @click="minimize"
                                key="1">
                                <v-icon :large="large" :small="compSmall">mdi-minus</v-icon>
                            </v-btn>
                        </v-slide-x-transition>
                        <v-btn
                            v-if="showCloseButton"
                            
                            icon
                            :large="large"
                            :small="compSmall"
                            title="Close Blade"
                            @click="close"
                            key="2">
                            <v-icon :large="large" :small="compSmall">mdi-close</v-icon>
                        </v-btn>
                        <slot name="toolbar">
                            <v-toolbar-title key="3">
                                {{ title }}
                            </v-toolbar-title>
                        </slot>
                        <slot name="toolbar-left" v-bind:data="data">

                        </slot>
                        <v-spacer key="4"/>
                        <slot name="toolbar-right" v-bind:data="data" v-bind:add="add">
                        </slot>
                        <v-btn
                            v-if="showRefreshButton"
                            
                            icon
                            :large="large"
                            :small="compSmall"
                            title="Refresh"
                            @click="toggleRefresh"
                            key="5">
                            <v-icon :large="large" :small="compSmall">mdi-refresh</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="showAddButton"
                            
                            icon
                            :large="large"
                            :small="compSmall"
                            title="Add"
                            @click="add"
                            key="6">
                            <v-icon :large="large" :small="compSmall">mdi-plus</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="showEditButton"
                            
                            icon
                            :large="large"
                            :small="compSmall"
                            title="Edit"
                            @click="toggleEdit"
                            key="7">
                            <v-icon :large="large" :small="compSmall">{{ isEditing ? 'mdi-pencil-off' : 'mdi-pencil' }}</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="showDeleteButton"
                            
                            icon
                            :large="large"
                            :small="compSmall"
                            title="Delete"
                            @click="toggleDelete"
                            key="8">
                            <v-icon :large="large" :small="compSmall">mdi-delete</v-icon>
                        </v-btn>
                        <v-btn
                            v-if="tourExists"
                            
                            icon
                            :large="large"
                            :small="compSmall"
                            title="Guided Tour"
                            @click="$startTour(tourName)">
                            <v-icon :large="large" :small="compSmall">mdi-help-circle</v-icon>
                        </v-btn>
                        <v-menu
                            v-if="showSettings"
                            offset-y 
                            :close-on-content-click="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn 
                                    
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    title="Settings"
                                    :large="large"
                                    :small="compSmall">
                                    <v-icon :large="large" :small="compSmall">mdi-cog</v-icon>
                                </v-btn>
                            </template>
                            <v-list :dense="dense">
                                <slot name="bladeSettings" v-bind:data="data" />
                            </v-list>
                        </v-menu>
                        <v-btn
                            v-if="bladesData != null && !cIsPinned && canPin"
                            
                            icon
                            :large="large"
                            :small="compSmall"
                            title="Pin Blade"
                            @click="pin"
                            key="9">
                            <v-icon :large="large" :small="compSmall">mdi-pin</v-icon>
                        </v-btn>
                        <v-btn
                            v-else-if="bladesData != null && !isPinned && canPin"
                            
                            icon
                            :large="large"
                            :small="compSmall"
                            title="Unpin Blade"
                            @click="unpin"
                            key="10">
                            <v-icon :large="large" :small="compSmall">mdi-pin-off</v-icon>
                        </v-btn>
                        <slot name="toolbar-right-far" v-bind:data="data" v-bind:add="add">
                        </slot>
                    </v-toolbar>
                    <v-card ref="one" :class="bladeInnerCardClass">
                        <slot v-bind:data="data" v-bind:add="add" v-bind:refresh="toggleRefresh" />
                    </v-card>
                </v-card-text>
            </v-slide-x-transition>
            <slot name="page" v-bind:data="data" />
            <v-overlay :value="isLoading || loadingMsg != null" absolute class="text-center">
                <v-progress-circular indeterminate size="64" />
                <p>{{ loadingMsg || 'Loading' }}</p>
            </v-overlay>
        </v-card>
        <div
            v-if="!isMinimized && resizable" 
            class="resizable-r" 
            @mousedown="onMouseDown" />
    </v-col>
</template>

<script>

export default {
    name: 'BT-Blade',
    data: function() {
        return {
            bladeData: null,
            calcMap: 0b1111,
            isDown: false,
            isEditing: false,
            isLoading: false,
            isMinimized: false,
            liveWidth: 0,
            mIsPinned: false,
            isResizing: false,
        }
    },
    props: {
        addBladeName: null,
        addTo: null,
        addTrainingBladeName: null,
        // bladeClass: {
        //     type: String,
        //     default: null
        // },
        bladesData: {
            type: Object,
            default: null
        },
        bladeName: null,
        bladesToClear: {
            type: Array,
            default: null
        },
        canAdd: {
            type: Boolean,
            default: false
        },
        canClose: {
            type: Boolean,
            default: true
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        canEdit: {
            type: Boolean,
            default: false
        },
        canMinimize: {
            type: Boolean,
            default: true
        },
        canPin: {
            type: Boolean,
            default: true
        },
        canRefresh: {
            type: Boolean,
            default: true,
        },
        canResize: {
            type: Boolean,
            default: true
        },
        canRestore: {
            type: Boolean,
            default: false
        },
        clearBlades: {
            type: Boolean,
            default: false
        },
        clearOtherBlades: {
            type: Boolean,
            default: false
        },
        colClass: {
            type: String,
            default: 'my-0 pa-0 ml-0 mr-1'
        },
        customBladeData: {
            type: Object,
            default: null
        },
        defaultBladeWidth: {
            type: Number,
            default: 350
        },
        defaultPageWidth: {
            type: String,
            default: '100%'
        },
        dense: {
            type: Boolean,
            default: true
        },
        dependantBlades: {
            type: Array,
            default: () => { return [] } //[String]
        },
        editToggle: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        flexColumn: {
            type: Boolean,
            default: false
        },
        getNewBladeData: {
            type: Function,
            default: null
        },
        getNewQuery: {
            type: Function,
            default: null
        },
        hideBackButton: {
            type: Boolean,
            default: false
        },
        hideBladeHeader: {
            type: Boolean,
            default: false
        },
        isChildBlade: {
            type: Boolean,
            default: false
        },
        isPinned: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        loadingMsg: {
            type: String,
            default: null
        },
        minimizeOnSelect: {
            type: Boolean,
            default: false
        },
        navigation: {
            type: String,
            default: null,
        },
        pageColor: {
            type: String,
            default: null
        },
        pageVariant: { //default, transparent, opaque, light, dark
            type: String,
            default: 'opaque'
        },
        permanent: {
            type: Boolean,
            default: false
        },
        proxyID: {
            type: String,
            default: null
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        resetToggle: {
            type: Boolean,
            default: false
        },
        resizable: {
            type: Boolean,
            default: true
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        large: {
            type: Boolean,
            default: null
        },
        scroll: {
            type: Boolean,
            default: true
        },
        small: {
            type: Boolean,
            default: null
        },
        startEditing: {
            type: Boolean,
            default: true
        },
        tile: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        toolbarClass: {
            type: String,
            default: 'primary lighten-1' //'bg-toolbar lighten-1 fg-toolbar--text'
        },
        tourStatus: {
            type: String,
            default: null
        },
        updateDependants: {
            type: Boolean,
            default: true
        }
    },
    watch: {
        customBladeData: function(val) {
            if (val != null) {
                this.updateBlade(val);
            }
        },
        editToggle: function() {
            this.toggleEdit();
        },
        isNew: function() {
            if (!this.isEditing && this.isNew) {
                this.isEditing = true;
            }
        },
        loading: function(val) {
            this.isLoading = val;
        },
        loadingMsg: function(val) {
            this.isLoading = val != null;
        },
        myBladeData: function(val) {
            if (val != null) {
                val.onUpdate = this.updateBlade;
                val.onUpdate(val);
            }
        },
        '$route.params'() {
            console.log('route params changed in blade');
            if (this.myBladeData != null) {
                this.myBladeData.isUpdated = true;
                this.myBladeData.onUpdate = this.updateBlade;
                this.myBladeData.onUpdate(this.myBladeData);
            }
            else if (this.bladesData == null) {
                this.$emit('sourceChanged', this.computedBladeData);
            }
        }
    },
    mounted() {
        if (this.myBladeData != null) {
            this.myBladeData.isUpdated = true;
            this.myBladeData.onUpdate = this.updateBlade;
            this.myBladeData.onUpdate(this.myBladeData);
        }
        else if (this.bladesData == null) {
            this.$emit('sourceChanged', this.computedBladeData);
        }

        this.liveWidth = this.defaultBladeWidth;
        this.isEditing = this.startEditing;
        this.isLoading = this.loading;

        this.$emit('mounted');
    },
    computed: {
        isBladeDark() {
            return this.pageVariant == 'dark';
        },
        isBladeLight() {
            return this.pageVariant == 'light';
        },
        addBladeNameC() {
            if (this.addTrainingBladeName == null || !this.$BlitzIt.navigation.isTraining()) {
                return this.addBladeName;
            }
            else {
                return this.addTrainingBladeName;
            }
        },
        computedBladeData() {
            if (this.bladesData != null) {
                return this.bladeData;
            }
            else if (this.customBladeData != null) {
                return this.customBladeData;
            }
            else {
                return {
                    data: {
                        id: this.$route.params['id'],
                        ...this.$route.params, //??
                        ...this.$route.query
                    }
                }
            }
        },
        // bladeCardClass() {
        //     return this.bladeClass;
        // },
        bladeCardStyle() {
            return this.bladeColStyle;
        },
        bladeColClass() {
            let e = this.colClass ?? '';

            if (this.resizable) {
                e = `${e} resizable-component`;
            }

            return e;
        },
        bladeColStyle() {
            let r = { 
                width: this.flexColumn ? '100%' : `${this.isMinimized ? '35' : this.liveWidth}px`,
                height: '100%' 
            };

            return r;
        },
        bladeInnerCardClass() {
            let e = 'ma-0 pa-0 transparent';

            if (this.scroll) {
                e = `${e} overflow-y-auto`;
            }

            return e;
        },
        pageCardClass() {
            let e = '';

            if (this.pageVariant == 'transparent') {
                e = `${e} transparent`
            }

            return e;
        },
        pageCardStyle() {
            let e = '';

            if (this.pageColor != null) {
                e = `${e} background-color: ${this.pageColor};`;
            }
            else if (this.pageVariant == 'opaque') {
                e = `${e} background: ${this.$vuetify.theme.dark ? 'rgba(0, 0, 0, 0.90)' : 'rgba(255, 255, 255, 0.65)'};`;
            }
            
            return e;
        },
        pageInnerCardClass() {
            let e = 'ma-0 pa-0 transparent';

            if (this.scroll) {
                e = `${e} overflow-y-auto`;
            }

            return e;
        },
        cIsPinned() {
            return this.isPinned || this.mIsPinned;
        },
        compSmall() {
            return this.small != null ? this.small : (this.$vuetify.breakpoint.mobile ? false : true);
        },
        data() {
            return {
                isEditing: this.isEditing,
                isMobile: this.isMobileWidth,
                isNew: this.isNew,
                openBlade: this.bladesData?.openBlade,
                closeBlade: this.bladesData?.closeBlade,
                maximizeBlade: this.bladesData?.maximizeBlade,
                minimizeBlade: this.bladesData?.minimizeBlade,
                toggleBlade: this.bladesData?.toggleBlade,
                toggleEdit: this.toggleEdit,
                ...this.computedBladeData,
            }
        },
        isChildTab() {
            return this.bladeData != null && this.bladeData.isChild || this.isChildBlade;
        },
        isMobileWidth() {
            return (this.bladeData != null && this.liveWidth < 600) || this.$vuetify.breakpoint.mobile;
        },
        isNew() {
            return this.computedBladeData != null && this.computedBladeData.data != null ? this.computedBladeData.data.id === 'new' : false;
        },
        myBladeData() {
            return this.bladesData != null ? this.bladesData.bladeData.find(x => x.bladeName == this.bladeName) : null;
        },
        proxyCompanyID() {
            if (this.proxyID != null) {
                return this.proxyID;
            }
            else if (this.proxyIDParam != null && this.bladeData != null && this.bladeData.data != null && this.bladeData.data[this.proxyIDParam] != null) {
                return this.bladeData.data[this.proxyIDParam];
            }
            else if (this.proxyIDParam != null && this.$route.query != null && this.$route.query[this.proxyIDParam] != null) {
                return this.$route.query[this.proxyIDParam];
            }

            return null;
        },
        showBlade() {
            return this.permanent === true || this.myBladeData != null; //&& (this.bladeData.isChild || !this.canClose);
        },
        showAddButton() {
            return this.canAdd;
        },
        showCloseButton() {
            return !this.permanent && this.canClose;
        },
        showDeleteButton() {
            return !this.permanent && this.canDelete && !this.isNew;
        },
        showEditButton() {
            return this.canEdit && !this.isNew;
        },
        showRefreshButton() {
            return this.canRefresh && !this.isNew;
        },
        showRestoreButton() {
            return !this.permanent && this.canRestore && !this.isNew;
        },
        tourName() {
            if (this.tourStatus != null) {
                return this.bladeName + '.' + this.tourStatus;
            }
            else if (this.isEditing) {
                return this.bladeName + '.edit';
            }
            else if (this.isNew) {
                return this.bladeName + '.new';
            }
            else {
                return this.bladeName;
            }
        },
        tourExists() {
            return this.$tourExists(this.tourName);
        },
    },
    methods: {
        //UI Changes
        add() {
            if (this.addTo == null) {
                //if (this.bladesData != null) {
                    var newBlade = {
                        bladeName: this.addBladeNameC,
                        data: { id: 'new' },
                        query: {},
                        clearBlades: this.clearBlades,
                        clearOtherBlades: this.clearOtherBlades ? this.bladeName : null,
                        bladesToClear: this.bladesToClear
                    };

                    if (this.getNewBladeData != null) {
                        newBlade.data = Object.assign({}, newBlade.data, this.getNewBladeData(this.computedBladeData));
                    }

                    if (this.proxyCompanyID != null) {
                        newBlade.query.proxyID = this.proxyCompanyID;
                    }

                    if (this.getNewQuery != null) {
                        newBlade.query = Object.assign({}, newBlade.query, this.getNewQuery(this.computedBladeData));
                    }

                    if (this.bladesData != null) {
                        this.bladesData.openBlade(newBlade);
                        
                        if (this.minimizeOnSelect == true && !this.mIsPinned) {
                            this.bladesData.minimizeBlade({ bladeName: this.bladeName });
                        }

                        this.$emit('add', this.addBladeNameC); //, this.bladesData.openBlade);
                    }
                    else {
                        this.$router.push({
                            name: newBlade.bladeName,
                            params: newBlade.data,
                            query: newBlade.query 
                        });
                    }
            }
            else {
                this.$router.push(this.addTo);
            }
        },
        close() {
            if (this.bladesData != null) {
                this.bladesData.closeBlade({ bladeName: this.bladeName, dependantBlades: this.dependantBlades });
            }
            this.$emit('close'); //, this.bladeName);
        },
        maximize() {
            if (this.isMinimized) {
                this.bladesData.maximizeBlade({ bladeName: this.bladeName });
                this.$emit('maximize', this.bladeName);
            }
        },
        minimize() {
            if (!this.isMinimized) {
                this.bladesData.minimizeBlade({ bladeName: this.bladeName });
                this.$emit('minimize', this.bladeName);
            }
        },
        pin() {
            this.bladesData.pinBlade({ bladeName: this.bladeName });
        },
        //Data Changes
        toggleRefresh() {
            this.$emit('refresh');
        },
        toggleRestore() {
            this.$emit('restore');
        },
        toggleDelete() {
            this.$emit('delete');
        },
        toggleEdit() {
            this.isEditing = !this.isEditing;
            if (this.isEditing) {
                this.$emit('edit', this.computedBladeData);
            }
        },
        unpin() {
            this.bladesData.unpinBlade({ bladeName: this.bladeName });
        },
        updateBlade(bladeData) {
            if (bladeData != null && bladeData.bladeName == this.bladeName) {
                this.bladeData = bladeData;

                if (bladeData.isMinimized != undefined && bladeData.isMinimized != null) {
                    this.isMinimized = bladeData.isMinimized;
                }

                if (bladeData.isPinned != undefined && bladeData.isPinned != null) {
                    this.mIsPinned = bladeData.isPinned;
                }
                
                if (bladeData.isUpdated === true || bladeData.isInitial === true) {
                    //update
                    bladeData.canAdd = this.showAddButton;
                    bladeData.canClose = this.showCloseButton;
                    bladeData.canDelete = this.showDeleteButton;
                    bladeData.canEdit = this.showEditButton;
                    bladeData.canRefresh = this.showRefreshButton;
                    bladeData.bladeNames = null;
                    bladeData.dependantBlades = this.dependantBlades;
                    bladeData.title = this.title;
                    bladeData.updateDependants = this.updateDependants;

                    if (bladeData.isInitial) {
                        bladeData.onUpdate = this.updateBlade;
                        bladeData.isInitial = false;
                    }

                    this.isEditing = false;
                    
                    switch (bladeData.toggle) {
                        case 'add':
                            this.add();
                            break;
                        case 'delete':
                            this.toggleDelete();
                            break;
                        case 'refresh':
                            this.toggleRefresh();
                            break;
                        case 'edit':
                            this.toggleEdit();
                            break;
                        default:
                        }

                    delete bladeData.toggle;
                    bladeData.isUpdated = false;
                    
                    if (this.bladeData.resetControls != null) {
                        bladeData.resetControls(this.bladeData);
                    }

                    this.$emit('sourceChanged', this.bladeData);
                }
            }
        },
        onMouseDown() {
            if (this.bladesData != null) {
                document.documentElement.addEventListener(
                    'mousemove',
                    this.onMove,
                    true
                );

                document.documentElement.addEventListener(
                    'mouseup',
                    this.onMouseUp,
                    true
                );

                document.documentElement.addEventListener(
                    'touchend',
                    this.onMouseUp,
                    true
                );
            }

            this.isResizing = true;
        },
        onMouseUp() {
            if (this.bladesData != null) {
                document.documentElement.removeEventListener(
                    'mousemove',
                    this.onMove,
                    true
                );

                document.documentElement.removeEventListener(
                    'mouseup',
                    this.onMouseUp,
                    true
                );

                document.documentElement.removeEventListener(
                    'touchend',
                    this.onMouseUp,
                    true
                );
            }

            this.isResizing = false;
        },
        onMove(event) {
            if (this.isResizing) {
                if (event.movementX != 0) {
                    this.liveWidth += event.movementX;
                    // this.style.width = (this.liveWidth + 'px');
                }
            }
        },
    }
}
</script>

<style scoped>

.vertical-txt {
    transform: rotate(90deg);
    display: block;
    white-space: nowrap;
    margin-top: 30px;
    font-size: 18px;
}

.minimizable {
    transition-property: width;
    transition-duration: 0.15s;
}

.resizable-component {
  position: relative;
}

.resizable-component > .resizable-r {
  display: block;
  position: absolute;
  z-index: 90;
  touch-action: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: e-resize;
  width: 12px;
  right: -6px;
  top: 0;
  height: 100%;
}

</style>