import Api from './blitzitapi.js'
import Auth from './blitzitauth.js'
//import Loader from './blitzitloader.js'
import Navigation from './blitzitnavigation.js'
import Store from './blitzitstore.js'
import { plugin as Style } from './blitzitstyles.js'
import Tracking from './blitzittracker.js'
import Help from './blitzithelp.js'

console.log(Style)

export default {
    api: Api,
    auth: Auth,
    help: Help,
    //loader: Loader,
    navigation: Navigation,
    store: Store,
    style: Style,
    tracker: Tracking,

    install(vue, options) {
        vue.use(Api);
        vue.use(Auth, options);
        vue.use(Help);
        //vue.use(Loader);
        vue.use(Navigation, options);
        vue.use(Store, options);
        vue.use(Tracking, options);
        vue.use(Style)
        
        vue.prototype.$BlitzIt = this;

        
console.log(Style)

    }
}