<template>
  <v-app>
    <v-app-bar
      v-if="showAppBar"
        app
        class="v-step-app-bar"
        clipped-right
        color="primary"
        dark
        dense
        flat>
        <v-slide-y-transition hide-on-leave>
          <v-app-bar-nav-icon v-if="showDrawer" @click="toggleDrawer">
            <v-avatar size="36">
              <v-icon class="rotateable-icon" :class="drawer ? null : 'rotate'">mdi-backburger</v-icon>
            </v-avatar>
          </v-app-bar-nav-icon>
        </v-slide-y-transition>

        <v-slide-y-transition>
          <v-btn v-if="$BlitzIt.auth.session.isTraining && $route.name != 'demo'" text :to="{ name: 'demo' }">
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </v-slide-y-transition>
        
        <v-spacer />

        <BT-Skin-Menu :small="false" />
        <BT-Assistant v-if="isLoggedIn" buttonClass="" />
        <v-menu v-if="isLoggedIn && !canUpdate" bottom close-on-click>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="drawer = false"
                  class="mr-2 v-step-hub-menu "
                  icon
                  title="Hub Menu"
                  v-bind="attrs"
                  v-on="on">
                  <v-icon>mdi-dots-grid</v-icon>
                </v-btn>
            </template>

            <v-card width="300" class="primary lighten-1" dark>
              <v-list-item v-if="$BlitzIt.auth.session.canInstallApp" class="primary" @click="$BlitzIt.auth.session.installPrompt.prompt()">
                <v-list-item-icon><v-icon>mdi-download</v-icon></v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Install The App!</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
                <v-subheader>Hubs</v-subheader>
                <v-container>
                    <v-row dense>
                        <v-col
                            v-for="(portal, index) in hubs"
                            :key="index"
                            dense
                            cols="4">
                            <v-btn
                              v-if="!isLengthyArray(portal.options)"
                                class="primary lighten-2"
                                :disabled="!isLoggedIn && portal.isAuth !== false || !$BlitzIt.auth.doShowByNavName(portal.bladeName)"
                                height="80"
                                :to="{ name: portal.bladeName, params: portal.params, query: portal.query }"
                                small
                                width="90">
                                <v-container dense class="text-center">
                                    <v-icon class="pb-4 pt-2 ">{{ portal.icon }}</v-icon>
                                    <p>{{ portal.title }}</p>
                                </v-container>
                            </v-btn>
                            <v-menu v-else offset-y bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  :disabled="!isLoggedIn && portal.isAuth !== false || portal.options.every(z => !$BlitzIt.auth.doShowByNavName(z.bladeName))"
                                  v-on="on"
                                  v-bind="attrs"
                                  class="primary lighten-2"
                                  height="80"
                                  small
                                  width="90">
                                  <v-container dense class="text-center">
                                      <v-icon class="pb-4 pt-2 " size="25">{{ portal.icon }}</v-icon>
                                      <p>{{ portal.title }}</p>
                                  </v-container>
                                </v-btn>
                              </template>
                              <v-card width="300" class="primary lighten-1" dark>
                                <v-subheader>{{ portal.title }}</v-subheader>
                                <v-container>
                                  <v-row dense>
                                    <v-col 
                                      v-for="(opt, oInd) in portal.options" 
                                      :key="oInd + 'aa'"
                                      dense
                                      cols="4">
                                      <v-btn
                                        class="primary lighten-2"
                                        :disabled="!isLoggedIn && opt.isAuth !== false || !$BlitzIt.auth.doShowByNavName(opt.bladeName)"
                                        height="80"
                                        :to="{ name: opt.bladeName, params: opt.params, query: opt.query }"
                                        small
                                        width="90">
                                        <v-container dense class="text-center">
                                          <v-icon class="pb-4 pt-2 " size="25">{{ opt.icon }}</v-icon>
                                          <p>{{ opt.title }}</p>
                                      </v-container>
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>

                <v-subheader>Dashboards</v-subheader>
                <v-container>
                    <v-row dense>
                        <v-col
                            v-for="(dashboard, index) in dashboards"
                            :key="index"
                            dense
                            cols="6">
                            <v-btn
                              v-if="!isLengthyArray(dashboard.options)"
                                class="primary lighten-2"
                                :disabled="!isLoggedIn && dashboard.isAuth !== false || !$BlitzIt.auth.doShowByNavName(dashboard.bladeName)"
                                height="80"
                                :to="{ name: dashboard.bladeName, params: dashboard.params, query: dashboard.query }"
                                small
                                width="135">
                                <v-container dense class="text-center">
                                    <v-icon class="pb-4 pt-2 " size="25">{{ dashboard.icon }}</v-icon>
                                    <p>{{ dashboard.title }}</p>
                                </v-container>
                            </v-btn>
                            <v-menu v-else open-on-hover open-delay="300">
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  class="primary lighten-2"
                                  :disabled="!isLoggedIn && dashboard.isAuth !== false || dashboard.options.every(z => !$BlitzIt.auth.doShowByNavName(z.bladeName))"
                                  v-on="on"
                                  v-bind="attrs"
                                  height="80"
                                  small
                                  width="135">
                                  <v-container dense class="text-center">
                                      <v-icon class="pb-4 pt-2 " size="25">{{ dashboard.icon }}</v-icon>
                                      <p>{{ dashboard.title }}</p>
                                  </v-container>
                                </v-btn>
                              </template>
                              <v-card class="primary lighten-1" dark>
                                <v-card-subtitle>{{ dashboard.title }}</v-card-subtitle>
                                <v-card-text>
                                  <v-btn v-for="(opt, index) in dashboard.options" :key="index + 'aa'"
                                    class="ma-1 primary lighten-2"
                                    :disabled="!isLoggedIn && opt.isAuth !== false || !$BlitzIt.auth.doShowByNavName(opt.bladeName)"
                                    height="80"
                                    :to="{ name: opt.bladeName, params: opt.params, query: opt.query }"
                                    small
                                    width="135">
                                    <v-container dense class="text-center">
                                        <v-icon class="pb-4 pt-2 " size="25">{{ opt.icon }}</v-icon>
                                        <p>{{ opt.title }}</p>
                                    </v-container>
                                  </v-btn>
                                </v-card-text>
                              </v-card>
                            </v-menu>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-menu>
        
        <v-btn v-if="canUpdate"
         
          @click="updateApp" 
          text>
          <v-icon left>mdi-refresh-circle</v-icon>
            Update App
        </v-btn>
        <v-btn v-else-if="$BlitzIt.auth.session.isTraining" 
         
          @click="endDemo"
          text>
            End Demo
        </v-btn>
        <v-btn v-else-if="!isLoggedIn" 
         
          @click="login"
          text>
            Login
        </v-btn>
    </v-app-bar>
    
    <v-bottom-navigation v-if="showBottomCustomerNavigation" 
      app
      grow
      class="primary"
      dark>
      <v-btn :to="{ name: 'customer-dashboard' }">
        <span>Home</span>
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn :to="{ name: 'supplier-consignments' }">
          <span>Deliveries</span>

          <v-icon>mdi-truck</v-icon>
      </v-btn>
      <v-btn :to="{ name: 'supplier-invoices' }">
          <span>Invoices</span>

          <v-icon>mdi-receipt</v-icon>
      </v-btn>
    </v-bottom-navigation>

    <v-navigation-drawer v-if="$route.name == 'home' || $route.name == 'demo'"
      v-model="drawer"
      app
      class="bg-primary lighten-1"
      dark
      width="300">
      <v-list class="ma-0 pa-0">
        <v-list-item>
          <v-list-item-content rounded>
            <v-list-item-title>
              <v-img src="/img/logo-dark.png" max-height="24" max-width="100" contain>
                  <template v-slot:placeholder><v-icon>mdi-account-outline</v-icon></template>
              </v-img>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="navTo('#home')">
          <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
            <v-list-item-subtitle>Take me back to the start!</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="navTo('#online-ordering')">
          <v-list-item-icon><v-icon>mdi-cellphone-text</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Online Ordering</v-list-item-title>
            <v-list-item-subtitle>Work easier</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  @click="navTo('#inventory')">
          <v-list-item-icon><v-icon>mdi-cube-outline</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Inventory</v-list-item-title>
            <v-list-item-subtitle>Work more confidently</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  @click="navTo('#delivery')">
          <v-list-item-icon><v-icon>mdi-truck</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Delivery</v-list-item-title>
            <v-list-item-subtitle>Work more efficiently</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  @click="navTo('#pricing')">
          <v-list-item-icon><v-icon>mdi-currency-usd</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pricing</v-list-item-title>
            <v-list-item-subtitle>Can I afford it?</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item  @click="navTo('#features')">
          <v-list-item-icon><v-icon>mdi-star-circle-outline</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Features</v-list-item-title>
            <v-list-item-subtitle>Gimme more details!</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="navTo('#contact')">
          <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Sign Me Up!</v-list-item-title>
            <v-list-item-subtitle>I Want In On This</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2" />
        <v-list-item v-if="$BlitzIt.auth.session.canInstallApp" class="bg-primary lighten-2" dark @click="installApp">
          <v-list-item-icon><v-icon>mdi-download</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Get The App!</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'demo' }">
          <v-list-item-icon><v-icon>mdi-slide</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Demo</v-list-item-title>
            <v-list-item-subtitle>Can I taste test?</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <BT-App-Office-Drawer v-else-if="($BlitzIt.auth.session.isTraining || isLoggedIn) && showDrawer" v-model="drawer" />

    <v-main :style="mainStyle" style="touch-action: manipulation;">
      <v-tour name="tour" :steps="steps" :options="tourOptions" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    BTAppOfficeDrawer: () => import('~components/BT-App-Office-Drawer.vue'),
    BTAssistant: () => import('~components/BT-Assistant.vue'),
    BTSkinMenu: () => import('~components/BT-Skin-Menu.vue')
  },
  data: function() {
    return {
      canUpdate: false,
      sWorker: null,
      isUpdating: false,
      drawer: false,
      steps: [],
      tourOptions: {
        highlight: true,
        stopOnTargetNotFound: false
      },
      // mUseCustomCompanyBackground: null
    }
  },
  computed: {
    dashboards() {
      var l = [
          { bladeName: 'customer-dashboard', title: 'Customer', icon: 'mdi-account-outline' },
          { bladeName: 'procurement-dashboard', title: 'Procurement', icon: 'mdi-chart-line' },
          { bladeName: 'supplier-dashboard', title: 'Supplier', icon: 'mdi-factory' },
          {
            title: 'Statistics',
            icon: 'mdi-chart-multiline',
            options: [
            {
                bladeName: 'delivery-statistics',
                title: 'Deliveries',
                icon: 'mdi-truck'
              },
              {
                bladeName: 'journey-statistics',
                title: 'Journeys',
                icon: 'mdi-car-shift-pattern'
              }
            ]
          }
      ];

      return l;
    },
    hubs() {
      var l = [
          {
            title: 'Dispatch',
            icon: 'mdi-clipboard-arrow-right',
            permission: '',
            options: [
              { 
                bladeName: 'dispatch-hub',
                title: 'Dispatch',
                icon: 'mdi-clipboard-arrow-right',
                permission: 'dispatcher'
              },
              {
                bladeName: 'driver-hub',
                title: 'Pickups',
                icon: 'mdi-clipboard-arrow-up',
                query: { mode: 'pickup' },
                permission: 'dispatch-pickups'
              }
            ]
          },
          { bladeName: 'driver-hub', title: 'Driving', icon: 'mdi-truck-delivery', permission: 'driver', query: { mode: 'journey' } },
          {
            title: 'Optimize',
            icon:'mdi-routes',
            options: [
              {
                bladeName: 'journey-optimization-hub',
                title: 'Journeys',
                icon: 'mdi-truck-fast',
                permission: 'journey-optimizer'
              },
              {
                bladeName: 'template-optimization-hub',
                title: 'Templates',
                icon: 'mdi-arrow-decision-outline',
                permission: 'journey-optimizer'
              },
            ]
          },
          { bladeName: 'packing-hub', title: 'Packing', icon: 'mdi-package-variant', permission: 'packer' },
          { bladeName: 'picking-hub', title: 'Picking', icon: 'mdi-clipboard', permission: 'picker' },
          { bladeName: 'production-hub', title: 'Production', icon: 'mdi-factory', permission: 'manufacturer' },
          {
            title: 'Sales',
            icon: 'mdi-receipt',
            options: [
              { 
                bladeName: 'sales-hub',
                title: 'Sales',
                icon: 'mdi-receipt',
                permission: 'sales-manager'
              },
              { 
                bladeName: 'order-sheets-hub',
                title: 'Sheets',
                icon: 'mdi-list-box',
                permission: 'sales-manager'
              },
              { 
                bladeName: 'customer-order',
                title: 'Instant',
                icon: 'mdi-cash-register',
                permission: 'sales-manager',
                params: { id: 'new', mode: 'instant' }
              }
            ]
          },
          {
            title: 'Stock',
            icon: 'mdi-cube-outline',
            options: [
              { 
                bladeName: 'stock-checker-hub',
                title: 'Levels',
                icon: 'mdi-file-chart',
                query: { filterBy: 'Sold' },
                permission: 'stock-manager'
              },
              { 
                bladeName: 'stock-balancer-hub',
                title: 'Balancer',
                icon: 'mdi-scale-balance',
                permission: 'stock-manager'
              },
              // { 
              //   bladeName: 'beta-stock-balancer-hub',
              //   title: 'BETA',
              //   icon: 'mdi-scale-balance',
              //   permission: 'stock-manager'
              // },
              {
                bladeName: 'stock-imports', 
                title: 'Imports', 
                icon: 'mdi-home-import-outline',
                permission: 'stock-manager'
              }
            ]
          },
          { bladeName: 'demo', isAuth: false, title: 'Training', icon: 'mdi-school' }
      ];

      return l;
    },
    isLoggedIn() {
      return this.$BlitzIt.auth.session?.isLoggedIn;
    },
    mainStyle() {
      return `background: url("${this.$BlitzIt.navigation.session.backgroundURL}") no-repeat top center fixed; -webkit-background-size: cover; -moz-background-size: cover; background-size: cover; -o-background-size: cover; min-height: 100%;`;
    },
    permissions() {
      return this.$BlitzIt.auth.session;
    },
    showAppBar() {
      return this.$BlitzIt.navigation.session?.appBar;
    },
    showBottomCustomerNavigation() {
      return this.$vuetify.breakpoint.mobile && (this.isLoggedIn || this.$BlitzIt.auth.session.isTraining) && (this.subscription == 'CUSTFREE' || this.subscription == 'CUST');
    },
    showDrawer() {
      return this.$BlitzIt.navigation.session?.appNavigation;
    },
    subscription() {
      return this.$BlitzIt.auth.session?.subscriptionCode;
    }
  },
  async created() {
    //prompt to update app
    document.addEventListener('swUpdated', this.updateAvailable, { once: true });

    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.isUpdating) return;
      this.isUpdating = true;
      window.location.reload();
    })

    //prompt to install app if not installed
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();

      console.log('caught install prompt');

      this.$BlitzIt.auth.session.canInstallApp = true;
      this.$BlitzIt.auth.session.installPrompt = e;
    })

    if (this.isLoggedIn) {
      this.drawer = true;
    }

    this.$BlitzIt.style.resetColors(this.$vuetify)
  },
  methods: {
    endDemo() {
      this.$router.push({ name: 'home' });
      this.$BlitzIt.auth.endTraining();
    },
    //prompt to update app
    updateApp() {
      this.canUpdate = false;
      if (!this.sWorker || !this.sWorker.waiting) {
        return;
      }

      this.sWorker.waiting.postMessage({ type: 'SKIP_WAITING' });
    },
    updateAvailable(event) {
      console.log('update available!!!');
      this.sWorker = event.detail;
      this.canUpdate = true;
    },
    //prompt to install app
    installApp() {
      console.log('install app');
      if (this.$BlitzIt.auth.session.installPrompt != null) {
          this.$BlitzIt.auth.session.installPrompt.prompt();
      }
      // if (this.deferredPrompt != null) {
      //   console.log('installing app');
      //   this.deferredPrompt.prompt();
      // }
    },
    isInstalled() {
      // For iOS
      if(window.navigator.standalone) return true

      // For Android
      if(window.matchMedia('(display-mode: standalone)').matches) return true

      // If neither is true, it's not installed
      return false
    },
    toggleDrawer() {
      // if (!this.isLoggedIn || this.$BlitzIt.style.root.cosmetics.drawerPermanent != true) {
        this.drawer = !this.drawer;
      // }
    },
    navTo(hash) {
      if (this.$route.name == 'home') {
        this.$vuetify.goTo(hash)
      }
      else {
        this.$router.push({ name: 'home', hash: hash })
      }
    },
    goHome() {
      if (this.$route.name != 'home') {
        this.$router.push({ name: 'home' });
      }
    }
  }
};
</script>

<style>

.pdf-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.pdf-page {
  font-family: sans-serif;
  height: 29.5cm;
  width: 21cm;
  padding-left: 1cm;
  padding-top: 1cm;
  padding-right: 1cm;
  padding-bottom: 1cm;
}

.pdf-page-landscape {
  font-family: sans-serif;
  width: 29.7cm;
  height: 21cm;
  padding-left: 1cm;
  padding-top: 1cm;
  padding-right: 1cm;
  padding-bottom: 1cm;
}

.pdf-half-page {
  font-family: sans-serif;
  background-color: white;
  height: 14.85cm;
  width: 21cm;
  padding-left: 2cm;
  padding-right: 2cm;
}

/* .a4 {
  height: 29.7cm;
  width: 21cm;
  padding-left: 2cm;
  padding-top: 2cm;
  padding-right: 2cm;
  padding-bottom: 2cm;
}

.half-a4 {
  height: 14.85cm;
  width: 21cm;
  padding-left: 2cm;
  padding-right: 2cm;
} */


.main-style {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  min-height: 100%;
}

tr .mouse-over-show {
    opacity: 0;
    transition: none;
}

tr:hover .mouse-over-show {
    opacity: 1;
    transition: opacity .5s ease-in-out .1s;
}

.mouse-item .mouse-over-show {
    display: none;
    opacity: 0;
    transition: none;
}

.mouse-item:hover .mouse-over-show {
    display: flex;
    opacity: 1;
    transition: opacity .5s ease-in-out .1s;
}

@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0.5; }
  100% { opacity:1; }
}
@-o-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.5; }
  100% { opacity:1; }
}
@-moz-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.5; }
  100% { opacity:1; }
}
@-webkit-keyframes flickerAnimation{
  0%   { opacity:1; }
  50%  { opacity:0.5; }
  100% { opacity:1; }
}

.flicker {
   -webkit-animation: flickerAnimation 2s infinite;
   -moz-animation: flickerAnimation 2s infinite;
   -o-animation: flickerAnimation 2s infinite;
    animation: flickerAnimation 2s infinite;
}



.rotateable-icon {
  transition: transform 1s ease-in-out !important;
}

.rotateable-icon.rotate {
  transform: rotate(180deg);
}

.vertical-text {
  transform: rotate(-90deg);
  float: left;
  overflow: hidden;
}

.span-lg {
  font-size: 18px;
}

</style>