<template>
    <v-slide-x-reverse-transition group hide-on-leave>
        <v-list-item 
            :dense="dense" 
            key="1"
            :class="fieldClass">
            <v-list-item-avatar v-if="icon != null">
                <v-icon :size="iconSize">{{ icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
                <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>
                <BT-Date-Picker
                    v-if="isEditing || isNew"
                    v-model="str"
                    :clearable="clearable"
                    :dense="dense"
                    :filled="filled"
                    :format="computedFormat"
                    :dateFrom="dateFrom"
                    :dateRules="dateRules"
                    :hideDetails="hideDetails"
                    :fromNow="fromNow"
                    @input="update" />
                <v-list-item-title v-else-if="!horizontal">{{ computedDateFormattedMomentJS }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action-text v-if="horizontal && !isEditing && !isNew">{{ computedDateFormattedMomentJS }}</v-list-item-action-text>
            <v-list-item-action v-if="showSettings || showActions">
                <v-row class="ma-0 pa-0">
                    <slot name="actions" />
                    <v-menu
                        v-if="showSettings"
                        offset-y 
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                icon
                                v-bind="attrs"
                                v-on="on"
                                title="Settings"
                                :small="small">
                                <v-icon :small="small">mdi-cog</v-icon>
                            </v-btn>
                        </template>
                        <v-list :dense="dense">
                            <slot name="settings" />
                        </v-list>
                    </v-menu>
                </v-row>
            </v-list-item-action>
        </v-list-item>
        <!-- <v-list-item 
            v-if="!isEditing && !isNew" 
            :dense="dense" 
            key="1"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>

                <v-list-item-title v-if="!horizontal">{{ computedDateFormattedMomentJS }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action-text v-if="horizontal">{{ computedDateFormattedMomentJS }}</v-list-item-action-text>
            <v-list-item-action v-if="showSettings || showActions">
                <v-row class="ma-0 pa-0">
                    <slot name="actions" />
                    <v-menu
                        v-if="showSettings"
                        offset-y 
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                icon
                                v-bind="attrs"
                                v-on="on"
                                title="Settings"
                                :small="small">
                                <v-icon :small="small">mdi-cog</v-icon>
                            </v-btn>
                        </template>
                        <v-list :dense="dense">
                            <slot name="settings" />
                        </v-list>
                    </v-menu>
                </v-row>
            </v-list-item-action>
        </v-list-item>
        <v-list-item 
            v-else
            :dense="dense"
            key="2"
            :class="fieldClass">
            <v-list-item-content>
                <BT-Date-Picker
                    v-model="str"
                    :clearable="clearable"
                    :dense="dense"
                    :filled="filled"
                    :format="computedFormat"
                    :label="label"
                    :dateFrom="dateFrom"
                    :dateRules="dateRules"
                    :hideDetails="hideDetails"
                    :fromNow="fromNow"
                    @input="update" />
            </v-list-item-content>
            <v-list-item-action v-if="showSettings || showActions">
                <v-row class="ma-0 pa-0">
                    <slot name="actions" />
                    <v-menu
                        v-if="showSettings"
                        offset-y 
                        :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn 
                                icon
                                v-bind="attrs"
                                v-on="on"
                                title="Settings"
                                :small="small">
                                <v-icon :small="small">mdi-cog</v-icon>
                            </v-btn>
                        </template>
                        <v-list :dense="dense">
                            <slot name="settings" />
                        </v-list>
                    </v-menu>
                </v-row>
            </v-list-item-action>
        </v-list-item> -->
    </v-slide-x-reverse-transition>
</template>

<script>
export default {
    name: 'BT-Field-Date',
    data: function() {
        return {
            str: null
        }
    },
    props: {
        alternateText: {
            type: String,
            default: null
        },
        canSelect: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: false
        },
        customFormat: null,
        //UTC
        dateFrom: {
            type: String,
            default: null
        },
        dateRules: {
            type: Function,
            default: null
        },
        defaultToday: {
            type: Boolean,
            default: false
        },
        dense: {
            type: Boolean,
            default: true
        },
        fieldClass: {
            type: String,
            default: null
        },
        filled: {
            type: Boolean,
            default: false
        },
        fromNow: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: true
        },
        horizontal: {
            type: Boolean,
            default: false
        },
        icon: null,
        iconSize: null,
        isEditing: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        itemText: {
            type: String,
            default: null
        },
        label: null,
        selectIcon: {
            type: String,
            default: 'mdi-arrow-right'
        },
        shortDateAndTime: {
            type: Boolean,
            default: false
        },
        showActions: {
            type: Boolean,
            default: false
        },
        showSettings: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: true
        },
        value: null,
    },
    watch: {
        value: function(val) {
            this.str = val;
        }
    },
    created() {
        if (this.value != null || !this.defaultToday) {
            this.str = this.value;
        }
        else {
            this.str = this.getToday();
        }
    },
    computed: {
        computedDateFormattedMomentJS() {
            return this.str ? this.$BlitzIt.auth.formatDate(this.str, this.computedFormat) : '';
        },
        computedFormat() {
            if (this.customFormat != null) {
                return this.customFormat;
            }
            else if (this.shortDateAndTime) {
                return 'dd LLL yyyy hh:mm a';
            }
            else {
                return 'ccc dd LLL yyyy';
            }
        }
    },
    methods: {
        update(val) {
            this.$emit('input', val);
            this.$emit('change', val);
        }
    }
}
</script>