<template>
    <!-- <v-slide-y-transition 
        group 
        hide-on-leave 
        tag="div" 
        class="container container--fluid ma-0 pa-0 d-flex flex-nowrap overflow-x-auto overflow-y-hidden no-gutters fluid"
        :class="bladeClass">
        <slot v-bind="bladesData"/>
    </v-slide-y-transition> -->
    <v-container
        class="ma-0 pa-0 d-flex flex-nowrap overflow-x-auto overflow-y-hidden no-gutters"
        :class="bladeClass"
        fill-height
        fluid>
        <slot v-bind="bladesData"/>
    </v-container>
</template>

<script>
export default {
    name: 'BT-Blades',
    data: function() {
        return {
            bladesData: {
                bladeHeight: this.bladeHeight,
                openBlade: this.mOpenBlade,
                closeBlade: this.mCloseBlade,
                findBlade: this.findBladeData,
                maximizeBlade: this.mMaximizeBlade,
                minimizeBlade: this.mMinimizeBlade,
                pinBlade: this.mPinBlade,
                toggleBlade: this.mToggleBlade,
                unpinBlade: this.mUnpinBlade,
                updateBladeData: this.mUpdateBladeData,
                usedHeight: this.usedHeight,
                bladeData: [] //{ bladeID: String, id: String, bladeName: String, clearBlades: Bool, maximize, minimize, maximizeBlades, minimizeBlades }
            }
        }
    },
    props: {
        anchorBlade: null,
        anchorBlades: {
            type: Array,
            default: null
        },
        bladeClass: {
            type: String,
            default: null
        },
        bladeHeight: {
            type: String,
            default: '94vh'
        },
        maximizedCount: {
            type: Number,
            default: 7
        },
        usedHeight: {
            type: Number,
            default: null
        },
    },
    computed: {
        hStyle() {
            let r = {}
            
            if (this.usedHeight != null) {
                r.height = `100vh - ${this.usedHeight}px)`
            }

            return r;
        }
    },
    created() {
        if (this.isLengthyArray(this.anchorBlades)) {
            this.anchorBlades.forEach(x => {
                this.mOpenBlade(x);
            })
        }
        else if (this.anchorBlade != null) {
            this.mOpenBlade(this.anchorBlade);
        }
    },
    mounted() {
        this.$emit('loaded', this.bladesData);
    },
    methods: {
        createBlade(bladeData, otherData, isChild = false, isMinimized = false) { //other data is overriden by blade data (can be parent or something)
            //var b = bladeData.bladeName;
            var e = Object.assign({}, bladeData, { data: otherData }, {
                //onUpdate: () => { console.log('default update: ' + b); }, //function to call on update (bladeData)
                onUpdate: () => { }, //function to call on update (bladeData)
                isChild: isChild,
                isInitial: true,
                isMinimized: isMinimized,
                isPinned: bladeData.isPinned === true ? true : false,
                isUpdated: false,
            })
            return e;
        },
        findBladeData(data, returnParent = true) {
            if (data != null) {
                var ind = this.findBladeDataIndex(data, returnParent);
                if (ind > -1) {
                    return this.bladesData.bladeData[ind];
                }
            }
            
            return null;
        },
        findBladeDataIndex(data, returnParent = true) {
            var ind = -1;
            if (data != null) {
                if (typeof(data) === 'string') {
                    ind = this.bladesData.bladeData.findIndex(x => x.bladeName == data);
                }
                else if (typeof(data) === 'object') {
                    ind = this.bladesData.bladeData.findIndex(x => x.bladeName == data.bladeName);
                }
            }

            if (ind > -1 && returnParent) {
                var bladeData = this.bladesData.bladeData[ind];
                if (bladeData.isChild === true) {
                    var parentInd = this.bladesData.bladeData.findIndex(x => this.isLengthyArray(x.bladeNames) && x.bladeNames.some(y => y.bladeName == data.bladeName));
                    if (parentInd > -1) {
                        ind = parentInd;
                    }
                }
            }
            return ind;
        },
        mCloseBlade(data) {
            var bladeData = this.findBladeData(data, false);
            if (bladeData != null) {
                //close blade dependants
                if (this.isLengthyArray(bladeData.dependantBlades)) {
                    for (let i = 0; i < bladeData.dependantBlades.length; i++) {
                        const bladeName = bladeData.dependantBlades[i];
                        var dependantInd = -1;
                        if (typeof(bladeName) === 'string') {
                            dependantInd = this.findBladeDataIndex({ bladeName: bladeName }, false);
                        }
                        else if (typeof(bladeName) === 'object') {
                            dependantInd = this.findBladeDataIndex(bladeName, false);
                        }

                        if (dependantInd > -1) {
                            this.bladesData.bladeData.splice(dependantInd, 1);
                        }
                    }
                }
            }

            bladeData = this.findBladeData(data, true);
            if (bladeData != null && this.isLengthyArray(bladeData.bladeNames)) {
                bladeData.bladeNames.forEach(x => {
                    var childBladeInd = this.findBladeDataIndex(x, false);
                    if (childBladeInd > -1) {
                        this.bladesData.bladeData.splice(childBladeInd, 1);
                    }
                });
            }
            //close blade
            var ind = this.findBladeDataIndex(bladeData != null ? bladeData : data, true);
            if (ind > -1) {
                this.bladesData.bladeData.splice(ind, 1);
            }

            if (this.bladesData.bladeData.length == 0) {
                this.restart();
            }
        },
        mMaximizeBlade(data) {
            if (data.maximizeBlades === true) {
                this.bladesData.bladeData.forEach(x => {
                    x.isMinimized = false;
                    //x.isUpdated = true;
                    x.onUpdate(x);
                });
            }
            else {
                var bladeData = this.findBladeData(data, true);
                if (bladeData != null) {
                    //maximize blade
                    bladeData.isMinimized = false;
                    //bladeData.isUpdated = true;
                    bladeData.onUpdate(bladeData);
                }
            }
        },
        mMinimizeBlade(data) {
            if (data.minimizeBlades === true) {
                this.bladesData.bladeData.forEach(x => {
                    if (!x.isPinned) {
                        x.isMinimized = true;
                        x.onUpdate(x);
                    }
                });
            }
            else {
                var bladeData = this.findBladeData(data, true);
                if (bladeData != null && !bladeData.isPinned) {
                    bladeData.isMinimized = true;
                    bladeData.onUpdate(bladeData);
                }
            }
        },
        mOpenBlade(data, asChild = false) {
            if (data != null) {
                if (data.clearBlades === true) {
                    //this.restart();
                }
                else if (this.isLengthyArray(data.bladesToClear)) {
                    data.bladesToClear.forEach(x => {
                        this.mCloseBlade(x);
                    })
                }
                else if (data.clearOtherBlades != null) {
                    this.restart(data.clearOtherBlades);
                }
                // else {
                //     console.log('notin');
                // }

                var bladeData = this.findBladeData(data, !asChild);
                if (bladeData == null) {
                    //create and push new blade
                    if (!data.onlyUpdate) {
                        bladeData = this.createBlade(data, data.data != null ? data.data : {}, asChild, data.isMinimized ? true : false);
                        delete bladeData.onlyUpdate;
                        this.bladesData.bladeData.push(bladeData);
                    }
                }
                else {
                    //update existing blade
                    data.isMinimized = false;
                    this.mUpdateBladeData(bladeData, data.data);
                }

                //minimize any outlying blades
                var maximizedBladeCount = this.bladesData.bladeData.filter(x => !x.isPinned && !x.isChild && !x.isMinimized).length;
                var i = 0;
                if (i < this.bladesData.bladeData.length && maximizedBladeCount > 0 && maximizedBladeCount >= this.maximizedCount) {
                    do {
                        var bData = this.bladesData.bladeData[i];
                        if (!bData.isPinned && !bData.isChild && !bData.isMinimized) {
                            bData.isMinimized = true;
                            bData.isUpdated = true;
                            bData.onUpdate(bData);
                            maximizedBladeCount -= 1;
                        }
                        i += 1;
                    } while (i < this.bladesData.bladeData.length && maximizedBladeCount > 0 && maximizedBladeCount >= this.maximizedCount)
                }
            }
            // else {
            //     console.log('no data');
            // }
        },
        mPinBlade(data) {
            var bladeData = this.findBladeData(data, true);
            if (bladeData != null) {
                //pin blade
                bladeData.isPinned = true;
                bladeData.isUpdated = true;
                bladeData.onUpdate(bladeData);
            }
        },
        mToggleBlade(data) { //{ bladeName, toggle }
            var bladeData = this.findBladeData(data, false);
            if (bladeData != null && data.toggle != null) {
                bladeData.toggle = data.toggle;
                bladeData.isUpdated = true;
                bladeData.onUpdate(bladeData);
            }
        },
        mUnpinBlade(data) {
            var bladeData = this.findBladeData(data, true);
            if (bladeData != null) {
                //pin blade
                bladeData.isPinned = false;
                bladeData.isUpdated = true;
                bladeData.onUpdate(bladeData);
            }
        },
        mUpdateBladeData(data, dataBag) {
            //find blade and update data
            if (data != null) {
                var bladeData = this.findBladeData(data, false);
                if (bladeData != null) {
                    bladeData.data = bladeData.copyData === false ? this.copyDeep(dataBag != null ? dataBag : {}) : dataBag != null ? dataBag : {};
                    bladeData.isUpdated = true;
                    bladeData.onUpdate(bladeData);

                    if (bladeData.updateDependants === true && this.isLengthyArray(bladeData.dependantBlades)) {
                        bladeData.dependantBlades.forEach(dBlade => {
                            if (bladeData.data != null && bladeData.data.id === 'new') {
                                //close dependant blade
                                this.mCloseBlade(dBlade);
                            }
                            else {
                                this.mUpdateBladeData(dBlade, data.data);
                            }
                        })
                    }
                }
            }
        },
        restart(anchorBladeName) {
            this.bladesData.bladeData = anchorBladeName ? this.bladesData.bladeData.filter(x => x.bladeName == anchorBladeName) : [];

            if (this.anchorBlade != null && !this.isLengthyArray(this.bladesData.bladeData)) {
                this.bladesData.bladeData.push(this.createBlade(this.anchorBlade, {}, false, true));
            }
        }
    }
}
</script>