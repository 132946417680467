import Vue from 'vue';
import authRepo from '~api/auth-api.js';
import urlHelpers from '~tools/url-helpers.js';
//import { microservices } from '~classes/microservices.js';
import { isLengthyArray, getLocally, removeLocally, saveLocally } from '~tools/helpers.js';
import { DateTime } from 'luxon/src/luxon';

const cookieKey = "BlitzItAuthenticationToken";
// const cookieLocationKey = 'BlitzItLocationID';

export default {
    install(vue, { router }) {
        
        const root = new Vue({
            data: {
                canInstallApp: false,
                data: null,
                expiresOn: null,
                ignorePreferences: false,
                installPrompt: null,
                isGlobalAdmin: false,
                isLoggedIn: false,
                isTraining: false,
                permissions: null,
                selectedLocationID: false,
                subscriptionCode: null,
                subscriptionsInUse: null,
                token: null,
                userID: null
            },
            computed: {
                userPermissions() {
                    if (this.permissions == null) {
                        return [];
                    }

                    return this.permissions.split(',');
                },
                company() {
                    return this.data ? this.data.companyAccount : null;
                },
                timeZone() {
                    return this.company ? this.company.defaultTimeZone : 'Australia/Melbourne';
                }
            },
            methods: {
                //if no access is allowed then don't show
                //if subscription and preference dictates, then don't show
                doShow(subcodes, permissions, action) {
                    //check permissions first? (permission = 'suppliers' or 'pricing-settings')
                    if (action == null || action != 'view' || action != 'edit') {
                        //default is view
                        //action = 'view';
                    }

                    var doShowRes = true;

                    if (isLengthyArray(permissions)) {
                        permissions.forEach(permit => {
                            if (action == 'edit') {
                                if (!this.canEditPermit(permit)) {
                                    doShowRes = false;
                                }
                            }
                            else {
                                if (!this.canViewPermit(permit)) {
                                    doShowRes = false;
                                }
                            }
                        })
                    }
                    
                    //preference?
                    if (isLengthyArray(subcodes)) {
                        // console.log(subcodes);
                        // var acceptSub = subcodes.some(subCode => this.isWithinSubscription(subCode));
                        // var acceptSub = false;
                        // subcodes.forEach(subcode => {
                        //     if (this.isWithinSubscription(subcode)) {
                        //         acceptSub = true;
                        //     }
                        // })
                        
                        if (!subcodes.some(subCode => this.isWithinSubscription(subCode))) {
                            return false;
                        }
                    }
                    
                    return doShowRes;
                },  
                doShowByNavName(navName, includeChildren = false) {
                    var navItem = null;
                    var doShowRes = false;

                    if (typeof navName === 'object') {
                        navItem = navName;
                    }
                    else {
                        navItem = this.$BlitzIt.navigation.findItem(navName);
                    }

                    if (navItem == null) {
                        return false;
                    }

                    var subCodes = isLengthyArray(navItem.subscriptions) ? navItem.subscriptions : [];
                    var permissions = isLengthyArray(navItem.permissions) ? navItem.permissions : [];
                    var oRes = this.$BlitzIt.auth.doShow(subCodes, permissions, 'view');

                    if (oRes || !includeChildren || !isLengthyArray(navItem.children)) {
                        return oRes;
                    }

                    navItem.children.forEach(child => {
                        if (this.doShowByNavName(child.name, true)) {
                            doShowRes = true;
                        }
                    })

                    return doShowRes;
                },
                isSubCode(subCode) {
                    var options = ['CUSTFREE', 'CUST', 'SUPPFREE', 'SUPP', 'COURFREE', 'COUR'];
                    return options.some(x => x == subCode);
                },
                canEditPermit(permitCSV) {
                    if (this.isGlobalAdmin == true && (this.company != null && !this.company.isSuspended)) {
                        return true;
                    }

                    var canEdit = false;

                    var pList = permitCSV.replaceAll(' ', '').split(',');
                    
                    pList.forEach(permit => {
                        if (isLengthyArray(this.userPermissions)) {
                            this.userPermissions.forEach(userPerm => {
                                var parts = userPerm.split('.');
                                if ((permit == parts[0] && parts[1] == 'edit') || userPerm == 'everything.edit') {
                                    canEdit = true;
                                }
                            })
                        }
                    })
                    
                    return canEdit;
                },
                canViewPermit(permitCSV) {
                    if (this.isGlobalAdmin == true && (this.company != null && !this.company.isSuspended)) {
                        return true;
                    }

                    var canView = false;

                    var pList = permitCSV.replaceAll(' ', '').split(',');
                    
                    pList.forEach(permit => {
                        if (isLengthyArray(this.userPermissions)) {
                            this.userPermissions.forEach(userPerm => {
                                var parts = userPerm.split('.');
                                if (permit == parts[0] || userPerm == 'everything.view' || userPerm == 'everything.edit') {
                                    canView = true;
                                }
                            })
                        }
                    })
                    
                    return canView;
                },
                canEdit(name) {
                    //user has access
                    if (name == null || this.isGlobalAdmin == true || this.$BlitzIt.navigation.isTraining()) {
                        return true;
                    }

                    var navItem = this.$BlitzIt.navigation.findItem(name);
                    var canE = true;

                    if (navItem == null) {
                        return false;
                    }
                    
                    if (isLengthyArray(navItem.subscriptions)) {
                        navItem.subscriptions.forEach(s => {
                            //s = requirement
                            if (!this.isWithinSubscription(s)) {
                                //requirement not met
                                canE = false;
                            }
                        })
                    }
                    
                    var permissions = this.$BlitzIt.navigation.findEditPermissions(name);
                    if (isLengthyArray(permissions)) {
                        permissions.forEach(p => {
                            //p = requirement
                            if (!this.canEditPermit(p)) {
                                //requirement not met
                                canE = false;
                            }
                        })
                    }

                    return canE;
                },
                canView(name) {
                    //user has access
                    if (name == null || this.$BlitzIt.navigation.isTraining()) {
                        return true;
                    }

                    var navItem = this.$BlitzIt.navigation.findItem(name);

                    if (this.isGlobalAdmin) {
                        //now test for subscription
                        if (isLengthyArray(navItem.subscriptions)) {
                            return navItem.subscriptions.some(subscript => this.isWithinSubscription(subscript));
                        }
                    
                        return true;
                    }
                    else {
                        var canV = true;
    
                        if (navItem == null) {
                            return false;
                        }
    
                        if (navItem.requiresAuth === false) {
                            return true;
                        }
                        
                        if (isLengthyArray(navItem.subscriptions)) {
                            navItem.subscriptions.forEach(subscript => {
                                if (!this.isWithinSubscription(subscript)) {
                                    canV = false;
                                }
                            })
                        }
                        
                        if (isLengthyArray(navItem.permissions)) {
                            navItem.permissions.forEach(perm => {
                                if (!this.canViewPermit(perm)) {
                                    canV = false;
                                }
                            })
                        }
                        
                        return canV;
                    }
                },
                isCompanyPreference(subCode) {
                    if (subCode == null || subCode.length == 0) {
                        return false;
                    }
                    
                    if (this.ignorePreferences == true) {
                        return true;
                    }

                    if (this.subscriptionsInUse == null || this.subscriptionsInUse.length == 0) {
                    //if (this.subscriptionCode == null || this.subscriptionCode.length == 0) {
                        return false;
                    }

                    // var levelToShow = this.getSubLevel(subCode);
                    // var maxPotentialLevel = this.getSubLevel(this.subsriptionCode);

                    // return levelToShow <= maxPotentialLevel;
                    
                    var subPrefs = this.subscriptionsInUse.split(',');
                    for (var i = 0; i < subPrefs.length; i++) {
                        if (subCode.search(subPrefs[i]) != -1 || subPrefs[i].search(subCode) != -1) {
                            return true;
                        }
                    }
                    
                    return false;
                },
                getSubLevel(subCode) {
                    if (subCode == 'COURFREE' || subCode == 'SUPPFREE' || subCode == 'CUSTFREE' || subCode == 'CLIENTFREE') {
                        return 1;
                    }
                    else if (subCode == 'COURFREE') {
                        return 1.1;
                    }
                    else if (subCode == 'SUPPFREE') {
                        return 1.2;
                    }
                    else if (subCode == 'CUST') {
                        return 2;
                    }
                    else if (subCode == 'COUR') {
                        return 3;
                    }
                    else if (subCode == 'SUPP') {
                        return 4;
                    }
                    else {
                        return 0;
                    }
                },
                isWithinSubscription(subCode) {
                    if (this.subscriptionCode == null) {
                        return false;
                    }

                    if (subCode == this.subscriptionCode) {
                        return true;
                    }

                    var subLevelNeeded = this.getSubLevel(subCode);
                    var subCompanyLevel = this.getSubLevel(this.subscriptionCode);

                    return subLevelNeeded <= subCompanyLevel;
                }
            }
        })

        this.session = root;

        this.tokenExpired = () => {
            if (root == null || root.expiresOn == null) {
                return false;
            }

            var eDate = DateTime.fromFormat(root.expiresOn, 'd/MM/yyyy h:mm:ss a');
            var dif = eDate.diff(DateTime.utc(), 'milliseconds');

            return dif.as('milliseconds') < 0;
        }

        this.getDiffFromNow = (val, spanName) => {
            if (spanName != null) {
                return DateTime.utc().diff(DateTime.fromISO(val), spanName);
            }
            else {
                var dif = DateTime.fromISO(val).diff(DateTime.utc(), ['months','days','hours','minutes','seconds']).toObject();

                var str = '';

                if (dif.months > 0) {
                    return `${dif.months} ${dif.months == 1 ? 'month ' : 'months '}`;
                }
                if (dif.days > 0) {
                    return `${str} ${dif.days} ${dif.days == 1 ? 'day ' : 'days '}`;
                }
                if (dif.hours > 0) {
                    str = `${str} ${dif.hours} ${dif.hours == 1 ? 'hour ' : 'hours '}`;
                }
                if (dif.minutes > 0) {
                    str = `${str} ${dif.minutes} ${dif.minutes == 1 ? 'minute ' : 'minutes '}`;
                }
                return str;
            }
        }

        this.getCustomRelativeTime = (val, includeTime) => {
            var d = this.formRawTZ(val);
            var n = this.createRawTZ();
            var str = includeTime ? d.toFormat('t') + ' ' : '';
            
            if (n.plus({ days: -1 }) == d.startOf('day')) {
                return str + 'yesterday';
            }
            else if (n.hasSame(d, 'day')) {
                return str + 'today';
            }
            else if (n.plus({ days: 1 }).hasSame(d, 'day')) {
                return str + 'tomorrow';
            }
            else if (d.diff(n, 'days').toObject().days < 7) {
                return d.toFormat('EEEE');
            }
            else {
                return DateTime.fromISO(val).toRelative();
            }
        }

        this.isPast = (val) => {
            return DateTime.fromISO(val) < DateTime.utc();
        }

        this.getRelativeTime = (val) => {
            return DateTime.fromISO(val).toRelative();
        }

        this.getTimeOfDay = (mins) => {
            var d = DateTime.utc(2000, 1, 1, 0, 0, 0);
            d = d.plus({ minutes: mins });
            return d.toFormat('T');
        }

        this.getToday = () => {
            return this.createRawTZ().startOf('day').toUTC().toString();
        }

        this.getTomorrow = () => {
            return this.createRawTZ().endOf('day').toUTC().toString();
        }

        this.getFuture = (days) => {
            return this.createRawTZ().endOf('day').plus({ days: days }).toUTC().toString();
        }

        this.getDayAfterTomorrow = () => {
            return this.createRawTZ().startOf('day').plus({ days: 2 }).toUTC().toString();
        }

        this.createUTC = (format = null, daysToAdd = 0, hoursToAdd = 0) => {
            if (format != null) {
                return DateTime.utc().plus({ days: daysToAdd, hours: hoursToAdd }).toFormat(format);
            }
            else {
                return DateTime.utc().plus({ days: daysToAdd, hours: hoursToAdd }).toString();
            }
        },

        this.createRawUTC = () => {
            return DateTime.utc();
        }

        this.createRawTZ = () => {
            return DateTime.utc({ zone: this.session.timeZone }).setZone(this.session.timeZone);
        }

        this.createTZ = (format = null) => {
            if (format) {
                return DateTime.utc().setZone(this.session.timeZone).toFormat(format);
            }
            else {
                return DateTime.utc().setZone(this.session.timeZone).toString();
            }
        },

        this.formTZtoUTCAndTime = (val, time) => {
            if (val == null || time == null) {
                return;
            }

            return DateTime.fromISO(val, { zone: root.timeZone }).plus({ hours: time.split(':')[0], minutes: time.split(':')[1] }).toUTC().toString();
        }

        this.formTZtoUTC = (val, format = null) => {
            if (format) {
                return DateTime.fromISO(val, { zone: this.session.timeZone }).toUTC().toFormat(format);
            }
            else {
                return DateTime.fromISO(val, { zone: this.session.timeZone }).toUTC().toString();
            }
        }

        this.formUTC = (val, format = null, daysToAdd = 0) => {
            if (format) {
                return DateTime.fromISO(val).plus({ days: daysToAdd }).toUTC().toFormat(format);
            }
            else {
                return DateTime.fromISO(val).plus({ days: daysToAdd }).toUTC().toString();
            }
        },

        this.formUTCDateTime = (val, daysToAdd = 0, hoursToAdd = 0, minutesToAdd = 0, monthsToAdd = 0) => {
            return DateTime.fromISO(val).plus({ days: daysToAdd, hours: hoursToAdd, minutes: minutesToAdd, months: monthsToAdd }).toUTC();
        },

        this.formTZ = (val, format = null) => {                                   
            if (val == null || val == 'Invalid DateTime') {
                return null;
            }

            if (format) {
                return DateTime.fromISO(val, { zone: this.session.timeZone }).toFormat(format);
            }
            else {
                return DateTime.fromISO(val, { zone: this.session.timeZone }).toISO();
            }            
        },

        this.formRawTZ = (val) => {
            if (val == null || val == 'Invalid DateTime') {
                return null;
            }
            
            return DateTime.fromISO(val, { zone: this.session.timeZone });
        }

        this.formISO = (val) => {
            return DateTime.fromISO(val).toISO();
        },

        this.formatDate = (val, format) => {            
            return DateTime.fromISO(val).toFormat(format);
        }

        this.isNotUTC = (val) => {
            return val.replaceAll('Z', '');
        }

        this.getCookie = (key) => {
            if (vue.$cookies.isKey(key)) {
                return vue.$cookies.get(key);
            }

            return null;
        }

        this.saveCookie = (key, value) => {
            if (value == null) {
                this.removeCookie(key);
            }
            else {
                vue.$cookies.set(key, value, 0);
            }
        }

        this.getCookieArray = (key) => {
            if (vue.$cookies.isKey(key)) {
                return JSON.parse(vue.$cookies.get(key));
            }

            return null;
        }

        this.saveCookieArray = (key, value) => {
            if (value == null) {
                this.removeCookie(key);
            }
            else {
                vue.$cookies.set(key, JSON.stringify(value), 0);
            }
        }

        this.removeAllCookies = () => {
            if (vue.$cookies.keys() != null) {
                var k = vue.$cookies.keys();
                k.forEach(key => {
                    vue.$cookies.remove(key);
                })
            }
        }

        this.removeCookie = (key) => {
            if (key == null) {
                return;
            }

            if (vue.$cookies.keys() != null) {
                if (vue.$cookies.keys().some(x => x == key)) {
                    vue.$cookies.remove(key);
                }
            }
        }

        this.jwtDecrypt = (token) => {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function(c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        },

        this.setLocation = (id) => {
            root.selectedLocationID = id;
        }

        this.setAuth = (jwtToken) => {
            if (!root.isTraining) {
                //decrypt token
                var data = this.jwtDecrypt(jwtToken);

                root.selectedLocationID = data.defaultLocationID;
                root.isLoggedIn = true;
                root.token = jwtToken;
                root.userID = data.UserLoginID;
                root.expiresOn = data.ExpiresOn;
                root.permissions = data.Permissions;
                root.subscriptionCode = data.Subscription;

                if (data.IsGlobalAdmin) {
                    root.isGlobalAdmin = data.IsGlobalAdmin == 'True';
                }

                if (data.SubscriptionsInUse) {
                    root.subscriptionsInUse = data.SubscriptionsInUse;
                }

                this.saveCookie(cookieKey, jwtToken);
            }
        },

        this.setUser = (data) => {
            root.selectedLocationID = data.defaultLocationID;

            root.data = data;
            console.log('setting user');
            root.$BlitzIt.navigation.updateBackgroundID(root.data.companyAccountID);
        },

        this.getAuthUrl = (redirectPath = null) => {
            var path = urlHelpers
                .getURL('Authentication')
                .appendURL(`authorize?response_type=code&client_id=appClient1&redirect_uri=${window.location.origin}/authentication&state=${this.getAuthState()}`);

            if (redirectPath) {
                path = path + '&redirect_path=' + redirectPath;
            }

            return path;
        },

        this.getAuthState = () => {
            let s = localStorage.getItem('authState');
            if (!s) {
                s = Math.random().toString(36).substring(4, 19) + Math.random().toString(12).substring(1, 11);
                localStorage.setItem('authState', s);
            }
            return s;
        },

        this.logout = (redirect = true) => {
            root.selectedLocationID = null;
            root.isLoggedIn = false;
            root.token = null;
            root.userID = null;
            root.expiresOn = null;
            root.data = null;
            root.permissions = null;
            root.subscriptionCode = null;
            root.subscriptionsInUse = null;
            root.isGlobalAdmin = false;
            root.$BlitzIt.store.clearData();
            
            this.removeCookie(cookieKey);
            
            this.removeAllCookies();
            
            if (redirect) {
                if (router.history.current.name != 'home') {
                    router.push({ name: 'home' });
                }
            }
        }

        this.tryLogin = async (noRedirect = false, isTraining = false) => {
            console.log('trying to log in');
            console.log(`is training: ${root.isTraining}`);
            if (!root.isTraining) {
                var subCode = getLocally('trainingSubscription');
                if (subCode != null) {
                    console.log(subCode);
                    this.startTraining(subCode);
                }
                else if (isTraining) {
                    console.log('training');
                    this.startTraining();
                }
            }
            else if (isTraining) {
                //then log out
            }

            if (root.isTraining || root.isLoggedIn) {
                return true;
            }


            if (!root.isLoggedIn) {
                var cookie = this.getCookie(cookieKey);
                if (cookie != null) {
                    this.setAuth(cookie);
                    
                    await this.loadUser(null, noRedirect);
                    return true;
                }
            }

            return false;
        }

        this.login = async (redirectPath) => {
            if (!root.isLoggedIn) {
                // if (root.isTraining) {
                    //clear
                    this.endTraining();
                // }
    
                var cookie = this.getCookie(cookieKey);
                if (cookie != null) {
                    this.setAuth(cookie);
                    await this.loadUser();
                }
                else {
                    this.redirectToLogin(redirectPath);
                }
            }
        }

        this.startTraining = (subCode = 'SUPP') => {
            root.$BlitzIt.store.clearData();
            root.isTraining = true;
            root.data = {
                id: 'user1',
                companyAccountID: 'demoCompany1',
                companyAccount: {
                    companyName: 'Cowz n Co',
                    accountName: 'Cowz n Co',
                    primaryEmail: 'demo@blitzitweb.com.au',
                    subscriptionCode: subCode,
                    defaultTimeZone: 'Australia/Melbourne',
                    isSuspended: false
                },
                creatorUserLoginID: 'user1',
                userName: 'Demo User',
                email: 'demo@blitzitweb.com.au',
                isGlobalAdmin: true
            };

            root.selectedLocationID = null;
            root.isLoggedIn = false;
            root.token = null;
            root.userID = 'user1';
            root.expiresOn = null;
            root.permissions = ['a'];
            root.subscriptionCode = subCode;
            root.isGlobalAdmin = true;

            saveLocally('trainingSubscription', root.subscriptionCode);
        }

        this.endTraining = () => {
            root.$BlitzIt.store.clearData();
            root.isTraining = false;
            removeLocally('trainingSubscription');
        }

        this.redirectToLogin = (redirectPath) => {
            if (root.isTraining) {
                //clear
                this.endTraining();
            }

            if (router.currentRoute.params && router.currentRoute.params['redirect'] != null) {
                redirectPath = router.currentRoute.params['redirect'];
            }

            root.$BlitzIt.navigation.updateHesitation();

            if (redirectPath != null) {
                window.location = this.getAuthUrl() + '&redirect_path=' + redirectPath;
            }
            else {
                window.location = this.getAuthUrl();
            }
        }

        this.loadUser = async (redirectPath, noRedirect = false) => {
            if (root.isLoggedIn && root.userID) {
                try {
                    var res = await root.$BlitzIt.store.get('my-profile', null, null, true, null, null); //this.getUserInfo(true);
                    
                    this.setUser(res);
                    
                    if (redirectPath) {
                        window.location = redirectPath;
                    }
                    else {
                        //nav based on subscription info, etc.
                        if (root.company.isSuspended) {
                            router.push({ name: 'error', params: { msg: 'Your account has been suspended! ' + root.company.reasonForSuspension } });
                        }
                        else {
                            if (!noRedirect) {
                                //navigate to relevant hub or portal or dashboard
                                if (root.subscriptionCode == 'CUST' || root.subscriptionCode == 'CUSTFREE') {
                                    router.push({ name: 'customer-dashboard' });
                                }
                                else {
                                    router.push({ name: 'office-portal-home' });
                                }
                            }
                        }
                    }
                }
                catch (err) {
                    //log out here
                    this.logout();
                    throw err;
                }
            }
            else {
                console.log('hmm');
            }
        }

        this.authenticate = async (data) => {
            console.log('authing')
            console.log(data)
            if (!data || !data.code || !data.state || data.state != this.getAuthState()) {
                //don't proceed
                router.push({ name: 'error', params: { message: 'Authentication Failed' } });
                return;
            }
            else {
                var res = await authRepo.token(data.code);
                console.log('res')
                console.log(res)
                this.setAuth(res.data.access_token);
                await this.loadUser(data.redirect_path);
            }
        }

        this.doShow = (subcodes, permissions, action) => {
            return root.doShow(subcodes, permissions, action);
        }

        this.doShowByNavName = (navName, includeChildren) => {
            return root.doShowByNavName(navName, includeChildren);
        }

        this.canEdit = (navName) => {
            return root.canEdit(navName);
        }

        this.canView = (navName) => {
            return root.canView(navName);
        }
  
        vue.prototype.$canView = root.canView;
        vue.prototype.$canEdit = root.canEdit;

        vue.prototype.$canViewPermit = root.canViewPermit;
        vue.prototype.$canEditPermit = root.canEditPermit;
        
        vue.prototype.$isWithinSubscription = root.isWithinSubscription;
        
        //helpers

        this.isCustomerSubscription = () => {
            return root.company != null && (root.company.subscriptionCode == 'CUSTFREE' || root.company.subscriptionCode == 'CUST');
        }

        this.isCourierSubscription = () => {
            return root.company != null && (root.company.subscriptionCode == 'COURFREE' || root.company.subscriptionCode == 'COUR');
        }

        this.isSupplierSubscription = () => {
            return root.company != null && (root.company.subscriptionCode == 'SUPPFREE' || root.company.subscriptionCode == 'SUPP');
        }
    },
}