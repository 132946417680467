<template>
    <!-- <v-slide-x-transition hide-on-leave> -->
        <div v-if="isLoaded">
            <slot></slot>
        </div>
        <v-card 
            v-else
            :disabled="disabled"
            height="100%" 
            min-height="100"
            :class="cardClass + ' ' + (!disabled ? 'flicker' : null)"
            class="d-flex align-center justify-center"
            @click="clicked">
            <v-btn 
                :class="!disabled ? 'flicker' : null"
                :disabled="disabled"
                outlined
                >{{ label }}</v-btn>
        </v-card>
    <!-- </v-slide-x-transition> -->
</template>

<script>
export default {
    name: 'BT-Preload',
    data() {
        return {
            isLoaded: false
        }
    },
    props: {
        cardClass: {
            type: String,
            default: 'secondary lighten-1 white--text'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: null,
        resetToggle: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        resetToggle() {
            this.isLoaded = false;
        }
    },
    methods: {
        clicked() {
            this.isLoaded = true;
            this.$emit('click');
        }
    }
}
</script>