<template>
    <div :class="inline ? 'd-inline' : ''">
        <v-slide-x-transition hide-on-leave>
            <div v-if="asyncItem != null" key="1" :class="inline ? 'd-inline' : ''">
                <slot name="prepend" v-bind:item="asyncItem">
                    {{ prefix }}
                </slot>
                <slot 
                    v-bind:deleteItem="deleteItem"
                    v-bind:isChanged="isChanged"
                    v-bind:item="asyncItem"
                    v-bind:save="save"
                    v-bind:data="data">
                    {{ displayText }}
                </slot>
                <slot name="append" v-bind:item="asyncItem" />
            </div>
            <div v-else key="2" :class="inline ? 'd-inline' : ''">
                <slot name="alternate" v-bind:item="asyncItem">
                    <span key="3">{{ alternateText }}</span>
                </slot>
            </div>
        </v-slide-x-transition>
        <v-overlay :value="loadingMsg != null" absolute class="text-center">
            <v-progress-circular indeterminate />
        </v-overlay>
    </div>
</template>

<script>
export default {
    name: 'BT-Entity',
    data: function() {
        return {
            asyncItem: null,
            currentID: null,
            loadingMsg: null,
            itemJSON: null,
            snapshotItemJSON: null,
        }
    },
    props: {
        navigation: null,
        alternateText: {
            type: String,
            default: null
        },
        changeToggle: {
            type: Boolean,
            default: false
        },
        entityProp: {
            type: String,
            default: 'id'
        },
        findItem: {
            type: Function,
            default: null
        },
        ignoreID: {
            type: Boolean,
            default: false
        },
        ignorePermissions: {
            type: Boolean,
            default: false
        },
        inline: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            default: null
        },
        itemProperties: {
            type: Array,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        onNew: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null
        },
        prefix: {
            type: String,
            default: null
        },
        proxyID: {
            type: String,
            default: null
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        saveToggle: {
            type: Boolean,
            default: false
        },
        single: {
            type: Boolean,
            default: false
        },
        textFilter: {
            type: String,
            default: null
        },
        textFunction: {
            type: Function,
            default: null
        },
        trackChanges: {
            type: Boolean,
            default: true
        },
        trackIgnore: {
            type: Array,
            default: null
        },
        useLocalCache: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.pullItem();
    },
    watch: {
        asyncItem: {
            deep: true,
            handler() {
                this.itemJSON = this.createSnapshot(this.asyncItem);
            }
        },
        changeToggle() {
            this.snapshotItemJSON = this.createSnapshot(this.copyDeep(this.asyncItem));
            this.itemJSON = this.snapshotItemJSON;
        },
        item: function() {
            this.pullItem();
        },
        itemValue: function(val) {
            if (val != this.currentID) {
                this.pullItem();
            }
        },
        refreshToggle: function() {
            this.pullItem();
        },
        saveToggle() {
            this.save();
        }
    },
    computed: {
        data() {
            return {
                isChanged: this.isChanged,
                isEditing: this.isEditing || (this.navigation != null && this.$canEdit(this.navigation)),
            }
        },
        displayText() {
            var t = this.asyncItem;

            if (this.textFunction != null && t != null) {
                return this.textFunction(t);
            }
            
            if (this.itemText != null) {
                t = this.getNestedValue(this.asyncItem, this.itemText);
            }
            
            if (this.textFilter != null) {
                return this.$options.filters[this.textFilter](t);
            }
            else {
                return t;
            }
        },
        isChanged() {
            var res = this.itemJSON != this.snapshotItemJSON || (this.asyncItem != null && this.asyncItem.id !== undefined && this.asyncItem.id == null);
            this.$emit('changed', res);
            return res;
        },
    },
    methods: {
        createSnapshot(data) {
            var copy = this.copyDeep(data);
            
            if (this.isLengthyArray(this.trackIgnore)) {
                this.trackIgnore.forEach(x => {
                    delete copy[x];
                });
            }

            return JSON.stringify(copy);
        },
        async deleteItem() {
            if (this.navigation != null && (!this.ignorePermissions && !this.$canView(this.navigation))) {
                return;
            }

            if (this.asyncItem == null) {
                return;
            }
            
            try {
                this.loadingMsg = 'Deleting';
                await this.$BlitzIt.store.delete(this.navigation, this.asyncItem.id, this.proxyID);
                this.$emit('deleted', this.asyncItem);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async pullItem() {
            console.log('e pull');
            if (this.navigation != null && (!this.ignorePermissions && !this.$canView(this.navigation))) {
                return;
            }
console.log('entity pulling');
            this.loadingMsg = 'Loading';
            this.currentID = this.itemValue;

            if (this.item != null) {
                this.asyncItem = this.item;
                this.loadingMsg = null;

                this.snapshotItemJSON = this.createSnapshot(this.asyncItem);
                this.itemJSON = this.snapshotItemJSON;
            }
            else if (this.currentID == null && !this.ignoreID && this.proxyID == null) {
                this.asyncItem = null;
                this.loadingMsg = null;
            }
            else {
                var params = this.params;
                if (params == null) {
                    params = {
                        includeDetails: false
                    }
                }
                else if (params.includeDetails == null) {
                    params.includeDetails = false;
                }
                
                if (this.isLengthyArray(this.itemProperties)) {
                    params.properties = this.itemProperties.toString();
                }

                try {
                    console.log('entity pulled');
                    if (this.single) {
                        this.asyncItem = await this.$BlitzIt.store.get(this.navigation, this.currentID, params, false, this.proxyID, null, this.useLocalCache);
                    }
                    else {
                        var res = await this.$BlitzIt.store.getAll(this.navigation, params, false, this.proxyID, null, this.useLocalCache);
                        this.asyncItem = this.findItem != null ? this.findItem(res) : res.find(y => this.getNestedValue(y, this.entityProp) === this.currentID);
                    }

                    if (this.asyncItem == null && this.onNew != null) {
                        this.asyncItem = this.onNew();
                    }

                    this.snapshotItemJSON = this.createSnapshot(this.asyncItem);
                    this.itemJSON = this.snapshotItemJSON;

                    this.$emit('loaded', this.asyncItem);
                }
                catch (err) {
                    this.loadingMsg = this.extractErrorDescription(err);
                }
                finally {
                    this.loadingMsg = null;
                }
            }
        },
        async save() {
            if (this.navigation != null && !this.$canEdit(this.navigation)) {
                return;
            }

            this.loadingMsg = 'Saving';
            var isNew = false;

            try {
                if (this.asyncItem.id == null) {
                    //add
                    isNew = true
                    var res = await this.$BlitzIt.store.post(this.navigation, this.asyncItem, this.proxyCompanyID);
                    if (res != null) {
                        this.asyncItem.id = res.id;
                        this.asyncItem.rowVersion = res.rowVersion;
                    }
                }
                else {
                    //update
                    var tRes = await this.$BlitzIt.store.patch(this.navigation, this.asyncItem, this.proxyCompanyID);
                    this.asyncItem.rowVersion = tRes.rowVersion;
                }

                if (this.trackChanges) {
                    var json = this.createSnapshot(this.asyncItem);
                    this.itemJSON = json;
                    this.snapshotItemJSON = json;
                }

                if (isNew) { this.$emit('posted'); }
                this.$emit('saved');
            }
            catch (err) {
                this.loadingMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        }
    }
}
</script>