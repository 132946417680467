<template>
    <v-list-item
        :dense="dense"
        :class="fieldClass">
        <v-list-item-avatar v-if="icon != null">
            <v-icon :size="iconSize">{{ icon }}</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
            <slot name="label">
                <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>
            </slot>
            <v-list-item-title v-if="value != null && value.companyAccount != null" class="font-weight-bold">{{ value.companyAccount.companyName }}</v-list-item-title>
            <v-list-item-title v-if="value != null && value.addressLineOne != null">{{ value.addressLineOne }}</v-list-item-title>
            <v-list-item-title v-if="value != null">
                <span v-if="value.streetNumber != null" class="mr-1">{{ value.streetNumber }}</span>
                <span v-if="value.streetName != null">{{ value.streetName }}</span>
            </v-list-item-title>
            <v-list-item-title v-if="value != null">
                <span v-if="value.suburb != null" class="mr-1">{{ value.suburb }}</span>
                <span v-if="value.state != null" class="mr-1">{{ value.state }}</span>
                <span v-if="value.postcode != null" class="mr-1">{{ value.postcode }}</span>
            </v-list-item-title>
        </v-list-item-content>
        <v-list-item-action v-if="showAction">
            <slot name="action" />
        </v-list-item-action>
    </v-list-item>
</template>

<script>
export default {
    name: 'BT-Field-Address',
    props: {
        dense: {
            type: Boolean,
            default: true
        },
        fieldClass: {
            type: String,
            default: null
        },
        icon: null,
        iconSize: null,
        label: null,
        showAction: {
            type: Boolean,
            default: false
        },
        value: null
    }
}
</script>