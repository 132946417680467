<template>
    <v-btn
        :block="block"
        :class="buttonClass ? buttonClass : danger ? 'error my-1' : (primary ? 'primary my-1' : '')"
        @click.stop="emitClick(true)"
        :small="small">
        <v-icon v-if="icon != null" left :small="small">{{ icon }}</v-icon>
        {{ label }}
        <v-icon v-if="icon == null" right :small="small">mdi-arrow-right</v-icon>
        <v-dialog v-model="showDialog" max-width="500px">
            <v-card>
                <v-card-title class="headline">{{ msg }}</v-card-title>
                <v-card-actions>
                    <v-btn text @click.stop="showDialog = false">Cancel</v-btn>
                    <v-btn text @click.stop="emitClick(false)">OK</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-btn>
</template>

<script>
export default {
    name: 'BT-Blade-Button',
    data: function() {
        return {
            showDialog: false
        }
    },
    props: {
        bladeName: {
            type: String,
            default: null
        },
        bladesData: {
            type: Object,
            default: null
        },
        block: {
            type: Boolean,
            default: true
        },
        buttonClass: {
            type: String,
            default: null
        },
        confirm: {
            type: Boolean,
            default: false
        },
        getBladeData: {
            type: Function,
            default: null
        },
        icon: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        msg: {
            type: String,
            default: 'Are you sure?'
        },
        primary: {
            type: Boolean,
            default: true
        },
        danger: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        emitClick(isFirst = false) {
            if (isFirst) {
                if (this.confirm === true) {
                    this.showDialog = true;
                    return;
                }
            }

            var selectBlade = {
                bladeName: null,
                data: {
                    id: null
                },
            }

            if (this.bladeName != null) {
                selectBlade.bladeName = this.bladeName;
            }

            if (this.id != null) {
                selectBlade.data.id = this.id;
            }

            if (this.getBladeData != null) {
                selectBlade.data = Object.assign({}, selectBlade.data, this.getBladeData());
            }

            if (this.bladeName != null) {
                if (this.bladesData != null && this.bladesData.openBlade != null) {
                    this.bladesData.openBlade(selectBlade);
                }
                else {
                    //navigate
                    this.$router.push({ name: selectBlade.bladeName, params: selectBlade.data });
                }
            }
            else {
                this.$emit('click');
            }
        }
    }
}
</script>