<template>
    <v-radio-group v-model="mVal" @change="update" column>
        <template v-slot:label>
            <slot name="label">{{ label }}</slot>
        </template>
        <slot name="options">
            <v-radio v-for="(opt, ind) in options" :key="ind" :value="opt.value">
                <template v-slot:label>
                    {{ opt.text }}
                </template>
            </v-radio>
        </slot>
    </v-radio-group>
</template>

<script>
export default {
    name: 'BT-Radio',
    data() {
        return {
            mVal: null
        }
    },
    mounted() {
        this.mVal = this.value;
    },
    watch: {
        value(v) {
            if (v !== this.mVal) {
                this.mVal = v;
            }
        }
    },
    props: {
        label: {
            type: String,
            default: null
        },
        options: {
            type: Array,
            default: () => { return [] } //{ text, value }
        },
        value: {
            Type: String,
            default: null
        },
    },
    methods: {
        update(v) {
            this.$emit('input', v);
            this.$emit('change', v);
        }
    }
}
</script>