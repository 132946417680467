import { copyItemByAlphabet, isLengthyArray } from "./helpers";

export default function(idPropertyName = 'id') {
    return {
        namespaced: true,
        state: () => ({
            allItems: [],
            items: [],
            count: null,
            filters: null, //[filter]
            lastRefreshOn: null,
            loadedSearches: [], //[{ { paramsJSON | JSON }, [results], { proxyID | String }, { count | int } }]
            promises: []
        }),
        getters: {
            count: (state) => { return state.count },
            filters: (state) => { return state.filters },
            hasFilters: (state) => { return state.filters != null },
            isItemLoaded: (state) => ({ id, params, proxyID }) => {
                if (params.properties != null && params.properties.length > 0) {
                    //check if all properties have been loaded
                    var item = state.allItems.find(x => x.data[idPropertyName] == id && x.proxyID == proxyID );
                    if (item == null) { return false; }
                    var propList = params.properties.split(',');
                    var res = Object.keys(item.data).some(y => propList.some(x => x == y));
                    return res;
                    // return Object.keys(item.data).some(y => !propList.some(x => x == y));
                }
                else {
                    //check if id with details has been loaded
                    if (params.includeDetails == true) {
                        return state.allItems.find(x => x.data[idPropertyName] == id && x.isDetailed == true && x.proxyID == proxyID) != null;
                    }
                    else {
                        return state.allItems.find(x => x.data[idPropertyName] == id && x.proxyID == proxyID) != null;
                    }
                }
            },
            promise: (state) => ({ params, proxyID, actionType, id, url, useLocalCache }) => {
                //don't stringify properties
                var copyParams = copyItemByAlphabet(params);
                delete copyParams.properties;
                var paramsJSON = JSON.stringify(copyParams);
                //even if local cache, return a promise that is pointed to the server
                var prm = state.promises.find(y => y.paramsJSON == paramsJSON && y.proxyID == proxyID && y.actionType == actionType && y.id == id && y.url == url && (useLocalCache || y.useLocalCache == useLocalCache));
                if (prm != null) {
                    return prm.promise;
                }
                return null;
            },
            isLoaded: (state) => ({ params, proxyID, url }) => { 
                //don't stringify properties
                var properties = params.properties;
                var copyParams = copyItemByAlphabet(params);
                delete copyParams.properties;
                var paramsJSON = JSON.stringify(copyParams);

                var loadedSearch = state.loadedSearches.find(y => y.paramsJSON == paramsJSON && y.proxyID == proxyID && y.url == url);

                if (loadedSearch == null) { return false; }

                if (loadedSearch.results == null) { loadedSearch.results = []; }

                for (let i = 0; i < loadedSearch.results.length; i++) {
                    const searchID = loadedSearch.results[i];
                    var searchItem = state.allItems.find(y => y.data[idPropertyName] == searchID && y.proxyID == proxyID);
                    //item exists?
                    if (searchItem == null) { return false; }
                    //item has request properties?
                    if (isLengthyArray(properties)) {
                        var itemProps = Object.keys(searchItem.data);
                        if (!properties.every(y => itemProps.some(x => x == y))) {
                            return false; //not all requested properties have been loaded
                        }
                    }
                    else {
                        if (params.includeDetails && searchItem.isDetailed == false) {
                            //check if loaded item is detailed if it needs to be
                            return false;
                        }
                    }
                }
                return true;
            },
            item: (state) => ({ id, proxyID, allSearches = false }) => { 
                var existing = state.items.find(x => x.data[idPropertyName] == id && x.proxyID == proxyID);

                if (existing != null || !allSearches) {
                    return existing != null ? existing.data : null;
                }
                else {
                    if (state.loadedSearches != null) {
                        for (let i = 0; i < state.loadedSearches.length; i++) {
                            const search = state.loadedSearches[i];
                            
                            if (Array.isArray(search.results)) {
                                var ind = search.results.findIndex(y => y[idPropertyName] == id && y.proxyID == proxyID);
                                if (ind >= 0) {
                                    return search.results[ind];
                                }
                            }
                            else if (search.results != null && search.results[idPropertyName] == id) {
                                return search.results;
                            }
                        }
                    }
                }
                
                return null;
            },
            items: (state) => { return state.items.map(x => x.data) },
            lastRefreshOn: (state) => { return state.lastRefreshOn }
        },
        mutations: {
            loadIntoMemory: (state, { params, proxyID, url }) => {
                var copyParams = copyItemByAlphabet(params);
                delete copyParams.properties;
                var paramsJSON = JSON.stringify(copyParams);
                var loadedSearch = state.loadedSearches.find(y => y.paramsJSON == paramsJSON && y.proxyID == proxyID && y.url == url);
                if (loadedSearch == null) {
                    state.items = [];
                }
                else {
                    state.count = loadedSearch.count;
                    state.items = state.allItems.filter(y => y.proxyID == proxyID && loadedSearch.results.some(x => x == y.data[idPropertyName]));
                }
            },
            //updateCount: (state, count) => { state.count = count },
            updateFilters: (state, filters) => { state.filters = filters },
            updateVersion: (state, { payload }) => { //{ item, proxyID }
                if (payload != null && payload.item != null) {
                    var eItem = state.allItems.find(x => x.data[idPropertyName] == payload.item[idPropertyName] && x.proxyID == payload.proxyID);
                    if (eItem) {
                        eItem.data.rowVersion = payload.item.rowVersion;
                    }
                    else {
                        var nItem = { isDetailed: payload.isDetailed, data: payload.item, proxyID: payload.proxyID};
                        state.allItems.push(nItem);
                        state.items.push(nItem);
                    }
                }
            },
            updateItem: (state, payload) => { //{ item, isDetailed, proxyID }
                if (payload != null && payload.item != null) {
                    const func = x => x.data[idPropertyName] == payload.item[idPropertyName] && x.proxyID == payload.proxyID;
                    var eItem = state.allItems.find(func);
                    if (eItem != null) {
                        if (eItem.url == payload.url) {
                            eItem.data = payload.item;
                            eItem.isDetailed = payload.isDetailed;
                        }
                    }
                    else {
                        state.allItems.push({ data: payload.item, isDetailed: payload.isDetailed, proxyID: payload.proxyID, url: payload.url });
                        state.items.push({ data: payload.item, isDetailed: payload.isDetailed, proxyID: payload.proxyID, url: payload.url });
                        // if (payload.url != null) {
                            
                        // }
                    }
                }
            },
            removeItem: (state, { id, proxyID }) => {
                if (id != null) {
                    var ind = -1;

                    if (state.loadedSearches != null) {
                        for (let i = 0; i < state.loadedSearches.length; i++) {
                            const search = state.loadedSearches[i];
                            
                            if (Array.isArray(search.data)) {
                                ind = search.data.findIndex(y => y.data[idPropertyName] == id && y.proxyID == proxyID);
                                if (ind >= 0) {
                                    search.data.splice(ind, 1);
                                }
                            }
                            else if (search.data != null && search.data[idPropertyName] == id) {
                                state.loadedSearches.splice(i, 1);
                                i -= 1;
                            }
                        }
                    }
                    
                    ind = state.allItems.findIndex(y => y.data[idPropertyName] == id && y.proxyID == proxyID);
            
                    if (ind >= 0) {
                        state.allItems.splice(ind, 1);
                    }

                    ind = state.items.findIndex(y => y.data[idPropertyName] == id && y.proxyID == proxyID);
                    
                    if (ind >= 0) {
                        state.items.splice(ind, 1);
                    }
                }
                //state.items = state.items.filter(x => x.data[idPropertyName] != id);
            },
            clear: (state) => {
                state.allItems = [],
                state.items = [],
                state.count = null,
                state.lastRefreshOn = null
                state.filters = null;               
                state.loadedSearches = []
            },
            addPromise: (state, { promise, params, proxyID, actionType, id, url, useLocalCache }) => {
                var copyParams = copyItemByAlphabet(params);
                delete copyParams.properties;
                var paramsJSON = JSON.stringify(copyParams);
                state.promises.push({ paramsJSON, proxyID, promise, actionType, id, url, useLocalCache });
            },
            removePromise: (state, { params, proxyID, actionType, id, url, useLocalCache }) => {
                var copyParams = copyItemByAlphabet(params);
                delete copyParams.properties;
                var paramsJSON = JSON.stringify(copyParams);
                var ind = state.promises.findIndex(y => y.paramsJSON == paramsJSON && y.proxyID == proxyID && y.actionType == actionType && y.id == id && y.url == url && y.useLocalCache == useLocalCache);
                if (ind >= 0) {
                    state.promises.splice(ind, 1);
                }
            },
            addLoadedSearch: (state, { params, results, count, proxyID, url }) => {
                var properties = params.properties;
                var copyParams = copyItemByAlphabet(params);
                delete copyParams.properties;
                var paramsJSON = JSON.stringify(copyParams);
                var ids = results != null ? results.map(y => y.id) : [];
                state.loadedSearches.push({ paramsJSON, results: ids, count: count != null ? count : 0, proxyID, url });
                //update all items
                var rList = [];
                if (isLengthyArray(results)) {
                    for (let i = 0; i < results.length; i++) {
                        let item = results[i];
                        var existing = state.allItems.find(y => y.data[idPropertyName] == item[idPropertyName] && y.proxyID == proxyID);
                        if (existing != null) {
                            existing.data = Object.assign(existing.data, item);
                            if (!isLengthyArray(properties)) {
                                //update isDetailed
                                existing.isDetailed = params.includeDetails;
                            }
                            rList.push(existing);
                        }
                        else {
                            var nItem = null;
                            if (!isLengthyArray(properties)) {
                                nItem = { isDetailed: params.includeDetails, data: item, proxyID: proxyID };
                            }                        
                            else {
                                //properties
                                nItem = { isDetailed: false, data: item, proxyID: proxyID };
                            }
                            state.allItems.push(nItem);
                            rList.push(nItem);
                        }
                    }
                }
                
                state.count = count;
                state.items = rList;
            }
        }
    }
}