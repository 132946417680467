export const TransferOrderStatus = {
    PENDING: 'pending',
    CONSIGNED: 'consigned',
    PACKED: 'packed',
    DISPATCHED: 'dispatched',
}

export const SubCode = {
    NONE: 0,
    CUSTFREE: 1,
    CUST: 2,
    COUR: 3,
    SUPP: 4
}

//permission
export const PM = {
    EVERYTHING: 'everything',
    COMPANYDETAILS: 'company-details',
    SUBSCRIPTION: 'company-subscription',
    LOCATION: 'locations',
    EXTPARTY: 'external-parties',
    USER: 'users',
    USERROLE: 'user-roles',
    ROLE: 'roles',
    USERCONNECTION: 'user-connections',
    CUSTOMER: 'customers',
    CUSTOMERTEMPLATE: 'customer-templates',
    CUSTOMERREQUEST: 'customer-requests',
    CUSTOMERORDERSETTING: 'order-customer-settings',
    SUPPLIERORDERSETTING: 'order-supplier-settings',
    ORDERRULE: 'order-rules',
    ORDERSLOT: 'order-slots',
    PRODUCTOFFERINGGROUP: 'product-offering-groups',
    PRODUCT: 'products',
    SUPPLIER: 'suppliers',
    SUPPLIERREQUEST: 'supplier-requests',
    PAYABLEITEM: 'payable-items',
    //RECEIVABLEITEM: 'receivable-items',
    PRICINGSETTING: 'pricing-settings',
    STOCKTHRESHOLD: 'stock-thresholds',
    BATCH: 'stock-batches',
    PACKAGES: 'packages',
    STOCKLEVEL: 'stock-levels',
    STOCKITEM: 'stock-items',
    STOCKITEMSETTING: 'stock-item-settings',
    STOCKCONSIGNMENTS: 'stock-consignments',
    STOCKTRANSFER: 'stock-transfers',
    STOCKMANAGEMENTCUSTOMER: 'stock-management-customers',
    STOCKMANAGEMENTSETTING: 'stock-management-settings',
    CLIENT: 'clients',
    CLIENTTEMPLATE: 'client-templates',
    CLIENTREQUEST: 'client-requests',
    COURIER: 'couriers',
    JOURNEY: 'journeys',
    COURIERSETTINGS: 'courier-settings'
}