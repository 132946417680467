<template>
    <v-btn
        :block="block"
        :class="(inline ? null : 'ma-1') + ' ' + buttonClass"
        @click.stop="emitClick(true)"
        :dark="dark"
        :disabled="isDisabled"
        :height="inline ? 'null' : cHeight"
        :href="href"
        :icon="hasIcon && label == null && !isButton"
        :large="large"
        :loading="loading"
        :outlined="outlined"
        :rounded="rounded"
        :small="small"
        :text="text"
        :title="title"
        :x-small="verySmall">
        <v-icon v-if="hasIcon && label == null" :large="large" :small="small">{{ icon || leftIcon || rightIcon }}</v-icon>
        <div v-else-if="!inline" class="d-flex flex-column my-1">
            <v-icon v-if="leftIcon != null || icon != null" class="my-1">{{ icon || leftIcon || rightIcon }}</v-icon>
            <div v-if="label != null">{{ label }}</div>
        </div>
        <span v-else>
            <v-icon v-if="leftIcon != null || leftIcon != null" :large="large" :small="small" :left="label != null">{{ icon || leftIcon }}</v-icon>
            {{ label }}
            <v-icon v-if="rightIcon != null" :large="large" :small="small" :right="label != null">{{ rightIcon }}</v-icon>
        </span>
    </v-btn>
</template>

<script>
export default {
    name: 'BT-Btn',
    data: function() {
        return {
            showDialog: false
        }
    },
    props: {
        acceptText: {
            type: String,
            default: 'Yes'
        },
        bladeName: {
            type: String,
            default: null
        },
        bladesData: {
            type: Object,
            default: null
        },
        block: {
            type: Boolean,
            default: false
        },
        buttonClass: {
            type: String,
            default: null
        },
        cancelText: {
            type: String,
            default: 'No'
        },
        confirm: {
            type: Boolean,
            default: false
        },
        confirmText: {
            type: String,
            default: 'Are you sure?'
        },
        dark: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        getBladeData: {
            type: Function,
            default: null
        },
        getQueryData: {
            type: Function,
            default: null
        },
        height: {
            type: String,
            default: 'auto'
        },
        href: null,
        icon: {
            type: String,
            default: null
        },
        id: {
            type: String,
            default: null
        },
        inline: {
            type: Boolean,
            default: false
        },
        isButton: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        leftIcon: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        nav: {
            type: Boolean,
            default: false
        },
        navigation: {
            type: String,
            default: null
        },
        outlined: {
            type: Boolean,
            default: false
        },
        rightIcon: {
            type: String,
            default: null
        },
        rounded: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        text: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: null
        },
        to: {
            type: Object,
            default: null
        },
        verySmall: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasIcon() {
            return this.icon != null || this.leftIcon != null || this.rightIcon != null;
        },
        cHeight() {
            return this.small ? 30 : (this.large ? 80 : this.height);
        },
        isDisabled() {
            if (this.navigation != null) {
                return this.disabled || !this.$BlitzIt.auth.doShowByNavName(this.navigation, true);
            }

            return this.disabled;
        },
    },
    methods: {
        async emitClick(isFirst = false) {
            if (this.href != null) {
                return;
            }
            if (isFirst) {
                if (this.confirm === true || this.confirmText != 'Are you sure?') {
                    if (!await this.$confirm({ text: this.confirmText, acceptText: this.acceptText, cancelText: this.cancelText })) {
                        return;
                    }
                }
            }

            var selectBlade = {
                bladeName: null,
                data: {
                    id: null
                },
            }

            if (this.bladeName != null) {
                selectBlade.bladeName = this.bladeName;
            }

            if (this.id != null) {
                selectBlade.data.id = this.id;
            }

            if (this.getBladeData != null) {
                selectBlade.data = Object.assign({}, selectBlade.data, this.getBladeData());
            }

            if (this.to != null) {
                this.$router.push(this.to);
            }
            else if (this.bladeName != null) {
                if (this.bladesData != null && this.bladesData.openBlade != null) {
                    this.bladesData.openBlade(selectBlade);
                }
                else {
                    //navigate
                    this.$router.push({ name: selectBlade.bladeName, params: selectBlade.data, query: this.getQueryData != null ? this.getQueryData() : null });
                }
            }
            else {
                this.$emit('click');
            }
        }
    }
}
</script>