<template>
    <v-tabs-items v-model="panel" :style="sliderStyle">
        <v-tab-item>
            <v-list active-class="primary">
                <v-list-item v-for="(setting, index) in options" :key="index" @click="panel = (index + 1)" two-line :disabled="setting.disabled == true">
                    <v-list-item-content>
                        <v-list-item-title>{{ setting.title }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-icon>mdi-arrow-right</v-icon>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
        </v-tab-item>
        <v-tab-item v-for="(setting, index) in options" :key="index">
            <v-slide-x-transition hide-on-leave>
                <v-toolbar v-if="!hideHeader" flat>
                    <v-btn icon @click="panel = 0">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ setting.title }}</v-toolbar-title>
                </v-toolbar>
            </v-slide-x-transition>
            <slot :name="setting.value">

            </slot>
        </v-tab-item>
    </v-tabs-items>
</template>

<script>
    export default {
        name: 'BT-Settings-List',
        data() {
            return {
                panel: 0
            }
        },
        props: {
            hideHeader: {
                type: Boolean,
                default: false
            },
            options: { //[{ title, value, disabled }]
                type: Array,
                default: () => []
            },
            width: {
                type: Number,
                default: null
            }
        },
        computed: {
            sliderStyle() {
                if (this.width != null) {
                    return `width: ${this.width}px`
                }

                return '';
            }
        },
        mounted() {
            if (this.$route.query.slide != null) {
                var ind = this.options.findIndex(x => x.value == this.$route.query.slide);
                if (ind >= 0) {
                    this.panel = ind + 1;
                }
            }
        },
        watch: {
            '$route': function(route) {
                var slide = route.query?.slide;

                if (slide == null && this.panel !== 0) {
                    this.panel = 0;
                }
                else if (slide != null) {
                    var ind = this.options.findIndex(x => x.value == slide);
                    if (ind >= 0 && this.panel !== (ind + 1)) {
                        this.panel = ind + 1;
                    }
                }
            }
        }
    }
</script>