import { DateTime } from "luxon/src/luxon";
import { isLengthyArray } from '~helpers';

const SignatureDefault = "[{\"x\":1,\"y\":1},{\"x\":60,\"y\":60},{\"x\":30,\"y\":30},{\"x\":1,\"y\":60},{\"x\":30,\"y\":30},{\"x\":60,\"y\":1},{\"x\":30,\"y\":30},{\"x\":60,\"y\":1}]";

//#region companies
const Cowz = {
    accountName: 'Cowz n Co',
    companyName: 'Cowz n Co',
    defaultTimeZone: 'Australia/Melbourne',
    id: 'demoCompany1',
    isManagedByAnotherAccount: false,
    isSuspended: false,
    primaryEmail: 'demo@blitzitweb.com.au',
    themeColour: '#1b6550'
};

const CobdenCrayons = {
    companyName: 'Cobden Crayons',
    id: 'demoCust1',
    isManagedByAnotherAccount: true,
    themeColour: '#313f5c'
};

const TimboonTreats = {
    companyName: 'Timboon Treacle Treats',
    id: 'demoCust2',
    isManagedByAnotherAccount: true,
    themeColour: '#752476'
};

const DeepCreek = {
    companyName: 'Deep Creek Company',
    id: 'demoCust3',
    isManagedByAnotherAccount: true,
    themeColour: '#752476'
};

const PortlandPackaging = {
    companyName: 'Portland Packaging',
    id: 'demoSupp1',
    isManagedByAnotherAccount: false,
    themeColour: '#9a629a'
};

const LegenDairy = {
    companyName: 'LegenDairy',
    id: 'demoSupp2',
    isManagedByAnotherAccount: false,
    themeColour: '#752476'
};
//#endregion

//#region locations

const CowzLocation = {
    addressLineOne: null,
    companyAccountID: Cowz.id,
    companyAccount: Cowz,
    id: '1',
    lat: -38,
    lng: 142,
    locationName: 'Warehouse',
    postcode: '4000',
    rowVersion: 'AAAAAAAAAAAAAA',
    state: 'VIC',
    streetName: 'Quartz Rd',
    streetNumber: '29',
    suburb: 'Stonebury'
}

const CowzLocationShop = {
    id: '2',
    addressLineOne: null,
    companyAccountID: Cowz.id,
    companyAccount: Cowz,
    lat: -38.320499,
    lng: 143.079758,
    locationName: 'Shop',
    postcode: '4000',
    rowVersion: "AAAAAAAAAAAAAA",
    state: 'VIC',
    streetName: 'Marble Lane',
    streetNumber: '168',
    suburb: 'Stonebury'
}

const CobdenCrayonLocation = {
    id: '3',
    addressLineOne: null,
    companyAccountID: CobdenCrayons.id,
    companyAccount: CobdenCrayons,
    lat: -38.320499,
    lng: 143.079758,
    locationName: 'Shop',
    postcode: '4050',
    rowVersion: 'AAAAAAAAAAAAAA',
    state: 'VIC',
    streetName: 'Crows Nest Lane',
    streetNumber: '2373',
    suburb: 'Cobden'
};

const CobdenCrayonLocationTwo = {
    id: '6',
    addressLineOne: null,
    companyAccountID: CobdenCrayons.id,
    lat: -38.681519,
    lng: 143.370140,
    locationName: 'Wholesale Outlet',
    postcode: '4050',
    rowVersion: 'AAAAAAAAAAAAAA',
    state: 'NSW',
    streetName: 'Pinnacle Crescent',
    streetNumber: '2290',
    suburb: 'Twin Peaks'
}

const TimboonTreatsLocation = {
    id: '4',
    addressLineOne: null,
    companyAccountID: TimboonTreats.id,
    companyAccount: TimboonTreats,
    lat: -38.466952,
    lng: 142.747672,
    locationName: 'Cellar',
    postcode: '4000',
    rowVersion: 'AAAAAAAAAAAAAA',
    state: 'VIC',
    streetName: 'Maple St',
    streetNumber: '1',
    suburb: 'Syrupy Bld'
}

const DeepCreekLocation = {
    id: '5',
    addressLineOne: null,
    companyAccountID: DeepCreek.id,
    companyAccount: DeepCreek,
    lat: -38.376423,
    lng: 142.484366,
    locationName: 'Farmhouse',
    postcode: '4000',
    rowVersion: 'AAAAAAAAAAAAAA',
    state: 'VIC',
    streetName: 'Watery Lane',
    streetNumber: '1',
    suburb: 'Trickling Falls'
};

const PortlandPackagingLocation = {
    id: '7',
    addressLineOne: null,
    companyAccountID: PortlandPackaging.id,
    companyAccount: PortlandPackaging,
    lat: -38.376423,
    lng: 142.484366,
    locationName: 'Factory',
    postcode: '6547',
    rowVersion: 'AAAAAAAAAAAAAA',
    state: 'VIC',
    streetName: 'Cardy Crs',
    streetNumber: '1',
    suburb: 'Boxers Lane'
};

const LegenDairyLocation = {
    id: '8',
    addressLineOne: null,
    companyAccountID: LegenDairy.id,
    companyAccount: LegenDairy,
    lat: -38.376423,
    lng: 142.484366,
    locationName: 'Dairyfarm',
    postcode: '3265',
    rowVersion: 'AAAAAAAAAAAAAA',
    state: 'VIC',
    streetName: 'Daisy Lane',
    streetNumber: '110',
    suburb: 'Farmlin South'
};

//#endregion

//#region users

const CowzUser = {
    companyAccountID: Cowz.id,
    companyAccount: Cowz,
    email: 'demo@user.com',
    firstName: 'Demo',
    id: 'user1',
    isGlobalAdmin: true,
    lastName: 'User',
    userName: 'Demo User',
}

const PortlandUser = {
    email: 'demo@portland.com',
    firstName: 'Portland',
    lastName: 'User',
    id: 'user2',
}

const LegenDairyUser = {
    email: 'demo@dairy.com',
    firstName: 'Dairy',
    lastName: 'Farmer',
    id: 'user3',
}

//#endregion

//#region categories

const Plastics = {
    categoryName: 'Plastics',
    categoryDescription: 'Plastics category description',
    companyAccountID: PortlandPackaging.id,
    companyAccount: PortlandPackaging,
    id: 'demoCat1',
    rowVersion: 'AAA',
}

const Cardboards = {
    categoryName: 'Cardboards',
    categoryDescription: 'Cardboard packaging and stuff like that.',
    companyAccountID: PortlandPackaging.id,
    companyAccount: PortlandPackaging,
    id: 'demoCat2',
    rowVersion: 'AAA'
}

//#endregion

//#region products

const MilkFullCreamTwoLitre = {
    abbreviation: 'M2FC',
    batchcodeFormat: 'yyyMMdd',
    companyAccountID: Cowz.id,
    expiryDays: 14,
    height: null,
    id: '1',
    isAcquired: false,
    isInactive: false,
    isInfinite: false,
    isManufactured: true,
    isPerishable: true,
    isReturnable: false,
    isSold: true,
    isTemperatureSensitive: true,
    length: null,
    productName: 'Milk 2-Litre Full Cream',
    rowVersion: 'AAAA',
    sortNumber: 5,
    volume: null,
    weight: 1.2,
    width: null
};

const MilkLowFatOneLitre = {
    abbreviation: 'L1',
    batchcodeFormat: 'yyyMMdd',
    companyAccountID: Cowz.id,
    expiryDays: 14,
    height: null,
    id: '2',
    isAcquired: false,
    isInactive: false,
    isInfinite: false,
    isManufactured: true,
    isPerishable: true, 
    isReturnable: false,
    isSold: true,
    isTemperatureSensitive: true,
    length: null,
    productName: 'Milk 1-Litre Low Fat',
    rowVersion: 'AAAA',
    sortNumber: 6,
    volume: null,
    weight: 1.05,
    width: null
};

const SweetChilliIceCream = {
    abbreviation: 'CHIC',
    batchcodeFormat: 'yyyMMdd',
    companyAccountID: Cowz.id,
    expiryDays: 50,
    height: null,
    id: '3',
    isAcquired: false,
    isInactive: false,
    isInfinite: false,
    isManufactured: true,
    isPerishable: true,
    isReturnable: false,
    isSold: true,
    isTemperatureSensitive: true,
    length: null,
    placeholderProductID: null,
    productName: 'Sweet Chilli Ice Cream - 395g',
    rowVersion: 'AAAA',
    sortNumber: 7,
    volume: null,
    weight: 0.5,
    width: null,
    taxType: null,
    tags: null,
}

const CheeseWheel = {
    abbreviation: 'CHWH',
    batchcodeFormat: 'yyyMMdd',
    companyAccountID: Cowz.id,
    expiryDays: 100,
    height: null,
    id: '5',
    isAcquired: false,
    isInactive: false,
    isInfinite: false,
    isManufactured: true,
    isPerishable: true,
    isReturnable: false,
    isSold: true,
    isTemperatureSensitive: true,
    length: null,
    placeholderProductID: null,
    productName: 'Cheese Wheel',
    rowVersion: 'AAAA',
    sortNumber: 15,
    volume: null,
    weight: 0.5,
    width: null,
    taxType: null,
    tags: null,
}

const CheeseKG = {
    abbreviation: 'CHKG',
    batchcodeFormat: null,
    companyAccountID: Cowz.id,
    expiryDays: 100,
    height: null,
    id: '6',
    isAcquired: false,
    isInactive: false,
    isInfinite: false,
    isManufactured: true,
    isPerishable: true,
    isReturnable: false,
    isSold: true,
    isTemperatureSensitive: true,
    length: null,
    placeholderProductID: null,
    productName: 'Cheese KG',
    rowVersion: 'AAAA',
    sortNumber: 16,
    volume: null,
    weight: 0.5,
    width: null,
    taxType: null,
    tags: null,
}

const GlassBottle = {
    abbreviation: 'GL1B',
    batchcodeFormat: 'yyyMMdd',
    companyAccountID: Cowz.id,
    height: null,
    id: '4',
    isAcquired: false,
    isInactive: false,
    isInfinite: false,
    isManufactured: false,
    isPerishable: false,
    isReturnable: true,
    isSold: false,
    isTemperatureSensitive: false,
    length: null,
    productName: '1-Litre Glass Bottle',
    rowVersion: 'AAAA',
    sortNumber: 9,
    volume: null,
    weight: 1.2,
    width: null
}

const PlasticBottle = {
    abbreviation: 'PBOT',
    batchcodeFormat: null,
    categoryID: Plastics.id,
    companyAccountID: PortlandPackaging.id,
    expiryDays: 0,
    height: null,
    id: 'demoProd1',
    isAcquired: false,
    isInactive: false,
    isInfinite: false,
    isManufactured: true,
    isPerishable: true,
    isReturnable: false,
    isSold: true,
    isTemperatureSensitive: false,
    length: null,
    productName: 'Plastic Bottle',
    publicDescription: 'Just your regular everyday perfectly shaped plastic recyclable bottle.',
    rowVersion: 'AAAA',
    sortNumber: 5,
    volume: null,
    weight: 1.2,
    width: null
}

const CardboardBox = {
    abbreviation: 'CBOX',
    batchcodeFormat: null,
    categoryID: Cardboards.id,
    companyAccountID: PortlandPackaging.id,
    expiryDays: 0,
    height: null,
    id: 'demoProd3',
    isAcquired: false,
    isInactive: false,
    isInfinite: false,
    isManufactured: false,
    isPerishable: false,
    isReturnable: false,
    isSold: true,
    isTemperatureSensitive: false,
    length: null,
    productName: 'Cardboard Container 500g',
    publicDescription: 'Eco-friendly cardboard packing for whatever you need to package.',
    rowVersion: 'AAAA',
    sortNumber: 6,
    volume: null,
    weight: 1.2,
    width: null
}

const ButterBlob = {
    abbreviation: 'BBLB',
    batchcodeFormat: null,
    companyAccountID: LegenDairy.id,
    expiryDays: 21,
    height: null,
    id: 'demoProd2',
    isAcquired: false,
    isInactive: false,
    isInfinite: false,
    isManufactured: true,
    isPerishable: true,
    isReturnable: false,
    isSold: true,
    isTemperatureSensitive: true,
    length: null,
    productName: 'Blob of Butter',
    rowVersion: 'AAAA',
    sortNumber: 8,
    volume: null,
    weight: 1.2,
    width: null
}

//#endregion

//#region batches

const BatchMilkTwoLitreFirst = {
    batchID: null,
    batchcode: 'M2M210001',
    createdOn: DateTime.utc().toString(),
    dom: DateTime.utc().toString(),
    exp: DateTime.utc().plus({ days: 14 }).toString(),
    id: '6',
    isPending: true,
    onHold: false,
    locationID: CowzLocation.id,
    productID: MilkFullCreamTwoLitre.id,
    product: MilkFullCreamTwoLitre,
    quantity: 0,
    quantityGoal: 1007,
    rowVersion: 'AAA',
}

const BatchMilkTwoLitreSecond = {
    batchID: null,
    batchcode: 'M2M22209345',
    createdOn: DateTime.utc().toString(),
    dom: DateTime.utc().toString(),
    exp: DateTime.utc().plus({ days: 18 }).toString(),
    id: '7',
    isPending: true,
    onHold: false,
    locationID: CowzLocation.id,
    productID: MilkFullCreamTwoLitre.id,
    product: MilkFullCreamTwoLitre,
    quantity: 0,
    quantityGoal: 638,
    rowVersion: 'AAA'
}

const BatchMilkTwoLitreThird = {
    batchID: null,
    batchcode: 'M2M2120922022',
    createdOn: DateTime.utc().toString(),
    dom: DateTime.utc().plus({ days: -5 }).toString(),
    exp: DateTime.utc().plus({ days: 14 }).toString(),
    id: '1',
    isPending: false,
    onHold: false,
    productID: MilkFullCreamTwoLitre.id,
    product: MilkFullCreamTwoLitre,
    quantity: 11000,
    quantityGoal: 0,
    rowVersion: 'AAA'
}

const BatchMilkTwoLitreFourth = {
    batchID: null,
    batchcode: 'M2M2220922022',
    createdOn: DateTime.utc().toString(),
    dom: DateTime.utc().plus({ days: -4 }).toString(),
    exp: DateTime.utc().plus({ days: 15 }).toString(),
    id: '2',
    isPending: false,
    onHold: false,
    productID: MilkFullCreamTwoLitre.id,
    product: MilkFullCreamTwoLitre,
    quantity: 110,
    quantityGoal: 0,
    rowVersion: 'AAA'
}

const BatchMilkLowFatFirst = {
    batchID: null,
    batchcode: 'L1212643',
    createdOn: DateTime.utc().toString(),
    dom: DateTime.utc().toString(),
    exp: DateTime.utc().plus({ days: 26 }).toString(),
    id: '8',
    isPending: false,
    onHold: false,
    locationID: CowzLocation.id,
    productID: MilkLowFatOneLitre.id,
    product: MilkLowFatOneLitre,
    quantity: 0,
    quantityGoal: 6500,
    rowVersion: 'AAA'
}

const BatchSweetChilliFirst = {
    batchID: null,
    batchcode: 'CHIC153745556',
    createdOn: DateTime.utc().toString(),
    dom: DateTime.utc().toString(),
    exp: DateTime.utc().plus({ days: 10 }).toString(),
    id: '9',
    isPending: false,
    onHold: false,
    locationID: CowzLocation.id,
    productID: SweetChilliIceCream.id,
    product: SweetChilliIceCream,
    quantity: 0,
    quantityGoal: 5,
    rowVersion: 'AAA'
}

const BatchSweetChilliSecond = {
    batchID: null,
    batchcode: 'CHIC210922022',
    createdOn: DateTime.utc().toString(),
    dom: DateTime.utc().plus({ days: -4 }).toString(),
    exp: DateTime.utc().plus({ days: 15 }).toString(),
    id: '5',
    isPending: false,
    onHold: false,
    productID: SweetChilliIceCream.id,
    product: SweetChilliIceCream,
    quantity: 11,
    quantityGoal: 0,
    rowVersion: 'AAA'
}

const BatchCheeseWheel = {
    batchID: null,
    batchcode: 'CHWH210922022',
    dom: DateTime.utc().plus({ days: -4 }).toString(),
    exp: DateTime.utc().plus({ days: 100 }).toString(),
    id: '10',
    isPending: false,
    onHold: false,
    productID: CheeseWheel.id,
    product: CheeseWheel,
    quantity: 6,
    quantityGoal: 6,
    rowVersion: 'AAA'
}

//#endregion

//#region agreements

const CobdenCrayonsAgreement = {
    buyerID: CobdenCrayons.id,
    buyer: CobdenCrayons,
    id: '1',
    isCustomerOrderingEnabled: false,
    rowVersion: 'AAA',
    supplyAgreementTemplateID: '1'
}

const TimboonTreatsAgreement = {
    buyerID: TimboonTreats.id,
    buyer: TimboonTreats,
    id: '2',
    isCustomerOrderingEnabled: false,
    rowVersion: 'AAA',
    supplyAgreementTemplateID: '1',
}

const DeepCreekAgreement = {
    buyerID: DeepCreek.id,
    buyer: DeepCreek,
    id: '3',
    isCustomerOrderingEnabled: false,
    rowVersion: 'AAA',
    supplyAgreementTemplateID: null
}

const PortlandPackagingAgreement = {
    buyerID: Cowz.id,
    buyer: Cowz,
    id: '4',
    isCustomerOrderingEnabled: true,
    rowVersion: 'AAA',
    sellerID: PortlandPackaging.id,
    seller: PortlandPackaging,
}

const LegenDairyAgreement = {
    buyerID: Cowz.id,
    buyer: Cowz,
    id: '5',
    isCustomerOrderingEnabled: true,
    rowVersion: 'AAA',
    sellerID: LegenDairy.id,
    seller: LegenDairy
}

//#endregion

//#region journey templates

var JourneyTemplateWesternRoute = {
    id: '1',
    endLocationID: CowzLocation.id,
    endLocation: CowzLocation,
    journeyName: 'Western Route',
    maxDestinations: 15,
    maxPackages: 25,
    maxWeight: 350,
    rowVersion: 'AAAAAAAA',
    startLocationID: CowzLocation.id,
    startLocation: CowzLocation
}

var JourneyTemplateInnerCity = {
    id: '2',
    endLocationID: CowzLocation.id,
    endLocation: CowzLocation,
    journeyName: 'Inner City Route',
    maxDestinations: 15,
    maxPackages: 25,
    maxWeight: 350,
    rowVersion: 'AAAAAAAA',
    startLocationID: CowzLocation.id,
    startLocation: CowzLocation
}

//#endregion

//#region measurements

var MilkCrate = {
    height: 320,
    id: '1',
    length: 350,
    measurementName: 'Crate',
    abbreviation: 'c',
    measurementStandard: 'Milk Crate',
    volume: null,
    weight: null,
    width: 350
}

var ChepPallet = {
    height: 600,
    id: '2',
    length: 1250,
    measurementName: 'Chep Pallet',
    abbreviation: 'p',
    measurementStandard: 'Standard Chep Pallet',
    volume: null,
    weight: null,
    width: 1250
}

//#endregion

//#region movements

const MovementOne = {
    id: '1',
    arrivalSortNumber: 1,
    bundleID: null,
    bundleName: null,
    buyerID: CobdenCrayons.id,
    buyer: CobdenCrayons,
    canBundle: false,
    courierID: Cowz.id,
    courier: Cowz,
    deliveredByUserID: null,
    deliveredOn: null,
    departureLocationID: CowzLocation.id,
    departureLocation: CowzLocation,
    departureSortNumber: 0,
    destinationLocationID: CobdenCrayonLocation.id,
    destinationLocation: CobdenCrayonLocation,
    dueArrivalOn: DateTime.utc().toString(),
    dueDepartureOn: DateTime.utc().toString(),
    isChanged: false,
    isFulfilled: false,
    isInactive: false,
    isPickup: false,
    isSourceChanged: false,
    journeyID: '1',
    packagedItems: [
        {
            code: null,
            height: 320,
            id: '1',
            length: 350,
            measurementID: '1',
            measurementStandard: 'Milk Crate',
            quantityDelivered: 0,
            quantityPacked: 6,
            quantityReceived: 0,
            quantityReleased: 0,
            volume: null,
            weight: null,
            width: 350
        }
    ],
    proofOfDelivery: null,
    proofOfRelease: null,
    receivedByUserID: null,
    receiverName: null,
    releasedByUserID: null,
    releasedOn: null,
    rowVersion: 'AAAA',
    sellerID: Cowz.id,
    seller: Cowz,
    tempOnDelivery: null
}

const MovementTwo = {
    id: '2',
    arrivalSortNumber: 2,
    bundleID: null,
    bundleName: null,
    buyerID: TimboonTreats.id,
    buyer: TimboonTreats,
    canBundle: false,
    courierID: Cowz.id,
    courier: Cowz,
    deliveredByUserID: null,
    deliveredOn: null,
    departureLocationID: CowzLocation.id,
    departureLocation: CowzLocation,
    departureSortNumber: 0,
    destinationLocationID: TimboonTreatsLocation.id,
    destinationLocation: TimboonTreatsLocation,
    dueArrivalOn: DateTime.utc().toString(),
    dueDepartureOn: DateTime.utc().toString(),
    isChanged: false,
    isFulfilled: false,
    isInactive: false,
    isPickup: false,
    isSourceChanged: false,
    journeyID: '1',
    packagedItems: [
        {
            code: null,
            height: 600,
            id: '2',
            length: 1250,
            measurementID: '2',
            measurementStandard: 'Standard Chep Pallet',
            quantityDelivered: 0,
            quantityPacked: 1,
            quantityReceived: 0,
            quantityReleased: 0,
            volume: null,
            weight: null,
            width: 1250
        }
    ],
    proofOfDelivery: null,
    proofOfRelease: null,
    receivedByUserID: null,
    receiverName: null,
    releasedByUserID: null,
    releasedOn: null,
    rowVersion: 'AAAA',
    sellerID: Cowz.id,
    seller: Cowz,
    tempOnDelivery: null
}

const MovementThree = {
    id: '3',
    arrivalSortNumber: 3,
    bundleID: null,
    bundleName: null,
    buyerID: DeepCreek.id,
    buyer: DeepCreek,
    canBundle: false,
    courierID: Cowz.id,
    courier: Cowz,
    deliveredByUserID: null,
    deliveredOn: null,
    departureLocationID: CowzLocation.id,
    departureLocation: CowzLocation,
    departureSortNumber: 0,
    destinationLocationID: DeepCreekLocation.id,
    destinationLocation: DeepCreekLocation,
    dueArrivalOn: DateTime.utc().toString(),
    dueDepartureOn: DateTime.utc().toString(),
    isChanged: false,
    isFulfilled: false,
    isInactive: false,
    isPickup: false,
    isSourceChanged: false,
    journeyID: '1',
    packagedItems: [
        {
            code: null,
            height: 320,
            id: '3',
            length: 350,
            measurementID: '1',
            measurementStandard: 'Milk Crate',
            quantityDelivered: 0,
            quantityPacked: 6,
            quantityReceived: 0,
            quantityReleased: 0,
            volume: null,
            weight: null,
            width: 350
        }
    ],
    proofOfDelivery: null,
    proofOfRelease: null,
    receivedByUserID: null,
    receiverName: null,
    releasedByUserID: null,
    releasedOn: null,
    rowVersion: 'AAAA',
    sellerID: Cowz.id,
    seller: Cowz,
    tempOnDelivery: null
}

const MovementFour = {
    id: '4',
    arrivalSortNumber: 0,
    bundleID: null,
    bundleName: null,
    buyerID: DeepCreek.id,
    buyer: DeepCreek,
    canBundle: false,
    courierID: Cowz.id,
    courier: Cowz,
    deliveredByUserID: null,
    deliveredOn: null,
    departureLocationID: CowzLocation.id,
    departureLocation: CowzLocation,
    departureSortNumber: 0,
    destinationLocationID: DeepCreekLocation.id,
    destinationLocation: DeepCreekLocation,
    dueArrivalOn: DateTime.utc().plus({ days: 1 }).toString(),
    dueDepartureOn: DateTime.utc().plus({ days: 1 }).toString(),
    isChanged: false,
    isFulfilled: false,
    isInactive: false,
    isPickup: false,
    isSourceChanged: false,
    journeyID: null,
    packagedItems: [
        {
            code: null,
            height: 320,
            id: '3',
            length: 350,
            measurementID: '1',
            measurementStandard: 'Milk Crate',
            quantityDelivered: 0,
            quantityPacked: 60,
            quantityReceived: 0,
            quantityReleased: 0,
            volume: null,
            weight: null,
            width: 350
        }
    ],
    proofOfDelivery: null,
    proofOfRelease: null,
    receivedByUserID: null,
    receiverName: null,
    releasedByUserID: null,
    releasedOn: null,
    rowVersion: 'AAAA',
    sellerID: Cowz.id,
    seller: Cowz,
    tempOnDelivery: null
}

const MovementFive = {
    id: '5',
    arrivalSortNumber: 1,
    bundleID: null,
    bundleName: null,
    buyerID: CobdenCrayons.id,
    buyer: CobdenCrayons,
    canBundle: false,
    courierID: Cowz.id,
    courier: Cowz,
    deliveredByUserID: null,
    deliveredOn: null,
    departureLocationID: CowzLocation.id,
    departureLocation: CowzLocation,
    departureSortNumber: 0,
    destinationLocationID: CobdenCrayonLocation.id,
    destinationLocation: CobdenCrayonLocation,
    dueArrivalOn: DateTime.utc().toString(),
    dueDepartureOn: DateTime.utc().toString(),
    isChanged: false,
    isFulfilled: false,
    isInactive: false,
    isPickup: true,
    isSourceChanged: false,
    journeyID: null,
    packagedItems: [
        {
            code: null,
            height: 320,
            id: '1',
            length: 350,
            measurementID: '1',
            measurementStandard: 'Milk Crate',
            quantityDelivered: 0,
            quantityPacked: 6,
            quantityReceived: 0,
            quantityReleased: 0,
            volume: null,
            weight: null,
            width: 350
        }
    ],
    proofOfDelivery: null,
    proofOfRelease: null,
    receivedByUserID: null,
    receiverName: null,
    releasedByUserID: null,
    releasedOn: null,
    rowVersion: 'AAAA',
    sellerID: Cowz.id,
    seller: Cowz,
    tempOnDelivery: null
}

//#endregion

//#region archived movements

const MovementArchivedOne = {
    buyerID: Cowz.id,
    courierID: Cowz.id,
    deliveredByUserID: PortlandUser.id,
    deliveredOn: DateTime.utc().plus({ days: -3 }).toString(),
    departureLocationID: PortlandPackagingLocation.id,
    destinationLocationID: CowzLocation.id,
    driverNotes: 'A very smooth delivery.',
    dueArrivalOn: DateTime.utc().plus({ days: -3, hours: 1 }).toString(),
    dueDepartureOn: DateTime.utc().plus({ days: -3, hours: -1 }).toString(),
    id: '1',
    isFlagged: false,
    isPickup: false,
    journeyID: null,
    lat: -38.8307476919384,
    lng: 143.833771209375,
    packagedItems: [],
    proofOfDelivery: SignatureDefault,
    proofOfRelease: SignatureDefault,
    receivedByUserID: CowzUser.id,
    receiverName: 'Andy',
    releasedOn: DateTime.utc().plus({ days: -3 }).toString(),
    releasedByUserID: PortlandUser.id,
    sellerID: PortlandPackaging.id,
    tempOnDelivery: 3.4,
}

const MovementArchivedTwo = {
    buyerID: Cowz.id,
    courierID: LegenDairy.id,
    deliveredByUserID: LegenDairyUser.id,
    deliveredOn: DateTime.utc().plus({ days: -7 }).toString(),
    departureLocationID: LegenDairyLocation.id,
    destinationLocationID: CowzLocation.id,
    driverNotes: 'A very smooth delivery.',
    dueArrivalOn: DateTime.utc().plus({ days: -7, hours: 1 }).toString(),
    dueDepartureOn: DateTime.utc().plus({ days: -7, hours: -1 }).toString(),
    id: '2',
    isFlagged: false,
    isPickup: false,
    journeyID: null,
    lat: -38.8307476919384,
    lng: 143.833771209375,
    packagedItems: [],
    proofOfDelivery: SignatureDefault,
    proofOfRelease: SignatureDefault,
    receivedByUserID: CowzUser.id,
    receiverName: 'Andy',
    releasedOn: DateTime.utc().plus({ days: -3 }).toString(),
    releasedByUserID: LegenDairyUser.id,
    sellerID: LegenDairy.id,
    tempOnDelivery: 3.4,
}

//#endregion

//#region purchase orders

const PortlandPackagingPurchaseOrder = {
    amountTotal: 100,
    buyerID: Cowz.id,
    buyer: Cowz,
    confirmedOn: null,
    confirmedByUserID: null,
    customerOrderNumber: '12453',
    dueDate: DateTime.utc().toString(),
    fulfillerID: PortlandPackaging.id,
    fulfiller: PortlandPackaging,
    id: '10',
    isBuyerCourier: false,
    isConfirmed: null,
    isDispatched: false,
    locationID: CowzLocationShop.id,
    location: CowzLocationShop,
    orderItems: [
        {
            id: '1',
            description: PlasticBottle.productName,
            unitPrice: 5,
            discount: 0,
            sortNumber: 1,
            tags: null,
            taxType: null,
            isPlaceholder: false,
            placeholderProductID: null,
            productID: PlasticBottle.id,
            quantity: 20
        }
    ],
    purchaseOrderNumber: '768',
    rowVersion: 'AAAA',
    sellerID: PortlandPackaging.id,
    seller: PortlandPackaging,
    subTotal: 100,
    supplyAgreementID: PortlandPackagingAgreement.id,
    taxTotal: 0
}

const LegenDairyPurchaseOrderOne = {
    amountTotal: 1050.34,
    buyerID: Cowz.id,
    buyer: Cowz,
    confirmedOn: DateTime.utc().toString(),
    confirmedByUserID: null,
    customerOrderNumber: '120',
    dueDate: DateTime.utc().toString(),
    fulfillerID: LegenDairy.id,
    fulfiller: LegenDairy,
    id: '11',
    isBuyerCourier: false,
    isConfirmed: true,
    isDispatched: false,
    locationID: CowzLocation.id,
    location: CowzLocation,
    orderItems: [
        {
            id: '1',
            description: ButterBlob.productName,
            unitPrice: 8.5,
            discount: 0,
            sortNumber: 8,
            tags: null,
            taxType: null,
            isPlaceholder: false,
            placeholderProductID: null,
            productID: ButterBlob.id,
            quantity: 3
        }
    ],
    purchaseOrderNumber: '68',
    rowVersion: 'AAAA',
    sellerID: LegenDairy.id,
    seller: LegenDairy,
    subTotal: 100,
    supplyAgreementID: LegenDairyAgreement.id,
    taxTotal: 0
}

const LegenDairyPurchaseOrderTwo = {
    amountTotal: 42.5,
    buyerID: Cowz.id,
    buyer: Cowz,
    confirmedOn: DateTime.utc().toString(),
    confirmedByUserID: null,
    customerOrderNumber: '121',
    dueDate: DateTime.utc().plus({ days: -1 }).toString(),
    fulfillerID: LegenDairy.id,
    fulfiller: LegenDairy,
    id: '12',
    isBuyerCourier: false,
    isConfirmed: true,
    isDispatched: true,
    locationID: CowzLocation.id,
    location: CowzLocation,
    orderItems: [
        {
            id: '1',
            description: ButterBlob.productName,
            unitPrice: 8.5,
            discount: 0,
            sortNumber: 8,
            tags: null,
            taxType: null,
            isPlaceholder: false,
            placeholderProductID: null,
            productID: ButterBlob.id,
            quantity: 5
        }
    ],
    purchaseOrderNumber: '3',
    rowVersion: 'AAAA',
    sellerID: LegenDairy.id,
    seller: LegenDairy,
    subTotal: 42.5,
    supplyAgreementID: LegenDairyAgreement.id,
    taxTotal: 0
}

//#endregion

export default {
    delete(navName, data) {
        var dataObj = this.data.find(x => x.name == navName);

        if (dataObj != null && data != null && data.id != null) {
            var list = this.getData(dataObj);
            if (isLengthyArray(list)) {
                var ind = list.findIndex(x => x.id == data.id)
                if (ind > -1) {
                    // dataObj.data.splice(ind, 1);
                    list.splice(ind, 1);
                }
            }
        }

        return new Promise(function(resolve) {
            setTimeout(function() {
                resolve({
                    data: {
                        data: null
                    }
                })
            }, 200)
        })
    },
    //retrieves the list of data - via name or aliases
    getData(dataObj) {
        var l = dataObj.data;
        if (dataObj.name != null) {
            var e = this.data.find(x => x.aliases != null && x.aliases.some(id => id == dataObj.name));
            if (e != null && e.data != null) {
                l = e.data;
            }
        }

        return l;
    },
    get(navName, id, path) {
        //first try match path
        var dataObj = null;
        var res = null;

        if (path != null) {
            var url = new URL(path);
            dataObj = this.data.find(x => x.path == url.pathname || x.path == (url.pathname + url.search));
            // if (dataObj == null) {
            //     console.log('data unable to find path: ' + path);
            // }
            // else {
            //     console.log('foudn path');
            // }
        }

        if (dataObj == null) {
            dataObj = this.data.find(x => x.name == navName);
        }
        
        if (dataObj != null) {
            var list = this.getData(dataObj);

            if (dataObj.getAction != null) {
                console.log('getting action');
                console.log(id);
                res = dataObj.getAction(list, id, this.data);
            }
            else {
                if (id != null) {
                    res = list.find(x => x.id == id);
                    console.log(res);
                }
                else {
                    if (list.data != undefined) {
                        res = list.data;
                    }
                    else {
                        res = list;
                    }
                }
            }
        }
        
        return new Promise(function(resolve) {
            setTimeout(function() {
                resolve({
                    data: {
                        data: res,
                        filters: []
                    }
                })
            }, 200)
        })
    },
    getAll(navName, path, params) {
        var dataObj = null;
        var res = null;

        if (path != null) {
            var url = new URL(path);
            dataObj = this.data.find(x => x.path == url.pathname);
        }

        if (dataObj == null) {
            dataObj = this.data.find(x => x.name == navName);
        }

        if (dataObj == null) {
            dataObj = this.data.find(x => x.aliases != null && x.aliases.some(id => id == navName));
        }

        if (dataObj != null) {
            var list = this.getData(dataObj);
            if (dataObj.getAllAction != null) {
                res = dataObj.getAllAction(list, params);
            }
            else {
                res = list;
            }
        }
        else {
            res = dataObj != null ? dataObj.data : null;
        }

        return new Promise(function(resolve) {
            setTimeout(function() {
                resolve({
                    data: {
                        data: res,
                        filters: [],
                        count: res != null ? res.length : 0
                    }
                })
            }, 200)
        })
    },
    patch(navName, data) {
        var dataObj = this.data.find(x => x.name == navName);
        var res = null;

        if (dataObj != null && data != null && data.id != null) {
            var list = this.getData(dataObj);
            if (dataObj.patchAction != null) {
                res = dataObj.patchAction(list, data);
            }
            else {
                var ind = list.findIndex(x => x.id == data.id);
                if (ind > -1) {
                    list.splice(ind, 1, data);
                    res = data;
                }
            }
        }

        return new Promise(function(resolve) {
            setTimeout(function() {
                resolve({
                    data: {
                        data: res,
                    }
                })
            }, 200)
        })
    },
    post(navName, data) {
        var dataObj = this.data.find(x => x.name == navName);

        if (dataObj != null) {
            var list = this.getData(dataObj);
            if (dataObj.postAction != null) {
                data = dataObj.postAction(list, data, this.data);
            }
            else {
                if (data.id == null) {
                    data.id = (list.length + 1).toString();
                }
            
                list.push(data);
            }
        }

        return new Promise(function(resolve) {
            setTimeout(function() {
                resolve({
                    data: {
                        data: data,
                    }
                })
            }, 200)
        })
    },

    data: [
        {
            name: 'batches',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.filterBy != null) {
                        if (params.filterBy.includes('Returnable')) {
                            r = r.filter(x => x.isReturnable);
                        }
                        if (params.filterBy.includes('Pending')) {
                            r = r.filter(x => x.isPending);
                        }
                    }
                    if (params.locationID != null) {
                        r = r.filter(x => x.locationID == params.locationID);
                    }
                }

                return r;
            },
            postAction: (list, post, db) => {
                post.product = db.find(x => x.name == 'products').data.find(x => x.id == post.productID);
                list.push(post);
                return post;
            },
            data: [
                //pending
                BatchMilkTwoLitreFirst,
                BatchMilkTwoLitreSecond,
                BatchMilkTwoLitreThird,
                BatchMilkTwoLitreFourth,
                BatchMilkLowFatFirst,
                BatchSweetChilliFirst,
                BatchSweetChilliSecond,
                BatchCheeseWheel
            ]
        },
        {
            name: 'customers',
            data: [
                CobdenCrayonsAgreement,
                TimboonTreatsAgreement,
                DeepCreekAgreement
            ]
        },
        {
            name: 'customer-consignments',
            getAllAction: (list, params) => {
                var r = list;

                if (params != null) {
                    if (params.ids != null) {
                        var idList = params.ids.split(',');
                        r = r.filter(x => idList.some(id => x.id == id)); //x.id == params.ids);
                    }
                }

                return r;
            },
            data: [
                {
                    buyerID: CobdenCrayons,
                    buyer: CobdenCrayons,
                    consignmentNumber: '46',
                    departureLocationID: CowzLocation.id,
                    departureLocation: CowzLocation,
                    destinationLocationID: CobdenCrayonLocation.id,
                    destinationLocation: CobdenCrayonLocation,
                    dueDate: DateTime.utc().toString(),
                    dueReadyOn: null,
                    fulfillmentBuyerID: Cowz.id,
                    id: '1',
                    isBuyerCourier: false,
                    isInactive: false,
                    isOrdered: true,
                    isReadyToOrderCourier: true,
                    isRequestPickup: false,
                    isSourceChanged: false,
                    orderWeight: 58.1,
                    packagedItems: [
                        {
                            code: null,
                            height: 320,
                            id: '10',
                            length: 350,
                            measurementID: '1',
                            measurementStandard: 'Milk Crate',
                            quantity: 6,
                            volume: null,
                            weight: null,
                            width: 350
                        }
                    ],
                    packedByUserID: null,
                    packedOn: null,
                    readyOn: DateTime.utc().toString(),
                    rowVersion: 'AAA',
                    sellerID: Cowz.id,
                    seller: Cowz,
                    stockConsignmentID: '1',
                    stockWeight: 58.1
                },
                {
                    buyerID: TimboonTreats,
                    buyer: TimboonTreats,
                    consignmentNumber: '468',
                    departureLocationID: CowzLocation.id,
                    departureLocation: CowzLocation,
                    destinationLocationID: TimboonTreatsLocation.id,
                    destinationLocation: TimboonTreatsLocation,
                    dueDate: DateTime.utc().toString(),
                    dueReadyOn: null,
                    fulfillmentBuyerID: Cowz.id,
                    id: '2',
                    isBuyerCourier: false,
                    isInactive: false,
                    isOrdered: true,
                    isReadyToOrderCourier: true,
                    isRequestPickup: false,
                    isSourceChanged: false,
                    orderWeight: 58.1,
                    packagedItems: [
                        {
                            code: null,
                            height: 320,
                            id: '10',
                            length: 350,
                            measurementID: '1',
                            measurementStandard: 'Milk Crate',
                            quantity: 6,
                            volume: null,
                            weight: null,
                            width: 350
                        }
                    ],
                    packedByUserID: null,
                    packedOn: null,
                    readyOn: DateTime.utc().toString(),
                    rowVersion: 'AAA',
                    sellerID: Cowz.id,
                    seller: Cowz,
                    stockConsignmentID: '2',
                    stockWeight: 58.1
                },
                {
                    buyerID: DeepCreek.id,
                    buyer: DeepCreek,
                    consignmentNumber: '46',
                    departureLocationID: CowzLocation.id,
                    departureLocation: CowzLocation,
                    destinationLocationID: DeepCreekLocation.id,
                    destinationLocation: DeepCreekLocation,
                    dueDate: DateTime.utc().toString(),
                    dueReadyOn: null,
                    fulfillmentBuyerID: Cowz.id,
                    id: '3',
                    isBuyerCourier: false,
                    isInactive: false,
                    isOrdered: true,
                    isReadyToOrderCourier: true,
                    isRequestPickup: false,
                    isSourceChanged: false,
                    orderWeight: 58.1,
                    packagedItems: [
                        {
                            code: null,
                            height: 320,
                            id: '10',
                            length: 350,
                            measurementID: '1',
                            measurementStandard: 'Milk Crate',
                            quantity: 6,
                            volume: null,
                            weight: null,
                            width: 350
                        }
                    ],
                    packedByUserID: null,
                    packedOn: null,
                    readyOn: DateTime.utc().toString(),
                    rowVersion: 'AAA',
                    sellerID: Cowz.id,
                    seller: Cowz,
                    stockConsignmentID: '3',
                    stockWeight: 58.1
                },
                {
                    buyerID: CobdenCrayons.id,
                    buyer: CobdenCrayons,
                    consignmentNumber: '46',
                    departureLocationID: CowzLocation.id,
                    departureLocation: CowzLocation,
                    destinationLocationID: CobdenCrayonLocation.id,
                    destinationLocation: CobdenCrayonLocation,
                    dueDate: DateTime.utc().toString(),
                    dueReadyOn: null,
                    fulfillmentBuyerID: Cowz.id,
                    id: '5',
                    isBuyerCourier: false,
                    isInactive: false,
                    isOrdered: true,
                    isReadyToOrderCourier: true,
                    isRequestPickup: true,
                    isSourceChanged: false,
                    orderWeight: 58.1,
                    packagedItems: [
                        {
                            code: null,
                            height: 320,
                            id: '10',
                            length: 350,
                            measurementID: '1',
                            measurementStandard: 'Milk Crate',
                            quantity: 6,
                            volume: null,
                            weight: null,
                            width: 350
                        }
                    ],
                    packedByUserID: null,
                    packedOn: null,
                    readyOn: DateTime.utc().toString(),
                    rowVersion: 'AAA',
                    sellerID: Cowz.id,
                    seller: Cowz,
                    stockConsignmentID: '5',
                    stockWeight: 58.1
                },
            ]
        },
        {
            name: 'customer-invoices',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    // if (params.filterBy != null) {
                        
                    // }
                    if (params.customerIDs != null) {
                        r = r.filter(x => x.buyerID == params.customerIDs);
                    }
                }

                return r;
            },
            data: [
                {
                    amountPaid: 0,
                    amountTotal: 150,
                    buyerID: DeepCreek.id,
                    buyer: DeepCreek,
                    customerOrderNumber: '455',
                    dueOn: DateTime.utc().plus({ days: 10 }).toString(),
                    id: '3',
                    invoiceNumber: '3452',
                    isBuyerCourier: false,
                    isConfirmed: true,
                    isDispatched: true,
                    isPaid: false,
                    issuedOn: DateTime.utc().toString(),
                    lineItems: [
                        {
                            description: 'Milk 2-Litre Full Cream',
                            discount: 0,
                            id: '1',
                            onOrder: 550,
                            itemID: '1',
                            quantity: 550,
                            sortNumber: 1,
                            tags: null,
                            taxType: null,
                            unitPrice: 4.5,
                        },
                        {
                            description: 'Sweet Chilli Ice Cream - 395g',
                            discount: 0,
                            id: '1',
                            onOrder: 60,
                            itemID: '4',
                            quantity: 50,
                            sortNumber: 2,
                            tags: null,
                            taxType: 'GST',
                            unitPrice: 2,
                        }
                    ],
                    paidOn: null,
                    purchaseOrderNumber: '34',
                    rowVersion: 'AAAA',
                    supplyAgreementID: DeepCreekAgreement.id,
                    taxTotal: 12,
                    subTotal: 150
                },
            ]
        },
        {
            name: 'customer-orders',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    // if (params.filterBy != null) {
                        
                    // }
                    if (params.customerIDs != null) {
                        r = r.filter(x => x.buyerID == params.customerIDs);
                    }
                }

                return r;
            },
            postAction: (list, post, db) => {
                var dbList = db.find(x => x.name == 'customers').data;
                post.buyer = dbList.find(x => x.id == post.supplyAgreementID).buyer;
                post.buyerID = post.buyer.id;
                post.customerOrderNumber = dbList.length + 1;
                post.purchaseOrderNumber = dbList.length + 1000;

                list.push(post);
                return post;
            },
            data: [
                {
                    amountTotal: 150,
                    buyerID: DeepCreek.id,
                    buyer: DeepCreek,
                    customerOrderNumber: '455',
                    fulfillerID: Cowz.id,
                    fulfiller: Cowz,
                    orderItems: [
                        {
                            id: '5',
                            description: 'Milk 2-Litre Full Cream',
                            unitPrice: 4.5,
                            discount: 0,
                            sortNumber: 5,
                            tags: null,
                            taxType: null,
                            isPlaceholder: false,
                            placeholderProductID: null,
                            productID: '1',
                            quantity: 550
                        },
                        {
                            id: '6',
                            description: 'Sweet Chilli Ice Cream - 395g',
                            unitPrice: 2,
                            discount: 0,
                            sortNumber: 7,
                            tags: null,
                            taxType: null,
                            isPlaceholder: false,
                            placeholderProductID: null,
                            productID: '3',
                            quantity: 1
                        },
                        {
                            id: '7',
                            description: 'Cheese Wheel',
                            unitPrice: 75,
                            discount: 0,
                            sortNumber: 15,
                            tags: null,
                            taxType: null,
                            isPlaceholder: false,
                            placeholderProductID: '6',
                            productID: '5',
                            quantity: 5
                        }
                    ],
                    locationID: DeepCreekLocation.id,
                    location: DeepCreekLocation,
                    confirmedOn: DateTime.utc().toString(),
                    confirmedByUserID: null,
                    isConfirmed: true,
                    isBuyerCourier: false,
                    dueDate: DateTime.utc().toString(),
                    id: '3',
                    supplyAgreementID: '1',
                    isDispatched: true,
                    purchaseOrderNumber: '34',
                    rowVersion: 'AAAA',
                    taxTotal: 0,
                    subTotal: 150
                },
            ]
        },
        {
            name: 'deliveries',
            patchAction: (list, patch) => {
                var item = null;
                if (list != null && patch != null && patch.id != null) {
                    item = list.find(x => x.id == patch.id);
                }

                if (item == null) {
                    item = patch;
                }

                if (patch.proofOfDelivery != null) {
                    item.deliveredOn = DateTime.utc().toString();
                    item.deliveredByUserID = '1';
                    item.proofOfDelivery = patch.proofOfDelivery;
                    item.receiverName = patch.receiverName;
                }
                
                return item;
            }
        },
        {
            path: '/api/v1/Courier/Deliveries/get/GetConsignmentIDs?id=1,2,3',
            data: [
                {
                    key: '1',
                    value: ['1']
                },
                {
                    key: '2',
                    value: ['2']
                },
                {
                    key: '3',
                    value: ['3']
                }
            ]
        },
        {
            path: '/api/v1/Courier/Deliveries/get/GetConsignmentIDs?id=1',
            data: [
                {
                    key: '1',
                    value: ['1']
                }
            ]
        },
        {
            path: '/api/v1/Courier/Releases/GetConsignmentIDs/GetConsignmentIDs/1',
            data: ['1']
        },
        {
            path: '/api/v1/Courier/Releases/GetConsignmentIDs/GetConsignmentIDs/2',
            data: ['2']
        },
        {
            path: '/api/v1/Courier/Releases/GetConsignmentIDs/GetConsignmentIDs/3',
            data: ['3']
        },
        {
            path: '/api/v1/Courier/Releases/GetConsignmentIDs/GetConsignmentIDs/5',
            data: ['5']
        },
        {
            path: '/api/v1/Courier/Journeys/GetConsignmentIDs/GetConsignmentIDs/1',
            data: ['1', '2', '3']
        },
        {
            name: 'journeys',
            getAction: (list, id, db) => {
                var j = list.find(x => x.id == id);

                j.movements = db.find(x => x.name == 'courier-movements').data.filter(x => x.journeyID == id);

                return j;
            },
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.departureLocationID != null) {
                        r = r.filter(x => x.startLocationID == params.departureLocationID);
                    }
                }

                return r;
            },
            patchAction: (list, patch) => {
                var item = null;
                if (list != null && patch != null && patch.id != null) {
                    item = list.find(x => x.id == patch.id);
                }

                if (item == null) {
                    item = patch;
                }

                if (patch.isStarted === true) {
                    item.startedOn = DateTime.utc().toString();
                }

                if (patch.isReadyRequest === true) {
                    item.isReady = true;

                }

                if (patch.movements != null) {
                    patch.lastPackages = patch.movements.length * 5;
                    patch.lastWeight = patch.movements.length * 13;
                }
                
                return item;
            },
            data: [
                {
                    id: '1',
                    destinationCount: 3,
                    driverUserID: CowzUser.id,
                    dueEndOn: DateTime.utc().toString(),
                    dueStartOn: DateTime.utc().toString(),
                    endLocationID: CowzLocation.id,
                    endLocation: CowzLocation,
                    endedOn: null,
                    isReady: true,
                    journeyName: 'Western Route',
                    journeyTemplateID: JourneyTemplateWesternRoute.id,
                    lastPackages: 13,
                    lastWeight: 161,
                    maxDestinations: 15,
                    maxPackages: 25,
                    maxWeight: 350,
                    movements: [],
                    rowVersion: 'AAAAAAAA',
                    startLocationID: CowzLocation.id,
                    startLocation: CowzLocation,
                    startedOn: null
                },
                {
                    id: '2',
                    destinationCount: 0,
                    driverUserID: CowzUser.id,
                    dueEndOn: DateTime.utc().toString(),
                    dueStartOn: DateTime.utc().toString(),
                    endLocationID: CowzLocation.id,
                    endLocation: CowzLocation,
                    endedOn: null,
                    isReady: false,
                    journeyName: 'Inner City Route',
                    journeyTemplateID: JourneyTemplateInnerCity.id,
                    lastPackages: 0,
                    lastWeight: 0,
                    maxDestinations: 15,
                    maxPackages: 25,
                    maxWeight: 350,
                    movements: [],
                    rowVersion: 'AAAAAAAA',
                    startLocationID: CowzLocation.id,
                    startLocation: CowzLocation,
                    startedOn: null
                },
            ]
        },
        {
            name: 'journey-pointers',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.journeyTemplateIDs != null) {
                        var idList = params.journeyTemplateIDs.split(',');
                        r = r.filter(x => idList.some(id => x.journeyTemplateID == id));
                    }
                }

                return r;
            },
            data: [
                {
                    id: '1',
                    pointerName: 'Area 1',
                    boundary: [
                        { 
                            lat: -38.8307476919384,
                            lng: 143.833771209375
                        },
                        {
                            lat: -36.79603581514871,
                            lng: 143.8172917171875
                        },
                        {
                            lat: -37.38319951283704,
                            lng: 141.9326481625
                        },
                        {
                            lat: -38.86497292761361,
                            lng: 141.767853240625
                        }],
                    weekdays: 'Always',
                    journeyTemplateID: '1',
                    journeyTemplate: null
                },
                {
                    id: '2',
                    pointerName: 'Area 2',
                    boundary: [
                        {
                            lat: -38.8136289,
                            lng: 145.8222966
                        },
                        {
                            lat: -36.8136289,
                            lng: 145.8222966
                        },
                        {
                            lat: -36.8136289,
                            lng: 143.8222966
                        },
                        {
                            lat: -38.8136289,
                            lng: 143.8222966
                        }],
                    weekdays: 'Always',
                    journeyTemplateID: '2',
                    journeyTemplate: null
                },
                {
                    id: '3',
                    pointerName: 'Area 1',
                    boundary: [
                        {
                            lat: -38.151431476447804,
                            lng: 145.371857146875
                        },
                        {
                            lat: -37.662027706222354,
                            lng: 145.31692550625
                        },
                        {
                            lat: -37.46172524635872,
                            lng: 144.3441471859375
                        },
                        {
                            lat: -38.164389481780006,
                            lng: 144.8275456234375
                        }],
                    weekdays: 'Mon,Tue,Wed',
                    journeyTemplateID: '1',
                    journeyTemplate: null
                }
            ]
        },
        {
            name: 'journey-templates',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.departureLocationID != null) {
                        r = r.filter(x => x.startLocationID == params.departureLocationID);
                    }
                }

                return r;
            },
            data: [
                JourneyTemplateWesternRoute,
                JourneyTemplateInnerCity
            ]
        },
        {
            name: 'locations',
            data: [
                CowzLocation,
                CowzLocationShop
            ]
        },
        {
            name: 'measurements',
            data: [
                MilkCrate,
                ChepPallet
            ]
        },
        {
            name: 'my-profile',
            getAction: () => {
                return CowzUser;
            },
            data: [
                CowzUser
            ]
        },
        {
            name: 'dispatch-releases'
        },
        {
            name: 'courier-movements',
            aliases: ['deliveries','releases','dispatch-releases','receivals'],
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.departureLocationID != null) {
                        r = r.filter(x => x.departureLocationID == params.departureLocationID);
                    }
                    if (params.filterBy != null) {
                        var filters = params.filterBy.split(',');

                        filters.forEach(f => {
                            if (f == 'With No Journeys') {
                                r = r.filter(x => x.journeyID == null);
                            }
                            else if (f == 'In Journeys') {
                                r = r.filter(x => x.journeyID != null);
                            }
                            else if (f == 'Delivered') {
                                r = r.filter(x => x.deliveredOn != null);
                            }
                            else if (f == 'Released') {
                                r = r.filter(x => x.releasedOn != null);
                            }
                            else if (f == 'Pending') {
                                r = r.filter(x => x.releasedOn == null);
                            }
                            else if (f == 'Bundled') {
                                r = r.filter(x => x.bundleID != null);
                            }
                            else if (f == 'Pickups') {
                                r = r.filter(x => x.isPickup);
                            }
                            else if (f == 'Not Pickups') {
                                r = r.filter(x => x.isPickup == false);
                            }
                        })
                    }
                }

                return r;
            },
            data: [
                MovementOne,
                MovementTwo,
                MovementThree,
                MovementFour,
                MovementFive
            ]
        },
        // {
        //     name: 'public-products',

        // },
        {
            name: 'products',
            aliases: ['public-products'],
            getAllAction: (list, params) => {
                console.log('p prodc');
                var r = list;
                if (params != null) {
                    if (params.filterBy != null) {
                        if (params.filterBy.includes('Returnable')) {
                            r = r.filter(x => x.isReturnable);
                        }
                    }
                    if (params.companyID != null) {
                        r = r.filter(x => x.companyAccountID == params.companyID);
                    }
                }

                return r;
            },
            data: [
                MilkFullCreamTwoLitre,
                MilkLowFatOneLitre,
                SweetChilliIceCream,
                CheeseWheel,
                CheeseKG,
                GlassBottle,
                PlasticBottle,
                CardboardBox,
                ButterBlob
            ]
        },
        {
            name: 'product-categories',
            aliases: ['public-product-categories'],
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.companyID != null) {
                        r = r.filter(x => x.companyAccountID == params.companyID);
                    }
                }

                return r;
            },
            data: [
                Cardboards,
                Plastics
            ]
        },
        {
            name: 'public-locations',
            data: [
                CowzLocation,
                CowzLocationShop,
                CobdenCrayonLocation,
                CobdenCrayonLocationTwo,
                TimboonTreatsLocation,
                DeepCreekLocation,
                PortlandPackagingLocation,
                LegenDairyLocation
            ]
        },
        {
            name: 'public-product-categories',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.companyID != null) {
                        r = r.filter(x => x.companyAccountID == params.companyID);
                    }
                }
                return r;
            },
            data: [
                Cardboards,
                Plastics
            ]
        },
        {
            name: 'public-users',
            data: [
                CowzUser,
                PortlandUser,
                LegenDairyUser
            ]
        },
        {
            name: 'purchase-orders',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.agreementID != null) {
                        r = r.filter(x => x.supplyAgreementID == params.agreementID);
                    }
                    if (params.supplierIDs != null) {
                        r = r.filter(x => x.sellerID == params.supplierIDs);
                    }
                }
                return r;
            },
            data: [
                PortlandPackagingPurchaseOrder,
                LegenDairyPurchaseOrderOne,
                LegenDairyPurchaseOrderTwo
            ]
        },
        {
            name: 'public-companies',
            data: [
                Cowz,
                CobdenCrayons,
                TimboonTreats,
                DeepCreek,
                PortlandPackaging,
                LegenDairy
            ]
        },
        {
            name: 'public-suppliers',
            data: [
                Cowz,
                CobdenCrayons,
                TimboonTreats,
                DeepCreek,
                PortlandPackaging,
                LegenDairy
            ]
        },
        {
            name: 'receivals',
            data: [
                MovementOne,
                MovementTwo,
                MovementThree,
                MovementFour,
                MovementFive
            ]
        },
        {
            name: 'receival-archives',
            getAllAction: (list, params) => {
                var r = list.orderByDate(x => x.deliveredOn);
                if (params != null) {
                    if (params.takeAmount != null) {
                        if (r.length > params.takeAmount) {
                            r = r.slice(0, params.takeAmount);
                        }
                    }
                }
                return r;
            },
            data: [
                MovementArchivedOne,
                MovementArchivedTwo,
            ]
        },
        {
            name: 'releases',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.departureLocationID != null) {
                        r = r.filter(x => x.departureLocationID == params.departureLocationID);
                    }
                    if (params.filterBy.includes('Unfulfilled')) {
                        r = r.filter(x => !x.isFulfilled);
                    }
                    if (params.filterBy.includes('Pickups')) {
                        r = r.filter(x => x.isPickup);
                    }
                    if (params.filterBy.includes('Pending')) {
                        r = r.filter(x => x.releasedOn == null);
                    }
                    if (params.filterBy.includes('Released')) {
                        r = r.filter(x => x.releasedOn != null);
                    }
                    if (params.filterBy.includes('Bundled')) {
                        r = r.filter(x => x.bundleID != null);
                    }
                    if (params.filterBy.includes('Unbundled')) {
                        r = r.filter(x => x.bundleID == null);
                    }
                }

                return r;
            },
            patchAction: (list, patch) => {
                var item = null;
                if (list != null && patch != null && patch.id != null) {
                    item = list.find(x => x.id == patch.id);
                }

                if (item == null) {
                    item = patch;
                }

                if (patch.proofOfRelease != null) {
                    item.releasedOn = DateTime.utc().toString();
                    item.proofOfRelease = patch.proofOfRelease;
                    item.releasedByUserID = '1';
                }

                return item;
            },
            data: [
                MovementOne,
                MovementTwo,
                MovementThree,
                MovementFour,
                MovementFive
            ]
        },
        {
            name: 'standing-customer-orders',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    // if (params.filterBy != null) {
                        
                    // }
                    if (params.customerIDs != null) {
                        r = r.filter(x => x.buyerID == params.customerIDs);
                    }
                }

                return r;
            },
            postAction: (list, post, db) => {
                var dbList = db.find(x => x.name == 'customers').data;
                post.buyer = dbList.find(x => x.id == post.supplyAgreementID).buyer;
                post.buyerID = post.buyer.id;
              
                list.push(post);
                return post;
            },
            data: [
                {
                    adjustments: null,
                    buyerID: DeepCreek.id,
                    buyer: DeepCreek,
                    cronExpression: '0 0 * * 1 0',
                    id: '1',
                    isBuyerCourier: false,
                    isOn: true,
                    orderItems: [
                        {
                            id: '1',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 5
                        },
                        {
                            id: '2',
                            productID: MilkLowFatOneLitre.id,
                            product: MilkLowFatOneLitre,
                            quantity: 54
                        }
                    ],
                    locationID: DeepCreekLocation.id,
                    location: DeepCreekLocation,
                    nextAttentionDate: DateTime.utc().plus({ days: 1 }).toString(),
                    orderSlots: [
                        {
                            slotName: 'Monday Order',
                            cronExpression: '',
                            isOn: true,
                            nextAttentionDate: DateTime.utc().plus({ days: 1}).toString(),
                            orderSlotGroupID: '1',
                            adjustments: null
                        }
                    ],
                    rowVersion: 'AAAA',
                    sellerID: Cowz.id,
                    seller: Cowz,
                    standingOrderName: 'Weekly Repeated Order',
                    supplyAgreementID: DeepCreekAgreement.id
                },
            ]
        },
        {
            name: 'standing-purchase-orders',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.customerIDs != null) {
                        r = r.filter(x => x.buyerID == params.customerIDs);
                    }
                }

                return r;
            },
            postAction: (list, post, db) => {
                var dbList = db.find(x => x.name == 'suppliers').data;
                post.seller = dbList.find(x => x.id == post.supplyAgreementID).seller;
                post.sellerID = post.seller.id;
              
                list.push(post);
                return post;
            },
            data: [
                {
                    adjustments: null,
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    cronExpression: '0 0 * * 1 0',
                    id: '1',
                    isBuyerCourier: false,
                    isOn: true,
                    orderItems: [
                        {
                            id: '1',
                            productID: PlasticBottle.id,
                            product: PlasticBottle,
                            quantity: 5000
                        }
                    ],
                    locationID: CowzLocation.id,
                    location: CowzLocation,
                    nextAttentionDate: DateTime.utc().plus({ days: 1 }).toString(),
                    orderSlots: [
                        {
                            slotName: 'Monday Order',
                            cronExpression: '',
                            isOn: true,
                            nextAttentionDate: DateTime.utc().plus({ days: 1}).toString(),
                            orderSlotGroupID: '1',
                            adjustments: null
                        }
                    ],
                    rowVersion: 'AAAA',
                    sellerID: PortlandPackaging.id,
                    seller: PortlandPackaging,
                    standingOrderName: 'Monday Order',
                    supplyAgreementID: PortlandPackagingAgreement.id
                },
            ]
        },
        {
            name: 'stock-adjustments',
            postAction: (list, post, db) => {
                post.product = db.find(x => x.name == 'products').data.find(x => x.id == post.productID);
                post.location = db.find(x => x.name == 'locations').data.find(x => x.id == post.locationID);
                post.userID = '1';
                post.createdOn = DateTime.utc().toString();
                list.push(post);
                return post;
            },
            data: []
        },
        
        {
            name: 'stock-consignments',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.filterBy != null) {
                        if (params.filterBy.includes('Transfers')) {
                            r = r.filter(x => x.departureLocation.companyAccountID == x.destinationLocation.companyAccountID);
                        }
                        if (params.filterBy.includes('Has Supply Location')) {
                            r = r.filter(x => x.departureLocationID != null);
                        }
                        if (params.filterBy.includes('Needs Supply Location')) {
                            r = r.filter(x => x.departureLocationID == null);
                        }
                        if (params.filterBy.includes('Needs Packing')) {
                            r = r.filter(x => x.needsPacking);
                        }
                    }
                    if (params.locationID != null) {
                        r = r.filter(x => x.departureLocationID == params.locationID);
                    }
                    if (params.customerIDs != null) {
                        r = r.filter(x => x.buyerID == params.customerIDs);
                    }
                    if (params.ids != null) {
                        var idList = params.ids.split(',');
                        r = r.filter(x => idList.some(id => x.id == id));
                    }
                }

                return r;
            },
            postAction: (list, post, db) => {
                console.log(post);
                console.log(db);

                var dbLocations = db.find(x => x.name == 'locations').data;

                post.departureLocation = dbLocations.find(x => x.id == post.departureLocationID);
                post.destinationLocation = dbLocations.find(x => x.id == post.destinationLocationID);
                post.id = dbLocations.length + 1;

                var dbProducts = db.find(x => x.name == 'products').data;
                var dbTracked = db.find(x => x.name == 'stock-tracked-items').data;

                post.consignmentOrderItems.forEach(oItem => {
                    oItem.product = dbProducts.find(x => x.id == oItem.productID);

                    var trackedItem = dbTracked.find(x => x.locationID == post.departureLocationID && x.productID == oItem.productID);
                    if (trackedItem != null) {
                        trackedItem.onRequest += oItem.quantity;
                    }

                    // trackedItem = dbTracked.find(x => x.locationID == post.destinationLocationID && x.productID == oItem.productID);
                    // if (trackedItem != null) {
                    //     trackedItem.
                    // }
                })

                post.consignmentEntryItems = [];
                
                list.push(post);
                return post;
            },
            data: [
                {
                    buyerID: CobdenCrayons.id,
                    buyer: CobdenCrayons,
                    consignmentEntryItems: [
                        {
                            batchID: BatchMilkTwoLitreThird.id,
                            batch: BatchMilkTwoLitreThird,
                            id: '1',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 5,
                            quantityPicked: 0
                        },
                        {
                            batchID: BatchMilkTwoLitreFourth.id,
                            batch: BatchMilkTwoLitreFourth,
                            id: '2',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 34,
                            quantityPicked: 0
                        },
                        {
                            batchID: BatchMilkLowFatFirst.id,
                            batch: BatchMilkLowFatFirst,
                            id: '3',
                            productID: MilkLowFatOneLitre.id,
                            product: MilkLowFatOneLitre,
                            quantity: 1,
                            quantityPicked: 0
                        },
                    ],
                    consignmentNumber: '46',
                    consignmentOrderItems: [
                        {
                            id: '1',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 39
                        },
                        {
                            id: '2',
                            productID: MilkLowFatOneLitre.id,
                            product: MilkLowFatOneLitre,
                            quantity: 1
                        }
                    ],
                    departureLocationID: CowzLocation.id,
                    departureLocation: CowzLocation,
                    destinationLocationID: CobdenCrayonLocation.id,
                    destinationLocation: CobdenCrayonLocation,
                    dueDate: DateTime.utc().toString(),
                    id: '1',
                    isBuyerCourier: false,
                    isDispatched: true,
                    isFullyProvisioned: true,
                    isTransfer: false,
                    performedOn: null,
                    pickedByUserID: null,
                    pickedOn: null,
                    rowVersion: 'AAAA',
                    sourceHasChanged: false
                },
                {
                    buyerID: TimboonTreats.id,
                    buyer: TimboonTreats,
                    consignmentEntryItems: [
                        {
                            batchID: BatchMilkTwoLitreThird.id,
                            batch: BatchMilkTwoLitreThird,
                            id: '4',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 50,
                            quantityPicked: 0
                        },
                        {
                            batchID: BatchSweetChilliSecond.id,
                            batch: BatchSweetChilliSecond,
                            id: '5',
                            productID: SweetChilliIceCream.id,
                            product: SweetChilliIceCream,
                            quantity: 1,
                            quantityPicked: 0
                        },
                    ],
                    consignmentNumber: '47',
                    consignmentOrderItems: [
                        {
                            id: '5',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 55
                        },
                        {
                            id: '6',
                            productID: SweetChilliIceCream.id,
                            product: SweetChilliIceCream,
                            quantity: 1
                        }
                    ],
                    departureLocationID: CowzLocation.id,
                    departureLocation: CowzLocation,
                    destinationLocationID: TimboonTreatsLocation.id,
                    destinationLocation: TimboonTreatsLocation,
                    dueDate: DateTime.utc().toString(),
                    id: '2',
                    isBuyerCourier: false,
                    isDispatched: true,
                    isFullyProvisioned: true,
                    isTransfer: false,
                    performedOn: null,
                    pickedByUserID: null,
                    pickedOn: null,
                    rowVersion: 'AAAA',
                    sourceHasChanged: false
                },
                {
                    buyerID: DeepCreek.id,
                    buyer: DeepCreek,
                    consignmentEntryItems: [
                        {
                            batchID: BatchMilkTwoLitreThird.id,
                            batch: BatchMilkTwoLitreThird,
                            id: '6',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 550,
                            quantityPicked: 0
                        },
                        {
                            batchID: BatchSweetChilliSecond.id,
                            batch: BatchSweetChilliSecond,
                            id: '7',
                            productID: SweetChilliIceCream.id,
                            product: SweetChilliIceCream,
                            quantity: 1,
                            quantityPicked: 0
                        },
                    ],
                    consignmentNumber: '455',
                    consignmentOrderItems: [
                        {
                            id: '5',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 550
                        },
                        {
                            id: '6',
                            productID: SweetChilliIceCream.id,
                            product: SweetChilliIceCream,
                            quantity: 1
                        },
                        {
                            id: '7',
                            productID: CheeseWheel.id,
                            product: CheeseWheel,
                            quantity: 5
                        }
                    ],
                    departureLocationID: CowzLocation.id,
                    departureLocation: CowzLocation,
                    destinationLocationID: DeepCreekLocation.id,
                    destinationLocation: DeepCreekLocation,
                    dueDate: DateTime.utc().toString(),
                    dueReadyOn: null,
                    id: '3',
                    isBuyerCourier: false,
                    isDispatched: true,
                    isFullyProvisioned: true,
                    isTransfer: false,
                    needsPacking: true,
                    performedOn: null,
                    pickedByUserID: null,
                    pickedOn: null,
                    rowVersion: 'AAAA',
                    sourceHasChanged: false
                },
                {
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    consignmentEntryItems: [],
                    consignmentNumber: '450',
                    consignmentOrderItems: [
                        {
                            id: '5',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 550
                        },
                        {
                            id: '6',
                            productID: SweetChilliIceCream.id,
                            product: SweetChilliIceCream,
                            quantity: 1
                        }
                    ],
                    departureLocationID: CowzLocation.id,
                    departureLocation: CowzLocation,
                    destinationLocationID: CowzLocation.id,
                    destinationLocation: CowzLocation,
                    dueDate: DateTime.utc().toString(),
                    dueReadyOn: null,
                    id: '4',
                    isBuyerCourier: false,
                    isDispatched: true,
                    isFullyProvisioned: true,
                    isTransfer: true,
                    performedOn: null,
                    pickedByUserID: null,
                    pickedOn: null,
                    rowVersion: 'AAAA',
                    sourceHasChanged: false
                },
                {
                    buyerID: CobdenCrayons.id,
                    buyer: CobdenCrayons,
                    consignmentEntryItems: [
                        {
                            batchID: BatchMilkTwoLitreThird.id,
                            batch: BatchMilkTwoLitreThird,
                            id: '8',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 5,
                            quantityPicked: 0
                        },
                        {
                            batchID: BatchMilkTwoLitreFourth.id,
                            batch: BatchMilkTwoLitreFourth,
                            id: '9',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 34,
                            quantityPicked: 0
                        },
                        {
                            batchID: BatchMilkLowFatFirst.id,
                            batch: BatchMilkLowFatFirst,
                            id: '10',
                            productID: MilkLowFatOneLitre.id,
                            product: MilkLowFatOneLitre,
                            quantity: 1,
                            quantityPicked: 0
                        },
                    ],
                    consignmentNumber: '46',
                    consignmentOrderItems: [
                        {
                            id: '1',
                            productID: MilkFullCreamTwoLitre.id,
                            product: MilkFullCreamTwoLitre,
                            quantity: 39
                        },
                        {
                            id: '2',
                            productID: MilkLowFatOneLitre.id,
                            product: MilkLowFatOneLitre,
                            quantity: 1
                        }
                    ],
                    departureLocationID: CowzLocation.id,
                    departureLocation: CowzLocation,
                    destinationLocationID: CobdenCrayonLocation.id,
                    destinationLocation: CobdenCrayonLocation,
                    dueDate: DateTime.utc().toString(),
                    id: '5',
                    isBuyerCourier: false,
                    isDispatched: true,
                    isFullyProvisioned: true,
                    isRequestPickup: true,
                    isTransfer: false,
                    performedOn: null,
                    pickedByUserID: null,
                    pickedOn: null,
                    rowVersion: 'AAAA',
                    sourceHasChanged: false
                },
            ]
        },
        {
            name: 'stock-increments',
            data: [
                {
                    id: '1',
                    rowVersion: 'A',
                    productID: '1', //M2
                    measurementID: '1', //crate
                    units: 9
                },
                {
                    id: '2',
                    rowVersion: 'A',
                    productID: '1', //M2
                    measurementID: '2', //pallet
                    units: 405
                },
                {
                    id: '3',
                    rowVersion: 'A',
                    productID: '2', //M1
                    measurementID: '1', //crate
                    units: 16
                },
                {
                    id: '4',
                    rowVersion: 'A',
                    productID: '2', //M1
                    measurementID: '2', //pallet
                    units: 720
                },
                {
                    id: '5',
                    rowVersion: 'A',
                    productID: '3', //CHIC
                    measurementID: '1', //crate
                    units: 45
                },
                {
                    id: '6',
                    rowVersion: 'A',
                    productID: '3', //CHI
                    measurementID: '2', //pallet
                    units: 2025
                },
                {
                    id: '7',
                    rowVersion: 'A',
                    productID: '4', //CHIC
                    measurementID: '1', //crate
                    units: 9
                },
                {
                    id: '8',
                    rowVersion: 'A',
                    productID: '4', //CHI
                    measurementID: '2', //pallet
                    units: 405
                }
            ]
        },
        {
            name: 'stock-items',
            getAllAction: (list, params) => {
                console.log(params);
                var r = list;
                if (params != null) {
                    if (params.locationID != null) {
                        r = r.filter(x => x.locationID == params.locationID);
                    }
                }

                return r;
            },
            data: [
                {
                    id: '1',
                    rowVersion: 'AAA',
                    locationID: CowzLocation.id,
                    location: CowzLocation,
                    productID: MilkFullCreamTwoLitre.id,
                    product: MilkFullCreamTwoLitre,
                    batchID: BatchMilkTwoLitreThird.id,
                    batch: BatchMilkTwoLitreThird,
                    onHold: 0,
                    available: 500,
                    wasted: 40
                },
                {
                    id: '2',
                    rowVersion: 'AAA',
                    locationID: CowzLocation.id,
                    location: CowzLocation,
                    productID: MilkFullCreamTwoLitre.id,
                    product: MilkFullCreamTwoLitre,
                    batchID: BatchMilkTwoLitreFourth.id,
                    batch: BatchMilkTwoLitreFourth,
                    onHold: 0,
                    available: 56,
                    wasted: 0
                },
                {
                    id: '3',
                    rowVersion: 'AAA',
                    locationID: CowzLocation.id,
                    location: CowzLocation,
                    productID: MilkLowFatOneLitre.id,
                    product: MilkLowFatOneLitre,
                    batchID: BatchMilkTwoLitreFourth.id,
                    batch: BatchMilkTwoLitreFourth,
                    onHold: 0,
                    available: 900,
                    wasted: 0
                },
                {
                    id: '4',
                    rowVersion: 'AAA',
                    locationID: CowzLocation.id,
                    location: CowzLocation,
                    productID: SweetChilliIceCream.id,
                    product: SweetChilliIceCream,
                    batchID: BatchSweetChilliSecond.id,
                    batch: BatchSweetChilliSecond,
                    onHold: 0,
                    available: 900,
                    wasted: 0
                },
                {
                    id: '5',
                    rowVersion: 'AAA',
                    locationID: CowzLocation.id,
                    location: CowzLocation,
                    productID: CheeseWheel.id,
                    product: CheeseWheel,
                    batchID: BatchCheeseWheel.id,
                    batch: BatchCheeseWheel,
                    onHold: 0,
                    available: 10,
                    wasted: 0
                }
            ]
        },
        {
            name: 'stock-tracked-items',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.locationID != null) {
                        r = r.filter(x => x.locationID == params.locationID);
                    }
                }

                return r;
            },
            data: [
                {
                    id: '1',
                    rowVersion: 'AAA',
                    companyAccountID: Cowz.id,
                    locationID: CowzLocation.id,
                    productID: MilkFullCreamTwoLitre.id,
                    onRequest: 0,
                    consigned: 100,
                    inStock: 1000,
                    onOrder: 0
                },
                {
                    id: '2',
                    rowVersion: 'AAA',
                    companyAccountID: Cowz.id,
                    locationID: CowzLocation.id,
                    productID: MilkLowFatOneLitre.id,
                    onRequest: 0,
                    consigned: 50,
                    inStock: 5,
                    onOrder: 0
                },
                {
                    id: '3',
                    rowVersion: 'AAA',
                    companyAccountID: Cowz.id,
                    locationID: CowzLocation.id,
                    productID: SweetChilliIceCream.id,
                    onRequest: 0,
                    consigned: 250,
                    inStock: 800,
                    onOrder: 0
                },
                {
                    id: '4',
                    rowVersion: 'AAA',
                    companyAccountID: Cowz.id,
                    locationID: CowzLocationShop.id,
                    productID: SweetChilliIceCream.id,
                    onRequest: 0,
                    consigned: 0,
                    inStock: 5,
                    onOrder: 0
                },
                {
                    id: '5',
                    rowVersion: 'AAA',
                    companyAccountID: Cowz.id,
                    locationID: null,
                    productID: MilkFullCreamTwoLitre.id,
                    onRequest: 5000,
                    consigned: 0,
                    inStock: 0,
                    onOrder: 0
                },
                {
                    id: '6',
                    rowVersion: 'AAA',
                    companyAccountID: Cowz.id,
                    locationID: null,
                    productID: SweetChilliIceCream.id,
                    onRequest: 25,
                    consigned: 0,
                    inStock: 0,
                    onOrder: 0
                },
            ]
        },
        {
            name: 'stock-thresholds',
            getAllAction: (list, params) => {
                var r = list;
                if (params != null) {
                    if (params.locationID != null) {
                        r = r.filter(x => x.locationID == params.locationID);
                    }
                }

                return r;
            },
            data: [
                {
                    id: '1',
                    rowVersion: 'AAA',
                    companyAccountID: Cowz.id,
                    productID: MilkFullCreamTwoLitre.id,
                    locationID: CowzLocation.id,
                    lowerThreshold: 1500,
                    upperThreshold: 3000,
                    minimumOrderAmount: 500,
                    orderIncrement: 1
                },
                {
                    id: '2',
                    rowVersion: 'AAA',
                    companyAccountID: Cowz.id,
                    productID: SweetChilliIceCream.id,
                    locationID: CowzLocation.id,
                    lowerThreshold: 500,
                    upperThreshold: 1000,
                    minimumOrderAmount: 0,
                    orderIncrement: 1
                }
            ]
        },
        {
            name: 'suppliers',
            data: [
                {
                    id: '4',
                    rowVersion: 'AAA',
                    sellerID: PortlandPackaging.id,
                    seller: PortlandPackaging
                },
                {
                    id: '5',
                    rowVersion: 'AAA',
                    sellerID: LegenDairy.id,
                    seller: LegenDairy
                }
            ]
        },
        {
            name: 'supplier-consignments',
            getAllAction: (list, params) => {
                var r = list;

                if (params != null) {
                    if (params.ids != null) {
                        var idList = params.ids.split(',');
                        r = r.filter(x => idList.some(id => x.id == id)); //x.id == params.ids);
                    }
                }

                return r;
            },
            data: [
                {
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    consignmentNumber: '10',
                    departureLocationID: PortlandPackagingLocation.id,
                    departureLocation: PortlandPackagingLocation,
                    destinationLocationID: CowzLocation.id,
                    destinationLocation: CowzLocation,
                    dueDate: DateTime.utc().plus({ days: -1 }).toString(),
                    dueReadyOn: null,
                    fulfillmentBuyerID: PortlandPackaging.id,
                    id: '1',
                    isBuyerCourier: false,
                    isInactive: false,
                    isOrdered: true,
                    isReadyToOrderCourier: true,
                    isRequestPickup: false,
                    isSourceChanged: false,
                    orderWeight: 58.1,
                    packagedItems: [
                        {
                            code: null,
                            height: 320,
                            id: '10',
                            length: 350,
                            measurementID: '2',
                            measurementStandard: null,
                            quantity: 6,
                            volume: null,
                            weight: null,
                            width: 350
                        }
                    ],
                    packedByUserID: null,
                    packedOn: null,
                    readyOn: DateTime.utc().toString(),
                    rowVersion: 'AAA',
                    sellerID: PortlandPackaging.id,
                    seller: PortlandPackaging,
                    stockConsignmentID: '1',
                    stockWeight: 58.1
                },
                {
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    consignmentNumber: '11',
                    departureLocationID: PortlandPackagingLocation.id,
                    departureLocation: PortlandPackagingLocation,
                    destinationLocationID: CowzLocation.id,
                    destinationLocation: CowzLocation,
                    dueDate: DateTime.utc().plus({ days: 2 }).toString(),
                    dueReadyOn: null,
                    fulfillmentBuyerID: PortlandPackaging.id,
                    id: '2',
                    isBuyerCourier: true,
                    isInactive: false,
                    isOrdered: false,
                    isReadyToOrderCourier: true,
                    isRequestPickup: false,
                    isSourceChanged: false,
                    orderWeight: 58.1,
                    packagedItems: [
                        {
                            code: null,
                            height: 320,
                            id: '10',
                            length: 350,
                            measurementID: '2',
                            measurementStandard: null,
                            quantity: 6,
                            volume: null,
                            weight: null,
                            width: 350
                        }
                    ],
                    packedByUserID: null,
                    packedOn: null,
                    readyOn: DateTime.utc().toString(),
                    rowVersion: 'AAA',
                    sellerID: PortlandPackaging.id,
                    seller: PortlandPackaging,
                    stockConsignmentID: '1',
                    stockWeight: 58.1
                },
                {
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    consignmentNumber: '12',
                    departureLocationID: LegenDairyLocation.id,
                    departureLocation: LegenDairyLocation,
                    destinationLocationID: CowzLocation.id,
                    destinationLocation: CowzLocation,
                    dueDate: DateTime.utc().plus({ days: 3 }).toString(),
                    dueReadyOn: null,
                    fulfillmentBuyerID: LegenDairy.id,
                    id: '3',
                    isBuyerCourier: true,
                    isInactive: false,
                    isOrdered: false,
                    isReadyToOrderCourier: false,
                    isRequestPickup: false,
                    isSourceChanged: false,
                    orderWeight: 58.1,
                    packagedItems: [
                        {
                            code: null,
                            height: 320,
                            id: '10',
                            length: 350,
                            measurementID: '2',
                            measurementStandard: null,
                            quantity: 6,
                            volume: null,
                            weight: null,
                            width: 350
                        }
                    ],
                    packedByUserID: null,
                    packedOn: null,
                    readyOn: DateTime.utc().toString(),
                    rowVersion: 'AAA',
                    sellerID: LegenDairy.id,
                    seller: LegenDairy,
                    stockConsignmentID: '1',
                    stockWeight: 58.1
                },
                {
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    consignmentNumber: '13',
                    departureLocationID: PortlandPackagingLocation.id,
                    departureLocation: PortlandPackagingLocation,
                    destinationLocationID: CowzLocation.id,
                    destinationLocation: CowzLocation,
                    dueDate: DateTime.utc().plus({ days: 4 }).toString(),
                    dueReadyOn: null,
                    fulfillmentBuyerID: PortlandPackaging.id,
                    id: '4',
                    isBuyerCourier: false,
                    isInactive: false,
                    isOrdered: true,
                    isReadyToOrderCourier: true,
                    isRequestPickup: true,
                    isSourceChanged: false,
                    orderWeight: 58.1,
                    packagedItems: [
                        {
                            code: null,
                            height: 320,
                            id: '10',
                            length: 350,
                            measurementID: '2',
                            measurementStandard: null,
                            quantity: 6,
                            volume: null,
                            weight: null,
                            width: 350
                        }
                    ],
                    packedByUserID: null,
                    packedOn: null,
                    readyOn: DateTime.utc().toString(),
                    rowVersion: 'AAA',
                    sellerID: PortlandPackaging.id,
                    seller: PortlandPackaging,
                    stockConsignmentID: '1',
                    stockWeight: 58.1
                },
                {
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    consignmentNumber: '15',
                    departureLocationID: PortlandPackagingLocation.id,
                    departureLocation: PortlandPackagingLocation,
                    destinationLocationID: CowzLocation.id,
                    destinationLocation: CowzLocation,
                    dueDate: DateTime.utc().plus({ hours: 5 }).toString(),
                    dueReadyOn: null,
                    fulfillmentBuyerID: PortlandPackaging.id,
                    id: '5',
                    isBuyerCourier: false,
                    isInactive: false,
                    isOrdered: true,
                    isReadyToOrderCourier: true,
                    isRequestPickup: false,
                    isSourceChanged: false,
                    orderWeight: 58.1,
                    packagedItems: [
                        {
                            code: null,
                            height: 320,
                            id: '10',
                            length: 350,
                            measurementID: '2',
                            measurementStandard: 'Chep Pallet',
                            quantity: 12,
                            volume: null,
                            weight: null,
                            width: 350
                        }
                    ],
                    packedByUserID: null,
                    packedOn: null,
                    readyOn: DateTime.utc().toString(),
                    rowVersion: 'AAA',
                    sellerID: PortlandPackaging.id,
                    seller: PortlandPackaging,
                    stockConsignmentID: '2',
                    stockWeight: 58.1
                },
                {
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    consignmentNumber: '45',
                    departureLocationID: LegenDairyLocation.id,
                    departureLocation: LegenDairyLocation,
                    destinationLocationID: LegenDairyLocation.id,
                    destinationLocation: LegenDairyLocation,
                    dueDate: DateTime.utc().plus({ days: 3 }).toString(),
                    dueReadyOn: null,
                    fulfillmentBuyerID: LegenDairy.id,
                    id: '6',
                    isBuyerCourier: false,
                    isInactive: false,
                    isOrdered: true,
                    isReadyToOrderCourier: true,
                    isRequestPickup: true,
                    isSourceChanged: false,
                    orderWeight: 58.1,
                    packagedItems: [
                        {
                            code: null,
                            height: 320,
                            id: '10',
                            length: 350,
                            measurementID: '2',
                            measurementStandard: 'Chep Pallet',
                            quantity: 6,
                            volume: null,
                            weight: null,
                            width: 350
                        }
                    ],
                    packedByUserID: null,
                    packedOn: null,
                    readyOn: DateTime.utc().toString(),
                    rowVersion: 'AAA',
                    sellerID: LegenDairy.id,
                    seller: LegenDairy,
                    stockConsignmentID: '5',
                    stockWeight: 58.1
                },
            ]
        },
        {
            name: 'supplier-invoices',
            getAllAction: (list, params) => {
                var r = list.orderByDate(x => x.issuedOn, false);
                if (params != null) {
                    if (params.takeAmount != null) {
                        if (r.length > params.takeAmount) {
                            r = r.slice(0, params.takeAmount);
                        }
                    }
                }
                return r;
            },
            data: [
                {
                    amountPaid: 0,
                    amountTotal: 200,
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    customerOrderNumber: '12',
                    dueOn: DateTime.utc().plus({ days: 10 }).toString(),
                    externalReferences: [],
                    fulfillerID: Cowz.id,
                    fulfiller: Cowz,
                    id: '6',
                    invoiceNumber: '12',
                    isPaid: false,
                    issuedByUserID: null,
                    issuedOn: DateTime.utc().plus({ days: -2 }).toString(),
                    lineItems: [
                        {
                            description: ButterBlob.productName,
                            discount: 0,
                            id: '2',
                            onOrder: 10,
                            itemID: ButterBlob.id,
                            quantity: 10,
                            sortNumber: 2,
                            tags: null,
                            taxType: null,
                            unitPrice: 20,
                        },
                    ],
                    paidOn: null,
                    purchaseOrderNumber: '37',
                    rowVersion: 'AAAA',
                    taxTotal: 0,
                    sellerID: LegenDairy.id,
                    seller: LegenDairy,
                    subTotal: 200
                    
                },
                {
                    amountPaid: 1000,
                    amountTotal: 1000,
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    customerOrderNumber: '4550',
                    dueOn: DateTime.utc().plus({ days: 5 }).toString(),
                    externalReferences: [],
                    fulfillerID: Cowz.id,
                    fulfiller: Cowz,
                    id: '5',
                    invoiceNumber: '3890',
                    isPaid: true,
                    issuedByUserID: null,
                    issuedOn: DateTime.utc().toString(),
                    lineItems: [
                        {
                            description: CardboardBox.productName,
                            discount: 0,
                            id: '2',
                            onOrder: 500,
                            itemID: CardboardBox.id,
                            quantity: 500,
                            sortNumber: 1,
                            tags: null,
                            taxType: null,
                            unitPrice: 0.5,
                        },
                    ],
                    paidOn: DateTime.utc().toString(),
                    purchaseOrderNumber: '35',
                    rowVersion: 'AAAA',
                    taxTotal: 0,
                    sellerID: PortlandPackaging.id,
                    seller: PortlandPackaging,
                    subTotal: 1000
                },
                {
                    amountPaid: 150,
                    amountTotal: 225,
                    buyerID: Cowz.id,
                    buyer: Cowz,
                    customerOrderNumber: '4550',
                    dueOn: DateTime.utc().plus({ days: 10 }).toString(),
                    externalReferences: [],
                    fulfillerID: Cowz.id,
                    fulfiller: Cowz,
                    id: '4',
                    invoiceNumber: '388',
                    isPaid: false,
                    issuedByUserID: null,
                    issuedOn: DateTime.utc().toString(),
                    lineItems: [
                        {
                            description: PlasticBottle.productName,
                            discount: 0,
                            id: '1',
                            onOrder: 55,
                            itemID: PlasticBottle.id,
                            quantity: 50,
                            sortNumber: 1,
                            tags: null,
                            taxType: null,
                            unitPrice: 4.5,
                        },
                    ],
                    paidOn: null,
                    purchaseOrderNumber: '34',
                    rowVersion: 'AAAA',
                    taxTotal: 0,
                    sellerID: PortlandPackaging.id,
                    seller: PortlandPackaging,
                    subTotal: 225
                }
                
            ]
        },
        {
            name: 'users',
            data: [
                CowzUser
            ]
        },
        {
            path: '/api/v1/Customer/OrderAgreements/Locations/Locations/1',
            data: [{
                id: '1',
                possibleLocations: [
                    CobdenCrayonLocation,
                    CobdenCrayonLocationTwo,
                ]
            }]
        },
        {
            path: '/api/v1/Customer/OrderAgreements/Locations/Locations/2',
            data: [{
                id: '2',
                possibleLocations: [
                    TimboonTreatsLocation
                ]
            }]
        },
        {
            path: '/api/v1/Customer/OrderAgreements/Locations/Locations/3',
            data: [{
                id: '3',
                possibleLocations: [
                    DeepCreekLocation
                ]
            }]
        },
        {
            path: '/api/v1/Customer/OrderAgreements/get/Calendar',
            data: {
                id: '1',
                scheduleItems: [
                    {
                        orderBy: DateTime.utc().plus({ hours: 2 }).toString(),
                        deliverBy: DateTime.utc().plus({ days: 2 }).toString(),
                        orderSlotID: '1',
                        purchaseOrderID: PortlandPackagingPurchaseOrder.id,
                        purchaseOrder: PortlandPackagingPurchaseOrder,
                        standingOrderID: null,
                        standingOrder: null,
                        isCancelled: false,
                        supplyAgreementID: PortlandPackagingAgreement.id,
                        buyerID: Cowz.id,
                        sellerID: PortlandPackaging.id
                    },
                    {
                        orderBy: DateTime.utc().plus({ days: 2 }).toString(),
                        deliverBy: DateTime.utc().plus({ days: 6 }).toString(),
                        orderSlotID: '2',
                        purchaseOrderID: null,
                        purchaseOrder: null,
                        standingOrderID: null,
                        standingOrder: null,
                        isCancelled: false,
                        supplyAgreementID: PortlandPackagingAgreement.id,
                        buyerID: Cowz.id,
                        sellerID: PortlandPackaging.id
                    },
                    {
                        orderBy: DateTime.utc().plus({ days: 1 }).toString(),
                        deliverBy: DateTime.utc().plus({ days: 3 }).toString(),
                        orderSlotID: '3',
                        purchaseOrderID: null,
                        purchaseOrder: null,
                        standingOrderID: null,
                        standingOrder: null,
                        isCancelled: false,
                        supplyAgreementID: LegenDairyAgreement.id,
                        buyerID: Cowz.id,
                        sellerID: LegenDairy.id
                    }
                ]
            }
        },
        {
            path: '/api/v1/Customer/OrderAgreements/get/Policy/' + CobdenCrayonsAgreement.id,
            data: [{
                id: CobdenCrayonsAgreement.id,
                automaticLineItems: [],
                buyerName: CobdenCrayons.companyName,
                buyerID: CobdenCrayons.id,
                defaultLocation: CobdenCrayonLocation,
                defaultLocationID: CobdenCrayonLocation.id,
                globalRules: [],
                isAvailableWeekends: false,
                isCustomerOrderingEnabled: false,
                isPreferPickup: false,
                isRequiredPickup: false,
                orderingArrangement: 'Slotted',
                orderItems: [
                    {
                        productID: MilkFullCreamTwoLitre.id,
                        product: MilkFullCreamTwoLitre,
                        rules: [],
                        unitPriceIncrements: [
                            {
                                unitCountTrigger: 0,
                                unitValue: 5.50
                            },
                            {
                                unitCountTrigger: 15,
                                unitValue: 5.00
                            },
                            {
                                unitCountTrigger: 50,
                                unitValue: 4.50
                            }
                        ]
                    },
                    {
                        productID: MilkLowFatOneLitre.id,
                        product: MilkLowFatOneLitre,
                        rules: [
                            {
                                productID: MilkLowFatOneLitre.id,
                                product: MilkLowFatOneLitre,
                                rule: 'QuantityInIncrements',
                                quantity: 16
                            }
                        ],
                        unitPriceIncrements: [
                            {
                                unitCountTrigger: 0,
                                unitValue: 3.75
                            }
                        ]
                    },
                    {
                        productID: SweetChilliIceCream.id,
                        product: SweetChilliIceCream,
                        rules: [],
                        unitPriceIncrements: [
                            {
                                unitCountTrigger: 0,
                                unitValue: 11.55
                            },
                            {
                                unitCountTrigger: 15,
                                unitValue: 10.00
                            },
                            {
                                unitCountTrigger: 100,
                                unitValue: 9.50
                            }
                        ]
                    },
                    {
                        productID: CheeseKG.id,
                        product: CheeseKG,
                        rules: [],
                        unitPriceIncrements: [
                            {
                                unitCountTrigger: 0,
                                unitValue: 11.55
                            },
                            {
                                unitCountTrigger: 15,
                                unitValue: 10.00
                            },
                            {
                                unitCountTrigger: 100,
                                unitValue: 9.50
                            }
                        ]
                    }
                ],
                orderLeadHours: 24,
                orderSlots: [
                    {
                        orderBy: DateTime.utc().toString(),
                        deliverBy: DateTime.utc().plus({ days: 2 }).toString(),
                        orderSlotID: '1',
                        purchaseOrderID: null
                    },
                    {
                        orderBy: DateTime.utc().plus({ days: 7 }).toString(),
                        deliverBy: DateTime.utc().plus({ days: 9 }).toString(),
                        orderSlotID: '1',
                        purchaseOrderID: null
                    },
                    {
                        orderBy: DateTime.utc().plus({ days: 14 }).toString(),
                        deliverBy: DateTime.utc().plus({ days: 16 }).toString(),
                        orderSlotID: '1',
                        purchaseOrderID: null
                    },
                    {
                        orderBy: DateTime.utc().plus({ days: 21 }).toString(),
                        deliverBy: DateTime.utc().plus({ days: 23 }).toString(),
                        orderSlotID: '1',
                        purchaseOrderID: null
                    }
                ],
                possibleLocations: [
                    CobdenCrayonLocation,
                    CobdenCrayonLocationTwo
                ],
                priceTierID: '1',
                requireLeadTime: false,
                sellerName: Cowz.companyName,
                sellerID: Cowz.id,
            }]
        },
        {
            path: '/api/v1/Customer/OrderAgreements/get/Policy/' + TimboonTreatsAgreement.id,
            data: [{
                id: TimboonTreatsAgreement.id,
                automaticLineItems: [],
                buyerName: TimboonTreats.companyName,
                buyerID: TimboonTreats.id,
                defaultLocation: null,
                defaultLocationID: null,
                globalRules: [],
                isAvailableWeekends: false,
                isCustomerOrderingEnabled: false,
                isPreferPickup: false,
                isRequiredPickup: false,
                orderingArrangement: 'Open',
                orderItems: [
                    {
                        productID: MilkFullCreamTwoLitre.id,
                        product: MilkFullCreamTwoLitre,
                        rules: [],
                        unitPriceIncrements: [
                            {
                                unitCountTrigger: 0,
                                unitValue: 5.50
                            },
                            {
                                unitCountTrigger: 15,
                                unitValue: 5.00
                            },
                            {
                                unitCountTrigger: 50,
                                unitValue: 4.50
                            }
                        ]
                    },
                    {
                        productID: SweetChilliIceCream.id,
                        product: SweetChilliIceCream,
                        rules: [],
                        unitPriceIncrements: [
                            {
                                unitCountTrigger: 0,
                                unitValue: 11.55
                            },
                            {
                                unitCountTrigger: 15,
                                unitValue: 10.00
                            },
                            {
                                unitCountTrigger: 100,
                                unitValue: 9.50
                            }
                        ]
                    }
                ],
                orderLeadHours: 24,
                orderSlots: [],
                possibleLocations: [
                    TimboonTreatsLocation
                ],
                priceTierID: '1',
                requireLeadTime: true,
                sellerName: Cowz.companyName,
                sellerID: Cowz.id,
            }]
        },
        {
            path: '/api/v1/Customer/OrderAgreements/get/Policy/' + PortlandPackagingAgreement.id,
            data: [{
                id: PortlandPackagingAgreement.id,
                automaticLineItems: [],
                buyerName: PortlandPackaging.companyName,
                buyerID: PortlandPackaging.id,
                defaultLocation: CowzLocation,
                defaultLocationID: CowzLocation.id,
                globalRules: [],
                isAvailableWeekends: false,
                isCustomerOrderingEnabled: false,
                isPreferPickup: false,
                isRequiredPickup: false,
                orderingArrangement: 'Open',
                orderItems: [
                    {
                        productID: PlasticBottle.id,
                        product: PlasticBottle,
                        rules: [],
                        unitPriceIncrements: [
                            {
                                unitCountTrigger: 0,
                                unitValue: 5.50
                            },
                            {
                                unitCountTrigger: 15,
                                unitValue: 5.00
                            },
                            {
                                unitCountTrigger: 50,
                                unitValue: 4.50
                            }
                        ]
                    },
                    {
                        productID: CardboardBox.id,
                        product: CardboardBox,
                        rules: [
                            {
                                productID: CardboardBox.id,
                                product: CardboardBox,
                                quantity: 500,
                                rule: 'QuantityInIncrements'
                            }
                        ],
                        unitPriceIncrements: [
                            { 
                                unitCountTrigger: 0,
                                unitValue: 1.25
                            },
                            {
                                unitCountTrigger: 1500,
                                unitValue: 1.00
                            }
                        ]
                    }
                ],
                orderLeadHours: 24,
                orderSlots: [],
                possibleLocations: [
                    CowzLocation,
                    CowzLocationShop
                ],
                priceTierID: null,
                requireLeadTime: false,
                sellerName: PortlandPackaging.companyName,
                sellerID: PortlandPackaging.id,
            }]
        },
        {
            path: '/api/v1/Customer/OrderAgreements/get/Policy/' + LegenDairyAgreement.id,
            data: [{
                id: LegenDairyAgreement.id,
                automaticLineItems: [],
                buyerName: LegenDairy.companyName,
                buyerID: LegenDairy.id,
                defaultLocation: CowzLocation,
                defaultLocationID: CowzLocation.id,
                globalRules: [],
                isAvailableWeekends: false,
                isCustomerOrderingEnabled: false,
                isPreferPickup: false,
                isRequiredPickup: false,
                orderingArrangement: 'Open',
                orderItems: [
                    {
                        productID: ButterBlob.id,
                        product: ButterBlob,
                        rules: [],
                        unitPriceIncrements: [
                            {
                                unitCountTrigger: 0,
                                unitValue: 5.50
                            },
                            {
                                unitCountTrigger: 15,
                                unitValue: 5.00
                            },
                            {
                                unitCountTrigger: 50,
                                unitValue: 4.50
                            }
                        ]
                    }
                ],
                orderLeadHours: 24,
                orderSlots: [],
                possibleLocations: [
                    CowzLocation,
                    CowzLocationShop
                ],
                priceTierID: null,
                requireLeadTime: false,
                sellerName: LegenDairy.companyName,
                sellerID: LegenDairy.id,
            }]
        },
    ]
}