<template>
    <v-card
        :width="width"
        :loading="isLoading"
        :dense="dense"
        :flat="flat">
        <v-card-title v-if="cardTitle != null">{{ cardTitle }}</v-card-title>
        <label v-if="label != null">{{ label }}</label>
        <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
        <v-list 
            v-if="isLengthyArray(filteredItems)" 
            :dense="dense" 
            :flat="flat"
            :height="height"
            :class="listClass">
            <v-slide-x-transition group>
                <v-list-item-group
                    v-model="selectedItem"
                    :active-class="activeClass"
                    :multiple="multiple"
                    @change="update"
                    key="listKey">
                    <template v-for="(item, index) in filteredItems">
                        <v-list-item
                            v-if="item != null"
                            :active-class="activeClass"
                            :key="index"
                            :value="item"
                            :two-line="twoLine"
                            :three-line="threeLine"
                            :dense="dense">
                            <slot v-bind:item="item">
                                <div>{{ itemText ? getNestedValue(item, itemText) : item }}</div>
                            </slot>
                        </v-list-item>
                        <v-divider v-if="dividers" :key="'d' + index" />
                    </template>
                </v-list-item-group>
            </v-slide-x-transition>
        </v-list>
    </v-card>
</template>

<script>
export default {
    name: 'BT-Select-List',
    data: function() {
        return {
            asyncItems: [],
            errorMessage: null,
            isLoading: false,
            searchData: null,
            selectedItem: null,
            showError: false,
        }
    },
    props: {
        activeClass: {
            type: String,
            default: 'accent--text'
        },
        cardTitle: {
            type: String,
            default: null
        },
        dense: {
            type: Boolean,
            default: false
        },
        dividers: {
            type: Boolean,
            default: true
        },
        flat: {
            type: Boolean,
            default: false,
        },
        height: {
            type: String,
            default: 'auto'
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: null
        },
        itemValue: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        listClass: {
            type: String,
            default: 'overflow-y-auto'
        },
        multiple: {
            type: Boolean,
            default: true
        },
        navigation: {
            type: String,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        returnCSV: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        searchString: {
            type: String,
            default: null
        },
        searchProperties: {
            type: Array,
            default: null
        },
        small: {
            type: Boolean,
            default: false
        },
        twoLine: {
            type: Boolean,
            default: false
        },
        threeLine: {
            type: Boolean,
            default: false
        },
        value: null,
        width: {
            type: String,
            default: 'auto'
        },
    },
    watch: {
        items() {
            this.pullItems();
        },
        params: function() {
            this.refresh();
        },
        refreshToggle: function() {
            this.refresh();
        },
        searchString: function(val) {
            this.searchData = val;
        },
        value(val) {
            if (val !== this.selectedItem) {
                this.loadSelection();
            }
        }
    },
    async mounted() {
        await this.pullItems();

        // if (this.returnCSV) {
        //     if (this.itemValue != null && this.value != null) {
        //         var options = this.value.split(',');
        //         this.selectedItem = this.asyncItems.filter(x => options.some(opt => opt == x[this.itemValue]));
        //     }
        //     else {
        //         this.selectedItem = this.value ? this.value.split(',') : null;
        //     }
        // }
        // else {
        //     this.selectedItem = this.value;
        // }
    },
    computed: {
        filteredItems() {
            var l = this.asyncItems;            
            if (this.searchData != null && this.isLengthyArray(this.searchProperties)) {                
                l = l.filter(y => this.hasSearch(y, this.searchData, this.searchProperties))                
            }
            return this.onFilter ? this.onFilter(l) : l;
        }
    },
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.isLoading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.isLoading = false;
            this.$forceUpdate();
        },
        loadSelection() {
            if (this.returnCSV) {
                if (this.itemValue != null && this.value != null) {
                    var options = this.value.split(',');
                    this.selectedItem = this.asyncItems.filter(x => options.some(opt => opt == x[this.itemValue]));
                }
                else {
                    this.selectedItem = this.value ? this.value.split(',') : null;
                }
            }
            else {
                this.selectedItem = this.value;
            }
        },
        async pullItems(refresh = false) {
            if (this.items != null) {
                this.asyncItems = this.items;
                this.loadSelection();
                return;
            }

            if (this.navigation == null) {
                return;
            }

            try {
                this.startLoading();
                var res = null;
                if (this.isSingle) {
                    res = await this.$BlitzIt.store.get(this.navigation, this.itemID, this.params, refresh);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, this.params, refresh);
                }
                
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh);
                }
                else {
                    this.asyncItems = res;
                }

                this.loadSelection();
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
        },
        refresh() {
            this.showError = false;
            this.errorMessage = null;
            this.pullItems(true);
        },
        update() {
            var v = null;
            
            if (this.selectedItem == null) {
                this.$emit('input', null)
                this.$emit('change', null);
                return;
            }
            
            if (this.returnObject) {
                v = this.selectedItem;
            }
            else if (this.returnCSV) {
                if (this.itemValue != null) {
                    this.$emit('input', this.selectedItem.map(x => x[this.itemValue]).toString())
                    this.$emit('change', this.selectedItem.map(x => x[this.itemValue]).toString());
                }
                else {
                    this.$emit('input', this.selectedItem.toString())
                    this.$emit('change', this.selectedItem.toString());
                }
                return;
            }
            else if (this.itemValue) {
                v = this.selectedItem[this.itemValue];
            }
            else {
                v = this.selectedItem;
            }

            this.$emit('input', v);
            this.$emit('change', v);
            this.selectedItem = null;
        }
    }
}
</script>