<template>
    <div>
        <BT-Field-String
            @change="updateRaw"
            v-if="isSpecial || showExpression"
            v-model="cronExpression"
            :isEditing="isEditing && editExpression"
            label="Cron Expression" />

        <div v-if="!isSpecial">
            <BT-Field-Checkbox
                @change="update"
                v-model="everyMonth"
                :isEditing="isEditing"
                label="Every Month" />

            <v-slide-y-transition>
                <BT-Field-Chips
                    @change="update"
                    v-if="!everyMonth"
                    v-model="months"
                    :isEditing="isEditing"
                    :items="monthOptions"
                    itemText="text"
                    itemValue="value"
                    label="Months"
                    multiple />
            </v-slide-y-transition>

            <BT-Field-Checkbox
                @change="update"
                v-model="everyWeek"
                :isEditing="isEditing"
                label="Every Week" />

            <v-slide-y-transition>
                <BT-Field-Chips
                    @change="update"
                    v-if="!everyWeek"
                    v-model="weeks"
                    :isEditing="isEditing"
                    :items="weekOptions"
                    itemText="text"
                    itemValue="value"
                    label="Weeks"
                    multiple />
            </v-slide-y-transition>

            <BT-Field-Chips
                @change="update"
                v-model="weekdays"
                :isEditing="isEditing"
                :items="weekdayOpts"
                itemText="text"
                itemValue="value"
                label="Days Of Week"
                multiple
                returnIndex />

            <BT-Field-Chips
                @change="update"
                v-model="hour"
                :isEditing="isEditing"
                :items="hourOptions"
                label="Cut-Off Time"
                mandatory
                :multiple="false"
                returnObject />

            <BT-Field-Chips
                @change="update"
                v-model="halfDay"
                :isEditing="isEditing"
                :items="halfOptions"
                mandatory
                :multiple="false"
                returnObject />

            <BT-Field-Number
                v-if="leadTimeLeftLabel != null"
                v-model.number="leadTimeLeft"
                @change="update"
                :isEditing="isEditing"
                :label="leadTimeLeftLabel" />
            
            <BT-Field-Number
                v-if="leadTimeRightLabel != null"
                v-model.number="leadTimeRight"
                @change="update"
                :isEditing="isEditing"
                :label="leadTimeRightLabel" />
        </div>
        <BT-Blade-Button
            v-else
            @click="reset"
            label="Reset"
            icon="mdi-refresh" />

    </div>
</template>

<script>
export default {
    name: 'BT-Cron',
    data: function() {
        return {
            cronExpression: null,
            hour: [],
            hourOptions: [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
            halfDay: [],
            halfOptions: ['AM', 'PM'],
            isSpecial: false,
            leadTimeLeft: 0,
            leadTimeRight: 0,
            months: [],
            monthOptions: [
                { text: 'Jan', value: 1 },
                { text: 'Feb', value: 2 },
                { text: 'Mar', value: 3 },
                { text: 'Apr', value: 4 }, 
                { text: 'May', value: 5 },
                { text: 'Jun', value: 6 },
                { text: 'Jul', value: 7 },
                { text: 'Aug', value: 8 },
                { text: 'Sep', value: 9 },
                { text: 'Oct', value: 10 },
                { text: 'Nov', value: 11 },
                { text: 'Dec', value: 12 }], //1-12
            everyMonth: false, //* OR specific
            everyWeek: false, //* OR specific
            weeks: [],
            weekOptions: [
                { text: '1st', value: '1-7' },
                { text: '2nd', value: '8-14' },
                { text: '3rd', value: '15-21' },
                { text: '4th', value: '22-28' },
                { text: '5th', value: '29-31' }], //1-7, 8-14, 15-21, 22-28, 29-31
            weekdays: [], //
            weekdayOpts: [
                { text: 'Sun', value: '0' },
                { text: 'Mon', value: '1' },
                { text: 'Tue', value: '2' },
                { text: 'Wed', value: '3' },
                { text: 'Thu', value: '4' },
                { text: 'Fri', value: '5' },
                { text: 'Sat', value: '6' }] //0-6
        }
    },
    props: {
        editExpression: {
            type: Boolean,
            default: false
        },
        isEditing: {
            type: Boolean,
            default: false
        },
        leadTimeInHours: {
            type: Boolean,
            default: false
        },
        leadTimeLeftLabel: {
            type: String,
            default: null
        },
        leadTimeRightLabel: {
            type: String,
            default: null
        },
        showExpression: {
            type: Boolean,
            default: false
        },
        value: null
    },
    mounted() {
        this.unpack();
    },
    watch: {
        value(val) {
            if (this.cronExpression != val) {
                this.unpack();
            }
        }
    },
    computed: {
        filteredLeadTimeLeft() {
            return this.leadTimeInHours ? this.leadTimeLeft * 60 : this.leadTimeLeft;
        },
        filteredLeadTimeRight() {
            return this.leadTimeInHours ? this.leadTimeRight * 60 : this.leadTimeRight;
        }
    },
    methods: {
        getCronSplit(exp = null) {
            return exp == null ? this.cronExpression.split(/\s+/) : exp.split(/\s+/);
        },
        getDefaultExpression() {
            var exp = '0 0 * * 1';

            if (this.leadTimeLeftLabel != null) {
                if (this.leadTimeRightLabel != null) {
                    exp = `${exp} ${this.filteredLeadTimeLeft}-${this.filteredLeadTimeRight}`;
                }
                else {
                    exp = `${exp} ${this.filteredLeadTimeLeft}`;
                }
            }

            return exp;
        },
        reset() {
            this.cronExpression = this.getDefaultExpression();
            this.updateRaw();
            this.isSpecial = false;
        },
        unpack() {
            if (this.value == null || !this.isLengthyArray(this.getCronSplit(this.value), 4)) {
                this.cronExpression = this.getDefaultExpression();
                this.updateRaw();
                this.isSpecial = false;
            }
            else {
                this.cronExpression = this.value;
                this.isSpecial = false;
            }

            this.unpackHours();
            this.unpackDays();
            this.unpackMonths();
            this.unpackWeekdays();
            this.unpackLeadTime();
            
        },
        unpackDays() {
            var cronSplit = this.getCronSplit();
            if (this.isLengthyArray(cronSplit, 2) && !this.isSpecial) {
                var secTxt = cronSplit[2];
                
                if (secTxt == '*') {
                    this.everyWeek = true;
                }
                else {
                    var daySplit = secTxt.split(',');

                    if (daySplit.some(x => !this.weekOptions.some(y => y.value == x))) {
                        console.log('days special');
                        this.isSpecial = true;
                    }
                    else {
                        this.weeks = daySplit;
                    }
                }
            }
        },
        unpackHours() {
            var cronSplit = this.getCronSplit();
            if (this.isLengthyArray(cronSplit, 1) && !this.isSpecial) {
                var secTxt = cronSplit[1];

                if (secTxt.includes(',') || secTxt.includes('-') || secTxt.includes('/')) {
                    console.log('hours special');
                    this.isSpecial = true;
                }
                else {
                    var n = Number.parseInt(secTxt);

                    if (n == 0) {
                        this.halfDay = 'AM';
                        this.hour = 12;
                    }
                    else if (n > 12) {
                        this.halfDay = 'PM';
                        this.hour = n - 12;
                    }
                    else {
                        if (n == 12) {
                            this.hour = 12;
                        }
                        else {
                            this.hour = n;
                        }
                        
                        this.halfDay = 'AM';
                    }
                }
            }
        },
        unpackMonths() {
            var cronSplit = this.getCronSplit();
            if (this.isLengthyArray(cronSplit, 3) && !this.isSpecial) {
                var monTxt = cronSplit[3];

                if (monTxt == '*') {
                    this.everyMonth = true;
                }
                else {
                    console.log(monTxt);
                    var monSplit = monTxt.split(',');

                    if (monSplit.some(x => !this.monthOptions.some(y => y.value == x))) {
                        console.log('months special');
                        this.isSpecial = true;
                    }
                    else {
                        this.months = monSplit;
                    }
                }
            }
        },
        unpackWeekdays() {
            var cronSplit = this.getCronSplit();
            if (this.isLengthyArray(cronSplit, 4) && !this.isSpecial) {
                var monTxt = cronSplit[4];
                var opt = this.copyDeep(this.weekdayOpts);

                if (monTxt == '*') {
                    this.weekdays = opt.map(x => x.value);
                }
                else if (monTxt.includes('/') || monTxt.includes('-')) {
                    this.isSpecial = true;
                }
                else {
                    this.weekdays = monTxt.split(',');
                }
            }
        },
        unpackLeadTime() {
            var cronSplit = this.getCronSplit();
            if (this.isLengthyArray(cronSplit, 5) && !this.isSpecial) 
            {
                if (this.leadTimeLeftLabel != null) {
                    var txtSplit = cronSplit[5].split('-');
                    if (this.leadTimeRightLabel != null) {
                        //unpack both
                        if (txtSplit.length > 1) {
                            var n = Number.parseInt(txtSplit[1]);
                            this.leadTimeRight = this.leadTimeInHours ? Math.round(n / 60) : n;
                        }
                    }

                    if (txtSplit.length > 0) {
                        var r = Number.parseInt(txtSplit[0]);
                        this.leadTimeLeft = this.leadTimeInHours ? Math.round(r / 60) : r;
                    }
                }
            }
        },
        update() {
            var exp = '0 ';

            if (this.halfDay == 'AM') {
                if (this.hour == 12) {
                    exp = `${exp} ${0} `;
                }
                else {
                    exp = `${exp} ${this.hour} `;
                }
            }
            else {
                if (this.hour == 12) {
                    exp = `${exp} ${this.hour} `;
                }
                else {
                    exp = `${exp} ${this.hour + 12} `;
                }
            }

            if (this.everyWeek || !this.isLengthyArray(this.weeks)) {
                exp = `${exp} * `;
            }
            else {
                exp = `${exp} ${this.weeks.toString()} `;
            }

            if (this.everyMonth || !this.isLengthyArray(this.months)) {
                exp = `${exp} * `;
            }
            else {
                exp = `${exp} ${this.months.toString()} `;
            }
            
            if (!this.isLengthyArray(this.weekdays) || this.weekdays.length == 7) {
                exp = `${exp} * `;
            }
            else {
                exp = `${exp} ${this.weekdays.toString()} `;
            }

            exp = `${exp} ${this.filteredLeadTimeLeft}-${this.filteredLeadTimeRight}`;

            this.cronExpression = exp;
            this.updateRaw();

            this.isSpecial = false;
        },
        updateRaw() {
            this.$emit('input', this.cronExpression);
            this.$emit('change', this.cronExpression);
            console.log('updating raw');
            this.isSpecial = true;
        }
    }
}
</script>