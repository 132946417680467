<template>    
    <v-card :width="width" class="mx-auto" :flat="flat" :opacity="0.8" :height="height">
        <v-card-text class="pa-0">
        <slot name="everything" v-bind:item="asyncItem">
            <v-toolbar v-if="showToolbar" flat>
                <v-toolbar-title>
                    <slot name="icon">
                        <v-icon v-if="pageIcon" color="primary" left>{{ pageIcon }}</v-icon>
                    </slot>
                    {{ pageTitle }}
                </v-toolbar-title>
                <v-divider class="ml-4" inset vertical />
                <v-btn v-if="canRefresh" color="primary" icon @click="refresh"><v-icon>mdi-refresh</v-icon></v-btn>
                <v-btn v-if="!isNew && canDelete && canAuthEdit" icon color="primary" @click="deleteItem"><v-icon>mdi-delete</v-icon></v-btn>
                <slot name="actions"/>
            </v-toolbar>
            <v-form v-if="asyncItem" ref="form" dense>
                <slot v-bind="{ item: asyncItem, isNew }" />
                <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
                <v-alert v-model="showAlert" dismissible type="success">Saved</v-alert>
            </v-form>
            <v-overlay :value="isLoading" class="text-center">
                <v-progress-circular indeterminate size="64" />
                <p>Loading</p>
            </v-overlay>
        </slot>
        </v-card-text>
        <v-card-actions> 
            <v-slide-x-transition>
                <v-btn v-if="showSave" color="primary" @click="saveItem"><v-icon left>mdi-content-save</v-icon>{{ isNew ? 'Create' : 'Save' }}</v-btn>
            </v-slide-x-transition> 
        </v-card-actions>
        <v-dialog v-model="showDelete" max-width="500px">
                <v-card>
                    <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
            </v-dialog>
    </v-card>
</template>

<script>
export default {
    name: 'BT-Item',
    data: function() {
        return {
            asyncItem: null,
            doSave: false,
            errorMessage: null,
            id: null,
            isLoading: true,
            itemJSON: null,
            showAlert: false,
            showDelete: false,
            showError: false,
            snapshotItemJSON: null,
        }
    },          
    props: {
        canDelete: {
            type: Boolean,
            default: true
        },
        canRefresh: {
            type: Boolean,
            default: true
        },
        canSave: {
            type: Boolean,
            default: true
        },
        //toggling actions
        deleteToggle: {
            type: Boolean,
            default: false
        },
        flat: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: "100%"
        },
        isPopNav: {
            type: Boolean,
            default: true
        },
        ignoreParams: { //for when loading my profile, company, and settings where id is not needed
            type: Boolean,
            default: false
        },
        idParam: {
            type: String,
            default: 'id'
        },
        itemID: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        navigation: null,
        onFinalize: {
            type: Function,
            default: null
        },
        onGetItem: {
            type: Function,
            default: (item) => { return item }
        },
        onNew: {
            type: Function,
            default: () => { return { }}
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        proxyIDParam: {
            type: String,
            default: 'proxyID'
        },
        proxyID: {
            type: String,
            default: null
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        showToolbar: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: null
        },
        titleProp: {
            type: String,
            default: null
        },
        saveToggle: {
            type: Boolean,
            default: false
        },
        trackChanges: {
            type: Boolean,
            default: true
        },
        trackIgnore: {
            type: Array,
            default: null
        },
        useCopy: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: 'auto'
        }
    },
    watch: {
        asyncItem: {
            deep: true,            
            handler() {            
                this.updateChange();
            }            
        },
        deleteToggle: function() {
            this.deleteItem();
        },
        refreshToggle: function() {
            this.refresh();
        },
        itemID: function(val) {
            this.id = val;
            this.refresh();
        },
        loading: function(val) {
            this.isLoading = val;
            this.$forceUpdate();
        },
        saveToggle: function(val) {
            this.doSave = val;
        }
    },
    created() {
        this.isLoading = this.loading;
        this.$forceUpdate();

        if (this.itemID) {
            this.id = this.itemID;
        }        
        else if (this.idParam != null && this.$route.params[this.idParam]) {
            this.id = this.$route.params[this.idParam];
        }
        
        this.pullItem();
    },       
    computed: {
        canAuthEdit() {
            return this.$BlitzIt.auth.canEdit(this.navigation);
        },
        isChanged() {
            return this.itemJSON != this.snapshotItemJSON;
        },
        isNew() {
            return this.$route.params['id'] === 'new' || (this.itemID == 'new');
        },
        pageIcon() {
            var e = this.$BlitzIt.navigation.findItem(this.navigation);
            return e ? e.icon : null;            
        },
        pageTitle() {              
            if (this.title) {
                return this.title;
            }
            else if (this.titleProp && !this.isNew && this.asyncItem) {
                var title = this.getNestedValue(this.asyncItem, this.titleProp);
                if (title) {
                    return title;
                }
            }

            var e = this.$BlitzIt.navigation.findSingleDisplayName(this.navigation);
            return this.isNew ? 'New ' + e : e;
        },
        proxyCompanyID() {
            if (this.proxyID) {
                return this.proxyID;
            }
            else if (this.proxyIDParam && this.$route.params[this.proxyIDParam]) {
                return this.$route.params[this.proxyIDParam];
            }

            return null;
        },
        showSave() {
            return this.doSave || (this.canSave && this.canAuthEdit && (!this.trackChanges || this.isChanged));
        },
    },
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.isLoading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.isLoading = false;
            this.$forceUpdate();
        },
        closeDelete() {            
            this.showDelete = false;
        },
        createSnapshot(data) {
            var copy = this.copyDeep(data);
            
            if (this.isLengthyArray(this.trackIgnore)) {
                this.trackIgnore.forEach(x => {
                    delete copy[x];
                });
            }

            return JSON.stringify(copy);
        },
        deleteItem() {
            this.showDelete = true;
        },
        async deleteItemConfirm() {
            this.showDelete = false;
            this.showError = false;
            this.errorMessage = null;

            this.startLoading();
            try {
                await this.$BlitzIt.store.delete(this.navigation, this.asyncItem.id, this.proxyCompanyID);
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
                if (!this.showError) {
                    this.finalize();
                    this.$emit('deleted');
                }
            }
        },
        finalize(item) {
            if (this.onFinalize != null) {
                this.onFinalize(item);
            }
            else if (this.isPopNav) { //|| this.isNew) {
                this.$router.go(-1);
            }
            else {
                this.showAlert = true;

                if (item != null) {
                    this.asyncItem.id = item.id;
                    this.asyncItem.rowVersion = item.rowVersion;
                    this.snapshotItemJSON = this.createSnapshot(this.asyncItem); //JSON.stringify(this.asyncItem);
                    this.itemJSON = this.snapshotItemJSON;
                }

                this.$emit('saved');
            }
        },
        async pullItem(refresh = false) {
            if (!refresh && this.asyncItem != null) {
                return;
            }
            
            this.startLoading();
            try {
                var idParam = this.id;
                if ((this.asyncItem == null || refresh == true) && (this.ignoreParams || idParam != null)) {
                    var res = null;
                    if (idParam != null && idParam === 'new') {
                        res = this.onNew();
                    }
                    else {
                        res = await this.$BlitzIt.store.get(this.navigation, this.ignoreParams ? null : idParam, this.params, refresh, this.proxyCompanyID);
                    }

                    if (this.onPullSuccessAsync != null) {
                        this.asyncItem = await this.onPullSuccessAsync(this.useCopy ? this.copyDeep(res) : res, refresh);
                    }
                    else {
                        this.asyncItem = this.useCopy ? this.copyDeep(res) : res;
                    }

                    this.snapshotItemJSON = this.createSnapshot(this.asyncItem); //JSON.stringify(this.asyncItem);
                    this.itemJSON = this.snapshotItemJSON;
                    this.$emit('fetched', this.asyncItem);
                }
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
        },
        refresh() {
            this.showError = false;
            this.errorMessage = null;
            this.showAlert = false;
            this.doSave = false;
            this.pullItem(true);
        },
        async saveItem() {
            this.showError = false;
            if (this.$refs.form.validate()) {
                //save or create
                this.startLoading();
                var itemToSave = this.onGetItem(this.asyncItem);
                try {
                    if (this.isNew) {
                        this.finalize(await this.$BlitzIt.store.post(this.navigation, itemToSave, this.proxyCompanyID));
                    }
                    else {
                        this.finalize(await this.$BlitzIt.store.patch(this.navigation, itemToSave, this.proxyCompanyID));
                    }

                    this.doSave = false;
                }
                catch (err) {
                    this.formError(err);
                }
                finally {
                    this.endLoading();
                }
            }
        },
        updateChange() {
            this.itemJSON = this.createSnapshot(this.asyncItem);
        }
    }
}
</script>