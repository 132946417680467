<template>
    <v-card
        :class="cardClass"
        :color="transparent ? 'transparent' : null"
        :width="width"
        :loading="isLoading"
        :flat="flat"
        :dense="dense"
        :height="height"
        :max-height="maxHeight"
        :min-height="minHeight"
        :tile="tile">
        <v-card-title v-if="cardTitle != null">{{ cardTitle }}</v-card-title>
        <label v-if="label != null">{{ label }}</label>
        <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>  
        <slot name="items" v-bind:items="filteredItems">
            <v-list 
                v-if="isLengthyArray(filteredItems) || showAnyway"
                :color="transparent ? 'transparent' : null"
                :dense="dense"
                :flat="flat"
                :height="height"
                key="kk"
                :max-height="maxHeight"
                :min-height="minHeight"
                class="overflow-y-auto pa-0"
                :class="listClass">
                <v-list-item-subtitle v-if="subheader != null">{{ subheader }}</v-list-item-subtitle>
                <slot name="top" />
                <template v-for="(item, index) in filteredItems">
                    <slot name="listItem" v-bind:item="item" v-bind:index="index">
                        <v-list-item
                            :key="index"
                            :small="small"
                            :two-line="twoLine"
                            :three-line="threeLine"
                            :class="listItemClass"
                            :dense="dense"
                            :value="item">
                            <slot v-bind:item="item">
                                <div>{{ itemText ? getNestedValue(item, itemText) : item }}</div>
                            </slot>
                        </v-list-item>
                    </slot>
                    <v-divider v-if="dividers" :key="'d' + index" />
                </template>
                <slot name="bottom" />
            </v-list>
        </slot>
        <slot name="append" />
    </v-card>
</template>

<script>
export default {
    name: 'BT-List',
    data: () => {
        return {
            asyncItems: [],
            isLoading: true,
            errorMessage: null,
            showError: false,
            stage: null
        }
    },
    props: {
        cardClass: {
            type: String,
            default: null
        },
        cardTitle: {
            type: String,
            default: null
        },
        customURL: {
            type: String,
            default: null
        },
        dense: {
            type: Boolean,
            default: false
        },
        dividers: {
            type: Boolean,
            default: true
        },
        flat: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: '100%'
        },
        isSingle: {
            type: Boolean,
            default: false
        },
        itemID: {
            type: String,
            default: null
        },
        items: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: 'text'
        },
        label: {
            type: String,
            default: null
        },
        listClass: {
            type: String,
            default: null
        },
        listItemClass: {
            type: String,
            default: null
        },
        loading: {
            type: Boolean,
            default: false
        },
        maxHeight: {
            type: String,
            default: '100%'
        },
        minHeight: {
            type: String,
            default: null
        },
        navigation: {
            type: String,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        showAnyway: {
            type: Boolean,
            default: false
        },
        small: {
            type: Boolean,
            default: false
        },
        subheader: {
            type: String,
            default: null
        },
        threeLine: {
            type: Boolean,
            default: false
        },
        tile: {
            type: Boolean,
            default: false
        },
        transparent: {
            type: Boolean,
            default: false
        },
        twoLine: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: 'auto'
        }
    },
    watch: {
        items: function() {
            this.pullItems();
        },
        itemID: function() {
            this.refresh();
        },
        loading: function(val) {
            this.isLoading = val;
            this.$forceUpdate();
        },
        params: function() {
            this.refresh();
        },
        refreshToggle: function() {
            this.refresh();
        }
    },
    created() {
        this.isLoading = this.loading;
        this.pullItems();
    },
    computed: {
        filteredItems() {
            return this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
        },
    },
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        startLoading() {
            this.isLoading = true;
            this.$forceUpdate();
        },
        endLoading() {
            this.isLoading = false;
            this.$forceUpdate();
        },
        async pullItems(refresh = false) {
            if (this.items != null) {
                this.asyncItems = this.items;
                return;
            }

            if (this.navigation == null) {
                return;
            }

            try {
                this.startLoading();

                var cURL = null;
                if (this.customURL != null) {
                    cURL = this.customURL;
                    if (this.itemID != null) {
                        cURL = cURL.replace('{id}', this.itemID);
                    }
                }

                var res = null;
                if (this.isSingle) {
                    res = await this.$BlitzIt.store.get(this.navigation, this.itemID, this.params, refresh, null, cURL);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, this.params, refresh, null, cURL);
                }
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, refresh);
                }
                else {
                    this.asyncItems = res;
                }
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.endLoading();
            }
        },
        refresh() {
            this.pullItems(true);
            this.showError = false;
            this.errorMessage = null;
        },
    } 
}
</script>