<template>
    <v-slide-x-reverse-transition group hide-on-leave>
        <v-list-item 
            v-if="!isEditing && !isNew" 
            :dense="dense"
            :disabled="disabled"
            key="1"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>
                <v-list-item-title>{{ displayText }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="actionIcon != null && fieldAction != null">
                <v-btn
                    :disabled="disabled"
                    icon
                    small
                    @click="fieldAction">
                    <v-icon>{{ actionIcon }}</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
        <v-list-item 
            v-else
            :dense="dense"
            :disabled="disabled"
            key="2"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-text-field
                    v-model.number="str"
                    type="number"
                    :class="fieldClass"
                    :disabled="disabled"
                    :hide-details="hideDetails"
                    @input="update"
                    :label="label"
                    :prefix="prefix"
                    :prepend-icon="prependIcon"
                    :dense="dense" />
            </v-list-item-content>
            <v-list-item-action v-if="showAction">
                <slot name="action" v-bind:item="item" />
                <v-btn
                    v-if="fieldAction != null"
                    :disabled="disabled"
                    icon
                    small
                    :title="actionTitle"
                    @click="fieldAction">
                    <v-icon>{{ actionIcon }}</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-slide-x-reverse-transition>
</template>

<script>
export default {
    name: 'BT-Field-Number',
    data: function() {
        return {
            str: null
        }
    },
    props: {
        actionIcon: {
            type: String,
            default: null
        },
        actionTitle: {
            type: String,
            default: null
        },
        dense: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fieldAction: {
            type: Function,
            default: null
        },
        fieldClass: {
            type: String,
            default: null
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        icon: null,
        isEditing: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        label: null,
        prefix: {
            type: String,
            default: null
        },
        prependIcon: {
            type: String,
            default: null
        },
        showAction: {
            type: Boolean,
            default: false
        },
        textFilter: {
            type: String,
            default: 'toDisplayNumber'
        },
        value: {
            type: Number,
            default: 0
        },
    },
    watch: {
        value: function(val) {
            this.str = val;
        }
    },
    created() {
        this.str = this.value;
    },
    computed: {
        displayText() {
            return this.$options.filters[this.textFilter](this.str);
        },
    },
    methods: {
        update(val) {
            var res = Number.parseFloat(val);
            if (!Number.isNaN(res)) {
                this.$emit('input', res);
                this.$emit('change', res);
            }
        }
    }
}
</script>