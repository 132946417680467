<template>
    <v-text-field
        v-model.number="val"
        :disabled="disabled"
        :label="label"
        @change="update"
        type="number" />
</template>

<script>
export default {
    name: 'BT-Number',
    data: function() {
        return {
            val: null
        }
    },
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: null
        },
        reverse: {
            type: Boolean,
            default: false
        },
        value: {
            type: Number,
            default: null
        }
    },
    watch: {
        value: function(val) {
            if (this.val !== val) {
                this.val = this.reverse ? 0 - val : val;
            }
        }
    },
    mounted() {
        this.val = this.reverse ? 0 - this.value : this.value;
    },
    methods: {
        update(v) {
            var e = this.reverse ? 0 - v : v;

            this.$emit('input', e);
            this.$emit('change', e);
        }
    }
}
</script>