<template>
    <v-list-item
        :dense="dense"
        :class="fieldClass">
        <v-list-item-icon v-if="icon != null">
            <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
            <v-list-item-subtitle v-if="label != null">{{ label }}</v-list-item-subtitle>
            <v-list-item-title v-if="!horizontal">{{ nestVal(value, itemText, textFilter) }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action-text v-if="horizontal">{{ nestVal(value, itemText, textFilter) }}</v-list-item-action-text>
    </v-list-item>
</template>

<script>
export default {
    name: 'BT-Field-Object',
    props: {
        dense: {
            type: Boolean,
            default: true
        },
        fieldClass: {
            type: String,
            default: null
        },
        horizontal: {
            type: Boolean,
            default: false
        },
        icon: null,
        label: null,
        itemText: {
            type: String,
            default: null
        },
        textFilter: {
            type: String,
            default: null
        },
        value: null
    },
    methods: {
        nestVal(item, path, filter) {
            var t = item;

            if (path != null) {
                t = this.getNestedValue(item, path);
            }
            
            if (filter != null) {
                return this.$options.filters[filter](t);
            }
            else {
                return t;
            }
        },
    }
}
</script>