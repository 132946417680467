<template>
    <v-card
        :class="cardClass"
        :color="transparent ? 'transparent' : null"
        :dense="dense"
        :flat="flat"
        :height="height"
        :loading="isLoading"
        :max-height="maxHeight"
        :min-height="minHeight"
        :max-width="maxWidth"
        :width="width">
        <v-toolbar
            v-if="canSearchLocal || canSearch || canAdd || canDelete || label != null"
            :dense="dense"
            class="mx-0 px-0"
            :class="toolbarClass"
            :dark="toolbarClass != null">
            <slot name="title-left" />
            <v-toolbar-title v-if="!showSearch" key="1">{{ label }}</v-toolbar-title>
            <slot name="toolbar-left" />
            <v-spacer key="2"/>
            <slot name="toolbar-right" v-bind:items="asyncItems" />
            <v-slide-x-transition group>
                <v-text-field
                    v-if="canSearchLocal && showSearch"
                    :dense="dense"
                    flat
                    hide-details
                    hide-no-data
                    append-icon="mdi-magnify"
                    label="Find"
                    solo
                    v-model="searchInput"
                    key="4" />
                <v-text-field
                    v-else-if="canSearch && showSearch"
                    :dense="dense"
                    flat
                    hide-details
                    hide-no-data
                    solo
                    append-icon="mdi-magnify"
                    label="Find"
                    v-model="searchInput"
                    @click:append="refresh(false)"
                    key="5" />
            </v-slide-x-transition>
            <v-btn v-if="(canSearch || canSearchLocal) && showSearch" :small="small" :large="large" icon @click="refresh(true)" key="3" class="">
                <v-icon :small="small">mdi-close</v-icon>
            </v-btn> 
            <v-btn v-else-if="(canSearch || canSearchLocal)" :small="small" :large="large" icon @click="showSearch = !showSearch" key="6" class="">
                <v-icon :small="small">mdi-magnify</v-icon>
            </v-btn> 
            <v-btn v-if="showRefreshButton" :small="small" :large="large" icon @click="refresh" key="6.1" class="">
                <v-icon :small="small">mdi-refresh</v-icon>
            </v-btn>
            <v-btn v-if="canAdd" :small="small" :large="large" icon @click="onAdd" key="7" class="">
                <v-icon :small="small">mdi-plus</v-icon>
            </v-btn>
            <v-btn v-if="canDelete" :disabled="selectedItem == null" :small="small" :large="large" icon @click="onDelete" key="8" class="">
                <v-icon :small="small">mdi-delete</v-icon>
            </v-btn>
        </v-toolbar>
        <slot name="toolbar-below" v-bind:items="asyncItems" />
        <v-alert v-model="showError" dismissible type="error">{{ errorMessage }}</v-alert>
        <slot 
            name="start" 
            v-bind:items="filteredItems" 
            v-bind:allItems="asyncItems" />
        <slot name="items" 
            v-bind:items="filteredItems" 
            v-bind:onIntersect="endIntersect"
            :selectItem="update">
            <v-list
                v-if="isLengthyArray(filteredItems)"
                :color="transparent ? 'transparent' : null"
                :flat="flat"
                :height="height"
                :max-height="maxHeight"
                :min-height="minHeight"
                key="kkk"
                :dense="dense"
                class="overflow-y-auto ma-0 pa-0"
                :style="listStyle">
                <v-list-item-group
                    v-model="selectedItem"
                    :active-class="activeClass">
                    <template v-for="(fItem, index) in filteredItems">
                        <slot name="listItem" v-bind:item="fItem" v-bind:index="index" :select="() => update(fItem)">
                            <v-list-item
                                v-if="fItem != null"
                                :active-class="activeClass"
                                class="mouse-item"
                                :key="index"
                                :ripple="ripple"
                                :two-line="twoLine"
                                :three-line="threeLine"
                                :dense="dense"
                                :value="fItem"
                                @click="update(fItem)"
                                @mouseover="$emit('mouse-over-item', fItem)">
                                <slot v-bind:item="fItem" v-bind:index="index">
                                    <div>{{ itemText ? getNestedValue(fItem, itemText) : fItem }}</div>
                                </slot>
                            </v-list-item>
                        </slot>
                        <v-divider v-if="dividers" :key="'d' + index" />
                    </template>
                    
                </v-list-item-group>
                <div v-if="canLoadMore" class="text-center" v-intersect="endIntersect">Load More</div>
                <slot name="bottom" v-bind:items="filteredItems" v-bind:allItems="asyncItems">

                </slot>
            </v-list>
            <div v-else>
                <slot name="notFound" v-bind:refreshToggle="refreshToggle">
                
                </slot>
            </div>
        </slot>
        <slot 
            name="end" 
            v-bind:items="filteredItems" 
            v-bind:allItems="asyncItems" />
        <v-overlay :value="isLoading || loadingMsg != null" absolute class="text-center">
            <v-progress-circular indeterminate size="64" />
            <p>{{ loadingMsg || 'Loading' }}</p>
        </v-overlay>
    </v-card>
</template>

<script>
export default {
    name: 'BT-List-Endless',
    data: () => {
        return {
            asyncItems: [],
            currentTakeFrom: 0,
            errorMessage: null,
            isLoading: true,
            lastSelectedItem: null,
            loadingMsg: null,
            searchInput: null,
            searchString: null,
            selectedItem: null,
            showError: false,
            showSearch: false,
        }
    },
    props: {
        activeClass: {
            type: String,
            default: 'accent--text'
        },
        canAdd: {
            type: Boolean,
            default: false
        },
        canDelete: {
            type: Boolean,
            default: false
        },
        canRefresh: {
            type: Boolean,
            default: false
        },
        canSearchLocal: {
            type: Boolean,
            default: false
        },
        canSearch: {
            type: Boolean,
            default: true
        },
        canDeselect: {
            type: Boolean,
            default: true
        },
        cardClass: {
            type: String,
            default: null
        },
        customURL: {
            type: String,
            default: null
        },
        dark: {
            type: Boolean,
            default: null
        },
        dense: {
            type: Boolean,
            default: true
        },
        dividers: {
            type: Boolean,
            default: true
        },
        flat: {
            type: Boolean,
            default: true
        },
        getParams: {
            type: Function,
            default: null
        },
        height: {
            type: String,
            default: '100%'
        },
        items: {
            type: Array,
            default: null
        },
        itemProperties: {
            type: Array,
            default: null
        },
        itemText: {
            type: String,
            default: 'text'
        },
        itemValue: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: null
        },
        large: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        maxHeight: {
            type: String,
            default: '100%'
        },
        minHeight: {
            type: String,
            default: null
        },
        maxWidth: {
            type: String,
            default: null
        },
        navigation: {
            type: String,
            default: null
        },
        onAddAsync: {
            type: Function,
            default: null
        },
        onCanPull: {
            type: Function,
            default: null
        },
        onDeleteAsync: {
            type: Function,
            default: null
        },
        onFilter: {
            type: Function,
            default: null
        },
        onPullAsync: {
            type: Function,
            default: null
        },
        onPullMoreAsync: {
            type: Function,
            default: null
        },
        onPullSuccessAsync: {
            type: Function,
            default: null
        },
        params: {
            type: Object,
            default: null // () => { return { }}
        },
        refreshToggle: {
            type: Boolean,
            default: false
        },
        reload: {
            type: Boolean,
            default: false
        },
        returnObject: {
            type: Boolean,
            default: false
        },
        ripple: {
            type: Boolean,
            default: true
        },
        searchProps: {
            type: Array,
            default: null
        },
        selectedIDToggle: {
            type: String,
            default: null
        },
        small: {
            type: Boolean,
            default: true
        },
        takeAmount: {
            type: Number,
            default: 25
        },
        threeLine: {
            type: Boolean,
            default: false
        },
        toolbarClass: {
            type: String,
            default: null
        },
        twoLine: {
            type: Boolean,
            default: false
        },
        actualUsedHeight: {
            type: Number,
            default: null
        },
        useServerFilters: {
            type: Boolean,
            default: true
        },
        useServerPagination: {
            type: Boolean,
            default: false //true
        },
        transparent: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: 'auto'
        },
        value: null
    },
    watch: {
        items: function() {
            this.pullItems();
        },
        loading: function(val) {
            this.isLoading = val;
            this.$forceUpdate();
        },
        loadingMsg: function(val) {
            this.$emit('update:loadingMsg', val);
        },
        params: function() {
            this.pullItems(true, false);
        },
        refreshToggle: function() {
            this.refresh(true);
        },
        reload: function() {
            this.pullItems(false, false);
        },
        selectedFilters: function(val) {
            this.isFilterChanged = JSON.stringify(val) != JSON.stringify(this.originalFilters);
        },
        selectedIDToggle(val) {
            if (val != null && this.isLengthyArray(this.filteredItems)) {
                this.selectedItem = this.filteredItems.find(x => x.id == val);
            }
        }
    },
    created() {
        this.isLoading = this.loading;
        this.pullItems(true, false);
    },
    computed: {
        listStyle() {
            if (this.actualUsedHeight != null) {
                return `height: calc(100vh - ${this.actualUsedHeight}px)`
            }
            else {
                return null
            }
        },
        filteredItems() {
            var l = this.onFilter ? this.onFilter(this.asyncItems) : this.asyncItems;
            
            if (this.canSearchLocal && this.searchInput != null) {
                l = l.filter(x => this.hasSearch(x, this.searchInput, this.searchProps));
            }
            
            return l;
        },
        chipFilters() {
            if (this.useServerFilters && this.navigation != null) {
                return this.$BlitzIt.store.filters(this.navigation);
            }
            else {
                return this.filters ? this.filters : [];
            }
        },
        canLoadMore() {
            return !this.isLoading && this.isLengthyArray(this.asyncItems) && (this.asyncItems.length >= this.takeAmount);
        },
        showRefreshButton() {
            return this.canRefresh;
        }
    },
    methods: {
        formError(err) {
            this.showError = true;
            this.errorMessage = this.extractErrorDescription(err);
        },
        async endIntersect() {
            if (this.onPullMoreAsync != null) {
                var res = await this.onPullMoreAsync(this.asyncItems);
                if (res != null) {
                    if (Array.isArray(res)) {
                        this.asyncItems.push(...res);
                    }
                    else {
                        this.asyncItems.push(res);
                    }
                }
            }
        },
        async onAdd() {
            if (this.onAddAsync != null) {
                await this.onAddAsync(this.asyncItems);
            }
        },
        async onDelete() {
            if (this.onDeleteAsync != null) {
                await this.onDeleteAsync(this.asyncItems);
            }
            else if (this.selectedItem != null) {
                var ind = this.asyncItems.findIndex(x => x === this.selectedItem);
                if (ind >= 0) {
                    this.asyncItems.splice(ind, 1);
                }
                this.selectedItem = null;
            }
        },
        async pullItems(reset = false, pullNext = false) {
            if (this.onCanPull != null && !this.onCanPull()) {
                return;
            }

            if (this.items != null) {
                this.asyncItems = this.items;
                return;
            }

            if (this.navigation == null) {
                return;
            }

            if (reset) {
                this.currentTakeFrom = 0;
                this.asyncItems = [];
            }
            else if (pullNext) {
                this.currentTakeFrom = this.currentTakeFrom + this.takeAmount;
            }

            var paramObj = this.copyDeep(this.params) || {};

            if (this.searchString && this.searchString.length > 0) {
                paramObj.searchString = this.searchString;
            }
            if (this.selectedFilters && this.selectedFilters.length > 0) {
                var fList = [];                    
                for (let i = 0; i < this.selectedFilters.length; i++) {
                    const fItem = this.selectedFilters[i];
                    fList.push(this.chipFilters[fItem]);
                }
                paramObj.filterBy = fList.toString();
            }
            
            if (this.isLengthyArray(this.itemProperties)) {
                var props = this.itemProperties.map(x => this.fromCamelCase(x).replaceAll(' ', ''));
                
                // if (!props.some(z => z == 'IsInactive')) {
                //     props.push('IsInactive');
                // }

                // if (!props.some(z => z == 'ID')) {
                //     props.push('ID');
                // }

                paramObj.properties = props.toString();
            }

            if (this.useServerPagination) {
                //add takeAmount, etc
                paramObj.takeFrom = this.currentTakeFrom;
                paramObj.takeAmount = this.takeAmount;
            }

            if (this.getParams != null) {
                paramObj = Object.assign({}, { ...paramObj, ...this.getParams() });
            }

            try {
                this.loadingMsg = 'Loading';
                
                var cURL = null;
                if (this.customURL != null) {
                    cURL = this.customURL;
                    var cID = this.itemID || this.id;
                    if (cID != null) {
                        cURL = cURL.replace('{id}', cID);
                    }
                }

                var res = null;
                if (this.onPullAsync != null) {
                    res = await this.onPullAsync(this.navigation, paramObj, reset, null, cURL);
                }
                else {
                    res = await this.$BlitzIt.store.getAll(this.navigation, paramObj, reset, null, cURL);
                }
            
                if (this.onPullSuccessAsync != null) {
                    this.asyncItems = await this.onPullSuccessAsync(res, reset);
                }
                else {
                    this.asyncItems = res;
                }

                this.$emit('loaded', this.asyncItems);
            }
            catch (err) {
                this.formError(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        refresh(resetSearch = false) {
            this.currentTakeFrom = 0;
            if (resetSearch) {
                this.showSearch = false;
                this.searchInput = null;
            }
            this.searchString = this.searchInput;
            this.pullItems(true, false);
        },
        update(item) {
            var v = null;
            
            if (item == null) {
                return;
            }

            if (this.returnObject) {
                v = item;
            }
            else if (this.itemValue) {
                v = item[this.itemValue];
            }
            else {
                v = item;
            }

            if (item != null && item === this.lastSelectedItem && this.canDeselect) {
                this.lastSelectedItem = null;
            }
            else {
                this.lastSelectedItem = item;
            }
            
            this.$emit('select', this.lastSelectedItem);
            this.$emit('input', v);
            this.$emit('change', v);
        }
    } 
}
</script>