import moduleCreator from '~/tools/basic-store.js';
import Vue from 'vue';
import { extractErrorDescription } from '~helpers';
import { DateTime } from 'luxon/src/luxon';

export default {
    install(vue, { store }) {   
        const root = new Vue({ });
        const currentTimeStampDays = DateTime.utc().toSeconds() / 86400;
        
        this.get = async (navName, id = null, params = null, refresh = false, proxyID = null, url = null, useLocalCache = false) => {
            var storeRootPath = this.ensureModuleExists(navName);

            if (!params) {
                params = { includeDetails: true }
            }
            else if (params.includeDetails == undefined || params.includeDetails == null) {
                params.includeDetails = true;
            }

            //test if all request properties have been loaded
            if (!refresh && store.getters[storeRootPath + '/isItemLoaded']({ id, params, proxyID, url })) {
                return store.getters[storeRootPath + '/item']({ id, proxyID });
            }
            else {
                var prm = store.getters[storeRootPath + '/promise']({ params, proxyID, actionType: 'get', id: id, url: url, useLocalCache: useLocalCache });

                if (prm == null) {
                    prm = root.$BlitzIt.api.getById(navName, id, params, proxyID, url, useLocalCache);
                    store.commit(storeRootPath + '/addPromise', { promise: prm, params, proxyID, actionType: 'get', id: id, url: url, useLocalCache: useLocalCache });
                }

                var res = null;
                var locallyObtained = false;

                if (useLocalCache) {
                    //attempt to find from local first
                    try {
                        res = await prm;

                        if (res != null) {
                            if (res.meta == null ||
                                res.meta.storedOn == null ||
                                res.meta.storedOn > (currentTimeStampDays - root.$BlitzIt.navigation.findLocalCacheExpiryDays(navName))) {
                                //not timed out so proceed to use local version
                                console.log('locally obtained');
                                locallyObtained = true;
                                res = { data: res };
                            }
                            else {
                                console.log('locally out of date');
                                res = null;
                            }
                        }
                    }
                    catch (err) {
                        console.log('error trying to find locally');
                    }

                    if (res == null) {
                        store.commit(storeRootPath + '/removePromise', { params, proxyID, actionType: 'get', id: id, url: url, useLocalCache: useLocalCache });
                        prm = root.$BlitzIt.api.getById(navName, id, params, proxyID, url, false);
                        store.commit(storeRootPath + '/addPromise', { promise: prm, params, proxyID, actionType: 'get', id: id, url: url, useLocalCache: false });
                    }
                }

                try {
                    if (res == null) {
                        res = await prm;
                    }

                    store.commit(storeRootPath + '/updateItem', { item: res.data.data, isDetailed: params.includeDetails, proxyID: proxyID, url: url });
                    store.commit(storeRootPath + '/removePromise', { params, proxyID, actionType: 'get', id: id, url: url, useLocalCache: locallyObtained });

                    if (useLocalCache && !locallyObtained) {
                        //save response to local cache with timestamp
                        res.data.meta = { storedOn: currentTimeStampDays };
                        root.$BlitzIt.api.getLocalDb().setItem(root.$BlitzIt.api.getLocalDbKey(navName, params, proxyID, url, '/get', id), res.data);
                    }

                    return res.data.data;
                }
                catch (err) {
                    store.commit(storeRootPath + '/removePromise', { params, proxyID, actionType: 'get', id: id, url: url, useLocalCache: locallyObtained });
                    throw new Error(extractErrorDescription(err));
                }
            }
        }

        this.clear = (navName) => {
            var storeRootPath = this.ensureModuleExists(navName);
            store.commit(storeRootPath + '/clear');
        }

        this.getLocalCount = (navName) => {
            var storeRootPath = this.ensureModuleExists(navName);
            return store.getters[storeRootPath + '/count'];
        }

        this.getAll = async (navName, params, refresh = false, proxyID = null, url = null, useLocalCache = false) => {
            var storeRootPath = this.ensureModuleExists(navName);
            
            if (!params) {
                params = { includeDetails: false }
            }
            else if (params.includeDetails == undefined || params.includeDetails == null) {
                params.includeDetails = false;
            }

            if (!refresh && store.getters[storeRootPath + '/isLoaded']({ params, proxyID, url })) {
                store.commit(storeRootPath + '/loadIntoMemory', { params, proxyID, url });
                return store.getters[storeRootPath + '/items'];
            }
            else {
                //refresh
                var prm = store.getters[storeRootPath + '/promise']({ params, proxyID, actionType: 'getAll', id: null, url: url, useLocalCache: useLocalCache });
                var addAsSearched = false;

                if (prm == null) {
                    addAsSearched = true;
                    prm = root.$BlitzIt.api.getAll(navName, params, proxyID, url, useLocalCache);
                    store.commit(storeRootPath + '/addPromise', { promise: prm, params, proxyID, actionType: 'getAll', id: null, url: url, useLocalCache: useLocalCache });
                }

                var res = null;
                var locallyObtained = false;

                if (useLocalCache) {
                    //attempt to find from local first
                    try {
                        res = await prm;

                        if (res != null) {
                            if (res.meta == null ||
                                res.meta.storedOn == null ||
                                res.meta.storedOn > (currentTimeStampDays - root.$BlitzIt.navigation.findLocalCacheExpiryDays(navName))) {
                                //not timed out so proceed to use local version
                                console.log('locally obtained');
                                locallyObtained = true;
                                res = { data: res };
                            }
                            else {
                                console.log('locally out of date');
                                res = null;
                            }
                        }
                    }
                    catch (err) {
                        console.log('error trying to find locally');
                    }

                    if (res == null) {
                        //replace promise to a server request
                        store.commit(storeRootPath + '/removePromise', { params, proxyID, actionType: 'getAll', id: null, url: url, useLocalCache: useLocalCache });
                        prm = root.$BlitzIt.api.getAll(navName, params, proxyID, url, false);
                        store.commit(storeRootPath + '/addPromise', { promise: prm, params, proxyID, actionType: 'getAll', id: null, url: url, useLocalCache: false });
                    }
                }
                
                try {
                    if (res == null) {
                        res = await prm; //root.$BlitzIt.api.getAll(navName, params, proxyID);
                    }
                    
                    if (refresh) {
                        //first reset
                        store.commit(storeRootPath + '/clear');
                    }

                    if (store.getters[storeRootPath + '/filters'] == null && res.data.filters) {
                        store.commit(storeRootPath + '/updateFilters', res.data.filters);
                    }
                    
                    if (addAsSearched) {
                        store.commit(storeRootPath + '/addLoadedSearch', { params, results: res.data.data, count: res.data.count, proxyID, url });
                    }

                    store.commit(storeRootPath + '/removePromise', { params, proxyID, actionType: 'getAll', id: null, url: url, useLocalCache: locallyObtained });

                    if (useLocalCache && !locallyObtained) {
                        //save response to local cache
                        res.data.meta = { storedOn: currentTimeStampDays };
                        root.$BlitzIt.api.getLocalDb().setItem(root.$BlitzIt.api.getLocalDbKey(navName, params, proxyID, url, '/getAll'), res.data);
                    }

                    return res.data.data;
                }
                catch (err) {
                    console.log('oof');

                    store.commit(storeRootPath + '/removePromise', { params, proxyID, actionType: 'getAll', id: null, url: url });
                    throw new Error(extractErrorDescription(err));
                }
            }
        }

        this.getAllLocal = (navName, params, proxyID = null) => {
            var storeRootPath = this.ensureModuleExists(navName);
            
            if (!params) {
                params = { includeDetails: false }
            }
            else if (params.includeDetails == undefined || params.includeDetails == null) {
                params.includeDetails = false;
            }

            if (store.getters[storeRootPath + '/isLoaded']({ params, proxyID })) {
                store.commit(storeRootPath + '/loadIntoMemory', { params, proxyID });
                return store.getters[storeRootPath + '/items'];
            }
        }

        this.post = async (navName, data, proxyID = null, url = null) => {
                var storeRootPath = this.ensureModuleExists(navName);

                var prm = store.getters[storeRootPath + '/promise']({ params: data, proxyID, actionType: 'post', id: null, url: url });

                if (prm == null) {
                    prm = root.$BlitzIt.api.post(navName, data, proxyID, url);
                    store.commit(storeRootPath + '/addPromise', { promise: prm, params: data, proxyID, actionType: 'post', id: null, url: url });
                }

                try {
                    var res = await prm;

                    store.commit(storeRootPath + '/updateItem', { item: res.data.data, isDetailed: false, proxyID, url });
                    store.commit(storeRootPath + '/removePromise', { params: data, proxyID, actionType: 'post', id: null, url });

                    return res.data.data;
                }
                catch (err) {
                    store.commit(storeRootPath + '/removePromise', { params: data, proxyID, actionType: 'post', id: null, url });
                    throw new Error(extractErrorDescription(err));
                }
        }

        this.patch = async (navName, data, proxyID = null) => {
            var storeRootPath = this.ensureModuleExists(navName);

            var prm = store.getters[storeRootPath + '/promise']({ params: data, proxyID, actionType: 'patch', id: null });

            if (prm == null) {
                prm = root.$BlitzIt.api.patch(navName, data, proxyID);
                store.commit(storeRootPath + '/addPromise', { promise: prm, params: data, proxyID, actionType: 'patch', id: null });
            }

            try {
                var res = await prm;
                if (res.data.data != null && res.data.data.rowVersion != null) {
                    store.commit(storeRootPath + '/updateVersion', { item: res.data.data, proxyID });
                }

                store.commit(storeRootPath + '/removePromise', { params: data, proxyID, actionType: 'patch', id: null });

                return res.data.data;
            }
            catch (err) {
                store.commit(storeRootPath + '/removePromise', { params: data, proxyID, actionType: 'patch', id: null });
                throw new Error(extractErrorDescription(err));
            }
        }

        this.partialPatch = async (navName, data, proxyID = null) => {
            var storeRootPath = this.ensureModuleExists(navName);

            var prm = store.getters[storeRootPath + '/promise']({ params: data, proxyID, actionType: 'partialPatch', id: null });

            if (prm == null) {
                prm = root.$BlitzIt.api.patch(navName, data, proxyID, '/patch/partial');
                store.commit(storeRootPath + '/addPromise', { promise: prm, params: data, proxyID, actionType: 'partialPatch', id: null });
            }

            try {
                var res = await prm;
                if (res.data.data != null && res.data.data.rowVersion != null) {
                    store.commit(storeRootPath + '/updateVersion', { item: res.data.data, proxyID });
                }

                store.commit(storeRootPath + '/removePromise', { params: data, proxyID, actionType: 'partialPatch', id: null });

                return res.data.data;
            }
            catch (err) {
                store.commit(storeRootPath + '/removePromise', { params: data, proxyID, actionType: 'partialPatch', id: null });
                throw new Error(extractErrorDescription(err));
            }
        }

        this.restore = async (navName, id, proxyID = null) => {
            var storeRootPath = this.ensureModuleExists(navName);

            var prm = store.getters[storeRootPath + '/promise']({ params: id, proxyID, actionType: 'restore', id: id });

            if (prm == null) {
                prm = root.$BlitzIt.api.patch(navName, null, proxyID, `/patch/restore?id=${id}`);
                store.commit(storeRootPath + '/addPromise', { promise: prm, params: id, proxyID, actionType: 'restore', id: id });
            }

            try {
                var res = await prm;
                if (res.data.data != null && res.data.data.rowVersion != null) {
                    store.commit(storeRootPath + '/updateVersion', { item: res.data.data, proxyID });
                }

                store.commit(storeRootPath + '/removePromise', { params: id, proxyID, actionType: 'restore', id: id });

                return res.data.data;
            }
            catch (err) {
                store.commit(storeRootPath + '/removePromise', { params: id, proxyID, actionType: 'partialPatch', id: id });
                throw new Error(extractErrorDescription(err));
            }
        }

        this.delete = async (navName, id, proxyID = null) => {
                var storeRootPath = this.ensureModuleExists(navName);
                var eItem = store.getters[storeRootPath + '/item']({ id, proxyID, allSearches: true });

                if (eItem == null) {
                    return null;
                }

                var prm = store.getters[storeRootPath + '/promise']({ params: {}, proxyID, actionType: 'delete', id: id });

                if (prm == null) {
                    prm = root.$BlitzIt.api.delete(navName, { id: id, rowVersion: eItem.rowVersion }, proxyID);
                    store.commit(storeRootPath + '/addPromise', { promise: prm, params: {}, proxyID, actionType: 'delete', id: id });
                }

                try {
                    await prm;
                    store.commit(storeRootPath + '/removeItem', { id, proxyID });
                    store.commit(storeRootPath + '/removePromise', { params: {}, proxyID, actionType: 'delete', id: id });

                    root.$BlitzIt.tracker.untrack(eItem);
                    
                    return null;
                }
                catch (err) {
                    store.commit(storeRootPath + '/removePromise', { params: {}, proxyID, actionType: 'delete', id: id });
                    throw new Error(extractErrorDescription(err));
                }

                // if (eItem) {
                //     root.$BlitzIt.api.delete(navName, { id: id, rowVersion: eItem.rowVersion }, proxyID)
                //         .then(() => {
                //             store.commit(storeRootPath + '/removeItem', { id, proxyID });
                //             resolve();
                //         })
                //         .catch(err => {
                //             console.log(extractErrorDescription(err));
                //             reject(err);
                //         })
                //     }
        }

        this.deleteLocal = (navName, id, proxyID = null) => {
            var storeRootPath = this.ensureModuleExists(navName);                
            var eItem = store.getters[storeRootPath + '/item']({ id, proxyID });
            if (eItem) {
                store.commit(storeRootPath + '/removeItem', { id, proxyID });
            }
        }

        this.filters = (navName) => {
            var storeRootPath = this.ensureModuleExists(navName);
            var res = store.getters[storeRootPath + '/filters'];
            return res ? res : [];
        }

        this.hasFilters = (navName) => {
            var storeRootPath = this.ensureModuleExists(navName);
            return store.getters[storeRootPath + '/hasFilters'];
        }

        this.getSyncItems = async (navName, externalPartyIDs, itemIDs, refresh = false) => {
            var storeRootPath = this.ensureModuleExists(navName, null, 'itemID');
            var params = { includeDetails: true };
            var proxyID = null;

            if (!refresh && store.getters[storeRootPath + '/isLoaded']({ params, proxyID })) {
                store.commit(storeRootPath + '/loadIntoMemory', { params, proxyID });
                return store.getters[storeRootPath + '/items'];
            }
            else {
                var prm = store.getters[storeRootPath + '/promise']({ params, proxyID });
                if (prm == null) {
                    prm = root.$BlitzIt.api.get(navName, '/get/matches', { partyIDs: externalPartyIDs.toString(), ids: itemIDs.toString() });
                    store.commit(storeRootPath + '/addPromise', { promise: prm, params, proxyID });
                }

                try {
                    var res = await prm; //root.$BlitzIt.api.getAll(navName, params, proxyID);
                    
                    if (refresh) {
                        //first reset
                        store.commit(storeRootPath + '/clear');
                    }

                    store.commit(storeRootPath + '/addLoadedSearch', { params, results: res.data.data, count: res.data.count, proxyID });
                    store.commit(storeRootPath + '/removePromise', { params, proxyID });

                    return res.data.data;
                }
                catch (err) {
                    store.commit(storeRootPath + '/removePromise', { params, proxyID });
                    throw new Error(extractErrorDescription(err));
                }
            }
        }

        this.pushSyncItem = async (navName, itemID, externalPartyID) => {
                var storeRootPath = this.ensureModuleExists(navName, null, 'itemID');
                var proxyID = null;

                var prm = store.getters[storeRootPath + '/promise']({ params: { itemID, externalPartyID }, proxyID, actionType: 'pushSyncItem', id: itemID });

                if (prm == null) {
                    console.log('pushing sync item');
                    prm = root.$BlitzIt.api.post(navName, { id: itemID, isPull: false }, null, `/post?partyID=${externalPartyID}&sync=true`);
                    store.commit(storeRootPath + '/addPromise', { promise: prm, params: { itemID, externalPartyID }, proxyID, actionType: 'pushSyncItem', id: itemID });
                }

                try {
                    var res = await prm;

                    store.commit(storeRootPath + '/updateItem', { item: res.data.data[0], isDetailed: false, proxyID });
                    store.commit(storeRootPath + '/removePromise', { params: { itemID, externalPartyID }, proxyID, actionType: 'pushSyncItem', id: itemID });

                    return res.data.data[0];
                }
                catch (err) {
                    store.commit(storeRootPath + '/removePromise', { params: { itemID, externalPartyID }, proxyID, actionType: 'pushSyncItem', id: itemID });
                    throw new Error(extractErrorDescription(err));
                }
            //     root.$BlitzIt.api.post(navName, { id: itemID, isPull: false }, null, '/post?partyID=' + externalPartyID)
            //         .then(res => {
            //             store.commit(storeRootPath + '/updateItem', { item: res.data.data, isDetailed: false, proxyID })
            //             resolve(res.data.data);
            //         })
            //         .catch(err => {
            //             console.log(err);
            //             reject(err);
            //         })
            //   })  
        }

        this.ensureModuleExists = (navName, moduleSuffix = null, idPropName = null) => {            
            var storeName = root.$BlitzIt.navigation.findMultiName(navName);

            if (moduleSuffix) {
                storeName = storeName + moduleSuffix;
            }

            if (root.$BlitzIt.navigation.isTraining()) {
                storeName = 'training-' + storeName;
            }

            if (!store.hasModule(storeName)) {
                var id = idPropName == null ? root.$BlitzIt.navigation.findStoreID(navName) : idPropName;
                store.registerModule(storeName, moduleCreator(id));
            }

            return storeName;
        }

        this.clearData = () => {
            var navNames = root.$BlitzIt.navigation.getNavNames();

            for (var i = 0; i < navNames.length; i++) {
                var navName = navNames[i];
                var storeRootPath = root.$BlitzIt.navigation.findMultiName(navName);
                if (store.hasModule(storeRootPath)) {
                    store.commit(storeRootPath + '/clear');
                    store.unregisterModule(storeRootPath);
                }    
                
                storeRootPath = storeRootPath + '-sync-items';
                if (store.hasModule(storeRootPath)) {
                    store.commit(storeRootPath + '/clear');
                    store.unregisterModule(storeRootPath);
                }
            }
        }

    }
}