<template>
    <v-slide-x-reverse-transition group hide-on-leave>
        <v-list-item 
            v-if="!isEditing && !isNew"
            :dense="dense"
            key="1"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-checkbox
                    :label="label"
                    v-model="str"
                    :class="fieldClass"
                    :dense="dense"
                    :disabled="disabled"
                    hide-details
                    readonly />
            </v-list-item-content>
        </v-list-item>
        <v-list-item
            v-else
            :dense="dense"
            key="2"
            :class="fieldClass">
            <v-list-item-icon v-if="icon != null">
                <v-icon>{{ icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="pa-0">
                <v-checkbox
                    :label="label"
                    :disabled="disabled"
                    v-model="str"
                    :class="fieldClass"
                    @change="update"
                    :dense="dense"
                    :hide-details="hideDetails" />
            </v-list-item-content>
            <v-list-item-action v-if="showAction">
                <slot name="action" v-bind:item="item" />
                <v-btn
                    v-if="fieldAction != null"
                    :disabled="disabled"
                    icon
                    small
                    :title="actionTitle"
                    @click="fieldAction">
                    <v-icon>{{ actionIcon }}</v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-slide-x-reverse-transition>
</template>

<script>
export default {
    name: 'BT-Field-Checkbox',
    data: function() {
        return {
            str: null
        }
    },
    props: {
        actionIcon: {
            type: String,
            default: null
        },
        actionTitle: {
            type: String,
            default: null
        },
        value: null,
        dense: {
            type: Boolean,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        fieldAction: {
            type: Function,
            default: null
        },
        fieldClass: {
            type: String,
            default: 'my-auto py-auto'
        },
        hideDetails: {
            type: Boolean,
            default: false
        },
        icon: null,
        isEditing: {
            type: Boolean,
            default: false
        },
        isNew: {
            type: Boolean,
            default: false
        },
        label: null,
        showAction: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        value: function(val) {
            this.str = val;
        }
    },
    created() {
        this.str = this.value;
    },
    methods: {
        update(val) {
            this.$emit('input', val);
            this.$emit('change', val);
        }
    }
}
</script>